<template>
  <div class="d2-container-full">
    <div v-if="$slots.header" class="d2-container-full__header" ref="header">
      <slot name="header"/>
    </div>
    <div class="d2-container-full__body" ref="body" :style="{ padding }">
      <slot/>
    </div>
    <div v-if="$slots.footer" class="d2-container-full__footer" ref="footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
import scroll from './mixins/normal'
export default {
  name: 'd2-container-full',
  mixins: [
    scroll
  ],
  props: {
    padding: {
      type: String,
      required: false,
      default: '20px'
    }
  },
  mounted() {
    // 增加滚动事件监听
    this.addScrollListener()
  },
  beforeDestroy() {
    // 移除滚动事件监听
    this.removeScrollListener()
  }
}
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/d2admin/components/d2-container/components/d2-container-full.vue"
}
</vue-filename-injector>
