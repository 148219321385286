








































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/module/components/typed/lolth-typed-input-chooser.vue"
}
