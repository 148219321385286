




import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

declare interface ILolthBoolCellRendererParams extends ICellRendererParams {
  nullAsFalse?: boolean
}

@Component
export default class LolthBoolCellRenderer extends Vue implements ICellRendererComp {
  private params: ILolthBoolCellRendererParams
  private mValue: boolean = null

  getGui() {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: ILolthBoolCellRendererParams): boolean {
    this.mValue = params.value
    return true
  }
}
