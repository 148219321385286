




import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import { MdTableImportMappingPreset } from '@/module/graphql'

@Component
export default class CmpMdTablePresetCellRenderer extends Vue implements ICellRendererComp {
  private params!: ICellRendererParams
  private mPresets: MdTableImportMappingPreset[] = null

  get formattedValue() {
    return this.mPresets.map(v => v.name + ':' + (v.options && v.options.wholeMode ? '全量导入' : '增量导入')).join('\n')
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: ICellRendererParams): boolean {
    this.mPresets = params.value
    return true
  }
}
