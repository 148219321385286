import { Component } from 'vue-property-decorator'
import 'handsontable/dist/handsontable.full.css'
import Handsontable from 'handsontable'
import { BaseEditorComponent } from '@handsontable/vue'

@Component
export default class MixinHotTableEditor extends BaseEditorComponent {
  /** instance of Handsontable */
  hotInstance: Handsontable
  TD: HTMLElement
  /** row index */
  row: number = null
  /** column index */
  col: number = null
  /** column property name */
  prop: String = null
  value: any = null
  /** cellProperties (the cellProperties object for the edited cell) */
  cellProperties: any = null

  // Editor related props
  originalValue: any = null
  /** is in Editor mode or not */
  isEditor = false
  /** is Editor currently activated or not, editor */
  isVisible = false
  /** editor panel style */
  style: CSSStyleDeclaration & any = {
    position: 'absolute',
    background: '#fff',
    zIndex: '9999',
    border: '1px solid #000',
    width: 'auto'
  }

  stopMousedownPropagation(e: MouseEvent) {
    e.stopPropagation()
  }

  prepare(row: any, col: any, prop: any, TD: Element, originalValue: any, cellProperties: any): any {
    (BaseEditorComponent as any).options?.methods.prepare.call(
      this,
      row,
      col,
      prop,
      TD,
      originalValue,
      cellProperties
    )
    const tdPosition = TD.getBoundingClientRect()
    this.style.left = tdPosition.left + window.pageXOffset + 1 + 'px'
    this.style.top = tdPosition.top + window.pageYOffset + 1 + 'px'
    this.style.width = Handsontable.dom.innerWidth(this.TD) - 2 + 'px'
    this.style.height = Handsontable.dom.innerHeight(this.TD) - 2 + 'px'
    delete this.style.border
  }

  open() {
    this.isVisible = true
  }

  close() {
    this.isVisible = false
  }

  setValue(value: any) {
    this.value = value
  }

  getValue() {
    return this.value
  }

  getInstance() {
    return this.hotInstance || this.$data.hotInstance
  }

  getRowData() {
    const hotTable = this.getInstance()
    if (!hotTable || hotTable.isDestroyed) return {}
    return hotTable.getSourceDataAtRow(this.row)
  }
}
