






























import { Component } from 'vue-property-decorator'
import BaseAttrInput from './base-attr-input'

@Component
export default class AttrInputText extends BaseAttrInput {
  get syncValue() {
    return this.attribute.value
  }

  set syncValue(val: string) {
    this.attribute.value = val
    this.$emit('attr-value-changed', this.attribute.value)
  }

  get inputType() {
    return this.mdField.option?.multipleLine ? 'textarea' : 'text'
  }

  get optionItems() {
    return this.mdField.option?.optionItemsFromScript || this.mdField.option?.optionItems || []
  }

  get hasOptionItems() {
    return this.optionItems.length > 0
  }

  querySearch(queryString: string, cb: Function) {
    const result: string[] = queryString
      ? this.optionItems.filter(this.createFilter(queryString))
      : this.optionItems
    cb(result.map(item => {
      return {
        value: item
      }
    }))
  }

  createFilter(queryString: string) {
    return (optionItem: string) => {
      return (optionItem.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
    }
  }
}
