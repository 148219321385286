






































import { Component, Watch } from 'vue-property-decorator'
import BaseAttrInput from './base-attr-input'
import AttrInputText from './attr-input-text.vue'
import AttrInputNumber from './attr-input-number.vue'
import AttrInputBoolean from './attr-input-boolean.vue'
import AttrInputDate from './attr-input-date.vue'
import AttrInputExtend from './attr-input-extend.vue'
import AttrInputDict from './attr-input-dict.vue'
import AttrInputMultiDict from './attr-input-multi-dict.vue'
import AttrInputAttachment from './attr-input-attachment.vue'
import AttrTbd from './attr-tbd.vue'
import { MdField } from '@/module/graphql'
import _ from 'lodash'

/**
 * @event attr-value-changed
 * @event attr-changed
 */
@Component({
  components: {
    AttrInputText,
    AttrInputNumber,
    AttrInputBoolean,
    AttrInputDate,
    AttrInputExtend,
    AttrInputDict,
    AttrInputMultiDict,
    AttrInputAttachment,
    AttrTbd
  }
})
export default class MdAttrInput extends BaseAttrInput {
  @Watch('mdField')
  private onMdFieldChanged(newVal: MdField, oldVal: MdField) {
    if (newVal.id === oldVal.id) return
    this.attribute.value = null
  }

  private onAttrValueChanged(value: any) {
    _.set(this.attribute, 'changed', true)
    this.$emit('attr-value-changed', value)
    this.$emit('attr-changed', this.attribute)
  }
}
