













import { Component, Mixins, Prop } from 'vue-property-decorator'
import 'handsontable/dist/handsontable.full.css'
import MixinHotTableEditor from '@/module/components/hot-table/mixin-hot-table-editor'

@Component
export default class HotValuedEnumCell extends Mixins(MixinHotTableEditor) {
  @Prop({ required: true }) readonly enumGroup!: string
  @Prop() readonly excludedKeys!: string[] // 次优先
  @Prop() readonly includedKeys!: string[] // 最优先

  @Prop() readonly multiple?: boolean
  @Prop({ default: true }) readonly clearable?: boolean
}
