import Vue from 'vue'
import VueI18n from 'vue-i18n'
import util from '@/d2admin/libs/util'
import _ from 'lodash'

const locales = {
  en: require('./locales/en.json'),
  ja: require('./locales/ja.json'),
  'zh-chs': require('./locales/zh-chs.json'),
  'zh-cht': require('./locales/zh-cht.json')
}

Vue.use(VueI18n)

function loadLocaleMessages() {
  const messages = {}
  _.keys(locales).forEach(localeName => {
    const locale = locales[localeName]
    const localeElementUI = require(`element-ui/lib/locale/lang/${locale._element}`)
    messages[localeName] = {
      ...locale,
      ...localeElementUI ? localeElementUI.default : {}
    }
  })
  return messages
}

const messages = loadLocaleMessages()

Vue.prototype.$languages = Object.keys(messages).map(language => ({
  label: messages[language]._name,
  value: language
}))

const i18n = new VueI18n({
  locale: util.cookies.get('lang') || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages
})

export default i18n

export function mergeMessages(locale, messages) {
  i18n.setLocaleMessage(locale, messages)
}
