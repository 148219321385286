




































import { Component, Prop, Vue } from 'vue-property-decorator'
import { MdpFinSize, MdpFinSizeFragmentDoc } from '@/module/graphql'

import _ from 'lodash'
import gql from 'graphql-tag'
import CmpMdAttrForm from '@/module/master-data/views/editor/cmp-md-attr-form.vue'
import { ID } from '@/module/common/types'
import { Indexed } from '@/d2admin/delegate'

/* 产品结构编辑 */
@Component({
  components: { CmpMdAttrForm }
})
export default class CmpMdpFinSizeDetail extends Vue {
  @Prop() entityId: ID

  private mSize: MdpFinSize = null

  created() {
    this.mSize = {
      size: null,
      sizeLeagues: [],
      measures: [],
      weights: []
    } as MdpFinSize
    if (this.entityId) {
      this.loadData()
    }
  }

  private loadData() {
    this.$apollo.query({
      query: gql`query {
        MdpFinSize(id: ${this.entityId}) {
           ...mdpFinSize
        }
      }
      ${MdpFinSizeFragmentDoc}`
    }).then(resp => {
      this.mSize = resp.data.MdpFinSize
    })
  }

  formatJsonValues(jsonValue: Indexed) {
    if (!jsonValue) return ''
    return _.keys(jsonValue)
      .map(key => jsonValue[key] && `${key}:${jsonValue[key]}`)
      .filter(t => t).join(';')
  }
}
