
























import { Component, Mixins, Prop } from 'vue-property-decorator'
import CmpMdDataEditor from './cmp-md-data-editor.vue'
import CmpMdDataBomEditor from './cmp-md-data-bom-editor.vue'
import {
  MdAttribute,
  MdAttributeInput,
  MdData,
  MdDataBomEntryFragmentDoc,
  MdDataWithAttrsFragmentDoc
} from '@/module/graphql'
import MixinSaveMdData from '@/module/master-data/views/mixin-save-md-data'
import gql from 'graphql-tag'
import _ from 'lodash'

@Component({
  components: {
    CmpMdDataEditor, CmpMdDataBomEditor
  }
})
export default class IcmpMdDataEditorDialog extends Mixins(MixinSaveMdData) {
  @Prop() readonly structureKey?: string
  @Prop() readonly mdDataId?: number
  @Prop() readonly setBom?: boolean
  @Prop() readonly copiedAttrs?: MdAttribute[]
  @Prop() readonly presetAttrs?: MdAttributeInput[]

  private visible = true
  private mBomMode = false
  private mRootMdData: MdData = null
  private mIsRootMdDataNew: boolean = false

  get resolvePresetAttrs(): MdAttributeInput[] {
    if (this.presetAttrs) return this.presetAttrs
    return !this.mdDataId && this.$env === 'development'
      ? [] : []
  }

  created() {
    if (this.mdDataId) {
      this.$apollo.query({
        query: gql`query {
        MdData(id: "${this.mdDataId}") {
          ...mdDataWithAttrs
          bomEntries {
            ...mdDataBomEntry
          }
        }
      }
      ${MdDataWithAttrsFragmentDoc}
      ${MdDataBomEntryFragmentDoc}`
      }).then(data => {
        this.mRootMdData = data.data.MdData
        this.mBomMode = this.setBom || false
      })
    } else if (!_.isEmpty(this.copiedAttrs)) {
      this.mRootMdData = {
        attributes: this.copiedAttrs
      } as MdData
    }
  }

  private switchToSetBomMode(mdData: MdData, attributes: MdAttributeInput[], isNew: boolean) {
    if (isNew) {
      this.saveMdData(mdData, attributes).then(mdData => {
        this.mRootMdData = mdData
        this.mBomMode = true
        this.mIsRootMdDataNew = isNew
      })
    } else {
      this.$apollo.query({
        query: gql`query fetchMdData($code: String) {
        MdData(code: $code) {
          bomEntries { ...mdDataBomEntry }
        }
      }
      ${MdDataBomEntryFragmentDoc}`,
        variables: {
          code: mdData.code
        }
      }).then(data => {
        mdData.bomEntries = data.data.MdData.bomEntries
        this.mRootMdData = mdData
        this.mBomMode = true
        this.mIsRootMdDataNew = isNew
      })
    }
  }

  private saveRootMdData(mdData: MdData, attributes: MdAttributeInput[], isNew: boolean) {
    this.saveMdData(mdData, attributes).then(mdData => {
      this.mRootMdData = mdData
      this.mIsRootMdDataNew = isNew
      return mdData
    }).then(mdData => {
      this.$emit('submit', mdData)
      this.visible = false
    })
  }

  private onBomSubmit(mdData: MdData) {
    this.$emit('submit', mdData)
    this.visible = false
  }
}
