



























import { Component, Watch } from 'vue-property-decorator'
import BaseAttrInput from './base-attr-input'
import { MdExtendData, FilterCondition } from '@/module/graphql'
import copy from 'copy-to-clipboard'
import { gql } from 'apollo-boost'
import { showMdExtendDataDetail } from '@/module/master-data/md-util'
import LocalDbDao from '@/module/common/local-db-dao'

import { stripFilterViewDef } from '@/module/butler/butler-util'

@Component
export default class AttrInputExtend extends BaseAttrInput {
  private mSelectedValue: MdExtendData = null
  private mSelecting = false
  private mOptionFilterCondition: FilterCondition = null

  get syncedValue(): any {
    if (this.mSelectedValue) return this.mSelectedValue
    if (this.attribute.value) {
      return {
        id: this.attribute.value,
        code: this.attribute.value
      }
    }
    return null
  }

  set syncedValue(val: any) {
    if (val) {
      this.$apollo.query({
        query: gql`query {
          MdExtendData(modelName: "${this.mdField.option.modelName}",
                       dataId: "${val.id || val.code}") {
            dataId, dataKey, dataValue, dataNote
          }
        }`
      }).then(data => {
        this.mSelectedValue = data.data.MdExtendData
        this.attribute.value = this.mSelectedValue && this.mSelectedValue.dataId
        if (this.mSelecting) {
          this.$emit('attr-value-changed', this.mSelectedValue)
        }
      })
    } else {
      this.mSelectedValue = null
      this.attribute.value = null
    }
    this.mSelecting = false
  }

  get explorerType() {
    return LocalDbDao.getExplorerType('MdExtendData')
  }

  get modelName() {
    return this.mdField.option.modelName
  }

  get extendExplorerType() {
    return LocalDbDao.getExplorerType(this.modelName)
  }

  mounted() {
    this.setupBindingFields()
    this.convertOptionFilterCondition()
    this.initValue()
  }

  itemLabelFormatter(item: any) {
    return this.explorerType.formatLabel(item) ||
        this.extendExplorerType.formatLabel(item)
  }

  private initValue() {
    if (!this.mdField.option.modelName) {
      throw new Error('无效的扩展表字段, 未指定扩展表')
    }
    this.$set(this.attribute.extra, 'modelName', this.mdField.option.modelName)
  }

  copyToClipboard() {
    if (!this.syncedValue) return
    copy(this.explorerType.formatLabel(this.syncedValue))
  }

  showDetail() {
    showMdExtendDataDetail(this, this.mdField.option.modelName,
      this.syncedValue)
  }

  @Watch('mdField.option.modelName')
  onModelNameChange() {
    this.attribute.value = null
    this.mSelectedValue = null
  }

  @Watch('mdField.option.dictFilter', { deep: true })
  convertOptionFilterCondition() {
    if (this.mdField.option?.dictFilter) {
      this.$apollo.query({
        query: gql`query convertMdExtendDataFilterCondition($filterCondition: FilterCondition!) {
          convertMdExtendDataFilterCondition(
            modelName: "${this.modelName}",
            filterCondition: $filterCondition)
        }`,
        variables: {
          filterCondition: stripFilterViewDef(this.mdField.option.dictFilter)
        }
      }).then(resp => {
        this.mOptionFilterCondition = resp.data.convertMdExtendDataFilterCondition
      })
    } else {
      this.mOptionFilterCondition = null
    }
  }
}
