




































































export default function (Component) {
  Component.options.__source = "src/module/plugin-tech-invest/views/components/cmp-kti-talent-cell-renderer.vue"
}
