import { fabric } from 'fabric'

export interface IFabricLineArrowOptions extends fabric.ILineOptions {
  arrowWeight: number;
}

export class FabricLineArrow extends fabric.Line implements IFabricLineArrowOptions {
  public constructor(points?: number[], options?: IFabricLineArrowOptions) {
    options = Object.assign(options || {}, {
      strokeWidth: 4,
      fill: 'red',
      stroke: 'red',
      originX: 'center',
      originY: 'center',
      hasBorders: false,
      hasControls: false,
      selectable: false,
      objectCaching: false,
      perPixelTargetFind: true,
      arrowWeight: 2
    })
    super(points, options)
    this.arrowWeight = options.arrowWeight
  }

  render(ctx: CanvasRenderingContext2D) {
    super.render(ctx)
    let p = this.calcLinePoints()
    let xDiff = this.x2 - this.x1
    let yDiff = this.y2 - this.y1
    let angle = Math.atan2(yDiff, xDiff)
    this.drawArrow(ctx, angle, this.x2, this.y2)
    ctx.save()
  }

  drawArrow(ctx: CanvasRenderingContext2D,
            angle: number, xPos: number, yPos: number) {
    ctx.save()

    ctx.translate(xPos, yPos)
    ctx.rotate(angle)
    ctx.beginPath()

    ctx.moveTo(this.strokeWidth, 0)
    ctx.lineTo(-this.strokeWidth * this.arrowWeight, this.strokeWidth * this.arrowWeight)
    ctx.lineTo(-this.strokeWidth * this.arrowWeight, -this.strokeWidth * this.arrowWeight)
    ctx.closePath()

    ctx.fillStyle = this.stroke
    ctx.fill()
    ctx.restore()
  }

  public readonly arrowWeight: number
}
