












































































































































export default function (Component) {
  Component.options.__source = "src/module/master-data/views/components/input/cmp-md-dictionary-entry-input-select.vue"
}
