// Vue
import 'vue-class-component/hooks'
import Vue from 'vue'
import { ThisTypedComponentOptionsWithRecordProps } from 'vue/types/options'
import App from './App.vue'
import '@/d2admin/missing-types'
// D2admin
import d2Admin from '@/d2admin/plugin/d2admin'
import moduleLoader from '@/d2admin/module'
import store from '@/store'
import i18n from './i18n'
// 注册通用组件
import '@/module/components'
// 代理 - 登录
import loginDelegate from '@/d2admin/delegate/login'
import loginImpl from '@/module/butler/api/auth'
// 代理 - Axios
import axios from '@/d2admin/plugin/axios'
import axiosDelegate from '@/d2admin/delegate/axios'
import axiosImpl from '@/module/common/api/axios'
// 代理 - menu
import menuDelegate from '@/d2admin/delegate/menu'
import menuImpl from '@/module/common/api/menu'
// 路由设置
import router from './router'
// 3rd party vue components
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import 'ag-grid-enterprise'
import 'reflect-metadata'

import 'v-contextmenu/dist/index.css'
import contextMenu from 'v-contextmenu'

import 'handsontable/dist/handsontable.full.css'
import { HotColumn, HotTable } from '@handsontable/vue'
import CodeEditor from 'simple-code-editor'
import 'highlight.js/styles/atom-one-light.css' // import code style
import highlight from 'highlight.js/lib/core'
import { Pane, Splitpanes } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import VueApollo from 'vue-apollo'
import ApolloClient, { ApolloError } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { buildAxiosFetch } from '@lifeomic/axios-fetch'
import { AxiosRequestConfig } from 'axios'
import { promptServerError } from '@/module/common/util/error-util'
import { frameInRoutes } from '@/router/routes'
import ButlerVuePlugin from '@/module/butler/vue-plugin'
import LocalDbDao from '@/module/common/local-db-dao'
import { GLOBAL_ENV } from '@/d2admin/setting'

Vue.config.productionTip = false

//* **************************************************************************
// D2Admin
//* **************************************************************************
Vue.use(d2Admin)

loginDelegate.set(loginImpl)
axiosDelegate.set(axiosImpl)
menuDelegate.set(menuImpl)

//* **************************************************************************
// Vue Plugins
//* **************************************************************************
Vue.component(AgGridVue.name, AgGridVue)
Vue.use(contextMenu)
Vue.component(HotTable.name, HotTable)
Vue.component(HotColumn.name, HotColumn)
Vue.component('CodeEditor', CodeEditor)
Vue.component('splitpanes', Splitpanes)
Vue.component('pane', Pane)
highlight.registerLanguage('json', require('highlight.js/lib/languages/json'))
highlight.registerLanguage('sql', require('highlight.js/lib/languages/sql'))
highlight.registerLanguage('pgsql', require('highlight.js/lib/languages/pgsql'))
highlight.registerLanguage('java', require('highlight.js/lib/languages/java'))
highlight.registerLanguage('markdown', require('highlight.js/lib/languages/markdown'))

//* **************************************************************************
// vue-apollo
//* **************************************************************************
Vue.use(VueApollo)

//* **************************************************************************
// My plugins
//* **************************************************************************
Vue.use(ButlerVuePlugin)

// fake default graphql endPoint, for initializing apolloProvider
const apolloClient = new ApolloClient({
  link: createHttpLink({
    uri: (GLOBAL_ENV.API_URL || process.env.VUE_APP_API) + 'graphql'
  }),
  cache: new InMemoryCache()
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  errorHandler: (error: ApolloError) => {
    promptServerError(error)
  }
})

export async function registerApolloClient(endPoint: string) {
  const httpLink = createHttpLink({
    uri: endPoint,
    fetch: buildAxiosFetch(axios, (config: AxiosRequestConfig) => {
      config.validateStatus = status => status >= 200 && status < 300
      return config
    })
  })

  // build client
  apolloProvider.defaultClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache'
      },
      query: {
        fetchPolicy: 'no-cache'
      },
      mutate: {
        fetchPolicy: 'no-cache'
      }
    }
  })
}

//* **************************************************************************
// vueOptions
//* **************************************************************************

const vueOptions: ThisTypedComponentOptionsWithRecordProps<Vue, any, any, any, any> = {
  router,
  store,
  i18n,
  apolloProvider,
  render: h => h(App),
  created() {
    // ModuleHook启动回调
    if (LocalDbDao.isUserLoggedIn()) {
      moduleLoader.hooks.forEach(hook => {
        hook.onUserEntered && hook.onUserEntered()
      })
    }
    registerApolloClient('/graphql')
    // 处理路由 得到每一级的路由设置
    this.$store.commit('d2admin/page/init', frameInRoutes)
  },
  mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow')
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch('d2admin/account/load')
    // 获取并记录用户 UA
    this.$store.commit('d2admin/ua/get')
    // 初始化全屏监听
    this.$store.dispatch('d2admin/fullscreen/listen')
  }
}

// ModuleHook加载回调
moduleLoader.hooks.forEach(hook => {
  if (hook.onModuleLoaded) hook.onModuleLoaded(vueOptions)
})

Vue.config.devtools = true
export default new Vue(vueOptions).$mount('#app')
