import _ from 'lodash'
import { Message } from 'element-ui'
import { extractGraphQLError } from '@/module/common/util/graphql-util'
import { HandledError } from '@/d2admin/plugin/util'

export function promptServerError(error: Error | Error[] | PromiseRejectionEvent) {
  // convert PromiseRejectionEvent to Error
  let castedError: Error
  if (_.isArray(error) && error.length > 0) {
    castedError = <Error>error[0]
  } else if (('reason' in error && _.isError(error.reason) && 'name' in error.reason && 'message' in error.reason)) {
    castedError = <Error>error.reason
  } else {
    castedError = <Error>error
  }
  if (castedError instanceof HandledError) return
  let op = '请求'
  let reason = castedError.message
  const errors = extractGraphQLError(castedError)
  if (errors.length > 0) {
    const extError = errors[0]
    reason = `[${extError.ec}]${extError.msg}`
  }
  reason = reason || ''

  if (error instanceof ProgressEvent) {
    op = '上传'
  }

  Message({
    message: `数据${op}失败${reason ? '. ' : ''}${reason}`,
    type: 'error',
    duration: 5 * 1000,
    showClose: true
  })
}

export function hasError(error: Error): boolean {
  if (!error) return false
  promptServerError(error)
  return true
}

export function exitIfError(error: Error) {
  if (!error) return
  promptServerError(error)
  throw error
}
