





















import { Component, Ref, Vue } from 'vue-property-decorator'
import ElDialog from 'element-ui/lib/dialog'
import ElPopup from 'element-ui/lib/utils/popup'

@Component({
  props: {
    ...ElPopup.props,
    ...ElDialog.props
  }
})
export default class LolthDialog extends Vue {
  @Ref() readonly vDialog: Vue
  @Ref() readonly vExpendButton: Vue

  private mFullscreen = false

  created() {
    this.mFullscreen = this.$props.fullscreen || false
  }

  onOpened() {
    this.$emit('opened')
    const btnClose = this.vDialog.$el.querySelector('.el-dialog__headerbtn')
    btnClose.before(this.vExpendButton.$el)
    if (this.$props.title) { this.$matomo?.trackPageView(this.$props.title) }
  }
}
