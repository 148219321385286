





























export default function (Component) {
  Component.options.__source = "src/module/components/hot-table/hot-valued-enum-cell.vue"
}
