












import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import UserInfo from '@/module/butler/views/components/cmp-user-info.vue'

@Component({
  name: 'cmp-user-info-dialog',
  components: {
    UserInfo
  }
})
export default class CmpUserInfo extends Vue {
  @Prop() accountId: number
  @PropSync('visible')
  syncedVisible!: boolean
}
