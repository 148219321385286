



















































import { Component } from 'vue-property-decorator'
import BaseAttrInput from './base-attr-input'
import { FilterCondition, FilterType, MdDictionaryEntry, SortColumn, SortType } from '@/module/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'
import _ from 'lodash'
import copy from 'copy-to-clipboard'
import LocalDbDao from '@/module/common/local-db-dao'
import { CascaderNode } from '@/module/components/types'
import gql from 'graphql-tag'
import LolthTypedInputSelect from '@/module/components/typed/lolth-typed-input-select'
import { isValid_ } from '@/module/common/filter-condition-extend'

@Component
export default class AttrInputDict extends BaseAttrInput {
  private mGroup: CascaderNode[] = null
  private mChooserVisible = false

  get syncedValue(): Maybe<MdDictionaryEntry> {
    if (_.isPlainObject(this.attribute.value)) { // value是MdDictionaryEntry
      return this.attribute.value as Maybe<MdDictionaryEntry>
    } else if (_.isInteger(this.attribute.value) || _.isInteger(parseInt(this.attribute.value))) {
      return { // value是long
        id: _.toString(this.attribute.value),
        key: this.attribute.extra.dataKey,
        value: this.attribute.extra.dataValue,
        valueForShort: this.attribute.extra.valueForShort,
        seq: this.attribute.extra.seq
      }
    }
    return null
  }

  set syncedValue(val: Maybe<MdDictionaryEntry>) {
    this.attribute.value = !val ? null : val
  }

  get filterCondition(): FilterCondition {
    if (!this.mdField.option.dictId) {
      throw new Error('无效的选项表字段, 未指定选项表')
    }

    const filterCondition = {
      filterType: FilterType.Number,
      field: 'dictionary.id',
      operator: 'equals',
      value: this.mdField.option.dictId
    }

    return isValid_(this.mdField.option?.dictFilter)
      ? {
        filterType: FilterType.And,
        conditions: [
          filterCondition,
          this.mdField.option.dictFilter
        ]
      } : filterCondition
  }

  get sortByColumn(): SortColumn[] {
    return [{
      field: 'seq',
      sort: SortType.Asc
    }]
  }

  get explorerType() {
    return LocalDbDao.getExplorerType('MdDictionaryEntry')
  }

  mounted() {
    if (!this.mdField.option.chooser) {
      this.$apollo.query({
        query: gql`query {
          MdDictionary(id: ${this.mdField.option.dictId}) {
            groups
          }
        }`
      }).then(data => {
        this.mGroup = data.data.MdDictionary.groups || null
      })
    }
  }

  copyToClipboard() {
    if (!this.syncedValue) return
    copy(this.explorerType.formatLabel(this.syncedValue))
  }

  onChooserSelect(dictEntries: MdDictionaryEntry[]) {
    if (!dictEntries || dictEntries.length <= 0) return
    this.syncedValue = dictEntries[0]
  }

  resetSelectOptions() {
    const vSelect = this.$refs.vSelect as LolthTypedInputSelect
    vSelect?.resetOptionsLoaded()
  }
}
