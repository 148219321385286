import axios from '@/d2admin/plugin/axios/index'
import qs from 'qs'
import { LoginDelegateDefault } from '@/d2admin/delegate/login'
import { SyncData } from '@/module/common/types'
import LocalDbDao, { DB_KEYS } from '@/module/common/local-db-dao'
import { Indexed, delegate } from '@/d2admin/delegate'
import fileService from '@/module/common/file-service'
import LoginParam = delegate.LoginParam
import LoginResult = delegate.LoginResult

export const refreshToken = async (): Promise<string> => {
  let resp: any = await axios.post('/auth/refresh-token',
    qs.stringify({ refreshToken: LocalDbDao.getRefreshToken() })
  )

  await LocalDbDao.saveAccessToken(resp[DB_KEYS.DB_KEY_ACCESS_TOKEN])
  await LocalDbDao.saveRefreshToken(resp[DB_KEYS.DB_KEY_REFRESH_TOKEN])
  return resp[DB_KEYS.DB_KEY_ACCESS_TOKEN]
}

export const syncData = async (): Promise<SyncData> => {
  const localSyncData = LocalDbDao.loadSyncData(false)
  // Object.setPrototypeOf(localSyncData, SyncData.prototype)
  const fundamentalData: SyncData = await axios.post('/butler/sync-fundamental-data', localSyncData.getEtags())
  const userData: SyncData = await axios.post('/butler/sync-user-data', localSyncData.getEtags())
  const syncData = Object.assign(userData, fundamentalData)
  await LocalDbDao.saveSyncData(syncData)
  fileService.init()
  return syncData
}

class LoginDelegateImpl extends LoginDelegateDefault {
  async login(loginParam: LoginParam): Promise<LoginResult> {
    let resp: any
    if (loginParam.loginToken) {
      resp = await axios.post('/auth/sign-in-by-token',
        qs.stringify({
          loginToken: loginParam.loginToken,
          ewxUserId: loginParam.ewxUserId
        })
      )
    } else if (loginParam.mobile) {
      resp = await axios.post('/auth/sign-in-by-sms',
        qs.stringify({
          mobile: loginParam.mobile,
          captcha: loginParam.captcha,
          ewxUserId: loginParam.ewxUserId
        })
      )
    } else if (loginParam.byEwx) {
      resp = await axios.post('/auth/sign-in-by-ewx',
        qs.stringify({
          ewxUserId: loginParam.ewxUserId,
          ewxUserMobile: loginParam.ewxUserMobile
        })
      )
    } else {
      resp = await axios.post('/auth/sign-in',
        qs.stringify({
          username: loginParam.username,
          password: loginParam.password,
          ewxUserId: loginParam.ewxUserId
        })
      )
    }

    const loginTokens: Indexed = await LocalDbDao.loadLoginTokens()
    if (loginParam.rememberMe && resp['login-token']) {
      loginTokens[resp.principal.name] = resp['login-token']
    } else if (!loginParam.loginToken) {
      // 用户密码登录, 且没有选择保留账号, 则从本地缓存删除loginToken
      delete loginTokens[resp.principal.name]
    }

    const saveToGlobal: any = {}
    saveToGlobal[DB_KEYS.DB_KEY_LOGIN_TOKENS] = loginTokens

    let loginResult: LoginResult = {
      uuid: resp.principal.identity,
      name: resp.principal.name,
      saveToCookie: {},
      saveToPrivate: {
        [DB_KEYS.DB_KEY_PRINCIPAL]: resp.principal,
        [DB_KEYS.DB_KEY_ACCESS_TOKEN]: resp[DB_KEYS.DB_KEY_ACCESS_TOKEN],
        [DB_KEYS.DB_KEY_REFRESH_TOKEN]: resp[DB_KEYS.DB_KEY_REFRESH_TOKEN]
      },
      saveToGlobal
    }
    LocalDbDao.saveLoggedInSession(loginResult)
    return loginResult
  }
}

export default new LoginDelegateImpl()
