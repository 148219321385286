



































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { MdDataBomTemplateEntry } from '@/module/graphql'
import { Message } from 'element-ui'

@Component
export default class CmpMdBomTemplateOptionalEntryChooser extends Vue {
  @Prop({ required: true }) readonly bomTemplateEntries!: MdDataBomTemplateEntry[]

  @PropSync('visible')
  syncedVisible!: boolean

  private mSelectedRow: MdDataBomTemplateEntry | null = null;

  private onRowSelected(bomTemplateEntry: MdDataBomTemplateEntry) {
    this.mSelectedRow = bomTemplateEntry
  }

  private confirmSelected() {
    if (!this.mSelectedRow) {
      Message({
        message: '请先选择一个Bom模板元件',
        type: 'error',
        duration: 3 * 1000,
        showClose: true
      })
      return
    }
    this.$emit('select', this.mSelectedRow)
    this.syncedVisible = false
  }
}
