import { FilterType, RowRequest } from '@/module/graphql'
import axios from '@/d2admin/plugin/axios'
import { IServerSideGetRowsRequest } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import { getRowId, RowsPage } from './supports'
import { ExplorerDataSource } from './explorer-data-source'
import { hasError } from '@/module/common/util/error-util'
import { AxiosResponse } from 'axios'
import { buildGraphQLQueryPartInput } from '@/module/common/util/graphql-util'
import _ from 'lodash'
import { GridApi } from 'ag-grid-community'
import { getGridOptionsWrapper } from '@/module/common/util/view-util'

export class GraphQLDataSource extends ExplorerDataSource<AxiosResponse> {
  doQuery(request: IServerSideGetRowsRequest): Promise<AxiosResponse> {
    return axios.post('/graphql', this.buildQueryParameters(request))
  }

  onDataLoaded(rows: any[]) {
    // fetch lazy batch fields
    const lazyBatchParams = this.buildLazyBatchQueryParameters(rows)
    if (lazyBatchParams) {
      const gridApi: GridApi = this.explorer.gridApi()
      axios.post('/graphql', lazyBatchParams)
        .then((response: AxiosResponse) => {
          const lazyFetchedRows = this.resolveResponse(response)
          try {
            lazyFetchedRows.rows.forEach(row => {
              const rowNode = gridApi.getRowNode(getRowId(row))
              Object.assign(rowNode.data, row)
              rowNode.setData(rowNode.data)
              const rowHeight = getGridOptionsWrapper(gridApi).getRowHeightForNode(rowNode)
              rowNode.setRowHeight(rowHeight.height, rowHeight.estimated)
            })
          } catch (ignored) {}
          gridApi.onRowHeightChanged()
        })
    }

    // fetch lazy fields
    const fields = this.buildLazyQueryFields()
    if (fields) {
      this.gridOptions.context.lazyFieldDataFetcher = (rowIndex: number) => {
        this.gridOptions.context.lazyFieldFetched.add(rowIndex)
        const gridApi: GridApi = this.explorer.gridApi()
        const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex)
        if (!rowNode?.data) return
        const queryParam = this.buildLazyQueryParameters(rowNode.data, fields)
        if (!queryParam) return
        axios.post('/graphql', queryParam)
          .then((response: AxiosResponse) => {
            const lazyFetchedRows = this.resolveResponse(response)
            if (_.isEmpty(lazyFetchedRows.rows)) return
            const fetchRow = lazyFetchedRows.rows[0]
            try {
              Object.assign(rowNode.data, fetchRow)
              rowNode.setData(rowNode.data)
              const rowHeight = getGridOptionsWrapper(gridApi).getRowHeightForNode(rowNode)
              rowNode.setRowHeight(rowHeight.height, rowHeight.estimated)
              gridApi.onRowHeightChanged()
            } catch (ignored) {}
          })
      }
    }
  }

  checkErrors(response: AxiosResponse): boolean {
    return hasError(response.data.errors)
  }

  resolveResponse(response: AxiosResponse): RowsPage {
    return response.data.data[this.explorerType.queryType] ||
      response.data.data[this.explorerType.queryType + 'GroupBy']
  }

  buildQueryParameters(request: IServerSideGetRowsRequest): any {
    const type = this.explorerType.queryType

    if (this.isDoingGrouping(request)) {
      const rowRequest: RowRequest = this.buildGroupByRowRequest(request)
      this.gridOptions.context.currentRowRequest = rowRequest
      return {
        query: `query ExplorerQuery($rowRequest: GroupByRowRequest!) {
          ${type}GroupBy(rowRequest: $rowRequest ${this.buildExtraQueryParameters()}) {
            total rows
          }
        }`,
        variables: { rowRequest }
      }
    } else {
      let fields: string = this.explorerType.graphQLFetchFields([],
        { ignoreForExpanding: this.ignoreForExpanding })
      const rowRequest: RowRequest = this.buildRowRequest(request)
      const extraFetchFields = this.gridOptions.context?.extraFetchFields
      if (extraFetchFields) fields += ',' + extraFetchFields

      this.gridOptions.context.currentRowRequest = rowRequest
      return {
        query: `query ExplorerQuery($rowRequest: RowRequest!) {
          ${type}(rowRequest: $rowRequest ${this.buildExtraQueryParameters()}) {
            total
            rows { ${fields} }
          }
        }`,
        variables: { rowRequest }
      }
    }
  }

  buildLazyQueryFields(): any {
    const context:any = {
      ignoreForExpanding: this.ignoreForExpanding,
      fetchLazy: true
    }
    const fields = this.explorerType.graphQLFetchFields([], context)
    if (!context.needFetchLazy) return
    return fields
  }

  buildLazyQueryParameters(row: any, fields: string): any {
    const rowId = getRowId(row)
    if (!rowId) return
    const type = this.explorerType.queryType
    const rowRequest: RowRequest = {
      filter: {
        filterType: FilterType.InIds,
        value: getRowId(row)
      }
    }
    return {
      query: `query ExplorerLazyQuery($rowRequest: RowRequest!) {
        ${type}(rowRequest: $rowRequest ${this.buildExtraQueryParameters()}) {
          rows { ${fields} }
        }
      }`,
      variables: { rowRequest }
    }
  }

  buildLazyBatchQueryParameters(rows: any[]): any {
    const type = this.explorerType.queryType
    const context:any = {
      ignoreForExpanding: this.ignoreForExpanding,
      fetchLazyBatch: true
    }
    let fields: string = this.explorerType.graphQLFetchFields([], context)
    if (!context.needFetchLazyBatch) return
    const rowRequest: RowRequest = {
      filter: {
        filterType: FilterType.InIds,
        value: rows.map(getRowId).filter(v => v).join(',')
      }
    }
    return {
      query: `query ExplorerLazyBatchQuery($rowRequest: RowRequest!) {
        ${type}(rowRequest: $rowRequest ${this.buildExtraQueryParameters()}) {
          rows { ${fields} }
        }
      }`,
      variables: { rowRequest }
    }
  }

  private buildExtraQueryParameters(): string {
    let encodedExtraQueryParameters = ''
    if (this.gridOptions.context.extraQueryParameters) {
      if (_.isObject(this.gridOptions.context.extraQueryParameters)) {
        encodedExtraQueryParameters = buildGraphQLQueryPartInput(this.gridOptions.context.extraQueryParameters)
      } if (_.isString(this.gridOptions.context.extraQueryParameters)) {
        encodedExtraQueryParameters = this.gridOptions.context.extraQueryParameters
      }
    }
    return encodedExtraQueryParameters
  }
}
