import LocalDbDao from '@/module/common/local-db-dao'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { LOCAL_DB_CUSTOM_DATA_MDO_FIELDS } from '@/module/plugin-md-product-order/hook'
import { MdField, MdoEndProduct, MdoPackingOption } from '@/module/graphql'
import { mdAttrAgGridFilterViewDef, mdAttrAgGridFloatingFilter } from '@/module/master-data/md-util'
import Vue from 'vue'
import IcmpProductOrderDetailDialog from './views/components/product-order/icmp-mdo-product-order-detail-dialog.vue'
import ICmpMdoEndProductDialog from './views/components/product-order/icmp-mdo-end-product-dialog.vue'
import ICmpMdoPackingOptionFormDialog from './views/components/boxing/icmp-mdo-packing-option-form-dialog.vue'
import util from '@/d2admin/libs/util'
import { ID } from '@/module/common/types'
import { Loading } from 'element-ui'
import axios from '@/d2admin/plugin/axios'
import printJS from 'print-js'

export const MDO_BOXING_TYPE_NORMAL = 0
export const MDO_BOXING_TYPE_RESIDUAL = 1

export function setShowInTableColumns(gridOptions: GridOptions, forProductOrder: boolean = false) {
  const mdFields: MdField[] = LocalDbDao.getCustomData(LOCAL_DB_CUSTOM_DATA_MDO_FIELDS)?.filter(
    (mdField: MdField) => (!forProductOrder ||
        (forProductOrder && mdField.payload.context.mdoConfig.editWithProductOrder)) &&
      mdField.payload.context.mdoConfig.showInTable)
  if (!forProductOrder) {
    gridOptions.context.extraFetchFields = `
      attributes (mdFieldIds: [${mdFields.map(field => field.id).join(',')}]) {
        field { id, name, dataType, option },
        alias, feature, bomFeature, hidden, value, tbd, extra
      }`
  }
  mdFields.forEach((mdField: MdField) => {
    gridOptions.columnDefs.push({
      headerName: mdField.name,
      field: '@' + mdField.id,
      filterParams: {
        viewDef: mdAttrAgGridFilterViewDef(mdField)
      },
      filter: 'explorerFilter',
      floatingFilterComponent: mdAttrAgGridFloatingFilter(mdField),
      cellRenderer: 'mdAttributeRenderer',
      cellRendererParams: {
        attributesField: 'attributes',
        mdFieldId: mdField.id
      }
    })
  })
}

export function showMdoProductOrderDialog(vue: Vue, productOrderCode: string) {
  const editor = new IcmpProductOrderDetailDialog({
    router: vue.$router,
    store: vue.$store,
    i18n: vue.$i18n,
    apolloProvider: vue.$apolloProvider,
    propsData: {
      productOrderCode,
      readonly: true
    },
    parent: vue
  })
  editor.$mount()
  document.body.appendChild(editor.$el)
}

export function showMdoEndProductDialog(vue: Vue,
                                        endProduct: MdoEndProduct = undefined,
                                        endProductId: ID = undefined) {
  if (!endProduct) {
    endProduct = {
      id: endProductId
    } as MdoEndProduct
  }
  const editor = new ICmpMdoEndProductDialog({
    router: vue.$router,
    store: vue.$store,
    i18n: vue.$i18n,
    apolloProvider: vue.$apolloProvider,
    propsData: util.objects.stripNil({
      endProduct,
      readonly: true
    }),
    parent: vue
  })
  editor.$mount()
  document.body.appendChild(editor.$el)
}

export function showMdoPackingOptionDialog(vue: Vue,
                                           packingOptionId: number = undefined,
                                           mode: 'new' | 'edit' | 'copy' | 'readonly' = 'readonly',
                                           submitCallback: (packingOption: MdoPackingOption) => any = undefined,
                                           packingOption: MdoPackingOption & any = undefined,
                                           inboundCode: string = undefined) {
  const editor = new ICmpMdoPackingOptionFormDialog({
    router: vue.$router,
    store: vue.$store,
    i18n: vue.$i18n,
    apolloProvider: vue.$apolloProvider,
    propsData: util.objects.stripNil({
      packingOptionId,
      mode,
      packingOption,
      inboundCode
    }),
    mounted() {
      editor.$on('submit', submitCallback)
    },
    parent: vue
  })
  editor.$mount()
  document.body.appendChild(editor.$el)
}

export function printDeliverySheet(sheetCode: string, templateName: string) {
  let loadingInstance = Loading.service({})
  let url = '/mdo/delivery-sheet/print?code=' +
          encodeURIComponent(sheetCode) + '&templateName=' + templateName
  axios.get(url, {
    responseType: 'arraybuffer'
  }).then(resp => {
    printJS({
      printable: URL.createObjectURL(new Blob([resp.data], {
        type: 'application/pdf'
      })),
      type: 'pdf',
      onLoadingEnd: function () {
        loadingInstance.close()
      }
    })
  })
}
