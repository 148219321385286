























import { Component, Prop, Vue } from 'vue-property-decorator'
import CmpMdoEndProduct from './cmp-mdo-end-product.vue'
import CmpMdoEndProductSummary from './cmp-mdo-end-product-summary.vue'
import CmpMdoProductOrderForm
  from '@/module/plugin-md-product-order/views/components/product-order/cmp-mdo-product-order-form.vue'
import { MdAttributeFragmentDoc, MdoProductOrder } from '@/module/graphql'
import gql from 'graphql-tag'

@Component({
  components: { CmpMdoEndProduct, CmpMdoEndProductSummary, CmpMdoProductOrderForm }
})
export default class IcmpProductOrderDetailDialog extends Vue {
  @Prop({ required: true }) readonly productOrderCode: string

  private mVisible = true
  private mOpenedPane: string = 'basic'
  private mProductOrder: MdoProductOrder = null

  created() {
    this.$apollo.query({
      query: gql`query {
        MdoProductOrder(code: "${this.productOrderCode}") {
          id, ver, code, note, orderQty, principals, participants, customer{ code, name }
          attributes { ...mdAttribute }, attachments,
          currency{ code, name }, exchangeRate, taxType, taxRate
        }
      }
      ${MdAttributeFragmentDoc}`
    }).then(data => {
      this.mProductOrder = data.data.MdoProductOrder
    })
  }
}
