import axios from '@/d2admin/plugin/axios'
import { IServerSideGetRowsRequest } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import { RowsPage } from './supports'
import { ExplorerDataSource, ExplorerDataSourceArgs } from './explorer-data-source'
import { paramCase } from 'change-case'

export class RestDataSource extends ExplorerDataSource<RowsPage> {
  private readonly urlPrefix: string

  constructor(args: ExplorerDataSourceArgs) {
    super(args)
    this.urlPrefix = args.urlPrefix
    if (this.urlPrefix && !this.urlPrefix.startsWith('/')) {
      this.urlPrefix = `/${this.urlPrefix}`
    }
  }

  doQuery(request: IServerSideGetRowsRequest): Promise<RowsPage> {
    return axios.post(`${this.urlPrefix}/${paramCase(this.explorerType.key)}/list`, this.buildRowRequest(request))
  }

  checkErrors(response: RowsPage): boolean {
    // axios will handle errors
    return false
  }

  resolveResponse(response: RowsPage): RowsPage {
    return response
  }
}
