export default [
  {
    path: '/mdm/dictionary',
    name: '选项表迁移',
    meta: {
      title: '选项表迁移',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mdm-dictionary')
  },
  {
    path: '/mdm/data',
    name: '主数据迁移',
    meta: {
      title: '主数据迁移',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mdm-data')
  },
  {
    path: '/mdm/bom',
    name: 'Bom迁移',
    meta: {
      title: 'Bom迁移',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mdm-bom')
  },
  {
    path: '/mdm/ats/po',
    name: 'ATS订单同步',
    meta: {
      title: 'ATS订单同步',
      auth: true
    },
    component: () => import('./views/page-mdm-ats-po')
  },
  {
    path: '/mdm/po/packing',
    name: '装箱历史数据',
    meta: {
      title: '装箱历史数据',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mdm-packing')
  },
  {
    path: '/mdm/packing-option',
    name: '装箱方式迁移',
    meta: {
      title: '装箱方式迁移',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mdm-packing-option')
  },
  {
    path: '/mdm/data-refactor',
    name: '主数据重构记录',
    meta: {
      title: '主数据重构记录',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mdm-data-refactor-batch')
  },
  {
    path: '/mdm/tally-surplus-diff',
    name: '库存流水核对',
    meta: {
      title: '库存流水核对',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mdm-tally-surplus-diff')
  },
  {
    path: '/mdm/rqa-tally',
    name: '量化数据迁移',
    meta: {
      title: '量化数据迁移',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mdm-rqa-tally')
  }
]
