






















import { Component, Watch } from 'vue-property-decorator'
import { FilterCondition, FilterType } from '@/module/graphql'
import BaseFieldFilter from './base-field-filter'
import _ from 'lodash'
import util from '@/d2admin/libs/util'

@Component
export default class StringFilter extends BaseFieldFilter {
  private mValueDisabled: boolean = false
  private operators = [
    'equalIgnoreCase', 'contains', 'startsWith', 'endsWith', 'in', 'regex', 'empty'
  ]

  public initFilterCondition(filterCondition: FilterCondition) {
    if (!filterCondition.operator) filterCondition.operator = 'contains'
    if (_.isNil(filterCondition.value)) filterCondition.value = ''
    util.objects.reactive(filterCondition)
  }

  public updateFilterType(operator: string) {
    if (operator === 'empty') {
      this.filterCondition.filterType = FilterType.IsNull
      this.mValueDisabled = true
      this.internalOnFilterChanged()
    } else {
      this.filterCondition.filterType = FilterType.String
      this.mValueDisabled = false
    }
  }

  onOperatorChanged(operator: string) {
    this.updateFilterType(operator)
    if (this.filterCondition.value) this.internalOnFilterChanged()
  }

  clearContent() {
    this.filterCondition.operator = 'contains'
    this.filterCondition.value = null
    this.internalOnFilterChanged()
  }
}
