


































import { Component, PropSync, Ref, Vue } from 'vue-property-decorator'
import axios from '@/d2admin/plugin/axios/index'
import { submitMutation } from '@/module/common/util/graphql-util'
import gql from 'graphql-tag'

@Component
export default class CmpCreateUserDialog extends Vue {
  @Ref() readonly userAccountFormRef!: any

  @PropSync('visible')
  syncedVisible!: boolean

  private mCreateAccount = false
  private mFormUserAccount = {
    displayName: '',
    employeeNo: '',
    username: '',
    mobilePhone: ''
  }

  private mUserAccountFormRules = {
    displayName: [
      { required: true, message: '请输入员工姓名', trigger: 'blur' }
    ],
    mobilePhone: [
      { message: '手机号格式不正确', trigger: 'blur', pattern: /^1[3456789]\d{9}$/ }
    ],
    username: [
      { required: true, message: '用户名只能包含字母、数字、下划线, 长度为3~20位', pattern: /^[a-z0-9A-Z_-]{3,20}$/, trigger: 'blur' },
      {
        validator: (rule: any, value: any, callback: any) => {
          axios.get('/auth/is-user-registered', {
            params: {
              username: this.mFormUserAccount.username
            }
          }).then(resp => {
            if (resp) {
              callback(new Error('用户名已注册'))
            } else {
              callback()
            }
          })
        },
        trigger: 'blur'
      }
    ]
  }

  clearUserAccountForm() {
    this.mFormUserAccount.displayName = ''
    this.mFormUserAccount.employeeNo = ''
    this.mFormUserAccount.username = ''
    this.mFormUserAccount.mobilePhone = ''
  }

  submitUser() {
    this.userAccountFormRef.validate((valid: boolean) => {
      if (valid) {
        this.$confirm('确认提交?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          submitMutation(this, {
            mutationOptions: {
              mutation: gql`mutation createUser($displayName: String!, $employeeNo: String,
                                                $username: String, $mobilePhone: String) {
                createUser(displayName: $displayName, employeeNo: $employeeNo,
                         username: $username, mobilePhone: $mobilePhone) { id }
              }`,
              variables: {
                displayName: this.mFormUserAccount.displayName,
                employeeNo: this.mFormUserAccount.employeeNo,
                username: this.mFormUserAccount.username || null,
                mobilePhone: this.mFormUserAccount.mobilePhone || null
              }
            },
            ignoreWarning: false
          }).then(data => {
            if (!data) return
            this.$message({
              message: '创建成功', type: 'success', showClose: true
            })
            this.syncedVisible = false
            this.$emit('submit')
          })
        })
      } else {
        this.$message({
          message: '提交信息有误,请修正', type: 'error', showClose: true
        })
      }
    })
  }
}
