import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { FilterConditionView } from '@/module/components/lolth-filter/types'
import { isValid_ } from '@/module/common/filter-condition-extend'

@Component
export default class BaseFilter extends Vue {
  @Prop() readonly disabled!:boolean;

  @Model('change', { required: true })
  filterCondition!: FilterConditionView

  get isValid() {
    return isValid_(this.filterCondition)
  }
}
