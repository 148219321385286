








































import { Component, Ref, Vue } from 'vue-property-decorator'
import { FilterCondition, FilterType } from '@/module/graphql'
import BaseFieldFilter from '../../lolth-filter/base-field-filter'
import StringFilter from '../../lolth-filter/string-filter.vue'
import NumberFilter from '../../lolth-filter/number-filter.vue'
import BooleanFilter from '../../lolth-filter/boolean-filter.vue'
import DateFilter from '../../lolth-filter/date-filter.vue'
import EnumFilter from '../../lolth-filter/enum-filter.vue'
import TagsFilter from '../../lolth-filter/tags-filter.vue'
import ReadonlyFilter from '../../lolth-filter/readonly-filter.vue'
import StringArrayFilter from '../../lolth-filter/string-array-filter.vue'
import { IDoesFilterPassParams, IFilterComp, IFilterParams } from 'ag-grid-community/dist/lib/interfaces/iFilter'
import { FilterConditionView, FilterViewDef, FilterViewType } from '@/module/components/lolth-filter/types'
import _ from 'lodash'

declare interface IExplorerFilterParams extends IFilterParams {
  viewDef: FilterViewDef
}

@Component({
  name: 'explorer-filter',
  components: {
    StringFilter, NumberFilter, BooleanFilter, DateFilter, EnumFilter, TagsFilter, ReadonlyFilter, StringArrayFilter
  }
})
export default class ExplorerFilter extends Vue implements IFilterComp {
  @Ref() readonly stringFilter!: StringFilter & BaseFieldFilter
  @Ref() readonly numberFilter!: NumberFilter & BaseFieldFilter
  @Ref() readonly booleanFilter!: BooleanFilter & BaseFieldFilter
  @Ref() readonly dateFilter!: DateFilter & BaseFieldFilter
  @Ref() readonly enumFilter!: EnumFilter & BaseFieldFilter
  @Ref() readonly tagsFilter!: TagsFilter & BaseFieldFilter
  @Ref() readonly readonlyFilter!: ReadonlyFilter & BaseFieldFilter
  @Ref() readonly stringArrayFilter!: StringArrayFilter & BaseFieldFilter
  @Ref() readonly customFilter!: BaseFieldFilter

  private params: IExplorerFilterParams | null = null
  private mFilterCondition: FilterConditionView = {
    filterType: FilterType.Custom,
    not: false
  }

  get fieldKey(): string {
    return this.params?.viewDef.fieldKey || ''
  }

  get activeFilter(): BaseFieldFilter | null {
    switch (this.params?.viewDef.filterViewType) {
      case FilterViewType.StringFilter: return this.stringFilter
      case FilterViewType.NumberFilter: return this.numberFilter
      case FilterViewType.BooleanFilter: return this.booleanFilter
      case FilterViewType.DateFilter: return this.dateFilter
      case FilterViewType.EnumFilter: return this.enumFilter
      case FilterViewType.TagsFilter: return this.tagsFilter
      case FilterViewType.StringArrayFilter: return this.stringArrayFilter
      case FilterViewType.CustomFilter: return this.customFilter
      default: return this.readonlyFilter
    }
  }

  get isValid() {
    return this.activeFilter?.isValid || false
  }

  created() {
    this.$set(this.mFilterCondition, 'filterViewDef', this.params?.viewDef)
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return true
  }

  isFilterActive(): boolean {
    return this.isValid
  }

  getFrameworkComponentInstance(): any {
    return this
  }

  getModel(): any {
    return this.isValid ? this.mFilterCondition : undefined
  }

  setModel(model: any): void {
    if (!model) {
      this.activeFilter?.clearContent()
    } else {
      Object.assign(this.mFilterCondition, model)
      this.activeFilter?.initFilterCondition(this.mFilterCondition)
      this.activeFilter?.updateFilterType(this.mFilterCondition.operator)
    }
  }

  onColumnFilterChanged(filter: FilterCondition): void {
    // @ts-ignore
    if (this.params?.api.context?.destroyed) return
    this.params?.filterChangedCallback()
  }

  // noinspection JSUnusedGlobalSymbols
  onFloatingFilterChanged(filterCondition: FilterCondition) {
    this.$set(this.mFilterCondition, 'value', filterCondition.value)
    if (filterCondition.filterType) this.$set(this.mFilterCondition, 'filterType', filterCondition.filterType)
    if (filterCondition.operator) this.$set(this.mFilterCondition, 'operator', filterCondition.operator)
    if (!_.isNil(filterCondition.not)) this.$set(this.mFilterCondition, 'not', filterCondition.not)
    if (filterCondition.extra) this.$set(this.mFilterCondition, 'extra', filterCondition.extra)
    this.onColumnFilterChanged(filterCondition)
  }

  private onBodyDoubleClick() {
    this.mFilterCondition.not = !this.mFilterCondition.not
  }
}
