
























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CmpHeaderDoc extends Vue {
  get internalDocLink() {
    return `${this.$apiUrl}anonymous/doc?url=${encodeURIComponent(this.$route.path)}&internal=1`
  }

  get publicDocLink() {
    return `${this.$apiUrl}anonymous/doc?url=${encodeURIComponent(this.$route.path)}`
  }
}
