import _ from 'lodash'
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef'
import { ExplorerTypeField } from '@/module/components/lolth-explorer/explorer-type-field'
import { getDirective, isIgnoredField } from '@/module/components/lolth-explorer/supports'
import { SortColumn, SortType } from '@/module/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'
import { ExplorerDataSource, ExplorerDataSourceArgs } from '@/module/components/lolth-explorer/explorer-data-source'
import LocalDbDao from '@/module/common/local-db-dao'
import json5 from 'json5'

const labelGroupPattern = /\${([\s\S]+?)}/g

interface BuildGraphQLFetchFieldsContext {
  // options
  ignoreForExpanding?: boolean
  fetchLazy?: boolean
  fetchLazyBatch?: boolean

  // context
  fetchDeep?: boolean
  fetchDeepType?: ExplorerType
  rootExplorerType?: ExplorerType
  needFetchLazy?: boolean
  needFetchLazyBatch?: boolean
}

export class ExplorerType {
  readonly key: string
  readonly name: string
  private readonly fieldMap: { [key: string]: ExplorerTypeField }
  deletable?: boolean
  taggable?: boolean
  seqSortable?: boolean
  queryType: string
  dataSourceProvider?: (args: ExplorerDataSourceArgs) => ExplorerDataSource<any>

  defaultSort?: SortColumn
  labelFormatterPattern?: string

  constructor(gqlType: any) {
    this.key = gqlType.name
    this.name = gqlType.description || gqlType.name
    this.fieldMap = {}
    gqlType.fields.forEach((gqlField: any) => {
      if (isIgnoredField(gqlField)) return
      this.fieldMap[gqlField.name] = new ExplorerTypeField(gqlField, this.key)
    })

    // resolve directive
    const directive = getDirective('bizType', gqlType.directives)
    if (json5.parse(directive?.deletable || false)) this.deletable = true
    if (json5.parse(directive?.taggable || false)) this.taggable = true
    if (json5.parse(directive?.seqSortable || false)) this.seqSortable = true
    this.queryType = directive?.explorerQueryType || this.key + 's'
    if (directive?.defaultSortBy) {
      this.defaultSort = {
        field: directive?.defaultSortBy,
        sort: directive?.defaultSortOrder || SortType.Desc
      }
    }
    this.dataSourceProvider = directive?.dataSourceProvider
    this.labelFormatterPattern = directive?.labelFormatter
  }

  fields(): ExplorerTypeField[] {
    const fields = _.values(this.fieldMap)
    fields.forEach(field => Object.setPrototypeOf(field, ExplorerTypeField.prototype))
    return fields
  }

  field(fieldKey: string): Maybe<ExplorerTypeField> {
    return this.fieldMap[fieldKey]
  }

  /**
   * build ag-grid columnDef from ExplorerType
   *
   * @param skipFieldKeys field keys to skip
   * @param groupHeader whether group headers by nested level
   * @param expendingParentField
   */
  agGridColumnDef(skipFieldKeys: string[] = [],
                  groupHeader: boolean = false,
                  expendingParentField: ExplorerTypeField | undefined = undefined): ColDef[] {
    let columnDefs: ColDef[] = []
    this.fields()
      .filter(field => _.indexOf(skipFieldKeys, field.key) < 0 && !field.ignoreColumn)
      .forEach(field => {
        columnDefs = columnDefs.concat(field.buildAgGridColumnDef({
          groupHeader: groupHeader,
          expendingParentField: expendingParentField
        }))
      })
    return columnDefs
  }

  getFilterOptions() {
    const columnDefs = this.agGridColumnDef()
    return columnDefs.filter(colDef => colDef.filterParams).map(colDef => colDef.filterParams.viewDef)
  }

  graphQLFetchFields(skipFieldKeys: string[] = [], context: BuildGraphQLFetchFieldsContext = {}): string {
    if (!context.rootExplorerType) context.rootExplorerType = this

    return this.fields()
      .filter(field => _.indexOf(skipFieldKeys, field.key) < 0 && !field.ignoreFetch)
      .map(field => {
        if (!_.isEmpty(field.nestedFields)) {
          // 从nestedFields级联下级Type的fields
          const nestedType = LocalDbDao.getExplorerType(field.typeKey)
          const skipFieldKeys = nestedType.fields()
            .filter(nestedField => nestedField.key !== 'id' && nestedField.key !== 'code' && // id和code强制获取
              (_.indexOf(field.nestedFields, nestedField.key) < 0 &&
                !nestedField.ignoreFetch && // 不需要从后台获取的字段
                (!context.ignoreForExpanding || !field.expandIgnoreFields || _.indexOf(field.expandIgnoreFields, nestedField.key) < 0))) // 展开时跳过获取的字段
            .map(nestedField => nestedField.key)
          let keys = nestedType.graphQLFetchFields(skipFieldKeys, context)
          if (keys) keys = field.key + '{' + keys + '}'
          return keys
        } else if (!field.isScalar() && (field.customCellRenderer || context.fetchDeep)) {
          // 指定了customCellRender/级联获取全部字段的非Scalar类型
          const nestedType = LocalDbDao.getExplorerType(field.typeKey)
          let skipFieldKeys: string[]
          if (field.fetchDeep) {
            // schema指定了递归获取该类型下的所有字段
            skipFieldKeys = []
            context.fetchDeep = true
            context.fetchDeepType = nestedType
          } else {
            // schema没有指定, 只获取scalar字段
            skipFieldKeys = nestedType.fields()
              .filter(nestedField => (nestedField.key !== 'id' && nestedField.key !== 'code' && // id和code强制获取
                    !nestedField.isScalar() && // 只提取scalar字段
                    !nestedField.ignoreFetch) || // 不需要从后台获取的字段
                nestedField.isAuditField()) // 过滤audit字段
              .map(nestedField => nestedField.key)
          }
          let keys = nestedType.graphQLFetchFields(skipFieldKeys, context)
          if (keys) keys = field.key + '{' + keys + '}'
          if (context.fetchDeepType === nestedType) {
            // 退出递归
            context.fetchDeepType = undefined
            context.fetchDeep = false
          }
          return keys
        } else {
          // Scalar类型
          // 只返回根节点的id字段
          if ((field.key === 'id' || field.key === 'code') && context.rootExplorerType === this) return field.key
          context.needFetchLazy = context.needFetchLazy || field.fetchLazy
          context.needFetchLazyBatch = context.needFetchLazyBatch || field.fetchLazyBatch
          if ((!context.fetchLazy && field.fetchLazy) ||
            (context.fetchLazy && !field.fetchLazy)) return
          if ((!context.fetchLazyBatch && field.fetchLazyBatch) ||
            (context.fetchLazyBatch && !field.fetchLazyBatch)) return
          return field.key
        }
      }).filter(field => field).join(',')
  }

  getExpanderField(): Maybe<ExplorerTypeField> {
    return _.find(this.fields(), field =>
      !_.isEmpty(field.expandField) || !_.isEmpty(field.expandType))
  }

  getExpandingField(expanderField: ExplorerTypeField): ExplorerTypeField {
    let toManyField = this.fieldMap[expanderField.expandField || '']
    if (!toManyField) throw new Error(`无效的展开字段${expanderField.key}.${expanderField.expandField}`)
    return toManyField as ExplorerTypeField
  }

  getIdFieldName(): string | null {
    if ('id' in this.fieldMap) return 'id'
    else if ('code' in this.fieldMap) return 'code'
    return null
  }

  formatLabel(item:any) {
    if (_.isNil(item)) return ''
    if (this.labelFormatterPattern) {
      try {
        return _.template(this.labelFormatterPattern, {
          escape: labelGroupPattern,
          evaluate: labelGroupPattern,
          interpolate: labelGroupPattern
        })(item)
      } catch (e) {
        console.warn(`format label pattern ${this.labelFormatterPattern} failed.`)
      }
    }
    return item.name || item.code || item.key || item.value
  }
}

declare interface DummyGqlArgument {
  name: string
  value: any
}

declare interface DummyGqlFieldType {
  name?: string
  kind?: string
  ofType?: DummyGqlFieldType
}

declare interface DummyGqlDirective {
  name: string
  args?: DummyGqlArgument[]
}

declare interface DummyGqlField {
  name: string
  description: string
  type: DummyGqlFieldType,
  directives: DummyGqlDirective[]
}

declare interface DummyGqlType {
  name: string
  description: string
  fields: DummyGqlField[]
  directives: DummyGqlDirective[]
}

export class CustomExplorerType extends ExplorerType {
  // eslint-disable-next-line no-useless-constructor
  constructor(gqlType: DummyGqlType) {
    super(gqlType)
  }
}
