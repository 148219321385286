








































export default function (Component) {
  Component.options.__source = "src/module/plugin-md-product-order/views/components/explorer-support/cmp-mdo-end-products-prop-renderer.vue"
}
