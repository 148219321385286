













































export default function (Component) {
  Component.options.__source = "src/module/components/lolth-explorer/filter/cmp-bool-floating-filter.vue"
}
