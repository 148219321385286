



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AttrIcon } from '@/module/master-data/store'
import _ from 'lodash'

@Component
export default class MdAttrIcons extends Vue {
  @Prop() readonly attribute:any
  @Prop() readonly attrIconsConfig!: AttrIcon[]

  get attrIcons() {
    return this.attrIconsConfig || this.$store.getters['master-data/md-store/attrIcons'] || []
  }

  private match(attrIcon: AttrIcon): boolean {
    if (!this.attribute) return false
    // match by field
    if (_.isString(attrIcon.matcher)) {
      return this.attribute[attrIcon.matcher] ||
        _.get(this.attribute?.extra, attrIcon.matcher) ||
        _.get(this.attribute?.option, attrIcon.matcher) ||
        _.get(this.attribute?.payload?.extra, attrIcon.matcher)
    // match by function
    } else if (_.isFunction(attrIcon.matcher)) {
      return attrIcon.matcher(this.attribute) ||
        attrIcon.matcher(this.attribute?.extra) ||
        attrIcon.matcher(this.attribute?.option) ||
        attrIcon.matcher(this.attribute?.payload?.extra)
    }
    return false
  }

  private description(attrIcon: AttrIcon): string {
    if (!this.attribute) return ''
    if (_.isString(attrIcon.description)) {
      return attrIcon.description
    } else if (_.isFunction(attrIcon.matcher)) {
      return attrIcon.description(this.attribute)
    }
    return ''
  }

  @Watch('attribute', { deep: true })
  private onValueChange() {
    this.$forceUpdate()
  }
}
