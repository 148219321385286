





import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import _ from 'lodash'

declare interface IExplorerListItemCountCellRendererComp extends ICellRendererParams {
  onClick?: (list: []) => {}
}

@Component
export default class LolthListItemCountCellRenderer extends Vue implements ICellRendererComp {
  private params!: IExplorerListItemCountCellRendererComp
  private mValue: any = null

  get value() {
    if (_.isNil(this.mValue)) return 0
    if (!_.isArray(this.mValue)) return ''
    return this.mValue.length
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: IExplorerListItemCountCellRendererComp): boolean {
    this.mValue = params.value
    return true
  }

  private onClick() {
    this.params.onClick && this.params.onClick(this.params.value)
  }
}
