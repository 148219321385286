























































































































































































































































































export default function (Component) {
  Component.options.__source = "src/module/plugin-md-product-structure/views/components/cmp-mdp-data-view-panel.vue"
}
