















































import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator'
import UserInfo from '@/module/butler/views/components/cmp-user-info.vue'
import axios from '@/d2admin/plugin/axios/index'
import qs from 'qs'
import LocalDbDao from '@/module/common/local-db-dao'
import { User } from '@/module/graphql'
import { APP_REALM_CODE } from '@/module/common/constants'

@Component({
  name: 'cmp-user-info-dialog',
  components: {
    UserInfo
  }
})
export default class CmpUserPassword extends Vue {
  @Ref() readonly elPasswordForm!: any

  @Prop({ default: false }) adminMode: boolean
  @Prop() user: User

  @PropSync('visible')
  private syncedVisible!: boolean
  private formPassword = {
    newPassword: '',
    oldPassword: '',
    repeatNewPassword: ''
  }

  get username() {
    return this.user?.account.username || LocalDbDao.getPrincipal().userProfile.username
  }

  private rules = {
    oldPassword: [
      { required: true, message: '请输入旧密码', trigger: 'blur' }
    ],
    newPassword: [
      { min: 6, message: '至少6个字符', trigger: 'blur' },
      { required: true, message: '请输入新密码', trigger: 'blur' }
    ],
    repeatNewPassword: [
      { required: true, message: '请再次输入密码', trigger: 'blur' },
      {
        validator: (rule: any, value: any, callback: any) => {
          if (value === '') {
            callback(new Error('请再次输入密码'))
          } else if (this.formPassword.newPassword !== '' && this.formPassword.newPassword !== this.formPassword.repeatNewPassword) {
            callback(new Error('两次输入密码不一致'))
          } else {
            callback()
          }
        },
        trigger: 'blur'
      }
    ]
  }

  private saveNewPassword() {
    let postData = {
      accountId: this.user?.account.id || LocalDbDao.getPrincipal().userProfile.accountId,
      newPassword: this.formPassword.newPassword,
      oldPassword: this.formPassword.oldPassword || null
    }
    this.elPasswordForm.validate((valid: boolean) => {
      if (valid) {
        axios.post('/butler/change-password',
          qs.stringify(postData)
        ).then(() => {
          if (!this.user) {
            // 修改完自己的密码, 重置强制修改密码标志
            const userSetting = LocalDbDao.getUserSetting(APP_REALM_CODE, 'needResetPassword')
            userSetting.value = false
            LocalDbDao.updateLocalUserSetting([userSetting])
          }

          this.syncedVisible = false
          this.$message({
            message: '修改成功', type: 'success', showClose: true
          })
        })
      }
    })
  }
}
