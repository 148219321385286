import { Component, Inject, Model, Prop, Vue } from 'vue-property-decorator'
import { ErrorInfo, MdAttributeInput, MdField } from '@/module/graphql'
import { ElForm } from 'element-ui/types/form'
import { ElFormItem } from 'element-ui/types/form-item'
import _ from 'lodash'
import AttrTbd from './attr-tbd.vue'

@Component({
  provide() {
    return {
      // @ts-ignore
      mdField: this.mdField
    }
  },
  components: {
    AttrTbd
  }
})
export default class BaseAttrInput extends Vue {
  @Prop({ required: true }) readonly mdField!: MdField

  @Prop() readonly disabled?: boolean
  @Prop() readonly alias?: string
  @Prop() readonly feature?: boolean
  @Prop() readonly bomFeature?: boolean
  @Prop() readonly extraFeatures?: {}
  @Prop() readonly errors?: ErrorInfo[]

  @Model('change', { required: true })
  attribute!: MdAttributeInput

  @Inject({ default: {} }) readonly elForm!: ElForm | any
  @Inject({ default: {} }) readonly elFormItem!: ElFormItem | any

  get fieldName() {
    return this.mdField?.payload?.alias || this.mdField?.name || this.alias
  }

  created() {
    if (!this.mdField) throw new Error('字段未设置')
    if (!this.attribute) throw new Error('v-model不能绑定null值')
    if (_.isNil(this.attribute.value) && !_.isNil(this.mdField.option.defaultValue)) {
      this.attribute.value = _.cloneDeep(this.mdField.option.defaultValue)
    }
    this.setupBindingFields()
  }

  updated() {
    this.setupBindingFields()
  }

  protected setupBindingFields() {
    if (this.alias && !('alias' in this.attribute)) this.$set(this.attribute, 'alias', this.alias)
    if (this.feature && !('feature' in this.attribute)) this.$set(this.attribute, 'feature', this.feature)
    if (this.bomFeature && !('bomFeature' in this.attribute)) this.$set(this.attribute, 'bomFeature', this.bomFeature)

    if (!('value' in this.attribute)) this.$set(this.attribute, 'value', null)
    if (!('extra' in this.attribute)) this.$set(this.attribute, 'extra', {})
    if (this.extraFeatures) {
      _.forOwn(this.extraFeatures, (value, key) => {
        this.attribute.extra[key] = value
      })
    }
  }
}
