




























import { Component } from 'vue-property-decorator'
import { FilterCondition, FilterType } from '@/module/graphql'
import BaseFieldFilter from './base-field-filter'
import util from '@/d2admin/libs/util'
import _ from 'lodash'

@Component
export default class NumberFilter extends BaseFieldFilter {
  private mValueDisabled: boolean = false
  private mValue2Disabled: boolean = true
  private operators = [
    'equals', 'lt', 'lte', 'gt', 'gte', 'between', 'empty'
  ]

  get valueParts(): string[] {
    return (this.filterCondition.value || '').split(',')
  }

  get syncedValue() {
    return this.decodeValue(this.filterCondition.value)[0]
  }

  set syncedValue(val: number) {
    const nums = this.decodeValue(this.filterCondition.value)
    nums[0] = val
    this.filterCondition.value = this.encodeValue(nums)
  }

  get syncedValue2() {
    return this.decodeValue(this.filterCondition.value)[1]
  }

  set syncedValue2(val: number) {
    const nums = this.decodeValue(this.filterCondition.value)
    nums[1] = val
    this.filterCondition.value = this.encodeValue(nums)
  }

  private encodeValue(val: number[]): string {
    if (this.filterCondition.operator !== 'between') return _.toString(val[0])
    return val.map(v => _.isNil(v) ? '' : v).join(',')
  }

  private decodeValue(val: string): number[] {
    if (_.isEmpty(val)) return [null, null]
    const parts = val.split(',')
    const nums: number[] = [null, null]
    for (let i = 0; i < 2; i++) {
      nums[i] = _.toNumber(parts[i])
    }
    return nums
  }

  public initFilterCondition(filterCondition: FilterCondition) {
    if (!filterCondition.operator) filterCondition.operator = 'equals'
    if (_.isNil(filterCondition.value)) filterCondition.value = null
    util.objects.reactive(filterCondition)
  }

  public updateFilterType(operator: string) {
    if (operator === 'empty') {
      this.filterCondition.filterType = FilterType.IsNull
      this.mValueDisabled = true
      this.mValue2Disabled = true
    } else {
      this.filterCondition.filterType = FilterType.Number
      this.mValueDisabled = false
      this.mValue2Disabled = (operator !== 'between')
    }
  }

  onOperatorChanged(operator: string): void {
    this.updateFilterType(operator)
    if (!_.isNil(this.filterCondition.value)) this.internalOnFilterChanged()
  }

  clearContent() {
    this.filterCondition.operator = 'equals'
    this.filterCondition.value = null
    this.internalOnFilterChanged()
  }
}
