












import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { MdAttribute, MdField } from '@/module/graphql'
import { ElForm } from 'element-ui/types/form'
import { ElFormItem } from 'element-ui/types/form-item'
import CmpMdAttrValue from '@/module/master-data/views/cmp-md-attr-viewer/cmp-md-attr-value.vue'
import { TagType } from '@/module/common/types'

@Component({
  components: { CmpMdAttrValue }
})
export default class CmpMdAttrValueViewer extends Vue {
  @Prop({ required: true }) readonly attribute!: MdAttribute
  @Prop() readonly mdField!: MdField
  @Prop() readonly showFieldName?: boolean
  @Prop() readonly tagType?: TagType
  @Prop({ default: true }) readonly showDictKey: boolean

  @Inject({ default: {} }) readonly elForm!: ElForm | any
  @Inject({ default: {} }) readonly elFormItem!: ElFormItem | any

  private get shouldWrapTag() {
    return this.actualMdField.dataType !== '多选选项表' &&
      this.actualMdField.dataType !== '附件'
  }

  private get fieldName() {
    if (!this.showFieldName ||
      this.actualMdField.dataType === '扩展表' ||
      this.actualMdField.dataType === '选项表' ||
      this.actualMdField.dataType === '多选选项表' ||
      this.actualMdField.dataType === '附件') return ''
    return this.attribute.alias || this.actualMdField.payload?.alias || this.actualMdField.name || ''
  }

  private get actualMdField() {
    return this.attribute.field || this.mdField
  }

  private get actualTagType(): TagType {
    return this.tagType || (this.attribute.extra.emphasis ? 'primary' : 'info' as TagType)
  }
}
