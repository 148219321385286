









import { Component, Prop, Vue } from 'vue-property-decorator'
import { fabric } from 'fabric'
import _ from 'lodash'
import { bindArrowDrawing, drawArrow } from '@/module/components/fabric/arrow-control'
import { FabricLineArrow } from '@/module/components/fabric/arrow'
import util from '@/d2admin/libs/util'
import fileService from '@/module/common/file-service'

/**
 * Fabric.js 封装.
 * 可以使用`attachmentElement()`嵌入HTML元素. 被嵌入的元素要符合以下要求:
 * ) 必须使用abstract定位
 * ) 父元素使用relative定位
 */
@Component
export default class LolthFabric extends Vue {
  @Prop({ required: true }) readonly width: number
  @Prop({ required: true }) readonly height: number
  @Prop({ default: 20 }) readonly attachElementOffset: number

  private mCanvasId = 'canvas_' + _.uniqueId()
  private mBgImgId = 'canvas_bg_' + _.uniqueId()
  private mCanvas: fabric.Canvas
  private mBackgroundImageUrl: string = null
  private mArrowMap: { elemGroup: fabric.Group, arrow: FabricLineArrow }[] = []

  public get canvas() {
    return this.mCanvas
  }

  public get arrowMap() {
    return this.mArrowMap
  }

  mounted() {
    this.mCanvas = new fabric.Canvas(this.mCanvasId, {
      selection: false,
      preserveObjectStacking: true
    })
  }

  getAbsoluteCoords(object: fabric.Object) {
    return {
      // left: object.left + _.get(this.mCanvas, '_offset.left'),
      // top: object.top + _.get(this.mCanvas, '_offset.top')
      left: object.left,
      top: object.top
    }
  }

  setBackgroundImage(url: string) {
    this.mBackgroundImageUrl = url
  }

  setBackgroundImageFromFileKey(fileKey: string) {
    this.mBackgroundImageUrl = fileService.getFileService(undefined, fileKey).getPreviewUrl(fileKey)
  }

  private setImgToCanvas(event: any) {
    const elImg = event.path[0]
    const imgWidth = elImg.width
    const imgHeight = elImg.height

    let scale = 1.0
    if (imgWidth > this.width || imgHeight > this.height) {
      const scaleX = this.width / imgWidth
      const scaleY = this.height / imgHeight
      scale = Math.min(scaleX, scaleY)
    }
    this.mCanvas.setBackgroundImage(this.mBackgroundImageUrl,
      this.mCanvas.renderAll.bind(this.mCanvas), {
        originX: 'left',
        originY: 'top',
        scaleX: scale,
        scaleY: scale
      })
  }

  public attachmentElement(elem: HTMLElement,
                           pos?: { x: number, y: number },
                           withMove = false,
                           withArrowDock = false,
                           arrowDrawing = false,
                           arrowHeadPos?: { x: number, y: number }) {
    const objGroup = []
    let pin: fabric.Object = null
    let arrowDock: fabric.Object = null

    const elemRect = elem.getBoundingClientRect()
    // 包装在elem外围的frame
    const frame = new fabric.Rect({
      fill: 'yellow',
      width: elemRect.width + 4,
      height: elemRect.height + 4,
      objectCaching: false,
      stroke: 'lightgreen',
      strokeWidth: 4,
      hasControls: false
    })
    objGroup.push(frame)

    // 移动图标
    const icoPinOptions = {
      left: -18,
      top: -18,
      scaleX: 28 / 64,
      scaleY: 28 / 64
    }
    if (withMove) {
      const icoPinElem = document.getElementById('ico_pin') as HTMLImageElement
      pin = new fabric.Image(icoPinElem, icoPinOptions)
      objGroup.push(pin)
    } else {
      pin = new fabric.Circle(icoPinOptions)
      objGroup.push(pin)
    }

    pos = pos || {
      x: Math.random() * this.width * 0.6 + this.width * 0.2,
      y: Math.random() * this.height * 0.6 + this.height * 0.2
    }
    // 装到一个组里
    const group = new fabric.Group(objGroup, {
      left: pos.x,
      top: pos.y,
      hasControls: false,
      selectable: withMove,
      hoverCursor: 'grab'
    })
    // 绑定位置
    group.on('moving', () => {
      this.syncElemPosition(elem, group, 22) // element的位置
      if (arrowDock) { // 箭头锚点的位置
        this.syncObjectPosition(arrowDock, group,
          (elemRect.width + 22) / 2, elemRect.height + 22)
        const arrowPair = _.find(this.mArrowMap, item => item.elemGroup === group)
        arrowPair?.arrow?.set({
          x1: arrowDock.left + arrowDock.width / 2,
          y1: arrowDock.top + arrowDock.height / 2
        })
      }
    })
    this.syncElemPosition(elem, group, 22)
    this.mCanvas.add(group)

    if (withArrowDock) {
      arrowDock = new fabric.Circle({
        radius: 4,
        stroke: 'blue',
        strokeWidth: 2,
        fill: 'lightgreen',
        hasControls: false,
        selectable: false,
        hoverCursor: 'sw-resize'
      })
      this.syncObjectPosition(arrowDock, group,
        (elemRect.width + 22) / 2, elemRect.height + 22)
      this.mCanvas.add(arrowDock).bringToFront(arrowDock)
      if (arrowDrawing) {
        // 启用拖箭头操作
        bindArrowDrawing(this.canvas, arrowDock, {
          onStart: () => {
            const arrowPair = _.find(this.mArrowMap, item => item.elemGroup === group)
            if (arrowPair) {
              this.mCanvas.remove(arrowPair.arrow)
              util.objects.remove(this.mArrowMap, arrowPair)
            }
          },
          onDrawn: lineObj => {
            this.mArrowMap.push({ elemGroup: group, arrow: lineObj })
          }
        })
      }
      if (arrowHeadPos) {
        const lineObj = drawArrow(this.canvas, arrowDock, arrowHeadPos)
        this.mArrowMap.push({ elemGroup: group, arrow: lineObj })
      }
    }
    return group
  }

  private syncElemPosition(target: HTMLElement, refer: fabric.Object, offset: number = 0) {
    const absCoords = this.getAbsoluteCoords(refer)
    target.style.left = (absCoords.left + offset) + 'px'
    target.style.top = (absCoords.top + offset) + 'px'
  }

  private syncObjectPosition(target: fabric.Object, refer: fabric.Object,
                             offsetX: number = 0,
                             offsetY: number = 0) {
    const absCoords = this.getAbsoluteCoords(refer)
    target.set({
      left: absCoords.left + offsetX,
      top: absCoords.top + offsetY
    }).setCoords()
    this.canvas.renderAll()
  }
}
