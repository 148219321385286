




























import { Component, ModelSync, Prop, PropSync, Ref, Vue } from 'vue-property-decorator'
import { getRowId } from '@/module/components/lolth-explorer/supports'
import _ from 'lodash'
import { setupSortable } from '@/module/common/util/view-util'

@Component
export default class LolthListView extends Vue {
  @Ref() private readonly vContextMenu!: any

  @Prop({ default: '100%' }) readonly height!: string
  @Prop() readonly itemKeyGetter!: (item: any) => string
  @Prop() readonly itemLabelFormatter!: (item: any) => string
  @Prop() readonly readonly: boolean

  @ModelSync('items', 'change', { required: true })
  syncItems!: any[]

  @PropSync('selectedItem')
  private syncedSelectedItem!: boolean

  private mFilterText: string = ''
  private mContextMenuItem: any = null

  get filteredItems() {
    if (!this.mFilterText) return this.syncItems

    return this.syncItems.filter(item => {
      return this.formatItemLabel(item).indexOf(this.mFilterText) >= 0
    })
  }

  mounted() {
    if (!this.readonly) {
      setupSortable((this.$el as HTMLElement).querySelector('.no-dot') as HTMLElement,
        this.onRowSeqChanged, true)
    }
  }

  private onRowSeqChanged(newSeq: number = -1, oldSeq: number = -1) {
    if (newSeq < 0 || oldSeq < 0) return
    const movingRow = this.syncItems[oldSeq]

    this.syncItems.splice(oldSeq, 1) &&
    this.syncItems.splice(newSeq, 0, movingRow)
  }

  getItemKey(item: any) {
    if (this.itemKeyGetter) return this.itemKeyGetter(item)
    if (_.isString(item)) return _.uniqueId()
    return getRowId(item) || _.uniqueId()
  }

  formatItemLabel(item: any) {
    if (this.itemLabelFormatter) return this.itemLabelFormatter(item) || ''
    if (_.isString(item)) return item
    return item.name || item.value || ''
  }

  onClick(item: any) {
    this.syncedSelectedItem = item
    this.$emit('click', item)
  }

  onDblClick(item: any) {
    this.syncedSelectedItem = item
    this.$emit('dblclick', item)
  }

  setContextItem(event: MouseEvent, item: any) {
    event.preventDefault()
    event.stopPropagation()
    this.mContextMenuItem = item
    this.vContextMenu.show({ top: event.pageY, left: event.pageX })
  }
}
