





















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  ErrorInfo,
  ErrorInfoFragmentDoc,
  FilterCondition,
  FilterType,
  MdAttributeFragmentDoc,
  MdAttributeInput,
  MdData,
  MdDataSavingResultFragmentDoc,
  MdField,
  MdFieldWithPayloadFragmentDoc,
  RowRequest
} from '@/module/graphql'
import gql from 'graphql-tag'
import {
  compareFeatureAttributes,
  convertAttrInputToValue,
  convertAttrTupleToFilterCondition,
  convertAttrValueToInput,
  findAttribute,
  initMdAttributeIfNeeded,
  isAttrEqualsExceptNil,
  isEmptyAttr,
  showMdDataViewer
} from '@/module/master-data/md-util'
import _ from 'lodash'
import uniqolor from 'uniqolor'
import { buildGraphQLQueryPartInput, submitPreview } from '@/module/common/util/graphql-util'
import util from '@/d2admin/libs/util'
import { v4 as uuidv4 } from 'uuid'
import { MdFieldTuple } from '@/module/master-data/types'
import LocalDbDao from '@/module/common/local-db-dao'

@Component
export default class CmpMdDataEditor extends Vue {
  @Prop() readonly structureKey?: string
  @Prop() readonly bomCascadingCache?: { [fieldId:number] : MdAttributeInput }
  @Prop() readonly presetAttrs?: { [fieldId:number] : MdAttributeInput }
  @Prop() readonly mdData?: MdData
  @Prop() readonly lockMdData!: boolean
  @Prop() readonly setBomEnabled!: boolean
  @Prop() readonly draftEnabled!: boolean
  @Prop() readonly selectionEnabled!: boolean

  private mEditingFields: MdFieldTuple[] = []
  private mRowStyleCache: { [indent:number]: {} } = {}
  private mGeneralErrors: ErrorInfo[] = []
  private mLocks: { [mdFieldId:string] : boolean} = {}

  private mMatchedMdDataCount: number = 0
  private mMdChooserFilterCondition: FilterCondition = {
    filterType: FilterType.And,
    conditions: []
  }
  private mMdChooserVisible: boolean = false

  private mPreviewVisible: boolean = false
  private mWhereToGoAfterPreview = 'submit'
  private mPreviewMdData: MdData | null = null
  private mPreviewAttrs: MdAttributeInput[] | null = null

  get valuedAttributes(): MdAttributeInput[] {
    return this.settledTuples.filter(tuple => !isEmptyAttr(tuple.attribute))
      .map(tuple => tuple.attribute as MdAttributeInput)
  }

  get settledTuples(): MdFieldTuple[] {
    return this.mEditingFields.filter(tuple => !isEmptyAttr(tuple.attribute) || tuple.attribute.tbd)
  }

  mounted() {
    this.initFromMdData()
  }

  @Watch('mdData')
  private initFromMdData() {
    // build init attributes.
    // 1. init from this.attributes
    const initAttributes: MdAttributeInput[] =
      this.mdData?.attributes?.map(attr => {
        const attrInput: MdAttributeInput = convertAttrValueToInput(attr)
        // 特征属性并且非待补充, 锁住不允许编辑
        if (attrInput.feature && !attr.tbd && this.lockMdData && !!this.mdData.id) {
          this.mLocks[attrInput.fieldId] = true
        }
        return attrInput
      }) || []
    // 2. supply bomCascading attributes
    if (this.bomCascadingCache) {
      _.values(this.bomCascadingCache).filter(bcAttr => !_.find(initAttributes, attr => attr.fieldId !== bcAttr.fieldId))
        .forEach(bcAttr => initAttributes.push(_.cloneDeep(bcAttr)))
    }
    // 3. override with preset attributes
    if (this.presetAttrs) {
      _.values(this.presetAttrs).forEach(presetAttr => {
        let attr = findAttribute(initAttributes, presetAttr.fieldId)
        if (attr) {
          // 属性已经设置过值不变. 先移出来, 等一下统一再push回去
          _.remove(initAttributes, attr => attr.fieldId === presetAttr.fieldId)
        } else {
          // 属性未设置过, 从预设属性初始化
          attr = _.cloneDeep(presetAttr)
        }
        this.mLocks[attr.fieldId] = this.lockMdData
        initAttributes.push(attr)
      })
    }
    this.fetchFieldTemplate(initAttributes.filter(attr => !isEmptyAttr(attr) || attr.tbd))
  }

  private isNewMdData(mdData: MdData | null | undefined) {
    return !mdData || !mdData.id || mdData.id === '0'
  }

  private fetchFieldTemplate(attributes: MdAttributeInput[]) {
    this.$apollo.query({
      query: gql`query matchMdDataAttributeTemplate($attributes: [MdAttributeInput!]!) {
        matchMdDataAttributeTemplate(attributes: $attributes) {
          ... mdFieldWithPayload
        }
      }
      ${MdFieldWithPayloadFragmentDoc}`,
      variables: {
        attributes: attributes.filter(attr => !isEmptyAttr(attr))
      }
    }).then(data => {
      this.initPayload(data.data.matchMdDataAttributeTemplate)
      this.rebuildAttributesByTemplate(data.data.matchMdDataAttributeTemplate, attributes)
    })
  }

  private initPayload(mdFields: MdField[]) {
    mdFields.forEach(field => {
      // 生成uid, 用于重新获取模板后刷新<cmp-md-attr-input/>
      field.uid = _.uniqueId()
      if (!field.payload) {
        field.payload = { indent: 0, extra: {} }
      }
    })
  }

  private rebuildAttributesByTemplate(mdFields: MdField[], attributes: MdAttributeInput[]) {
    let currentAttributes = [...attributes]
    let currentErrors: any = {}
    this.mEditingFields.forEach(tuple => {
      currentErrors[tuple.mdField.id] = tuple.errors
    })

    this.mEditingFields.splice(0, this.mEditingFields.length)
    mdFields.forEach(field => {
      let attr = findAttribute(currentAttributes, field.id)
      this.mEditingFields.push({
        mdField: field,
        attribute: initMdAttributeIfNeeded(attr, field),
        errors: currentErrors[field.id] || []
      })
      if (attr && field.option.bomCascading && this.bomCascadingCache && !this.bomCascadingCache[_.toNumber(field.id)]) {
        this.updateBomCascadingAttr(attr)
      }
    })
    this.countMatchedMdData()
  }

  private rowStyle(indent: number) {
    if (this.mRowStyleCache[indent]) return this.mRowStyleCache[indent]

    const bgColor = uniqolor((indent + 1) * 15, {
      saturation: 50,
      lightness: 90
    })
    this.mRowStyleCache[indent] = {
      'padding-bottom': '10px',
      'padding-top': '10px',
      'border-bottom': '1px solid gainsboro',
      'background-color': bgColor.color
    }
    return this.mRowStyleCache[indent]
  }

  private onAttrValueChanged(attr: any, mdField: MdField) {
    if (mdField.payload?.templateBranch) {
      this.fetchFieldTemplate(this.valuedAttributes)
    }
    if (mdField.option.feature) {
      this.countMatchedMdData()
    }
  }

  private isReadonly(attr: MdAttributeInput) {
    return this.mLocks[attr.fieldId]
  }

  private findMdFieldTuple(fieldId: string | number): MdFieldTuple {
    return _.find(this.mEditingFields,
      tuple => _.toNumber(tuple.mdField.id) === _.toNumber(fieldId))!
  }

  private findMdField(fieldId: string | number): MdField {
    return this.findMdFieldTuple(fieldId).mdField
  }

  private getBomCascadingAttr(fieldId: number | string) {
    return this.bomCascadingCache && this.bomCascadingCache[_.toNumber(fieldId)]
  }

  private isBomCascadingConflict(attr: MdAttributeInput) {
    const cascadingAttr = this.getBomCascadingAttr(attr.fieldId)
    return cascadingAttr && !isAttrEqualsExceptNil(cascadingAttr, attr)
  }

  private updateBomCascadingAttr(attr: MdAttributeInput) {
    if (!attr.value) return // 空值不缓存
    this.$set(this.bomCascadingCache, _.toNumber(attr.fieldId), attr)
    this.$emit('bom-cascading-value-changed', attr)
  }

  private useBomCascadingAttr(attr: MdAttributeInput) {
    attr.value = this.getBomCascadingAttr(attr.fieldId).value
    this.onAttrValueChanged(attr, this.findMdField(attr.fieldId))
  }

  private clearErrors() {
    this.mGeneralErrors.splice(0, this.mGeneralErrors.length)
    this.mEditingFields.forEach(tuple => tuple.errors.splice(0, tuple.errors.length))
  }

  private saveDraft() {
    let savingMdData = this.mdData || {
      code: ''
    }
    if (!savingMdData.uid) savingMdData.uid = uuidv4()
    savingMdData.attributes = this.settledTuples.map(tuple => convertAttrInputToValue(tuple))
    this.$emit('save-draft', savingMdData)
  }

  private setBom() {
    this.preview().then(() => {
      this.mWhereToGoAfterPreview = 'setBom'
    })
  }

  private submit() {
    this.preview().then(() => {
      this.mWhereToGoAfterPreview = 'submit'
    })
  }

  private async preview() {
    this.clearErrors() // 选清除掉之前的校验错误
    let mdDataId = this.mdData?.id
    // 修改了主数据的特征属性
    let featureChanged = mdDataId && !compareFeatureAttributes(this.mdData?.attributes?.filter(attr => {
      // 排除掉这套模板没有涵盖的特征属性
      return _.find(this.mEditingFields, tuple => attr.field.id === tuple.mdField.id)
    }) || [], this.settledTuples)

    if (mdDataId && featureChanged) {
      // 修改主数据, 如果特征属性变更
      await this.$confirm('主数据特征变更, 是否新建主数据?', '提示', {
        confirmButtonText: '继续修改',
        cancelButtonText: '新建主数据',
        cancelButtonClass: 'el-button--success',
        type: 'warning'
      }).catch(() => {
        mdDataId = null
      })
    }

    const attributes = util.objects.stripNil(
      _.cloneDeep(this.mEditingFields.map(tuple => tuple.attribute))) as MdAttributeInput[]
    const ctxHeader: any = {
      'ctx-mdx-data-fix-field-regenerate': JSON.stringify(['all'])
    }
    if (LocalDbDao.getSysSetting('MasterDataStructure', 'setting_mdx_structure_remove_extra_attr')) {
      ctxHeader['mdx-data-remove-attributes-not-in-template'] = true
    }
    return submitPreview(this, {
      previewOptions: {
        query: gql`query previewMdData($mdDataId: ID, $attributes:[MdAttributeInput!]!) {
          previewMdData(mdDataId:$mdDataId, attributes: $attributes) {
            ...mdDataSavingResult,
            attributes { ...mdAttribute }
            errors { ...errorInfo },
            duplicateMdDataCode
          }
        }
        ${MdDataSavingResultFragmentDoc}
        ${MdAttributeFragmentDoc}
        ${ErrorInfoFragmentDoc}`,
        variables: {
          mdDataId,
          attributes
        },
        context: { headers: ctxHeader }
      },
      fullValidate: true,
      collectErrors: data => data.data.previewMdData?.errors,
      handleErrors: errors => {
        if (!_.isArray(errors)) return
        // 装填错误信息
        errors.forEach((err: ErrorInfo) => {
          const attrTuple = err.extra && err.extra.path && this.findMdFieldTuple(err.extra.path)
          if (attrTuple) attrTuple.errors.push(err)
          else this.mGeneralErrors.push(err)
        })
      }
    }).then(data => {
      if (!data || !data.data || !data.data.previewMdData) return
      this.mPreviewMdData = data.data.previewMdData
      if (this.structureKey) this.mPreviewMdData.structureKey = this.structureKey
      this.mPreviewAttrs = attributes

      if (!mdDataId) {
        // 新建主数据, 如果特征属性与已有主数据特征属性一致, Warning提示主数据, 确定再进预览, 变为修改已存在的主数据 #MD1020
        if (this.mPreviewMdData!.id) {
          this.$confirm(`主数据${this.mPreviewMdData!.code}已存在, 是否提交变更?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.mPreviewVisible = true
          })
        } else {
          this.mPreviewVisible = true
        }
      } else if (data.data.previewMdData.duplicateMdDataCode) {
        // 修改主数据, 存在相同特征的其它主数据
        this.$confirm(`主数据特征变更, 与${data.data.previewMdData.duplicateMdDataCode}重复`, {
          confirmButtonText: `查看${data.data.previewMdData.duplicateMdDataCode}`,
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          showMdDataViewer(this, data.data.previewMdData.duplicateMdDataCode!)
        })
      } else {
        this.mPreviewVisible = true
      }
      return this.mPreviewMdData
    })
  }

  private doSubmit() {
    if (this.mWhereToGoAfterPreview === 'submit') {
      this.$emit('save', util.objects.stripField(this.mPreviewMdData, 'errors'),
        this.mPreviewAttrs, this.isNewMdData(this.mPreviewMdData))
    } else if (this.mWhereToGoAfterPreview === 'setBom') {
      this.$emit('set-bom', util.objects.stripField(this.mPreviewMdData, 'errors'),
        this.mPreviewAttrs, this.isNewMdData(this.mPreviewMdData))
    }
    this.mPreviewVisible = false
  }

  private countMatchedMdData() {
    if (!this.selectionEnabled) return
    this.mMdChooserFilterCondition =
      convertAttrTupleToFilterCondition(this.settledTuples
        .filter(tuple => !isEmptyAttr(tuple.attribute))
        .filter(tuple => tuple.mdField.option.feature))

    if (this.mMdChooserFilterCondition.conditions!.length <= 0) {
      // no condition is set, return 0
      this.mMatchedMdDataCount = 0
      return
    }

    const queryVariables: RowRequest = {
      filter: this.mMdChooserFilterCondition
    }
    this.$apollo.query({
      query: gql`query {
        MdDatas(rowRequest: ${buildGraphQLQueryPartInput(queryVariables, true)}) {
          total
        }
      }`
    }).then(data => {
      this.mMatchedMdDataCount = data.data.MdDatas.total
    })
  }

  private onSelectMdData(mdDatas: MdData[]) {
    if (!_.isEmpty(mdDatas)) {
      this.$emit('select', mdDatas[0])
    }
    this.mMdChooserVisible = false
  }

  getFieldName(tuple: MdFieldTuple) {
    return tuple.mdField.payload?.alias || tuple.mdField.name
  }
}
