import { delegate } from '@/d2admin/delegate'
import { ErrorInfo } from '@/module/graphql'
import MenuItem = delegate.MenuItem

export type ID = string | number

export interface UserPrincipal {
  identity: string
  name: string
  userProfile?: any
  adminLevel?: number
}

export interface Setting {
  id: number
  realm: string
  code: string
  value: any
  description?: string
  tags?: string[]
}

export interface ValuedEnum {
  groupName: string
  groupCode: string
  key: string
  value: string
  seq: number
  actions?: { [action: string]: string }
}

export class SyncEtag {
  sysSettingEtag?: string;
  userSettingEtag?: string;
  globalVariableEtag?: string;
  menusEtag?: string;
  valuedEnumsEtag?: string;
}

export class SyncData extends SyncEtag {
  sysSettings?: Setting[];
  userSettings?: Setting[];
  globalVariables?: Setting[];
  menus?: MenuItem[];
  valuedEnums?: ValuedEnum[];

  public getEtags(): SyncEtag {
    return {
      sysSettingEtag: this.sysSettingEtag,
      userSettingEtag: this.userSettingEtag,
      globalVariableEtag: this.globalVariableEtag,
      menusEtag: this.menusEtag,
      valuedEnumsEtag: this.valuedEnumsEtag
    }
  }
}

export enum SelectionMode {
  None = 'none',
  Single = 'single',
  Multiple = 'multiple'
}

export type BatchInput = {
  id: ID,
  ver: number
}

export type TagType = 'primary' | 'gray' | 'success' | 'warning' | 'danger'

export type ProgressEvent = {
  eventType: number,
  executionId: string,
  title: string
  batchTitle: string,
  total?: number,
  progress?: number,
  processCount?: number,
  failedEntitySummary?: string,
  errors?: ErrorInfo[],
  duration?: string
}

export type ProgressState = {
  executionId: string,
  title: string,
  total: number,
  progress: number,
  processCount: number,
  errors: { [entity: string]: ErrorInfo[] },
  duration: string
}
