




import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import LocalDbDao from '@/module/common/local-db-dao'
import { ExplorerType } from '@/module/components/lolth-explorer/explorer-type'
import _ from 'lodash'

declare interface IObjectLabelCellRendererParams extends ICellRendererParams {
  explorerType: string
}

@Component
export default class LolthObjectLabelCellRenderer extends Vue implements ICellRendererComp {
  private params: IObjectLabelCellRendererParams
  private mValue: any = null

  get explorerType(): ExplorerType {
    return LocalDbDao.getExplorerType(this.params.explorerType)
  }

  get label(): string {
    if (!this.explorerType) return ''
    if (_.isArray(this.mValue)) {
      return this.mValue.map(item => this.explorerType.formatLabel(item)).join(',')
    } else {
      return this.explorerType.formatLabel(this.mValue)
    }
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: IObjectLabelCellRendererParams): boolean {
    this.mValue = params.value
    return true
  }
}
