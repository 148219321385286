





import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

@Component
export default class LolthBoolean extends Vue {
  @Prop() readonly value!: boolean | string | number
  @Prop({ default: true }) readonly nullAsFalse!: boolean

  get boolValue() {
    if (_.isNil(this.value) || this.value === '') {
      return this.nullAsFalse ? false : null
    }
    return this.value === 1 || this.value === 'true' ||
      this.value === '1' || this.value === true
  }
}
