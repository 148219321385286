























import { Component, Prop, Vue } from 'vue-property-decorator'
import { MdAttribute } from '@/module/graphql'

@Component
export default class MdAttrViewer extends Vue {
  @Prop() readonly attributes!: MdAttribute[]

  get sortedAttributes() {
    return (this.attributes || []).sort((lhs, rhs) => {
      return this.formatFieldName(lhs).localeCompare(this.formatFieldName(rhs), 'en')
    })
  }

  formatFieldName(attribute: MdAttribute): string {
    return attribute.alias || attribute.field.payload?.alias || attribute.field.name
  }
}
