




















































































import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { MdField, MdoProductOrder, User } from '@/module/graphql'
import LocalDbDao from '@/module/common/local-db-dao'
import { findAttribute } from '@/module/master-data/md-util'
import { LOCAL_DB_CUSTOM_DATA_MDO_FIELDS } from '@/module/plugin-md-product-order/hook'
import CmpMdAttrForm from '@/module/master-data/views/editor/cmp-md-attr-form.vue'

import { MDO_REALM_CODE } from '@/module/common/constants'

@Component({
  components: { CmpMdAttrForm }
})
export default class CmpMdoProductOrderForm extends Vue {
  @Model('change', { required: true })
  readonly productOrder: MdoProductOrder

  @Prop() readonly readonly: boolean

  private mPrincipals: User[] = []
  private mParticipants: User[] = []
  private mMdFields: MdField[] = []

  get isNew() {
    return !this.productOrder?.id
  }

  public get principals() {
    return this.mPrincipals.map((user: { id: any }) => user.id)
  }

  public get participants() {
    return this.mParticipants.map((user: { id: any }) => user.id)
  }

  created() {
    this.mMdFields = LocalDbDao.getCustomData(LOCAL_DB_CUSTOM_DATA_MDO_FIELDS)?.filter(
      (mdField: MdField) => mdField.payload.context.mdoConfig.editWithProductOrder)
    this.mMdFields.forEach(mdField => {
      if (!findAttribute(this.productOrder.attributes, mdField.id)) {
        this.productOrder.attributes.push({
          field: mdField
        })
      }
    })
    this.mPrincipals = this.productOrder.principals.map(userId => {
      return {
        id: userId
      } as User
    })
    this.mParticipants = (this.productOrder.participants || []).map(userId => {
      return {
        id: userId
      } as User
    })
  }

  public static initProductOrder(productOrder: MdoProductOrder = {
    id: '',
    code: '',
    customer: { code: '', name: '', fullName: '' },
    attributes: [],
    principals: [],
    participants: [],
    attachments: []
  } as MdoProductOrder) {
    if (!productOrder.id) {
      productOrder.principals = [LocalDbDao.getPrincipal().identity]
      productOrder.participants.push(
        ...LocalDbDao.getUserSetting(MDO_REALM_CODE,
          'userFollowers')?.value || [])
    }
    if (!productOrder.attributes) productOrder.attributes = []
    if (!productOrder.attachments) productOrder.attachments = []
    return productOrder
  }
}
