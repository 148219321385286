
















































import { Component, Mixins, Model, Prop, Ref, Watch } from 'vue-property-decorator'
import { t } from 'element-ui/lib/locale'
import _ from 'lodash'
import LolthSelectReadonlyMask from '@/module/components/lolth-select-readonly-mask'
import util from '@/d2admin/libs/util'
import { SelectionMode } from '@/module/common/types'
import { CascaderNode } from '@/module/components/types'
import { CascaderProps } from 'element-ui/types/cascader-panel'

const GROUP_CASCADER_PATH_SEPARATOR = '.'

@Component
export default class LolthInputSelect extends Mixins(LolthSelectReadonlyMask) {
  @Ref() readonly elSelect: any

  @Prop({ default: SelectionMode.Single }) readonly selectionMode!: SelectionMode
  @Prop({ default: 'id' }) readonly valueKey!: string

  @Prop() readonly id?: string
  @Prop() readonly name?: string
  @Prop() readonly size?: string
  @Prop({ default: true }) readonly clearable?: boolean
  @Prop() readonly popperClass?: string
  @Prop() readonly itemLabelFormatter!: (item: any) => string
  @Prop({
    default: () => { return t('el.select.placeholder') }
  }) readonly placeholder!: string
  @Prop() readonly collapseTags?: boolean
  @Prop({ default: true }) readonly popperAppendToBody: boolean

  @Prop() readonly group?: CascaderNode[]

  @Prop() readonly optionsFetcher: () => any | Promise<any>
  @Prop() readonly presetValueFetcher?: (items: any[]) => any | Promise<any>

  private mOptionsLoading = false
  private mOptionsLoaded = false
  private mOptions: any[] = []

  @Model('change')
  value: any

  get syncValue(): any {
    return this.resolveValue(this.value)
  }

  set syncValue(val: any) {
    this.$emit('change', this.resolveValue(val))
    // 更新el-cascader的data, 它的checkedValue没有绑定
    if (this.elSelect?.panel) {
      this.elSelect.checkedValue = this.syncValue
    }
  }

  get multiple() {
    return this.selectionMode === SelectionMode.Multiple
  }

  get selectedArray(): any[] {
    if (_.isNil(this.value)) return []
    if (this.multiple) {
      if (!_.isArray(this.value)) {
        throw new Error('v-model should be array')
      }
      return this.value
    } else {
      if (_.isArray(this.value)) {
        throw new Error('v-model should not be array')
      }
      return [this.value]
    }
  }

  get cascaderProps(): CascaderProps<any, any> {
    return {
      emitPath: false,
      multiple: this.multiple
    }
  }

  get groupedOptions(): CascaderNode[] {
    const assembledGroups = _.cloneDeep(this.group)
    const ungroupNode: CascaderNode = {
      label: '未分组',
      leaf: false,
      children: []
    }
    assembledGroups.push(ungroupNode)
    const rootNode = {
      label: 'root',
      leaf: false,
      children: assembledGroups
    } // 用于查找节点
    this.assignCascaderGroupNodeValue(rootNode)
    this.mOptions.forEach(option => {
      if (!option.groupPath) {
        // 未指定groupPath, 归入未分组
        ungroupNode.children.push({
          value: option,
          leaf: true,
          label: this.formatItemLabel(option)
        })
      } else {
        // 找到节点, 放入children
        const node = this.findCascaderNode(
          rootNode, option.groupPath) || ungroupNode
        if (!node.children) node.children = []
        node.children.push({
          value: option,
          leaf: true,
          label: this.formatItemLabel(option)
        })
      }
    })
    return assembledGroups
  }

  assignCascaderGroupNodeValue(node: CascaderNode) {
    node.leaf = false
    if (!node.value) node.value = '_cascader_group_' + node.label
    node.children?.forEach(child => this.assignCascaderGroupNodeValue(child))
  }

  findCascaderNode(rootNode: CascaderNode, groupPath: string): CascaderNode | undefined {
    let currentNode: CascaderNode = rootNode
    for (let part of groupPath.split(GROUP_CASCADER_PATH_SEPARATOR)) {
      let matchNode = _.find(currentNode.children, node => node.label === part)
      if (matchNode) {
        currentNode = matchNode
      } else {
        return undefined
      }
    }
    return currentNode
  }

  countAllChildCascaderNodes(node: CascaderNode): number {
    if (node.leaf) return 1
    if (!node.leaf && !node.children) return 0
    return node.children.map(child => this.countAllChildCascaderNodes(child))
      .reduce((total, current) => total + current, 0)
  }

  created() {
    this.setFakeOptionsIfNeeded()
  }

  updated() {
    if (this.selectedArray.length) this.setFakeOptionsIfNeeded()
  }

  /* 设置fakeOptions, 使预设值能正确显示 */
  protected setFakeOptionsIfNeeded() {
    if (this.mOptionsLoaded || !this.value) return

    // 因为在每个updated回调里面都会触发, 需要检查是否已经设置过fakeOptions
    const valueKeys = this.resolveValueKeys(this.selectedArray)
    const optionKeys = this.resolveValueKeys(this.mOptions)
    let stubOptionsMatched = optionKeys === valueKeys
    if (stubOptionsMatched) return

    this.mOptions = this.selectedArray

    if (this.selectedArray && this.needFetchPresetValue()) {
      Promise.resolve(this.doFetchPresetValue(this.selectedArray))
        .then(remoteValues => {
          this.syncValue = remoteValues
          if (remoteValues) this.$nextTick(() => { this.mOptions = this.selectedArray })
        })
    }
  }

  private resolveValue(val: any) {
    let convertedVal: any
    if (!val) convertedVal = val // 直接返回空值
    else {
      if (this.multiple) { // 多选, this.value应该是array
        convertedVal = _.isArray(val) ? val : [val]
        // 尝试从options里面获取值, 如果没有的话还是用设进来的val
        if (this.elSelect?.panel) {
          convertedVal = convertedVal.map((item: any) => {
            return this.resolveValueFromOptions(item) || item
          })
        }
      } else { // 单选, this.value应该是object
        if (_.isArray(val)) {
          convertedVal = (val.length > 0 && val[0]) ? val[0] : null
        } else {
          convertedVal = val
        }
        // 尝试从options里面获取值, 如果没有的话还是用设进来的val
        if (this.elSelect?.panel) {
          convertedVal = this.resolveValueFromOptions(convertedVal) || convertedVal
        }
      }
    }
    return convertedVal
  }

  private resolveValueFromOptions(val: any) {
    return _.find(this.mOptions, option =>
      this.resolveValueKey(option) === this.resolveValueKey(val))
    // const optionsVal = _.find(this.mOptions, option =>
    //         this.resolveValueKey(option) === this.resolveValueKey(val))
    // if (optionsVal && optionsVal !== val) {
    //   Object.assign(optionsVal, val)
    // }
    // return optionsVal
  }

  private resolveValueKey(item: any): string {
    return _.isPlainObject(item) ? _.get(item, this.getValueKey()) : item
  }

  private resolveValueKeys(val: any): string {
    if (_.isNil(val)) return ''
    if (!_.isArray(val)) {
      val = [val]
    }
    return val.map((item: any) => this.resolveValueKey(item)).sort().join('|')
  }

  protected formatItemLabel(item: any) {
    return this.itemLabelFormatter ? this.itemLabelFormatter(item)
      : _.isPlainObject(item) ? item?.name : item
  }

  private fetchOptionsInternal(visible: boolean) {
    if (!visible || this.mOptionsLoaded) return

    this.mOptionsLoading = true
    Promise.resolve(this.doFetchOptions())
      .then(remoteValues => {
        this.mOptions = remoteValues
        this.mOptionsLoaded = true
        this.mOptionsLoading = false

        // el-cascader是按照对象来找的, 所以当options拉下来以后, 跟原来的fakeOptions
        // 就不一致了, 所以要根据valueKey从mOptions里面找到对应的对象更新syncValue
        if (this.elSelect?.panel) {
          this.syncValue = this.resolveValue(this.selectedArray.map(selected => {
            return this.resolveValueFromOptions(selected)
          }))
          // 外部设值可能没带groupPath, 清除掉重新生成
          util.objects.clear(this.elSelect?.panel.activePath)
        }

        return this.mOptions
      })
  }

  @Watch('optionsFetcher')
  public resetOptionsLoaded() {
    util.objects.clear(this.mOptions)
    this.mOptionsLoaded = false
  }

  //* **************************************************************************
  // Abstraction Interfaces
  //* **************************************************************************

  protected getValueKey() {
    return this.valueKey
  }

  protected needFetchPresetValue(): boolean {
    return !_.isNil(this.presetValueFetcher)
  }

  protected doFetchPresetValue(items: any[]): any | Promise<any> {
    return this.presetValueFetcher && this.presetValueFetcher(items)
  }

  protected doFetchOptions(): any | Promise<any> {
    if (!this.optionsFetcher) {
      throw new Error('optionsFetcher is not defined')
    }
    return this.optionsFetcher()
  }
}
