








import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'

declare interface IExplorerMdAttrCellRendererComp extends ICellRendererParams {
  attributesField?: string
  sortByMdField?: boolean
  showFieldName?: boolean
  distinct?: boolean
}

/**
 * 对应两种格式的值:
 * 1. 整个attributes + attributesField, 需要通过gridOptions找到列定义colDef, 并将里面并调整其中的`cellRendererParams`
 * 2. MdAttribute
 */
@Component
export default class CmpMdAttributesInlineCellRenderer extends Vue implements ICellRendererComp {
  private params!: IExplorerMdAttrCellRendererComp
  private mValue: any = null

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: IExplorerMdAttrCellRendererComp): boolean {
    if (params.attributesField) {
      this.mValue = params.data[this.params.attributesField]
    } else {
      this.mValue = params.value || []
    }
    return true
  }
}
