
































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { MdInvoice } from '@/module/graphql'
import _ from 'lodash'

@Component
export default class CmpMdInvoiceForm extends Vue {
  @Prop() readonly: boolean
  @Prop() invoice: MdInvoice

  @PropSync('visible')
  syncedVisible!: boolean

  get title() {
    if (this.readonly) return '查看发票'
    return this.invoice && this.invoice.id ? '编辑发票' : '新增发票'
  }

  get detailItems() {
    var items = []
    for (const k in this.invoice.data) {
      if (_.isArray(this.invoice.data[k])) {
        items = this.invoice.data[k]
      }
    }
    return items
  }

  created() {

  }

  isNestedField(value: string | object) {
    return _.isObject(value)
  }
  upperFirst(str: string | number) {
    return str.toString().substring(0, 1).toUpperCase() + str.toString().substring(1, str.toString().length)
  }
}
