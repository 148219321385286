




import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import _ from 'lodash'

declare interface IExplorerListItemConcatCellRendererComp extends ICellRendererParams {
  separator?: null
}

@Component
export default class LolthListItemConcatCellRenderer extends Vue implements ICellRendererComp {
  private params!: IExplorerListItemConcatCellRendererComp
  private mValue: any = null

  get formattedValue() {
    if (_.isNil(this.mValue)) return ''
    if (!_.isArray(this.mValue)) return ''
    return this.mValue.map(v => _.toString(v)).join(this.separator)
  }

  get separator() {
    return this.params.separator || '|'
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: IExplorerListItemConcatCellRendererComp): boolean {
    this.mValue = params.value
    return true
  }
}
