



















































import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator'
import {
  MdeWarehouse,
  MdExtendData,
  MutationCreateMdeWarehouseArgs,
  MutationUpdateMdeWarehouseArgs
} from '@/module/graphql'
import util from '@/d2admin/libs/util'
import gql from 'graphql-tag'
import { buildGraphQLQueryPartInput, submitMutation } from '@/module/common/util/graphql-util'
import _ from 'lodash'

@Component
export default class CmpMdeWarehouseForm extends Vue {
  @Prop({ default: true }) readonly readonly?: boolean
  @Prop() readonly edit?: boolean
  @Prop() readonly mdExtendData: MdExtendData

  @ModelSync('value', 'change', { required: true })
  syncedValue!: MdeWarehouse

  created(): void {
    if (this.mdExtendData) {
      this.$apollo.query({
        query: gql`query {
            MdeWarehouse(code: "${this.mdExtendData.dataId}") {
              code ver name note address allowExceed defect lineSide limitation countAsAvailable
            }
          }`
      }).then(data => {
        this.syncedValue = data.data.MdeWarehouse
      })
    }
  }

  submit() {
    let mutation:string
    let postValues = _.cloneDeep(this.syncedValue) as any
    if (postValues.limitation) {
      postValues.limitation = parseFloat(this.syncedValue.limitation as any)
    } else {
      postValues.limitation = null
    }
    delete postValues.ver
    let mutationArgs: MutationCreateMdeWarehouseArgs | MutationUpdateMdeWarehouseArgs
    if (this.edit) {
      mutation = 'updateMdeWarehouse'
      delete postValues.code
      mutationArgs = {
        code: this.syncedValue.code,
        ver: this.syncedValue.ver || 0,
        values: {
          ...postValues
        }
      }
    } else {
      mutation = 'createMdeWarehouse'
      mutationArgs = {
        ...postValues
      }
    }
    const mutationOptions: any = {
      mutation: gql(`mutation {
        ${mutation}(${buildGraphQLQueryPartInput(mutationArgs)}) {
          code, ver, name, note
        }
      }`)
    }
    submitMutation(this, {
      mutationOptions,
      fullValidate: true
    }).then(data => {
      this.$message({
        message: '保存成功', type: 'success', showClose: true
      })
      this.$emit('submit', this.syncedValue)
    })
  }

  public static initMdeWarehouse(mdeWarehouse: MdeWarehouse = {
    code: '', name: ''
  }) {
    util.objects.reactive(mdeWarehouse)
    return mdeWarehouse
  }
}
