




import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'

declare interface IExplorerCollapseTextCellRendererComp extends ICellRendererParams {
  line?: number
}

// TODO 展开后要重新计算行高度
@Component
export default class LolthCollapseTextCellRenderer extends Vue implements ICellRendererComp {
  private params: IExplorerCollapseTextCellRendererComp
  private mValue: string = null
  private mLine: number = null

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: IExplorerCollapseTextCellRendererComp): boolean {
    this.mValue = params.value
    this.mLine = params.line || null
    return true
  }
}
