import { Module } from 'vuex'
import { AttrIcon, MasterDataStore, MdExtendDataDetailPanel } from '@/module/master-data/store/index'

const module: Module<MasterDataStore, any> = {
  namespaced: true,
  state: {
    attrIcons: [{
      matcher: 'feature',
      description: '特征属性',
      class: 'text-success',
      icon: 'star'
    }, {
      matcher: 'bomFeature',
      description: 'BOM特征属性',
      class: 'text-success',
      icon: 'star-half-o'
    }, {
      matcher: 'mandatory',
      description: '必填',
      class: 'ic-required',
      icon: 'asterisk'
    }, {
      matcher: 'bomCascading',
      description: 'BOM级联特征属性',
      color: '#FF8C00',
      icon: 'chevron-circle-down'
    }, {
      matcher: 'tbd',
      description: '待确认',
      class: 'text-warning',
      icon: 'flag'
    }, {
      matcher: (attr) => attr?.field?.dataType === '文字',
      description: '文字',
      class: 'text-info',
      icon: 'font'
    }, {
      matcher: (attr) => attr?.field?.dataType === '数字',
      description: '数字',
      class: 'text-info',
      icon: 'sort-numeric-asc'
    }, {
      matcher: (attr) => attr?.field?.dataType === '是否',
      description: '是否',
      class: 'text-info',
      icon: 'check-square'
    }, {
      matcher: (attr) => attr?.field?.dataType === '日期',
      description: '日期',
      class: 'text-info',
      icon: 'calendar'
    }, {
      matcher: (attr) => attr?.field?.dataType === '附件',
      description: '附件',
      class: 'text-info',
      icon: 'paperclip'
    }, {
      matcher: (attr) => attr?.field?.dataType === '选项表',
      description: '选项表',
      class: 'text-info',
      icon: 'list'
    }, {
      matcher: (attr) => attr?.field?.dataType === '多选选项表',
      description: '多选选项表',
      class: 'text-info',
      icon: 'list-alt'
    }, {
      matcher: (attr) => attr?.extra?.source,
      description: (attr) => '来源' + attr?.extra?.source,
      class: 'text-info',
      icon: 'binoculars'
    }],
    mdExtendDataDetailPanels: []
  },
  getters: {
    attrIcons(state) {
      return state.attrIcons
    },
    mdExtendDataDetailPanels(state) {
      return state.mdExtendDataDetailPanels
    }
  },
  mutations: {
    defineIcons(state, attrIcons:AttrIcon[]) {
      attrIcons.forEach(i => state.attrIcons.push(i))
    },
    registerMdExtendDataDetailPanels(state, panels:MdExtendDataDetailPanel[]) {
      panels.forEach(p => state.mdExtendDataDetailPanels.push(p))
    }
  }
}

export default module
