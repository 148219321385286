




































































































































































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import {
  ErrorInfo,
  MdField,
  MdFieldFragmentDoc,
  MdpCustomComponentInput,
  MdpFinModel,
  MdpFinModelFragmentDoc,
  MdpFinSize
} from '@/module/graphql'

import _ from 'lodash'
import gql from 'graphql-tag'
import { convertAttrValueToInput, filterEmptyAttr } from '@/module/master-data/md-util'
import CmpMdAttrForm from '@/module/master-data/views/editor/cmp-md-attr-form.vue'
import { ID } from '@/module/common/types'
import { v4 as uuidv4 } from 'uuid'
import util from '@/d2admin/libs/util'
import { Indexed } from '@/d2admin/delegate'
import LolthEditableTable from '@/module/components/lolth-editable-table.vue'
import { submitMutation } from '@/module/common/util/graphql-util'

type FlattenJsonValue = {
  key: string
  value?: string
}

/* 产品结构编辑 */
@Component({
  components: { CmpMdAttrForm }
})
export default class CmpMdpFinModelForm extends Vue {
  @Ref() readonly vSizeTable: LolthEditableTable

  @Prop() entityId: ID
  @Prop() disabled: boolean

  private mModel: MdpFinModel = null
  private mSpecialMdFields: MdField[] = null
  private mOtherMdFields: MdField[] = null
  private mFeatureMdFields: MdField[] = null

  private mJsonValueEditorVisible = false
  private mJsonValueField = ''
  private mJsonValueFieldName = ''
  private mEditingSize: MdpFinSize = null
  private mEditingJsonValue: FlattenJsonValue[] = []
  private mJsonValueErrors: { [row: number]: ErrorInfo } = {}
  private mCustomComponentErrors: { [row: number]: ErrorInfo } = {}

  created() {
    this.mModel = {
      code: null,
      name: null,
      description: null,
      specialAttributes: [],
      otherAttributes: [],
      customComponents: [],
      sizes: []
    }
    if (this.entityId) {
      this.loadData()
    }

    this.$apollo.query({
      query: gql`query{
        getMdpFinModelCatalogConfig {
          featureFields { ... mdField }
          specialFields { ...mdField }
          otherFields { ...mdField }
        }
      }
      ${MdFieldFragmentDoc}`
    }).then(resp => {
      this.mFeatureMdFields = resp.data.getMdpFinModelCatalogConfig?.featureFields || []
      this.mSpecialMdFields = resp.data.getMdpFinModelCatalogConfig?.specialFields || []
      const otherMdFields = resp.data.getMdpFinModelCatalogConfig?.otherFields || []
      this.mOtherMdFields = _.uniqBy([...otherMdFields,
        ...(this.mModel.otherAttributes?.map(attr => attr.field) || [])], 'id')
    })
  }

  private itemLabelFormatter(item: any) {
    if (!item) return ''
    if (item.keyValue) {
      return item.keyValue
    } else {
      return item.value + '(' + item.key + ')'
    }
  }

  private loadData() {
    this.$apollo.query({
      query: gql`query {
        MdpFinModel(id: ${this.entityId}) {
          ...mdpFinModel
          featureFields { ...mdField }
          sizes { ...mdpFinSize }
          customComponents {
            name mdFields { ...mdField }
          }
        }
      }
      ${MdFieldFragmentDoc}
      ${MdpFinModelFragmentDoc}`
    }).then(resp => {
      const model = resp.data.MdpFinModel
      if (!model.specialAttributes) model.specialAttributes = []
      if (!model.otherAttributes) model.otherAttributes = []
      this.mModel = model
      if (!this.mModel.customComponents) this.$set(this.mModel, 'customComponents', [])
      this.mOtherMdFields = _.uniqBy([...(this.mOtherMdFields || []), ...(this.mModel.otherAttributes?.map(attr => attr.field) || [])], 'id')
    })
  }

  formatJsonValues(jsonValue: Indexed) {
    if (!jsonValue) return ''
    return _.keys(jsonValue)
      .map(key => jsonValue[key] && `${key}:${jsonValue[key]}`)
      .filter(t => t).join(';')
  }

  addSize() {
    const newSize = {
      uid: uuidv4(),
      applicable: null,
      dictKey: null,
      dictValue: null,
      size: null,
      sizeLeagues: {},
      measures: {},
      weights: {}
    } as MdpFinSize
    this.vSizeTable.addRow(newSize)
    this.$apollo.query({
      query: gql`query {
        getMdpFinMaxDictKey
      }`
    }).then(resp => {
      if (resp.data.getMdpFinMaxDictKey) {
        newSize.dictKey = resp.data.getMdpFinMaxDictKey + ''
      }
    })
  }

  private onSizeSaved(index: number, row: MdpFinSize, editingRow: MdpFinSize) {
    if (!editingRow.size) {
      this.$message({ message: '尺码必填', type: 'error', showClose: true })
      return false
    }
    if (row !== editingRow) {
      row.applicable = editingRow.applicable
      row.size = editingRow.size
      row.dictKey = editingRow.dictKey
      row.dictValue = editingRow.dictValue
      row.sizeLeagues = editingRow.sizeLeagues
      row.measures = editingRow.measures
      row.weights = editingRow.weights
      row.onShelf = editingRow.onShelf
    }
    return true
  }

  private doCopySize(row: MdpFinSize) {
    const copied = _.cloneDeep(row)
    copied.uid = uuidv4()
    return copied
  }

  editJsonValue(editingRow: MdpFinSize, property: string) {
    switch (property) {
      case 'sizeLeagues':
        this.mJsonValueFieldName = '尺码对照'
        this.mEditingJsonValue = _.keys(editingRow.sizeLeagues || [])
          .map(key => {
            return { key, value: editingRow.sizeLeagues[key] }
          })
        if (!this.mEditingJsonValue.length) {
          this.mEditingJsonValue = [{ key: 'EU' }, { key: 'UK' }, { key: 'US' }]
        }
        break
      case 'measures':
        this.mJsonValueFieldName = '尺寸'
        this.mEditingJsonValue = _.keys(editingRow.measures || [])
          .map(key => {
            return { key, value: editingRow.measures[key] }
          })
        if (!this.mEditingJsonValue.length) {
          this.mEditingJsonValue = [{ key: 'A(mm)' }, { key: 'B(mm)' }, { key: 'C(mm)' }, { key: 'D(mm)' }, { key: 'E(mm)' }]
        }
        break
      case 'weights':
        this.mJsonValueFieldName = '重量'
        this.mEditingJsonValue = _.keys(editingRow.weights || [])
          .map(key => {
            return { key, value: editingRow.weights[key] }
          })
        if (!this.mEditingJsonValue.length) {
          this.mEditingJsonValue = [{ key: '3D鞋重量(g)' }, { key: '3D整体重量(g)' }]
        }
        break
    }
    this.mJsonValueField = property
    this.mEditingSize = editingRow
    this.mJsonValueEditorVisible = true
  }

  saveJsonValue() {
    util.objects.clear(this.mJsonValueErrors)

    const editingProperty: Indexed = {}
    for (let i = 0; i < this.mEditingJsonValue.length; i++) {
      const jsonValue = this.mEditingJsonValue[i]
      if (!jsonValue.key) {
        this.$set(this.mJsonValueErrors, i, {
          ec: -1,
          msg: '必填',
          elv: 'error'
        })
        continue
      }
      if (_.has(editingProperty, jsonValue.key)) {
        this.$set(this.mJsonValueErrors, i, {
          ec: -1,
          msg: '数据重复',
          elv: 'error'
        })
        continue
      }
      editingProperty[jsonValue.key] = jsonValue.value
    }
    if (!_.isEmpty(this.mJsonValueErrors)) {
      this.$message({
        message: '数据有误, 请检查后重新提交', type: 'error', showClose: true
      })
      return
    }
    this.$set(this.mEditingSize, this.mJsonValueField, editingProperty)
    this.mJsonValueEditorVisible = false
  }

  public submit() {
    if (this.vSizeTable.editing) {
      this.$message({ message: '请先保存编辑中的尺码', type: 'warning', showClose: true })
      return
    }

    util.objects.clear(this.mCustomComponentErrors)
    const compNames: Indexed = {}
    for (let i = 0; i < this.mModel.customComponents?.length; i++) {
      const customComp = this.mModel.customComponents[i]
      if (!customComp.name) {
        this.$set(this.mCustomComponentErrors, i, {
          ec: -1,
          msg: '必填',
          elv: 'error'
        })
        continue
      }
      if (compNames[customComp.name]) {
        this.$set(this.mCustomComponentErrors, i, {
          ec: -1,
          msg: '部件名称重复',
          elv: 'error'
        })
        continue
      }
      if (_.isEmpty(customComp.mdFields)) {
        this.$set(this.mCustomComponentErrors, i, {
          ec: -1,
          msg: '字段必填',
          elv: 'error'
        })
        continue
      }
      compNames[customComp.name] = customComp.name
    }
    if (!_.isEmpty(this.mCustomComponentErrors)) return

    const input = _.cloneDeep(this.mModel)
    const sizesInput = input.sizes
    sizesInput.forEach(size => {
      _.set(size, 'applicableId', size.applicable?.id)
      delete size.id
      delete size.ver
      delete size.applicable
      delete size.seq
      delete (size as any).__typename
    })
    const specialAttributes = filterEmptyAttr(input.specialAttributes).map(convertAttrValueToInput)
    const otherAttributes = filterEmptyAttr(input.otherAttributes).map(convertAttrValueToInput)
    const customComponents: MdpCustomComponentInput[] = this.mModel.customComponents.map(comp => {
      return {
        name: comp.name,
        mdFieldIds: comp.mdFields.map(f => f.id)
      }
    })

    submitMutation(this, {
      mutationOptions: {
        mutation: gql`mutation saveMdpFinModel(
          $id: ID
          $ver: Int
          $code: String!, $name: String!, $description: String
          $specialAttributes: [MdAttributeInput]
          $otherAttributes: [MdAttributeInput]
          $featureFieldIds: [ID]
          $customComponents: [MdpCustomComponentInput]
          $sizes: [MdpFinSizeInput]
        ) {
          saveMdpFinModel(
            id: $id, ver: $ver
            code: $code, name: $name, description: $description
            specialAttributes: $specialAttributes
            otherAttributes: $otherAttributes
            featureFieldIds: $featureFieldIds
            customComponents: $customComponents
            sizes: $sizes
          ) { id }
        }`,
        variables: Object.assign(input, {
          specialAttributes: specialAttributes,
          otherAttributes: otherAttributes,
          featureFieldIds: input.featureFields?.map(field => field.id),
          customComponents,
          sizes: sizesInput
        })
      }
    }).then(resp => {
      this.$message({ message: '保存成功', type: 'success', showClose: true })
      this.$emit('saved')
    })
  }
}
