









































import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { MdoEndProduct, MdoEndProductFragmentDoc, MdoEndProductInfo, MdoEndProductInfoTemplate } from '@/module/graphql'
import LocalDbDao from '@/module/common/local-db-dao'
import CmpMdAttrForm from '@/module/master-data/views/editor/cmp-md-attr-form.vue'
import _ from 'lodash'
import { ID, ValuedEnum } from '@/module/common/types'
import { LOCAL_DB_CUSTOM_DATA_MDO_EP_INFO_TEMPLATES } from '@/module/plugin-md-product-order/hook'
import gql from 'graphql-tag'
import CmpMdDataBomViewer from '@/module/master-data/views/components/cmp-md-data-bom-viewer.vue'

@Component({
  components: { CmpMdAttrForm, CmpMdDataBomViewer }
})
export default class ICmpMdoEndProductDialog extends Vue {
  @Prop() readonly readonly: boolean

  @Model('change', { required: true }) readonly endProduct: MdoEndProduct

  private mVisible = true
  private mEndProduct: MdoEndProduct = null
  private mOpenedPanes: { [typeKey: string]: string[] } = {}

  private mTemplateTypes: readonly ValuedEnum[] = Object.freeze(
    _.sortBy(LocalDbDao.getValuedEnum('MdoEndProductInfoType'), 'key'))
  private mTemplates: readonly MdoEndProductInfoTemplate[] = Object.freeze(
    LocalDbDao.getCustomData(LOCAL_DB_CUSTOM_DATA_MDO_EP_INFO_TEMPLATES) || [])

  created() {
    this.$apollo.query({
      query: gql`query {
          MdoEndProduct(id: "${this.endProduct.id}") {
            ... mdoEndProduct
          }
        }
        ${MdoEndProductFragmentDoc}`
    }).then(data => {
      this.mEndProduct = data.data.MdoEndProduct
      // 默认展开所有成品属性
      this.mEndProduct.infos.forEach(epInfo => {
        const typeInStr = _.toString(epInfo.type)
        if (!this.mOpenedPanes[typeInStr]) this.$set(this.mOpenedPanes, typeInStr, [])
        this.mOpenedPanes[typeInStr].push(epInfo.id)
      })
    })
  }

  getTemplateByTypeAndName(type: ID, name: string): MdoEndProductInfoTemplate | undefined {
    return _.find(this.mTemplates,
      template => template.type === _.toInteger(type) && template.name === name)
  }

  getEpInfos(type: string | number): MdoEndProductInfo[] {
    const epInfos = this.mEndProduct.infos.filter(info => info.type === _.toInteger(type)) || []
    // 移除掉禁用模板的成品属性
    _.remove(epInfos, epInfo => !this.getTemplateByTypeAndName(
      epInfo.type, epInfo.name))
    return epInfos
  }
}
