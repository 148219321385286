








import { Component, Vue } from 'vue-property-decorator'
import { FilterChangedEvent, IFloatingFilterComp, IFloatingFilterParams } from 'ag-grid-community'

@Component
export default class LolthBoolFloatingFilter extends Vue implements IFloatingFilterComp {
  private params: IFloatingFilterParams | null = null
  private mValue: boolean | null = null

  get yesLabel() {
    const customProps = (this.params?.filterParams as any).viewDef?.customProps || {}
    return customProps.yesLabel || '是'
  }

  get noLabel() {
    const customProps = (this.params?.filterParams as any).viewDef?.customProps || {}
    return customProps.noLabel || '否'
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent): void {
    this.mValue = !parentModel ? '' : parentModel.value
  }

  onValueChanged() {
    this.params?.parentFilterInstance(instance => {
      (instance as any).onFloatingFilterChanged({
        value: this.mValue
      })
    })
  }
}
