




























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { MdUnit } from '@/module/graphql'
import gql from 'graphql-tag'
import { submitMutation } from '@/module/common/util/graphql-util'

@Component
export default class CmpMdUnitEditorDialog extends Vue {
  @Prop() readonly: boolean
  @Prop() mdUnitCode: string

  @PropSync('visible')
  syncedVisible!: boolean

  private mMdUnit: MdUnit = null

  get title() {
    if (this.readonly) return '查看单位'
    return this.mdUnitCode ? '编辑单位' : '新增单位'
  }

  created() {
    if (this.mdUnitCode) {
      this.$apollo.query({
        query: gql`query {
          MdUnit(id: "${this.mdUnitCode}") {
            ver code note
          }
        }`
      }).then(data => {
        this.mMdUnit = this.initMdUnit(data.data.MdUnit)
      })
    } else {
      this.mMdUnit = this.initMdUnit({} as MdUnit)
    }
  }

  initMdUnit(mdUnit: MdUnit) {
    if (!mdUnit.code) mdUnit.code = ''
    if (!mdUnit.note) mdUnit.note = ''
    return mdUnit
  }

  saveMdUnit() {
    submitMutation(this, {
      mutationOptions: {
        mutation: gql`mutation ($code: ID, $ver: Int, $note: String) {
          saveMdUnit(code: $code, ver: $ver, note: $note) {
            code ver
          }
        }`,
        variables: {
          code: this.mMdUnit.code,
          ver: this.mMdUnit.ver,
          note: this.mMdUnit.note
        }
      }
    }).then(() => {
      this.$message({
        message: '保存成功', type: 'success', showClose: true
      })
      this.$emit('saved')
      this.syncedVisible = false
    })
  }
}
