
















import { Component, Prop, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { MdoEndProduct } from '@/module/graphql'

@Component
export default class CmpMdoEndProductSummary extends Vue {
  @Prop() readonly productOrderCode: string

  private mSummaries: MdoEndProduct[] = []

  created() {
    this.$apollo.query({
      query: gql`query mdoEndProductSummary($productOrderCode: String!) {
          mdoEndProductSummary(productOrderCode: $productOrderCode) {
            categoryKey categoryKeyValue productModel orderQty endProductCount packing
          }
        }`,
      variables: {
        productOrderCode: this.productOrderCode
      }
    }).then(data => {
      this.$set(this, 'mSummaries', data.data.mdoEndProductSummary)
    })
  }
}
