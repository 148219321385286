











import { Component } from 'vue-property-decorator'
import BaseAttrInput from './base-attr-input'
import _ from 'lodash'

@Component
export default class AttrInputAttachment extends BaseAttrInput {
  get syncValue() {
    if (!this.attribute.value) return []
    return this.attribute.value
  }

  set syncValue(val: string[]) {
    this.attribute.value = val
    this.$emit('attr-value-changed', this.attribute.value)
  }

  get accept() {
    if (!this.mdField.option?.fileTypes) return
    return this.mdField.option?.fileTypes.map((type: string) => '.' + type).join(',')
  }

  get vendor() {
    return this.attribute.extra?.vendor || this.mdField.option?.vendor
  }

  mounted() {
    this.setupBindingFields()
    this.initValue()
  }

  private initValue() {
    if (_.isNil(this.attribute.value)) {
      this.$set(this.attribute, 'value', [])
    }
  }
}
