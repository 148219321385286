import { RouteConfig } from 'vue-router/types/router'
import { ModuleTree } from 'vuex/types'
import { ModuleHook } from '@/d2admin/module/types'
import _ from 'lodash'

function scan(filename : string): any {
  const moduleConfig: { [moduleName: string]: RouteConfig[] } = {}
  const push = (context: __WebpackModuleApi.RequireContext) => context.keys().map(key => {
    let module = context(key)
    let moduleName: string
    const matches = key.match(/\.\/(.*?)\//)
    if (matches && matches.length > 1) {
      moduleName = matches[1]
    }
    if (moduleName && module.default) moduleConfig[moduleName] = module.default
  })

  // filter regexp must be literal
  // https://github.com/webpack/webpack/issues/9300
  switch (filename) {
    case 'routes' :
      push(require.context('@/module', true, /routes\.(js|ts)$/))
      break
    case 'store' :
      push(require.context('@/module', true, /store\/index\.(js|ts)$/))
      break
    case 'hook' :
      push(require.context('@/module', true, /hook\.(js|ts)$/))
      break
  }

  return moduleConfig
}

export class ModuleLoader {
  readonly moduleRoutes: { [moduleName: string]: RouteConfig[] }
  readonly moduleStores: ModuleTree<any>
  readonly moduleHooks: { [moduleName: string]: ModuleHook }

  constructor() {
    this.moduleRoutes = {}
    this.moduleStores = {}
    this.moduleHooks = {}
  }

  public loadRoutes(moduleName: string, routes: any) {
    this.moduleRoutes[moduleName] = routes
  }

  public loadStores(moduleName: string, store: any) {
    this.moduleStores[moduleName] = store
  }

  public loadHooks(moduleName: string, hooks: any) {
    this.moduleHooks[moduleName] = hooks
  }

  get routes(): RouteConfig[] {
    return _.flatMap(this.moduleRoutes)
  }

  get stores(): ModuleTree<any> {
    return this.moduleStores
  }

  get hooks(): ModuleHook[] {
    return _.flatMap(this.moduleHooks)
  }
}

const moduleLoader = new ModuleLoader()

if (process.env.VUE_APP_PROFILES) {
  const profile = require('@/profiles').default
  for (const moduleName in profile.routes) {
    moduleLoader.loadRoutes(moduleName, profile.routes[moduleName])
  }
  for (const moduleName in profile.stores) {
    moduleLoader.loadStores(moduleName, profile.stores[moduleName])
  }
  for (const moduleName in profile.hooks) {
    moduleLoader.loadHooks(moduleName, profile.hooks[moduleName])
  }
} else {
  Object.assign(moduleLoader.moduleRoutes, scan('routes'))
  Object.assign(moduleLoader.moduleStores, scan('store'))
  Object.assign(moduleLoader.moduleHooks, scan('hook'))
}

export default moduleLoader
