































import { Component, Prop } from 'vue-property-decorator'
import { FilterCondition, FilterType, KtiTalent } from '@/module/graphql'
import BaseFieldFilter from '@/module/components/lolth-filter/base-field-filter'
import util from '@/d2admin/libs/util'

@Component
export default class CmpKtiTalentFilter extends BaseFieldFilter {
  @Prop({ default: true }) readonly emptyOption: boolean
  @Prop({ default: 'single' }) readonly refType: 'single' | 'array'

  private mSelectValue: KtiTalent = null
  private mOperator = 'in'

  private operators = ['empty', 'in', 'equals']

  get syncSelectValue(): KtiTalent {
    if (this.mOperator !== 'in') return null
    return this.mSelectValue
  }

  set syncSelectValue(user: KtiTalent) {
    this.mSelectValue = user
    this.filterCondition.value = user.id
  }

  get syncOperator() {
    return this.mOperator
  }

  set syncOperator(operator: string) {
    this.mOperator = operator
    this.filterCondition.operator = operator
  }

  get isValid() {
    return !(!this.filterCondition.filterType ||
      !this.filterCondition.field ||
      !this.filterCondition.operator ||
      !this.filterCondition.value ||
      this.filterCondition.value.length <= 0)
  }

  get defaultOperator() {
    return this.refType === 'single' ? 'equals' : 'in'
  }

  created(): void {
    if (this.refType === 'single') {
      util.objects.remove(this.operators, 'in')
    } else {
      util.objects.remove(this.operators, 'equals')
    }

    this.initFilterCondition(this.filterCondition)
    this.updateFilterType(this.filterCondition.operator!)
    if (this.filterCondition.value) {
      if (this.mOperator !== 'empty') {
        this.syncSelectValue = {
          id: this.filterCondition.value,
          name: this.filterCondition.extra?.userName ?? this.filterCondition.value
        } as KtiTalent
      }
    }
  }

  public initFilterCondition(filterCondition: FilterCondition) {
    if (!filterCondition.operator) this.syncOperator = this.defaultOperator
    if (!filterCondition.value) filterCondition.value = null
    util.objects.reactive(filterCondition)
  }

  public updateFilterType(operator: string) {
    if (operator === 'empty') {
      this.filterCondition.filterType = FilterType.IsNull
    } else if (operator === 'in') {
      this.filterCondition.filterType = FilterType.Array
    } else {
      this.filterCondition.filterType = FilterType.Number
    }
  }

  onOperatorChanged(operator: string) {
    this.updateFilterType(operator)
    if (this.filterCondition.value) this.internalOnFilterChanged()
  }

  clearContent() {
    this.syncOperator = this.defaultOperator
    this.filterCondition.value = null
  }
}
