import { Component, Vue } from 'vue-property-decorator'
import {
  MdData,
  MdAttributeInput,
  MdDataBomEntryFragmentDoc,
  MdDataWithAttrsFragmentDoc
} from '@/module/graphql'
import gql from 'graphql-tag'
import { buildGraphQLQueryPartInput, submitMutation } from '@/module/common/util/graphql-util'
import { Message } from 'element-ui'
import LocalDbDao from '@/module/common/local-db-dao'

@Component
export default class MixinSaveMdData extends Vue {
  saveMdData(mdData: MdData, attributes: MdAttributeInput[]): Promise<MdData> {
    const savingMdDataDoc = mdData.id && mdData.id !== '0'
      ? gql`mutation {
        updateMdData(id:"${mdData.id}", ver: ${mdData?.ver}, values:{
          field0:"${mdData.field0 || ''}", field1:"${mdData.field1 || ''}",
          field2:"${mdData.field2 || ''}", field3:"${mdData.field3 || ''}", field4:"${mdData.field4 || ''}",
          field5:"${mdData.field5 || ''}", field6:"${mdData.field6 || ''}", field7:"${mdData.field7 || ''}",
          field8:"${mdData.field8 || ''}", field9:"${mdData.field9 || ''}", 
          structureKey:"${mdData.structureKey || ''}"
          attributes:${buildGraphQLQueryPartInput(attributes, true, true)}
        }) {
          ...mdDataWithAttrs,
          bomEntries {
            ...mdDataBomEntry
          }
        }
      }
      ${MdDataWithAttrsFragmentDoc}
      ${MdDataBomEntryFragmentDoc}`
      : gql`mutation {
        insertMdData(code:"${mdData.code}", field0:"${mdData.field0 || ''}", field1:"${mdData.field1 || ''}",
          field2:"${mdData.field2 || ''}", field3:"${mdData.field3 || ''}", field4:"${mdData.field4 || ''}",
          field5:"${mdData.field5 || ''}", field6:"${mdData.field6 || ''}", field7:"${mdData.field7 || ''}",
          field8:"${mdData.field8 || ''}", field9:"${mdData.field9 || ''}",
          structureKey:"${mdData.structureKey || ''}",
          attributes:${buildGraphQLQueryPartInput(attributes, true, true)}) {
          ...mdDataWithAttrs
        }
      }
      ${MdDataWithAttrsFragmentDoc}`

    const ctxHeader: any = {
      'ctx-mdx-data-fix-field-regenerate': JSON.stringify(['all'])
    }
    if (LocalDbDao.getSysSetting('MasterDataStructure', 'setting_mdx_structure_remove_extra_attr')) {
      ctxHeader['mdx-data-remove-attributes-not-in-template'] = true
    }
    return submitMutation(this, {
      mutationOptions: {
        mutation: savingMdDataDoc,
        context: {
          headers: ctxHeader
        }
      },
      fullValidate: true,
      ignoreWarning: true
    }).then(data => {
      Message({
        message: '保存成功', type: 'success', showClose: true
      })
      return data.data.insertMdData || data.data.updateMdData
    })
  }
}
