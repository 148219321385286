





































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/module/plugin-md-product-order/views/components/boxing/icmp-mdo-packing-option-form-dialog.vue"
}
