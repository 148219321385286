








































export default function (Component) {
  Component.options.__source = "src/module/plugin-md-product-order/views/components/boxing/cmp-mdo-boxing-sheet-entry-dialog.vue"
}
