












import { Component, Prop } from 'vue-property-decorator'
import { FilterCondition, FilterType } from '@/module/graphql'
import BaseFieldFilter from '@/module/components/lolth-filter/base-field-filter'
import util from '@/d2admin/libs/util'

@Component
export default class CmpMdAttrKeywordsFilter extends BaseFieldFilter {
  @Prop({ default: 'attrKeywords' }) customOperator: string;

  get isValid() {
    return !(!this.filterCondition.filterType ||
      !this.filterCondition.field ||
      !this.filterCondition.operator ||
      !this.filterCondition.value ||
      this.filterCondition.value.length <= 0)
  }

  created(): void {
    this.initFilterCondition(this.filterCondition)
  }

  public initFilterCondition(filterCondition: FilterCondition) {
    filterCondition.filterType = FilterType.Custom
    filterCondition.operator = this.customOperator
    if (!filterCondition.value) filterCondition.value = null
    util.objects.reactive(filterCondition)
  }

  clearContent() {
    this.filterCondition.value = null
  }
}
