






import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'

@Component
export default class LolthRgbColorCellRenderer extends Vue implements ICellRendererComp {
  private params!: ICellRendererParams
  private mValue: string = null

  get style() {
    return {
      border: '1px solid #d3d3d3',
      height: '20px',
      width: '70px',
      'background-color': this.mValue
    }
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: ICellRendererParams): boolean {
    this.mValue = params.value
    return true
  }
}
