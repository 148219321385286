import store from '@/store'
import util from '@/d2admin/libs/util'
import { promptServerError } from '@/module/common/util/error-util'
import { HandledError } from '@/d2admin/plugin/util'
import { reloadApp } from '@/module/common/util/view-util'
import _ from 'lodash'
// TODO shouldn't import `/module` code

export default {
  install(Vue, options) {
    function handleError(logType) {
      return (error, vm, info = '') => {
        if (/loading.*chunk.*failed./i.test(error.message)) {
          reloadApp()
          return
        }
        if (_.isObject(error) && (error.reason === 'cancel' || error instanceof HandledError)) return
        Vue.nextTick(() => {
          // store 追加 log
          store.dispatch('d2admin/log/push', {
            message: `${info}: ${(typeof error === 'object') ? error.message : error}`,
            type: logType,
            meta: {
              error,
              vm
            }
          })
          // 只在开发模式下打印 log
          if (process.env.NODE_ENV === 'development') {
            util.log.capsule('D2Admin', 'ErrorHandler', logType)
            util.log.danger('>>>>>> 错误信息 >>>>>>')
            console.log(info)
            util.log.danger('>>>>>> Vue 实例 >>>>>>')
            console.log(vm)
            util.log.danger('>>>>>> Error >>>>>>')
            console.trace(error)
          }
        })
      }
    }
    if (process.env.NODE_ENV === 'development') {
      Vue.config.warnHandler = handleError('warning')
    }
    Vue.config.errorHandler = handleError('danger')

    window.onunhandledrejection = error => {
      if (!error || !error.reason || error.reason === 'cancel' || error instanceof HandledError ||
        error.reason instanceof HandledError) return
      store.dispatch('d2admin/log/push', {
        message: error.message || 'Unknown error',
        type: 'danger',
        meta: {
          error: error.reason,
          trace: 'stack' in error && error.stack
        }
      })
      promptServerError(error)
    }
    window.onerror = (event, source, lineno, colno, error) => {
      // ignore noisy browser warning. ref: https://stackoverflow.com/questions/49384120
      if ((event && event === 'ResizeObserver loop limit exceeded') ||
        error instanceof HandledError || error.reason instanceof HandledError) return
      store.dispatch('d2admin/log/push', {
        message: (error && error.message) || 'Unknown error',
        type: 'danger',
        meta: {
          error,
          trace: error && error.stack,
          source: `${source}@${lineno}:${colno}`,
          event: event
        }
      })
      promptServerError(error)
    }
  }
}
