















import { Component, Prop } from 'vue-property-decorator'
import { FilterType, FilterCondition } from '@/module/graphql'
import BaseFieldFilter from './base-field-filter'

import util from '@/d2admin/libs/util'

@Component
export default class BooleanFilter extends BaseFieldFilter {
  @Prop({ default: '是' }) yesLabel: string;
  @Prop({ default: '否' }) noLabel: string;

  get isValid() {
    return !(!this.filterCondition.filterType ||
      !this.filterCondition.field ||
      !this.filterCondition.operator ||
      this.filterCondition.value === null)
  }

  public initFilterCondition(filterCondition: FilterCondition) {
    filterCondition.filterType = FilterType.Bool
    if (!filterCondition.operator) filterCondition.operator = 'equals'
    util.objects.reactive(filterCondition)
  }

  clearContent() {
    this.filterCondition.operator = 'equals'
    this.filterCondition.value = null
    this.internalOnFilterChanged()
  }
}
