








import { Component, Vue } from 'vue-property-decorator'
import { FilterChangedEvent, IFloatingFilterComp, IFloatingFilterParams } from 'ag-grid-community'
import { FilterCondition } from '@/module/graphql'
import _ from 'lodash'

@Component
export default class LolthFlagFloatingFilter extends Vue implements IFloatingFilterComp {
  private params: IFloatingFilterParams | null = null
  private mValue: boolean | null = null

  get yesLabel() {
    const customProps = (this.params?.filterParams as any).viewDef?.customProps || {}
    return customProps.yesLabel || '是'
  }

  get noLabel() {
    const customProps = (this.params?.filterParams as any).viewDef?.customProps || {}
    return customProps.noLabel || '否'
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  onParentModelChanged(filterCondition: FilterCondition, filterChangedEvent?: FilterChangedEvent): void {
    if (!filterCondition || !filterCondition.value) this.mValue = null
    else this.mValue = !filterCondition.not
  }

  onValueChanged() {
    this.params?.parentFilterInstance(instance => {
      (instance as any).onFloatingFilterChanged(_.isNil(this.mValue) ? {
        value: null
      } : {
        value: '1',
        not: !this.mValue
      })
    })
  }
}
