




























































import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { FilterCondition } from '@/module/graphql'
import BaseFilter from './base-filter'
import BaseFieldFilter from './base-field-filter'
import StringFilter from './string-filter.vue'
import NumberFilter from './number-filter.vue'
import BooleanFilter from './boolean-filter.vue'
import DateFilter from './date-filter.vue'
import EnumFilter from './enum-filter.vue'
import TagsFilter from './tags-filter.vue'
import StringArrayFilter from './string-array-filter.vue'
import ReadonlyFilter from './readonly-filter.vue'
import { FilterViewDef, FilterViewType } from '@/module/components/lolth-filter/types'
import _ from 'lodash'

@Component({
  name: 'field-filter',
  components: {
    StringFilter, NumberFilter, BooleanFilter, DateFilter, EnumFilter, ReadonlyFilter, TagsFilter, StringArrayFilter
  }
})
export default class FieldFilter extends BaseFilter {
  @Prop() readonly fixed!:boolean;
  @Prop() readonly external!:boolean;
  @Prop({ default: true }) readonly deletable!:boolean;

  @Prop() readonly onFilterChanged?: (filter: FilterCondition) => {}
  @Prop({ required: true }) readonly fieldOptions?: FilterViewDef[]

  @Ref() readonly stringFilter!: StringFilter & BaseFieldFilter
  @Ref() readonly numberFilter!: NumberFilter & BaseFieldFilter
  @Ref() readonly booleanFilter!: BooleanFilter & BaseFieldFilter
  @Ref() readonly dateFilter!: DateFilter & BaseFieldFilter
  @Ref() readonly enumFilter!: EnumFilter & BaseFieldFilter
  @Ref() readonly tagsFilter!: TagsFilter & BaseFieldFilter
  @Ref() readonly stringArrayFilter!: StringArrayFilter & BaseFieldFilter
  @Ref() readonly readonlyFilter!: ReadonlyFilter & BaseFieldFilter
  @Ref() readonly customFilter!: BaseFieldFilter

  get selectedField(): FilterViewDef | null {
    return _.find(this.fieldOptions,
      field => field.fieldKey === this.filterCondition.field) || null
  }

  set selectedField(newFieldDef: FilterViewDef | null) {
    const oldFieldDef = this.selectedField
    this.filterCondition.field = newFieldDef?.fieldKey
    this.filterCondition.filterViewDef = newFieldDef || undefined
    if (_.isNil(newFieldDef) ||
      (oldFieldDef && newFieldDef.fieldKey !== oldFieldDef.fieldKey)) {
      this.clearContent()
    }
  }

  get disabledOperators(): string[] {
    return this.selectedField?.disabledOperators || []
  }

  get selectedFieldClass(): string {
    return this.selectedField?.class || ''
  }

  get activeFilter(): BaseFieldFilter | null {
    if (!this.selectedField?.filterViewType) return null
    switch (this.selectedField?.filterViewType) {
      case FilterViewType.StringFilter: return this.stringFilter
      case FilterViewType.NumberFilter: return this.numberFilter
      case FilterViewType.BooleanFilter: return this.booleanFilter
      case FilterViewType.DateFilter: return this.dateFilter
      case FilterViewType.EnumFilter: return this.enumFilter
      case FilterViewType.TagsFilter: return this.tagsFilter
      case FilterViewType.StringArrayFilter: return this.stringArrayFilter
      case FilterViewType.CustomFilter: return this.customFilter
      default: return this.readonlyFilter
    }
  }

  get isValid(): boolean {
    return this.activeFilter?.isValid || false
  }

  created() {
    this.initData()
  }

  @Watch('filterCondition')
  initData() {
    if (!this.filterCondition.field) this.$set(this.filterCondition, 'field', null)
    if (_.isNil(this.filterCondition.value)) this.$set(this.filterCondition, 'value', null)
    if (_.isNil(this.filterCondition.not)) this.$set(this.filterCondition, 'not', null)
  }

  @Watch('selectedField.filterViewType')
  onFilterTypeChanged() {
    this.activeFilter?.initFilterCondition(this.filterCondition)
  }

  private onBodyDoubleClick() {
    if (this.fixed || this.external || this.disabled) return
    this.filterCondition.not = !this.filterCondition.not
  }

  clearContent() {
    this.filterCondition.operator = null
    this.filterCondition.value = null
  }
}
