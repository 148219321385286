export const DATE_FORMAT = 'YYYY-MM-DD'

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const ERROR_LEVEL_WARN = 'warn'
export const ERROR_LEVEL_ERROR = 'error'

export const IMAGE_FILE_TYPES = 'png,bmp,jpg,jpeg'

export const ADMIN_LEVEL_USER = 0
export const ADMIN_LEVEL_SYS_ADMIN = 1
export const ADMIN_LEVEL_ORG_ADMIN = 2

export const PERMISSION_SECURITY_LEVEL_BUSINESS = 0
export const PERMISSION_SECURITY_LEVEL_SYSTEM = 1

export const SET_NULL_MARK = '◊'

export const NO_PERMISSION_NUMBER = -1e21

export const DIAG_STATE = Object.freeze({
  OK: 'ok',
  IGNORED: 'ignored',
  WARNING: 'warn',
  ERROR: 'error',
  FATAL: 'fatal',
  CRASH: 'crash'
})

export const APP_REALM_CODE = 'LolthApp'
export const MDC_REALM_CODE = 'MasterDataCategory'
export const MDP_REALM_CODE = 'MasterDataProductStructure'
export const MDO_REALM_CODE = 'MasterDataProductOrder'
export const MDM_REALM_CODE = 'MasterDataMigration'
