













































































































































































































































export default function (Component) {
  Component.options.__source = "src/module/plugin-md-essential/views/components/cmp-mde-supplier-form.vue"
}
