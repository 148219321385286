<template>
  <cmp-portal>
    <!-- logo -->
<!--    <img class="page-login&#45;&#45;logo" src="../image/logo@2x.png">-->
    <h1>{{ pageTitle }}</h1>
    <!-- 表单 -->
    <div class="page-login--form">
      <el-card shadow="never"
               v-loading="$store.state.d2admin.loading.loading"
               element-loading-text="登录中, 请稍候……">
        <el-tabs v-model="mActiveName">
          <el-tab-pane label="账户登录" name="byUserName">
            <el-form ref="elLoginForm" label-position="top" :rules="rules" :model="formLogin" size="default">
              <el-form-item prop="username">
                <el-input type="text" v-model="formLogin.username" placeholder="用户名/手机号" @keyup.enter.native="nextFocus" ref="username">
                  <i slot="prepend" class="fa fa-user-circle-o"></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input type="password" v-model="formLogin.password" placeholder="密码" ref="password" @keyup.enter.native="nextFocus">
                  <i slot="prepend" class="fa fa-keyboard-o"></i>
                </el-input>
              </el-form-item>
              <el-button size="default" @click="submit" type="primary" class="button-login">登录</el-button>
              <el-form-item v-if="enableRememberMe">
                <el-checkbox v-model="formLogin.rememberMe">7天内保留登录账号</el-checkbox>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="手机快捷登录" name="bySms">
            <el-form ref="elSmsLoginForm" label-position="top" :rules="smsRules" :model="formSmsLogin" size="default">
              <el-form-item prop="mobile">
                <el-input type="text" v-model="formSmsLogin.mobile" placeholder="手机号" @input="onMobileChange">
                  <i slot="prepend" class="el-icon-mobile-phone"></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="captcha">
                <el-input type="text" v-model="formSmsLogin.captcha" placeholder="验证码">
                  <i slot="prepend" class="el-icon-message"></i>
                  <el-button slot="append" @click="getCaptcha" :disabled="countDownOption.sendCaptchaBtnDisable">
                    {{countDownOption.captchaCountDownText}}
                  </el-button>
                </el-input>
              </el-form-item>
              <el-button size="default" @click="submit" type="primary" class="button-login">登录</el-button>
              <el-form-item v-if="enableRememberMe">
                <el-checkbox v-model="formLogin.rememberMe">7天内保留登录账号</el-checkbox>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="企业微信" name="byWeChat" v-if="ewxEnabled">
            <div id="wx_qr_code" style="text-align: center"></div>
          </el-tab-pane>
      </el-tabs>
      </el-card>
      <p
        class="page-login--options"
        flex="main:justify cross:center">
        <span><d2-icon name="question-circle"/>忘记密码</span>
        <router-link to="/sign-up" v-if="enableSignUp"><d2-icon name="user"/>注册新用户</router-link>
      </p>
      <el-button class="page-login--quick" size="default" type="primary"
                 v-if="enableRememberMe"
                 @click="switchUserDialog = true">
        快速登录
      </el-button>
    </div>
    <el-dialog
      title="选择用户"
      :visible.sync="switchUserDialog"
      width="400px">
      <el-row :gutter="10" style="margin: -20px 0px -10px 0px;">
        <el-col v-for="(loginToken, username) in rememberedUsers" :key="username" :span="8" style="min-height: 85px; position: relative;">
          <div class="page-login--quick-user" @click="loginByToken(loginToken)">
            <d2-icon name="user-circle-o"/>
            <span>{{username}}</span>
          </div>
          <i class="el-tag__close el-icon-close el-tag--light" style="position: absolute; left:90px; top: 10px;color: #409eff" @click="removeToken(username)"></i>
        </el-col>
      </el-row>
    </el-dialog>
  </cmp-portal>
</template>

<script>
import { mapActions } from 'vuex'
import CmpPortal from './components/cmp-portal'
import LocalDbDao from '@/module/common/local-db-dao'
import { UserNotApprovedError } from '@/module/butler/hook'
import { sendCaptchaSms, setCountDownTimer } from '@/module/butler/butler-util'
import axios from '@/d2admin/plugin/axios'
import qs from 'qs'
import _ from 'lodash'
import { GLOBAL_ENV } from '@/d2admin/setting'

export default {
  components: {
    CmpPortal
  },

  created() {
    // window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    const ua = window.navigator.userAgent.toLowerCase()
    // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (ua.match(/MicroMessenger/i) && !this.$route.params.ewxUserId) {
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?' + qs.stringify({
        appid: GLOBAL_ENV.EWX_CORP_ID,
        agentid: GLOBAL_ENV.EWX_AGENT_ID,
        redirect_uri: encodeURIComponent(GLOBAL_ENV.PUBLIC_URL),
        scope: 'snsapi_privateinfo',
        state: 'ewx-sign-in',
        response_type: 'code'
      }) + '#wechat_redirect'
    }
  },

  data() {
    return {
      // 快速选择用户
      switchUserDialog: false,
      // 表单
      formLogin: {
        username: process.env.NODE_ENV === 'development' ? 'dev' : '',
        password: process.env.NODE_ENV === 'development' ? 'dev' : '',
        rememberMe: process.env.NODE_ENV === 'development'
      },
      formSmsLogin: {
        mobile: '',
        captcha: ''
      },
      countDownOption: {
        captchaCountDownText: '获取验证码',
        sendCaptchaBtnDisable: false
      },
      // 校验
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      smsRules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '请输入验证码' }
        ]
      },
      rememberedUsers: {},
      mActiveName: 'byUserName',
      ewxEnabled: !!GLOBAL_ENV.EWX_CORP_ID
    }
  },

  computed: {
    pageTitle: () => GLOBAL_ENV.TEXT_APP_TITLE || process.env.VUE_APP_TITLE || 'Lolth',
    enableSignUp: () => Boolean(GLOBAL_ENV.ENABLE_SIGN_UP) || true,
    enableRememberMe: () => Boolean(GLOBAL_ENV.ENABLE_REMEMBER_ME) || true
  },

  async mounted() {
    this.rememberedUsers = await LocalDbDao.loadLoginTokens()

    // 加载企业微信登录插件
    if (this.ewxEnabled) {
      const plugin = document.createElement('script')
      plugin.setAttribute('src', 'http://wwcdn.weixin.qq.com/node/wework/wwopen/js/wwLogin-1.2.7.js')
      plugin.onload = function () {
        // eslint-disable-next-line no-undef
        const wwLogin = new WwLogin({
          id: 'wx_qr_code',
          appid: GLOBAL_ENV.EWX_CORP_ID,
          agentid: GLOBAL_ENV.EWX_AGENT_ID,
          redirect_uri: encodeURIComponent(`${GLOBAL_ENV.PUBLIC_URL}#/ewx-sign-in`),
          state: '',
          lang: 'zh'
        })
      }
      document.head.appendChild(plugin)
    }
  },

  methods: {
    ...mapActions('d2admin/account', [
      'login'
    ]),
    loginByToken(loginToken) {
      // 每次登陆前先把缓存的token清掉, 免得污染
      LocalDbDao.flushTokenCache()
      this.login({
        loginToken,
        rememberMe: true,
        ewxUserId: this.$route.params.ewxUserId
      }).then(async () => {
        // 重定向对象不存在则返回顶层路径
        this.$router.replace(this.$route.query.redirect || '/')
        if (this.$route.params.ewxUserId) {
          axios.post('/auth/bind-ewx', qs.stringify({
            loginToken: loginToken,
            ewxUserId: this.$route.params.ewxUserId
          })).then(resp => {
            this.$message({
              message: '成功关联企业微信', type: 'success', showClose: true
            })
          })
        }
      })
    },
    nextFocus(event) {
      if (this.$refs.username.$refs.input === event.target) {
        this.$refs.password.focus()
      }
      if (this.$refs.password.$refs.input === event.target) {
        this.submit()
      }
    },
    submit() {
      let loginParam = null
      let loginForm = null
      if (this.mActiveName === 'byUserName') {
        loginParam = {
          username: this.formLogin.username,
          password: this.formLogin.password,
          rememberMe: this.formLogin.rememberMe,
          ewxUserId: this.$route.params.ewxUserId
        }
        loginForm = this.$refs.elLoginForm
      } else {
        loginForm = this.$refs.elSmsLoginForm
        loginParam = {
          mobile: this.formSmsLogin.mobile,
          captcha: this.formSmsLogin.captcha,
          rememberMe: this.formLogin.rememberMe,
          ewxUserId: this.$route.params.ewxUserId
        }
      }
      loginForm.validate((valid) => {
        if (valid) {
          LocalDbDao.flushTokenCache()
          this.login(loginParam).then(async () => {
            // 重定向对象不存在则返回顶层路径
            this.$router.replace(this.$route.query.redirect || '/')
            const loginTokens = await LocalDbDao.loadLoginTokens()
            const loginToken = loginTokens[LocalDbDao.getPrincipal().name]
            if (this.$route.params.ewxUserId) {
              axios.post('/auth/bind-ewx', qs.stringify({
                loginToken: loginToken,
                ewxUserId: this.$route.params.ewxUserId
              })).then(resp => {
                this.$message({
                  message: '成功关联企业微信', type: 'success', showClose: true
                })
              })
            }
          }).catch(error => {
            if (error instanceof UserNotApprovedError) {
              let principal = LocalDbDao.getPrincipal()
              if (!principal.rejectReason) {
                this.$alert('您的加入申请尚未通过, 请联系系统管理员', '提示', {
                  confirmButtonText: '确定',
                  type: 'warning',
                  callback: action => {
                    this.$store.dispatch('d2admin/account/logout', { confirm: false })
                  }
                })
              } else {
                this.$confirm('您的加入申请未通过审批, 原因:`' + principal.rejectReason + '`<br/>' +
                  '是否重新提交加入申请?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  dangerouslyUseHTMLString: true,
                  type: 'warning'
                }).then(() => {
                  this.$router.push({ name: 'sign-up', params: { accountId: principal.identity } })
                }).catch(() => {
                  this.$store.dispatch('d2admin/account/logout', { confirm: false })
                })
              }
            } else throw error
          })
        } else {
          // 登录表单校验失败
          this.$message.error('表单校验失败')
        }
      })
    },
    getCaptcha() {
      this.$refs.elSmsLoginForm.validateField(['mobile'], error => {
        if (!error) {
          axios.get('/auth/is-mobile-registered', {
            params: {
              mobile: this.formSmsLogin.mobile
            }
          }).then(resp => {
            if (resp) {
              sendCaptchaSms(this, this.formSmsLogin.mobile, this.countDownOption)
            } else {
              this.$message.error('手机号未注册')
            }
          }).catch()
        }
      })
    },
    onMobileChange() {
      setCountDownTimer(this.countDownOption, this.formSmsLogin.mobile)
    },
    removeToken(username) {
      this.$delete(this.rememberedUsers, username)
      LocalDbDao.removeLoginToken(username)
    }
  }
}
</script>

<style lang="scss">
.page-login {
  h1 {
    color: #247fd5;
  }
  a {
    text-decoration: none;
  }
  // main
  .page-login--logo {
    width: 240px;
    margin-bottom: 2em;
    margin-top: -2em;
  }
  // 登录表单
  .page-login--form {
    width: 280px;
    // 卡片
    .el-card {
      margin-bottom: 15px;
    }
    // 登录按钮
    .button-login {
      width: 100%;
    }
    // 输入框左边的图表区域缩窄
    .el-input-group__prepend {
      padding: 0px 14px;
    }
    .login-code {
      height: 40px - 2px;
      display: block;
      margin: 0px -20px;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
    // 登陆选项
    .page-login--options {
      margin: 0px;
      padding: 0px;
      font-size: 14px;
      color: $color-primary;
      margin-bottom: 15px;
      font-weight: bold;
    }
    .page-login--options a {
      color: $color-primary;
    }
    .page-login--quick {
      width: 100%;
    }
  }
  // 快速选择用户面板
  .page-login--quick-user {
    @extend %flex-center-col;
    padding: 10px 0px;
    border-radius: 4px;
    &:hover {
      background-color: $color-bg;
      i {
        color: $color-text-normal;
      }
      span {
        color: $color-text-normal;
      }
    }
    i {
      font-size: 36px;
      color: $color-text-sub;
    }
    span {
      font-size: 12px;
      margin-top: 10px;
      color: $color-text-sub;
    }
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/module/butler/views/page-sign-in.vue"
}
</vue-filename-injector>
