



















































import { Component } from 'vue-property-decorator'
import BaseInput from './base-input'
import copy from 'copy-to-clipboard'
import LocalDbDao from '@/module/common/local-db-dao'
import { CascaderNode } from '@/module/components/types'
import { FilterCondition, FilterType, MdDictionaryEntry, SortColumn, SortType } from '@/module/graphql'
import _ from 'lodash'
import gql from 'graphql-tag'
import LolthTypedInputSelect from '@/module/components/typed/lolth-typed-input-select'

@Component
export default class CmpMdTableDataInputMultiDict extends BaseInput<string[]> {
  private mGroup: CascaderNode[] = null
  private mChooserVisible = false

  private mDictEntries: MdDictionaryEntry[] = []

  get syncedDictEntries(): MdDictionaryEntry[] {
    return this.mDictEntries
  }

  set syncedDictEntries(val: MdDictionaryEntry[]) {
    this.mDictEntries = val
    this.syncValue = _.isEmpty(val) ? null : val.map(entry => entry.id)
  }

  get filterCondition(): FilterCondition {
    if (!this.column.option.dictId) {
      throw new Error('无效的选项表字段, 未指定选项表')
    }

    const filterCondition = {
      filterType: FilterType.Number,
      field: 'dictionary.id',
      operator: 'equals',
      value: this.column.option.dictId
    }

    return this.column.option?.dictFilter
      ? {
        filterType: FilterType.And,
        conditions: [
          filterCondition,
          this.column.option.dictFilter
        ]
      } : filterCondition
  }

  get sortByColumn(): SortColumn[] {
    return [{
      field: 'seq',
      sort: SortType.Asc
    }]
  }

  get explorerType() {
    return LocalDbDao.getExplorerType('MdDictionaryEntry')
  }

  created() {
    if (!_.isEmpty(this.syncValue)) {
      this.$apollo.query({
        query: gql`query ($rowRequest: RowRequest!){
          MdDictionaryEntries(rowRequest: $rowRequest) {
            rows {
              id key value valueForShort seq
            }
          }
        }`,
        variables: {
          rowRequest: {
            filter: {
              filterType: FilterType.InIds,
              value: this.syncValue.join(',')
            }
          }
        }
      }).then(data => {
        this.mDictEntries = data.data.MdDictionaryEntries.rows
      })
    }
  }

  mounted() {
    if (!this.column.option.chooser) {
      this.$apollo.query({
        query: gql`query {
          MdDictionary(id: ${this.column.option.dictId}) {
            groups
          }
        }`
      }).then(data => {
        this.mGroup = data.data.MdDictionary.groups || null
      })
    }
  }

  copyToClipboard() {
    if (_.isEmpty(this.syncedDictEntries)) return
    copy(this.mDictEntries
      .map(value => this.explorerType.formatLabel(value))
      .join(','))
  }

  onChooserSelect(dictEntries: MdDictionaryEntry[]) {
    this.syncedDictEntries = dictEntries
  }

  resetSelectOptions() {
    const vSelect = this.$refs.vSelect as LolthTypedInputSelect
    vSelect?.resetOptionsLoaded()
  }
}
