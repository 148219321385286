import Vue from 'vue'
import VueRouter from 'vue-router'
// 进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import store from '@/store'

import util from '@/d2admin/libs/util'
// 路由数据
import routes from './routes'
import { reloadApp } from '@/module/common/util/view-util'

Vue.use(VueRouter)

// 导出路由 在 main.js 里使用
const router = new VueRouter({
  routes
})

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, from, next) => {
  // 确认已经加载多标签页数据 https://github.com/d2-projects/d2-admin/issues/201
  await store.dispatch('d2admin/page/isLoaded')
  // 确认已经加载组件尺寸设置 https://github.com/d2-projects/d2-admin/issues/198
  await store.dispatch('d2admin/size/isLoaded')
  // 进度条
  NProgress.start()
  // 关闭搜索面板
  store.commit('d2admin/search/set', false)
  let href = window.location.href
  // 企业微信登录回调，不支持带#号的url，把#号加上
  if (href.indexOf('&state=ewx-sign-in') !== -1) {
    window.location.href = href
      .replace('/?', '/#/ewx-sign-in?')
      .replace('&state=ewx-sign-in', '&state=')
    // 因为跨域问题,本地调试使用
    // .replace('test.ats-oa.com:8092', 'localhost:8080')
  } else if (href.indexOf('&state=ewx-invitation') !== -1) {
    href = href.replace('#/', '')
    const invitationCode = href.substring(href.lastIndexOf('.') + 1)
    window.location.href = href
      .replace('/?', '/#/invitation/' + invitationCode + '?')
      .replace('&state=ewx-invitation.' + invitationCode, '&state=')
  } else if (to.matched.some(r => r.meta.auth)) { // 验证当前路由所有的匹配中是否需要有登录验证的
    // 这里暂时将cookie里是否存有uuid作为验证是否登录的条件
    const userId = util.cookies.get('uuid')
    if (userId && userId !== 'undefined') {
      next()
    } else {
      // 没有登录的时候跳转到登录界面
      // 携带上登陆成功之后需要跳转的页面完整路径
      next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      })
      // https://github.com/d2-projects/d2-admin/issues/138
      NProgress.done()
    }
  } else {
    // 不需要身份校验 直接通过
    next()
  }
})

router.afterEach(to => {
  // 进度条
  NProgress.done()
  // 多页控制 打开新的页面
  store.dispatch('d2admin/page/open', to)
  // 更改标题
  util.views.setPageTitle(to.meta.title)
})

router.onError(error => {
  if (/loading.*chunk.*failed./i.test(error.message)) {
    reloadApp()
  }
})

export default router
