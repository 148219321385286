












































import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator'
import {
  MdeCurrency,
  MdExtendData,
  MutationCreateMdeCurrencyArgs,
  MutationUpdateMdeCurrencyArgs
} from '@/module/graphql'
import util from '@/d2admin/libs/util'
import gql from 'graphql-tag'
import { buildGraphQLQueryPartInput, submitMutation } from '@/module/common/util/graphql-util'

@Component
export default class CmpMdeCurrencyEditorForm extends Vue {
  @Prop({ default: true }) readonly readonly?: boolean
  @Prop() readonly edit?: boolean
  @Prop() readonly mdExtendData: MdExtendData

  @ModelSync('value', 'change', { required: true })
  syncedValue!: MdeCurrency

  created(): void {
    if (this.mdExtendData) {
      this.$apollo.query({
        query: gql`query {
            MdeCurrency(code: "${this.mdExtendData.dataId}") {
              code ver name note symbol
            }
          }`
      }).then(data => {
        this.syncedValue = data.data.MdeCurrency
      })
    }
  }

  submit() {
    let mutation:string
    let mutationArgs: MutationCreateMdeCurrencyArgs | MutationUpdateMdeCurrencyArgs
    if (this.edit) {
      mutation = 'updateMdeCurrency'
      mutationArgs = {
        code: this.syncedValue.code,
        ver: this.syncedValue.ver || 0,
        values: {
          name: this.syncedValue.name,
          note: this.syncedValue.note,
          symbol: this.syncedValue.symbol,
          customerDefault: this.syncedValue.customerDefault,
          supplierDefault: this.syncedValue.supplierDefault
        }
      }
    } else {
      mutation = 'createMdeCurrency'
      mutationArgs = {
        code: this.syncedValue.code,
        name: this.syncedValue.name,
        note: this.syncedValue.note,
        symbol: this.syncedValue.symbol,
        customerDefault: this.syncedValue.customerDefault,
        supplierDefault: this.syncedValue.supplierDefault
      }
    }
    const mutationOptions: any = {
      mutation: gql(`mutation {
        ${mutation}(${buildGraphQLQueryPartInput(mutationArgs)}) {
          code, ver, name, note
        }
      }`)
    }
    submitMutation(this, {
      mutationOptions,
      fullValidate: true
    }).then(data => {
      this.$message({
        message: '保存成功', type: 'success', showClose: true
      })
      this.$emit('submit', this.syncedValue)
    })
  }

  public static initMdeCurrency(mdeCurrency: MdeCurrency = {
    code: '', name: ''
  }) {
    util.objects.reactive(mdeCurrency)
    return mdeCurrency
  }
}
