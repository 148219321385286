















import { Component } from 'vue-property-decorator'
import BaseAttrInput from './base-attr-input'
import moment from 'moment'
import { buildDatePickerOption } from '@/module/common/util/view-util'
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@/module/common/constants'

@Component
export default class AttrInputDate extends BaseAttrInput {
  get syncValue(): Date | null {
    if (this.attribute.value) {
      return moment(this.attribute.value).toDate()
    } else {
      return null
    }
  }

  set syncValue(val: Date | null) {
    this.attribute.value = (val && moment(val).format(DATE_TIME_FORMAT)) || null
    this.$emit('attr-value-changed', this.attribute.value)
  }

  get formattedValue() {
    if (!this.attribute.value) return ''
    switch (this.mdField.option.pickMode) {
      case undefined:
      case 'date':
        return moment(this.attribute.value).format(DATE_FORMAT)
      case 'datetime':
        return moment(this.attribute.value).format(DATE_TIME_FORMAT)
      case 'month':
        return moment(this.attribute.value).format('YYYY-MM')
      case 'year':
        return moment(this.attribute.value).format('YYYY')
    }
  }

  private mPickerOptions = buildDatePickerOption()

  mounted() {
    if (this.mdField.option.futureDateOnly) {
      this.mPickerOptions.disabledDate = (time) => {
        return time.getTime() < Date.now()
      }
    }
  }
}
