












































import { Component, Prop, Watch } from 'vue-property-decorator'
import { FilterCondition, FilterType } from '@/module/graphql'
import BaseFilter from './base-filter'
import { FilterViewDef } from '@/module/components/lolth-filter/types'
import _ from 'lodash'
import util from '@/d2admin/libs/util'

declare type FilterConditionWrapper = {
  condition: FilterCondition
}

@Component({
  name: 'and-or-filter'
})
export default class AndOrFilter extends BaseFilter {
  @Prop() readonly deletable?: boolean
  @Prop() readonly fieldOptions?: FilterViewDef[]
  @Prop({ default: true }) readonly allowSwitchAndOr: boolean

  // 固定查询条件, 不能动
  @Prop({ default: ():FilterCondition[] => [] }) readonly fixedConditions!: FilterCondition[]
  // 外部组件设置的查询条件, 只能删不能动
  @Prop({ default: ():FilterCondition[] => [] }) readonly externalConditions!: FilterCondition[]

  get filterTypeText() {
    return this.filterCondition.filterType === FilterType.Or ? '或者' : '并且'
  }

  get fixedConditionWrappers(): FilterConditionWrapper[] {
    if (!this.filterCondition.conditions) return []
    return this.filterCondition.conditions
      .filter(condition => this.fixedConditions.indexOf(condition) >= 0)
      .map(condition => { return { condition: condition } })
  }

  get externalConditionWrappers(): FilterConditionWrapper[] {
    if (!this.filterCondition.conditions) return []
    return this.filterCondition.conditions
      .filter(condition => this.externalConditions.indexOf(condition) >= 0)
      .map(condition => { return { condition: condition } })
  }

  get internalConditionWrappers(): FilterConditionWrapper[] {
    if (!this.filterCondition.conditions) return []
    return this.filterCondition.conditions
      .filter(condition => {
        return this.fixedConditions.indexOf(condition) < 0 && this.externalConditions.indexOf(condition) < 0
      }).map(condition => { return { condition: condition } })
  }

  get isValid() {
    return !(!this.filterCondition.filterType ||
      !this.filterCondition.conditions ||
      this.filterCondition.conditions.length <= 0)
  }

  created() {
    this.initFilterCondition(this.filterCondition)
  }

  @Watch('filterCondition')
  public initFilterCondition(filterCondition: FilterCondition) {
    if (filterCondition.filterType !== FilterType.And &&
        filterCondition.filterType !== FilterType.Or) {
      filterCondition.filterType = FilterType.And
    }
    if (_.isNil(filterCondition.conditions)) filterCondition.conditions = []
    util.objects.reactive(filterCondition)
  }

  private onFilterTypeChanged(filterType: FilterType) {
    if (this.disabled) return
    this.filterCondition.filterType = filterType
  }

  private onAddCondition(command: string) {
    switch (command) {
      case 'field':
        this.filterCondition.conditions?.push({
          filterType: FilterType.Custom
        }); break
      case 'and-or':
        this.filterCondition.conditions?.push({
          filterType: FilterType.And,
          conditions: [{
            filterType: FilterType.Custom
          }]
        }); break
    }
  }

  private removeCondition(condition: FilterCondition) {
    let removingIndex = _.indexOf(this.filterCondition.conditions, condition)
    if (removingIndex >= 0) {
      this.filterCondition.conditions?.splice(removingIndex, 1)
      if (!this.filterCondition.conditions?.length && this.deletable) {
        this.$emit('remove-condition', this.filterCondition)
      } else {
        this.$emit('remove-condition', condition)
      }
    }
  }
}
