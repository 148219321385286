













































import { Component, ModelSync, PropSync, Vue } from 'vue-property-decorator'
import { ChartView, RowRequest } from '@/module/graphql'
import { submitMutation } from '@/module/common/util/graphql-util'
import gql from 'graphql-tag'
import { ChartModel, CreateRangeChartParams } from 'ag-grid-community'
import fileService from '@/module/common/file-service'
import { FILE_KEY_SEPARATOR } from '@/module/common/file-service/types'

export type SavingChartView = ChartView & {
  snapshotFile?: File
}

@Component
export default class ExplorerChartViewSaver extends Vue {
  @PropSync('visible')
  syncedVisible!: boolean

  @ModelSync('value', 'change')
  syncValue: SavingChartView

  private snapshot: File = null

  public attachToParent(parent: HTMLElement) {
    this.$el.classList.remove('hidden')
    parent.prepend(this.$el)
  }

  async submitChartView() {
    const chartOptions = this.syncValue.chartOptions as CreateRangeChartParams
    chartOptions.cellRange.rowEndIndex = this.syncValue.rowRequest.rows - 1

    let snapshotHandle = null
    if (this.syncValue.snapshotFile) {
      snapshotHandle = await fileService.getFileService().upload(this.syncValue.snapshotFile) +
        FILE_KEY_SEPARATOR + this.syncValue.snapshotFile.name
    }
    submitMutation(this, {
      mutationOptions: {
        mutation: gql`mutation saveChartView($ver: Int, $rowRequest: Json, $chartOptions: Json, $snapshot: String) {
          saveChartView(
            modelName: "${this.syncValue.modelName}"
            chartType: "${this.syncValue.chartType}"
            name: "${this.syncValue.name}"
            hash: "${this.syncValue.hash}"
            note: "${this.syncValue.note || ''}"
            ver: $ver
            snapshot: $snapshot
            rowRequest: $rowRequest
            chartOptions: $chartOptions) {
            id ver modelName chartType name hash rowRequest chartOptions note
          }
        }`,
        variables: {
          ver: this.syncValue.ver,
          rowRequest: this.syncValue.rowRequest,
          chartOptions: this.syncValue.chartOptions,
          snapshot: snapshotHandle
        }
      }
    }).then(resp => {
      this.syncValue = resp.data.saveChartView
      this.$message({
        message: '保存成功', type: 'success', showClose: true
      })
      this.$emitToGlobal('chart-view-saved')
      this.syncedVisible = false
    })
  }

  deleteChartView() {
    submitMutation(this, {
      mutationOptions: {
        mutation: gql`mutation deleteChartView($ids: [ID!]!){
          deleteChartView(ids: $ids)
        }`,
        variables: {
          ids: [this.syncValue.id]
        }
      }
    }).then(resp => {
      this.$message({
        message: '保存成功', type: 'success', showClose: true
      })
      this.syncedVisible = false
      this.$emit('deleted', this.syncValue)
    })
  }

  public static initChartView(modelName: string,
                              chartOptions:CreateRangeChartParams,
                              rowRequest: RowRequest,
                              snapshot: File,
                              chartView: SavingChartView = {} as SavingChartView) {
    if (!chartView.name) chartView.name = ''
    chartView.modelName = modelName
    chartView.chartType = 'ag_grid'
    chartView.chartOptions = chartOptions
    let rows = 10
    if (chartView.rowRequest && chartView.rowRequest.rows) {
      rows = chartView.rowRequest.rows
    }
    chartView.rowRequest = rowRequest || {}
    chartView.rowRequest.rows = rows
    chartView.snapshotFile = snapshot
    return chartView
  }

  static getXKey(chartModel: ChartModel, rowRequest: any, xKey: string, mdTableChart: boolean) {
    if (chartModel.chartType === 'scatter' || chartModel.chartType === 'bubble' || chartModel.chartType === 'histogram') {
      return xKey
    } else {
      return rowRequest.groupByCols && rowRequest.groupByCols.length > 0 ? rowRequest.groupByCols[0].field : xKey
    }
  }

  static getLabelKey(chartModel: ChartModel, rowRequest: any, labelKey: string, mdTableChart: boolean) {
    const key = rowRequest.groupByCols && rowRequest.groupByCols.length > 0 ? rowRequest.groupByCols[0].field : labelKey
    if (mdTableChart && key && key.indexOf('values.') === -1) {
      return '' + key
    } else {
      return key
    }
  }

  static getYName(chartModel: ChartModel, yName: string, title: string) {
    if (chartModel.chartType === 'bubble') {
      return title
    } else {
      return yName
    }
  }

  public static convertChartModel(chartModel: ChartModel, rowRequest: any, mdTableChart: boolean = false): any {
    let series: any[] = []
    chartModel.chart._series.forEach((s: any) => {
      const clone = {
        yKeys: s.yKeys,
        xKey: this.getXKey(chartModel, rowRequest, s.xKey, mdTableChart),
        yKey: s.yKey,
        grouped: s.grouped,
        xName: s.xName,
        yName: this.getYName(chartModel, s.yName, s.title),
        yNames: s.yNames,
        normalizedTo: s.normalizedTo,
        outerRadiusOffset: s.outerRadiusOffset,
        innerRadiusOffset: s.innerRadiusOffset,
        angleKey: s.angleKey,
        angleName: s.angleName,
        labelKey: this.getLabelKey(chartModel, rowRequest, s.labelKey, mdTableChart),
        labelName: s.labelName,
        sizeKey: s.sizeKey,
        sizeName: s.sizeName,
        title: s.title && s.title.text ? { text: s.title.text } : s.title
      }
      series.push(clone)
    })
    return {
      chart: {
        _series: series
      },
      chartOptions: chartModel.chartOptions,
      cellRange: chartModel.cellRange,
      chartType: chartModel.chartType,
      chartThemeName: chartModel.chartThemeName
    }
  }
}
