




import { Component, Vue } from 'vue-property-decorator'
import { GridOptions, IToolPanel, IToolPanelParams } from 'ag-grid-community'
import { VueFrameworkComponentWrapper } from 'ag-grid-vue/lib/VueFrameworkComponentWrapper'
import { FilterCondition } from '@/module/graphql'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import CmpMdcDataSearch from './cmp-mdc-data-search.vue'

declare interface IExplorerToolPanelParams extends IToolPanelParams {
  frameworkComponentWrapper: VueFrameworkComponentWrapper,
  explorer: LolthExplorer,
  gridOptions: GridOptions,
  attributesField: string,
}

@Component({
  components: {
    CmpMdcDataSearch
  }
})
export default class MdcDataSearchPanel extends Vue implements IToolPanel {
  private params!: IExplorerToolPanelParams

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  onQuery(filterCondition: FilterCondition) {
    this.params.explorer.setExternalFilterCondition(filterCondition)
    this.params.explorer.refresh()
  }

  refresh(): void {
    this.$forceUpdate()
  }
}
