import _ from 'lodash'
import Vue from 'vue'

/**
 * Remove undefined/null filed in an object deeply.
 *
 * @param obj
 * @param stripFalse strip false field, default false
 * @param stripEmptyString strip empty string , default false
 * @return obj
 */
function stripNil(obj, stripFalse = false, stripEmptyString = false) {
  if (_.isArray(obj)) {
    obj.forEach(el => stripNil(el, stripFalse, stripEmptyString))
  } else if (_.isObject(obj)) {
    Object.keys(obj).forEach(key => {
      if (_.isNil(obj[key]) ||
        (stripFalse && key !== 'value' && obj[key] === false) ||
        (stripEmptyString && obj[key] === '')) {
        delete obj[key]
      } else if (_.isObject(obj[key])) {
        stripNil(obj[key], stripFalse, stripEmptyString)
      }
    })
  }
  return obj
}

/**
 * Remove specific fields in an object deeply.
 *
 * @param obj
 * @param fields to be stripped.
 * @return {*}
 */
function stripField(obj, ...fields) {
  if (!fields || fields.length <= 0) return obj
  if (_.isArray(obj)) {
    obj.forEach(el => stripField(el, ...fields))
  } else if (_.isObject(obj)) {
    fields.forEach(field => delete obj[field])
    Object.keys(obj).forEach(key => {
      if (_.isObject(obj[key])) {
        stripField(obj[key], ...fields)
      }
    })
  }
  return obj
}

/**
 * remove all fields/items in an object/array
 * Use this method to clear Vue binding object/array.
 * @param obj
 */
function clear(obj) {
  if (_.isArray(obj)) obj.splice(0, obj.length)
  else if (_.isObject(obj)) for (let member in obj) delete obj[member]
}

/**
 * remove item from array
 */
function remove(array, predicate) {
  const removedItems = []
  let index
  do {
    index = _.isFunction(predicate)
      ? _.findIndex(array, predicate) : _.indexOf(array, predicate)
    if (index >= 0) removedItems.push(array.splice(index, 1))
  } while (index >= 0)
  return removedItems
}

/**
 * replace item in array
 */
function replace(array, predicate, replacement) {
  let index
  let fromIndex = 0
  let replaced = false
  do {
    index = _.isFunction(predicate)
      ? _.findIndex(array, predicate, fromIndex) : _.indexOf(array, predicate, fromIndex)
    if (index >= 0) {
      array.splice(index, 1, replacement)
      fromIndex = index + 1
      replaced = true
    }
  } while (index >= 0)
  return replaced
}

/**
 * replace or push item to array
 */
function replaceOrPush(array, predicate, replacement) {
  let index
  let fromIndex = 0
  let replaced = false
  do {
    index = _.isFunction(predicate)
      ? _.findIndex(array, predicate, fromIndex) : _.indexOf(array, predicate, fromIndex)
    if (index >= 0) {
      array.splice(index, 1, replacement)
      fromIndex = index + 1
      replaced = true
    }
  } while (index >= 0)
  if (!replaced) array.push(replacement)
  return array
}

/**
 * clear an object/array first and fill given fields/items.
 * Use this method to update Vue binding object/array.
 * @param from
 * @param to
 */
function refill(from, to) {
  clear(to)
  if (_.isArray(to)) to.push(...from)
  else if (_.isObject(to)) for (let member in from) to[member] = from[member]
}

/**
 * 如果两个参数都是空值, 则不再比较直接返回false
 */
function equalExceptNil(value, other) {
  return (!_.isNil(value) || !_.isNil(other)) && _.isEqual(value, other)
}

/**
 * 用于比较两个array
 *
 * @param keyProvider 返回用于对齐两个array的item key的函数
 * @param iterator 对齐后的回调函数
 */
function diffIndex(list1, list2, keyProvider, iterator) {
  if (!list1) list1 = []
  if (!list2) list2 = []
  let keyCount = 0
  let list1Cursor = 0
  let list2Cursor = 0
  // 两个列表都触底了才退出循环
  while (list1Cursor < list1.length || list2Cursor < list2.length) {
    const list1ItemKey = keyProvider(list1[list1Cursor])
    const list2ItemKey = keyProvider(list2[list2Cursor])
    if (list1Cursor >= list1.length) {
      // list1已到头, 继续走list2
      iterator(null, list2[list2Cursor])
      list2Cursor += 1
    } else if (list2Cursor >= list2.length) {
      // list2已到头, 继续走list1
      iterator(list1[list1Cursor], null)
      list1Cursor += 1
    } else if (!_.isEqual(list1ItemKey, list2ItemKey)) {
      // list1/list2当前行key不一致, 先走list1, 走完list1再走list2
      iterator(list1[list1Cursor], null)
      if (list1Cursor < list1.length) list1Cursor += 1
      else list2Cursor += 1 // list1走到头了再走list2
    } else if (_.isEqual(list1ItemKey, list2ItemKey)) {
      iterator(list1[list1Cursor], list2[list2Cursor])
      list1Cursor += 1
      list2Cursor += 1
    }
  }
}

function reactive(obj, ...ignoreFields) {
  if (_.isArray(obj)) {
    obj.forEach(el => reactive(el, ...ignoreFields))
  } else if (_.isPlainObject(obj) && !Object.isFrozen(obj)) {
    Object.keys(obj).forEach(key => {
      let value = obj[key]
      if (value === undefined) return // undefined字段无需处理
      if (!_.isNil(value) && value.__ob__) return // 已经reactive了
      // 这时value应该是非空并且还未reacitve, 枪查是否排队
      if (ignoreFields && ignoreFields.indexOf(key) >= 0) return
      // make reactive
      if (!Object.getOwnPropertyDescriptor(obj, key).get) {
        delete obj[key]
      }
      Vue.set(obj, key, value)
      if (_.isObject(value)) {
        reactive(value, ...ignoreFields)
      }
    })
  }
  return obj
}

export default {
  stripNil,
  stripField,
  clear,
  remove,
  replace,
  replaceOrPush,
  refill,
  equalExceptNil,
  diffIndex,
  reactive
}
