





























































































































export default function (Component) {
  Component.options.__source = "src/module/components/validation/icmp-diagnosis-dialog.vue"
}
