

























import { Component, Watch } from 'vue-property-decorator'
import BaseInput from './base-input'
import { FilterCondition, Maybe, MdExtendData } from '@/module/graphql'
import copy from 'copy-to-clipboard'
import { gql } from 'apollo-boost'
import { showMdExtendDataDetail } from '@/module/master-data/md-util'
import LocalDbDao from '@/module/common/local-db-dao'

import { stripFilterViewDef } from '@/module/butler/butler-util'
import { getRowId } from '@/module/components/lolth-explorer/supports'

@Component
export default class CmpMdTableDataInputExtend extends BaseInput<string> {
  private mOptionFilterCondition: FilterCondition = null

  private mExtendData: MdExtendData = null

  get syncedExtendData(): Maybe<MdExtendData> {
    return this.mExtendData
  }

  set syncedExtendData(val: Maybe<MdExtendData>) {
    this.mExtendData = val
    this.syncValue = val && getRowId(val)
  }

  get explorerType() {
    return LocalDbDao.getExplorerType('MdExtendData')
  }

  get modelName() {
    return this.column.option.modelName
  }

  get extendExplorerType() {
    return LocalDbDao.getExplorerType(this.modelName)
  }

  created() {
    if (!this.column.option.modelName) {
      throw new Error('无效的扩展表字段, 未指定扩展表')
    }

    if (this.syncValue) {
      this.$apollo.query({
        query: gql`query {
          MdExtendData(modelName: "${this.modelName}", dataId: "${this.syncValue}") {
            dataId dataKey dataValue dataNote extra
          }
        }`
      }).then(resp => {
        this.mExtendData = resp.data.MdExtendData
      })
    }
    this.convertOptionFilterCondition()
  }

  itemLabelFormatter(item: any) {
    return this.explorerType.formatLabel(item) ||
        this.extendExplorerType.formatLabel(item)
  }

  copyToClipboard() {
    if (!this.syncedExtendData) return
    copy(this.explorerType.formatLabel(this.syncedExtendData))
  }

  showDetail() {
    showMdExtendDataDetail(this, this.modelName,
      this.syncedExtendData)
  }

  @Watch('column.option.dictFilter', { deep: true })
  convertOptionFilterCondition() {
    if (this.column.option?.dictFilter) {
      this.$apollo.query({
        query: gql`query convertMdExtendDataFilterCondition($filterCondition: FilterCondition!) {
          convertMdExtendDataFilterCondition(
            modelName: "${this.modelName}",
            filterCondition: $filterCondition)
        }`,
        variables: {
          filterCondition: stripFilterViewDef(this.column.option.dictFilter)
        }
      }).then(resp => {
        this.mOptionFilterCondition = resp.data.convertMdExtendDataFilterCondition
      })
    } else {
      this.mOptionFilterCondition = null
    }
  }
}
