








































export default function (Component) {
  Component.options.__source = "src/module/components/lolth-explorer/explorer-view-switch-widget.vue"
}
