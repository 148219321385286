









import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import _ from 'lodash'
import '@/d2admin/components/d2-icon/font-awesome-4.7.0/css/font-awesome.min.css'

declare interface IExplorerMultiLineTextCellRendererComp extends ICellRendererParams {
  distinct?: boolean
  mapNestedField?: string
  line: 1|2|3|4|5
}

@Component
export default class LolthMultiLineTextCellRenderer extends Vue implements ICellRendererComp {
  private params: IExplorerMultiLineTextCellRendererComp
  private mValue: string = null
  private mLine: 1|2|3|4|5 = 5

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: IExplorerMultiLineTextCellRendererComp): boolean {
    this.mLine = params.line || 5
    if (_.isArray(params.value)) {
      let value = params.value
      if (params.mapNestedField) {
        value = value.map((item: any) => _.get(item, params.mapNestedField) || '')
      }
      if (params.distinct) {
        value = _.uniq(value)
      }
      this.mValue = value.join('\n')
    } else {
      this.mValue = params.value
    }
    return true
  }
}
