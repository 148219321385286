













import { Component, PropSync } from 'vue-property-decorator'
import Vue from 'vue'
import ElPopup from 'element-ui/lib/utils/popup'
import ElDialog from 'element-ui/lib/dialog'

@Component({
  props: {
    ...ElPopup.props,
    ...ElDialog.props
  }
})
export default class LolthIcmpDialog extends Vue {
  @PropSync('visible')
  syncedVisible!: boolean

  destroyed() {
    this.$el?.parentNode?.removeChild(this.$el)
  }

  private onClosed() {
    this.$emit('closed')
    this.$nextTick(() => {
      this.$destroy()
    })
  }
}
