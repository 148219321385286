import { Component, Prop } from 'vue-property-decorator'
import { TagInfo } from '@/module/graphql'
import gql from 'graphql-tag'
import LocalDbDao from '@/module/common/local-db-dao'
import LolthInputTags from '@/module/components/lolth-input-tags.vue'

@Component
export default class LolthTagsEditor extends LolthInputTags {
  @Prop({ required: true }) readonly explorerTypeKey!: string
  @Prop({ required: true }) readonly entityIds!: any[]

  private mAllTags: TagInfo[] = []
  private mLoaded: boolean = false;

  protected get autoCompleteItems(): any[] {
    if (this.mLoaded) return this.mAllTags

    const explorerType = LocalDbDao.getExplorerType(this.explorerTypeKey)
    this.$apollo.query({
      query: gql`query ExplorerFetchTags {
        ${this.explorerTypeKey}Tags {
          tag, count
        }
      }`
    }).then(data => {
      this.mLoaded = true
      this.mAllTags = data.data[this.explorerTypeKey + 'Tags'].map((tag: TagInfo) => {
        return {
          text: tag.tag,
          count: tag.count
        }
      })
      return this.mAllTags
    })
    return []
  }

  public saveTags() {
    const explorerType = LocalDbDao.getExplorerType(this.explorerTypeKey)
    return this.$apollo.mutate({
      mutation: gql`mutation SetTags($ids: [ID!]!, $tags: [String]) {
        setTags${this.explorerTypeKey}(ids: $ids, tags: $tags)
      }`,
      variables: {
        ids: this.entityIds,
        tags: this.tags
      }
    }).then(() => {
      return this.tags
    })
  }
}
