









import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import LocalDbDao from '@/module/common/local-db-dao'
import { ValuedEnum } from '@/module/common/types'
import _ from 'lodash'

@Component
export default class LolthValuedEnumSelect extends Vue {
  @Prop({ required: true }) readonly enumGroup!: string
  @Prop() readonly excludedKeys!: string[] // 次优先
  @Prop() readonly includedKeys!: string[] // 最优先

  @Prop() readonly size?: string
  @Prop() readonly disabled?: boolean
  @Prop({ default: true }) readonly clearable?: boolean
  @Prop() readonly popperClass?: string

  @Prop() readonly id?: string

  @Model('change')
  readonly value: string

  get syncValue() {
    return _.toString(this.value)
  }

  set syncValue(value) {
    this.$emit('change', value)
  }

  private valuedEnumItems?: ValuedEnum[]

  created() {
    if (this.includedKeys) {
      this.valuedEnumItems = []
      this.includedKeys.forEach(key => {
        const valuedEnum = LocalDbDao.getValuedEnumItem(this.enumGroup, key)
        if (valuedEnum) this.valuedEnumItems?.push(valuedEnum)
      })
    } else {
      this.valuedEnumItems = LocalDbDao.getValuedEnum(this.enumGroup)
      if (this.excludedKeys) {
        this.valuedEnumItems = this.valuedEnumItems.filter(valuedEnum => {
          return this.excludedKeys.indexOf(valuedEnum.key) < 0
        })
      }
    }
  }
}
