import _ from 'lodash'
import { GLOBAL_ENV } from '@/d2admin/setting'

/**
 * @param location Location | string
 */
function openTab(vue, location) {
  let path = _.has(location, 'path') ? location.path : location
  const query = _.get(location, 'query') || {}
  if (_.isString(path) && path.indexOf('?') > 0) {
    if (vue.$route.fullPath !== path) {
      vue.$router.push(location)
    }
  } else {
    if (vue.$route.path !== path || !_.isEqual(vue.$route.query, query)) {
      vue.$router.push(location)
    }
  }
}

/**
 * @description 更新标题
 * @param {String} title 标题
 */
function setPageTitle(title) {
  const processTitle = GLOBAL_ENV.TEXT_APP_TITLE || process.env.VUE_APP_TITLE || 'Lolth'
  window.document.title = `${processTitle}${title ? ` | ${title}` : ''}`
}

/**
 * @description 打开新页面
 * @param {String} url 地址
 */
function openNewPage(url) {
  if (/^https:\/\/|http:\/\//.test(url)) {
    window.open(url, '_blank')
  } else {
    window.open('/#' + url, '_blank')
  }
}

export default {
  openTab,
  openNewPage,
  setPageTitle
}
