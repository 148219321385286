





























































export default function (Component) {
  Component.options.__source = "src/module/master-data/views/components/cmp-md-data-chooser.vue"
}
