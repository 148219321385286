











import { Component } from 'vue-property-decorator'
import _ from 'lodash'
import BaseInput from '@/module/master-data/views/table/cmp-md-table-data-input/base-input'

@Component
export default class CmpMdTableDataInputAttachment extends BaseInput<string[]> {
  get accept() {
    if (!this.columnOption.fileTypes) return
    return this.columnOption.fileTypes.map((type: string) => '.' + type).join(',')
  }

  get vendor() {
    return this.columnOption.vendor
  }

  protected onInputCreated() {
    if (_.isNil(this.syncValue)) this.syncValue = []
  }

  isPopup(): boolean {
    return true
  }
}
