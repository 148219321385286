






























































































































































































export default function (Component) {
  Component.options.__source = "src/d2admin/layout/header-aside/components/tabs/index.vue"
}
