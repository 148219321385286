import util from '@/d2admin/libs/util'
import { MenuDelegateDefault } from '@/d2admin/delegate/menu'
import { Store } from 'vuex'
import LocalDbDao from '@/module/common/local-db-dao'
import _ from 'lodash'

class MenuDelegateImpl extends MenuDelegateDefault {
  async loadMenu(store: Store<any>): Promise<void> {
    const principal = LocalDbDao.getPrincipal()
    if (!principal || !_.has(principal, 'userProfile')) {
      return Promise.resolve()
    }
    const syncData = LocalDbDao.loadSyncData()
    // 设置顶栏菜单
    store.commit('d2admin/menu/headerSet', syncData.menus, { root: true })
    // 设置侧边栏菜单
    // store.commit('d2admin/menu/asideSet', syncData.menus, { root: true })
    // 初始化菜单搜索功能
    store.commit('d2admin/search/init', syncData.menus, { root: true })
  }
}

export default new MenuDelegateImpl()
