























































































































import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator'
import {
  FilterType,
  MdeCustomer,
  MdExtendData,
  MutationCreateMdeCustomerArgs,
  MutationUpdateMdeCustomerArgs
} from '@/module/graphql'
import util from '@/d2admin/libs/util'
import gql from 'graphql-tag'
import { buildGraphQLQueryPartInput, submitMutation } from '@/module/common/util/graphql-util'
import _ from 'lodash'

@Component
export default class CmpMdeCustomerForm extends Vue {
  @Prop({ default: true }) readonly readonly?: boolean
  @Prop() readonly edit?: boolean
  @Prop() readonly mdExtendData: MdExtendData

  @ModelSync('value', 'change', { required: true })
  syncedValue!: MdeCustomer

  private paymentConditionFixedCondition = {
    filterType: FilterType.String,
    operator: 'equals',
    field: 'dictionary.name',
    value: '付款条件'
  }

  created(): void {
    if (this.mdExtendData) {
      this.$apollo.query({
        query: gql`query {
            MdeCustomer(code: "${this.mdExtendData.dataId}") {
              code ver name note fullName paymentCondition { id key value valueForShort} currency { code name symbol}
              taxType taxRate depositRate manager contact contactNumber email faxNumber country remittingBank remittingAccount taxAccount contactAddress billAddress
            }
          }`
      }).then(data => {
        this.syncedValue = data.data.MdeCustomer
      })
    }
  }

  submit() {
    let mutation:string
    let postValues = _.cloneDeep(this.syncedValue) as any
    if (postValues.paymentCondition) {
      postValues.paymentConditionId = postValues.paymentCondition.id
    }
    if (postValues.currency) {
      postValues.currencyCode = postValues.currency.code
    }
    if (postValues.taxRate) {
      postValues.taxRate = parseFloat(this.syncedValue.taxRate as any)
    } else {
      postValues.taxRate = null
    }
    if (postValues.depositRate) {
      postValues.depositRate = parseFloat(this.syncedValue.depositRate as any)
    } else {
      postValues.depositRate = null
    }
    if (postValues.taxType) {
      postValues.taxType = parseInt(postValues.taxType)
    } else {
      postValues.taxType = null
    }

    let mutationArgs: MutationCreateMdeCustomerArgs | MutationUpdateMdeCustomerArgs
    delete postValues.ver
    delete postValues.paymentCondition
    delete postValues.currency

    if (this.edit) {
      mutation = 'updateMdeCustomer'
      delete postValues.code
      mutationArgs = {
        code: this.syncedValue.code,
        ver: this.syncedValue.ver || 0,
        values: {
          ...postValues
        }
      }
    } else {
      mutation = 'createMdeCustomer'
      mutationArgs = {
        ...postValues
      }
    }
    const mutationOptions: any = {
      mutation: gql(`mutation {
        ${mutation}(${buildGraphQLQueryPartInput(mutationArgs)}) {
          code, ver, name, note
        }
      }`)
    }
    submitMutation(this, {
      mutationOptions,
      fullValidate: true
    }).then(data => {
      this.$message({
        message: '保存成功', type: 'success', showClose: true
      })
      this.$emit('submit', this.syncedValue)
    })
  }

  public static initMdeCustomer(mdeCustomer: MdeCustomer = {
    code: '',
    name: '',
    fullName: '',
    paymentCondition: { id: '', key: '', value: '' },
    currency: null
  }) {
    if (mdeCustomer.taxType) {
      mdeCustomer.taxType = mdeCustomer.taxType.toString()
    }
    util.objects.reactive(mdeCustomer)
    return mdeCustomer
  }
}
