


















import { Component } from 'vue-property-decorator'
import _ from 'lodash'
import BaseInput from '@/module/master-data/views/table/cmp-md-table-data-input/base-input'
import copy from 'copy-to-clipboard'

@Component
export default class CmpMdTableDataInputBoolean extends BaseInput<boolean> {
  get falseLabel() {
    return _.isNil(this.syncValue) ? '未设置'
      : (this.columnOption.noLabel || '否')
  }

  setNull() {
    this.syncValue = null
  }
}
