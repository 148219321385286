




































import { Component, Prop, Watch } from 'vue-property-decorator'
import { FilterCondition, FilterType, MdDictionaryEntry } from '@/module/graphql'
import BaseFieldFilter from '@/module/components/lolth-filter/base-field-filter'
import util from '@/d2admin/libs/util'
import _ from 'lodash'

@Component
export default class CmpMdDictAttrFilter extends BaseFieldFilter {
  @Prop({ required: true }) readonly dictId!: number
  @Prop({ default: false }) readonly multipleDict!: boolean

  private mValueMode = 'select' // select | text | empty
  private mSelectValues: MdDictionaryEntry[] = []
  private mTextValue = ''
  private operators = [
    'keyEquals', 'keyContains', 'keyStartsWith', 'keyEndsWith',
    'valueEquals', 'valueContains', 'valueStartsWith', 'valueEndsWith',
    'in', 'empty'
  ]

  get syncSelectValue() {
    if (this.filterCondition.operator !== 'in') return []
    return this.mSelectValues
  }

  set syncSelectValue(selections: MdDictionaryEntry[]) {
    util.objects.refill(selections, this.mSelectValues)
    this.filterCondition.value = selections.map(s => s.id).join(',')
  }

  get syncTextValue() {
    if (this.filterCondition.operator === 'in' || this.filterCondition.operator === 'empty') return ''
    return this.mTextValue
  }

  set syncTextValue(textVal: string) {
    this.mTextValue = textVal
    this.filterCondition.value = textVal
  }

  get syncOperator() {
    if (!this.filterCondition) return 'unknown'
    if (this.filterCondition.operator === 'in' || this.filterCondition.operator === 'empty') {
      return this.filterCondition.operator
    } else if (this.filterCondition.extra?.path) {
      switch (this.filterCondition.operator) {
        case 'equals':
          return this.filterCondition.extra.path.indexOf('Key') >= 0 ? 'keyEquals' : 'valueEquals'
        case 'contains':
          return this.filterCondition.extra.path.indexOf('Key') >= 0 ? 'keyContains' : 'valueContains'
        case 'startsWith':
          return this.filterCondition.extra.path.indexOf('Key') >= 0 ? 'keyStartsWith' : 'valueStartsWith'
        case 'endsWith':
          return this.filterCondition.extra.path.indexOf('Key') >= 0 ? 'keyEndsWith' : 'valueEndsWith'
      }
    }
    return 'unknown'
  }

  set syncOperator(operator: string) {
    if (operator === 'in' || operator === 'empty') {
      this.filterCondition.extra = null
      this.filterCondition.operator = operator
    } else {
      this.filterCondition.extra = {}
      const extraKeyPath = this.multipleDict ? 'extra.*.dataKey' : 'extra.dataKey'
      const extraValuePath = this.multipleDict ? 'extra.*.dataValue' : 'extra.dataValue'
      switch (operator) {
        case 'keyEquals':
          this.filterCondition.operator = 'equals'
          this.filterCondition.extra.path = extraKeyPath
          break
        case 'keyContains':
          this.filterCondition.operator = 'contains'
          this.filterCondition.extra.path = extraKeyPath
          break
        case 'keyStartsWith':
          this.filterCondition.operator = 'startsWith'
          this.filterCondition.extra.path = extraKeyPath
          break
        case 'keyEndsWith':
          this.filterCondition.operator = 'endsWith'
          this.filterCondition.extra.path = extraKeyPath
          break
        case 'valueEquals':
          this.filterCondition.operator = 'equals'
          this.filterCondition.extra.path = extraValuePath
          break
        case 'valueContains':
          this.filterCondition.operator = 'contains'
          this.filterCondition.extra.path = extraValuePath
          break
        case 'valueStartsWith':
          this.filterCondition.operator = 'startsWith'
          this.filterCondition.extra.path = extraValuePath
          break
        case 'valueEndsWith':
          this.filterCondition.operator = 'endsWith'
          this.filterCondition.extra.path = extraValuePath
          break
      }
    }
  }

  get isValid() {
    return !(!this.filterCondition.filterType ||
      !this.filterCondition.field ||
      !this.filterCondition.operator ||
      !this.filterCondition.value ||
      this.filterCondition.value.length <= 0)
  }

  get fixedFilterCondition(): FilterCondition {
    return {
      filterType: FilterType.Number,
      field: 'dictionary.id',
      operator: 'equals',
      value: this.dictId + ''
    }
  }

  created(): void {
    this.initData()
  }

  @Watch('filterCondition')
  initData() {
    if (!this.filterCondition.operator) this.$set(this.filterCondition, 'operator', 'in')
    if (!this.filterCondition.value) this.$set(this.filterCondition, 'value', null)
    this.updateFilterType(this.filterCondition.operator!)
    if (this.filterCondition.value) {
      if (this.filterCondition.operator === 'in') {
        this.syncSelectValue = this.filterCondition.value.split(',').map(id => {
          return { id, key: id, value: id }
        })
      } else if (this.filterCondition.operator !== 'empty') {
        this.syncTextValue = this.filterCondition.value
      }
    }
    if (_.isUndefined(this.filterCondition.extra)) this.$set(this.filterCondition, 'extra', null)
  }

  public updateFilterType(operator: string) {
    if (operator === 'empty') {
      this.filterCondition.filterType = FilterType.IsNull
      this.mValueMode = 'empty'
    } else if (operator === 'in') {
      this.filterCondition.filterType = FilterType.Number
      this.mValueMode = 'select'
    } else {
      this.filterCondition.filterType = FilterType.String
      this.mValueMode = 'text'
    }
  }

  onOperatorChanged(operator: string) {
    this.updateFilterType(operator)
    if (this.filterCondition.value) this.internalOnFilterChanged()
  }

  clearContent() {
    this.filterCondition.operator = 'in'
    this.filterCondition.value = null
  }
}
