import { Component, Ref, Vue } from 'vue-property-decorator'
import { ColDef, GridOptions, RowDoubleClickedEvent, SideBarDef } from 'ag-grid-community'
import LocalDbDao from '@/module/common/local-db-dao'
import ExplorerMdAttrPanel from '@/module/master-data/views/components/explorer-support/cmp-explorer-md-attr-panel.vue'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import { showMdDataEditor, showMdDataViewer } from '@/module/master-data/md-util'
import _ from 'lodash'
import { MdDataWithAttrsFragmentDoc } from '@/module/graphql'
import gql from 'graphql-tag'
import { Indexed } from '@/d2admin/delegate'

@Component
export default class MixinMdDataExplorer extends Vue {
  @Ref() readonly vExplorer!: LolthExplorer

  protected sMdDataFieldName = ''

  protected onRowDoubleClicked(event: RowDoubleClickedEvent) {
    showMdDataViewer(this, this.sMdDataFieldName ? event.data[this.sMdDataFieldName].code : event.data.code)
  }

  protected copyMdData(vue: any, code: string, presetAttrs: Indexed) {
    this.$apollo.query({
      query: gql`query {
        MdData(code: "${code}") {
          ...mdDataWithAttrs
        }
      }
      ${MdDataWithAttrsFragmentDoc}`
    }).then(data => {
      // let presetAttrs = data.data.MdData.attributes.map((attr: MdAttribute) => convertAttrValueToInput(attr))
      showMdDataEditor(vue, {
        copiedAttrs: data.data.MdData.attributes,
        presetAttrs: presetAttrs,
        setBom: false
      }, mdData => {
        vue.explorer?.refresh()
      })
    })
  }

  protected extendGridOptions(explorer: LolthExplorer, gridOptions: GridOptions) {
    // setup fixed fields
    const fixedFields = LocalDbDao.getMdDomainFixedFields('', true)
    let firstFixedColumnIndex = -1
    const fixedFieldColumns: ColDef[] = _.remove(gridOptions.columnDefs!, colDef => {
      if (!('field' in colDef)) return false
      const index = _.findIndex(fixedFields, fixedField => fixedField.field === colDef.field)
      if (index < 0) return false
      if (firstFixedColumnIndex < 0) firstFixedColumnIndex = _.indexOf(gridOptions.columnDefs, colDef)
      return true
    })
    fixedFieldColumns.sort((lhs, rhs) => {
      const lhsSeq = _.find(fixedFields, fixedField => fixedField.field === (lhs as ColDef).field)!.seq
      const rhsSeq = _.find(fixedFields, fixedField => fixedField.field === (rhs as ColDef).field)!.seq
      return rhsSeq - lhsSeq
    })
    fixedFieldColumns.forEach(colDef => {
      const fixedField = _.find(fixedFields, fixedField => fixedField.field === (colDef as ColDef).field)
      if (!fixedField || fixedField.disable) return

      colDef.headerName = fixedField.name
      colDef.filterParams.viewDef.fieldName = fixedField.name
      // (colDef as ColDef).width = fixedField.width
      gridOptions.columnDefs?.splice(firstFixedColumnIndex, 0, colDef)
    })

    gridOptions.frameworkComponents.explorerMdAttrPanel = ExplorerMdAttrPanel
    const sideBarDef = gridOptions.sideBar as SideBarDef
    sideBarDef?.toolPanels?.push({
      id: 'md-attr-selector-panel',
      labelDefault: '添加列',
      labelKey: 'explorerMdAttrPanel',
      iconKey: 'columns',
      toolPanel: 'explorerMdAttrPanel',
      toolPanelParams: {
        explorer: explorer,
        gridOptions: gridOptions,
        attrExtend: this,
        attributesField: 'attributes'
      }
    })

    gridOptions.context.diagnosisPermission = 'MdDataDiagnosis'
  }

  protected extendDetailGridOptions(detailGridOptions: GridOptions) {
    // setup fixed fields
    const fixedFields = LocalDbDao.getMdDomainFixedFields('entryData', true)
    let firstFixedColumnIndex = -1
    const fixedFieldColumns = _.remove(detailGridOptions.columnDefs!, colDef => {
      if (!('field' in colDef)) return false
      const index = _.findIndex(fixedFields, fixedField => fixedField.field === colDef.field)
      if (index < 0) return false
      if (firstFixedColumnIndex < 0) firstFixedColumnIndex = _.indexOf(detailGridOptions.columnDefs, colDef)
      return true
    })
    fixedFieldColumns.sort((lhs, rhs) => {
      const lhsSeq = _.find(fixedFields, fixedField => fixedField.field === (lhs as ColDef).field)!.seq
      const rhsSeq = _.find(fixedFields, fixedField => fixedField.field === (rhs as ColDef).field)!.seq
      return rhsSeq - lhsSeq
    })
    fixedFieldColumns.forEach(colDef => {
      const fixedField = _.find(fixedFields, fixedField => fixedField.field === (colDef as ColDef).field)
      if (!fixedField || fixedField.disable) return

      colDef.headerName = '元件' + fixedField.name;
      (colDef as ColDef).width = fixedField.width
      detailGridOptions.columnDefs?.splice(firstFixedColumnIndex, 0, colDef)
    })
  }
}
