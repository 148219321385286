import { ModuleHook } from '@/d2admin/module/types'
import { ComponentOptions } from 'vue'
import { FrameworkComponentsRegistry } from '@/module/components/lolth-explorer/supports'
import CmpMdDataCodeListRenderer
  from '@/module/master-data/views/components/explorer-support/cmp-md-data-code-list-cell-renderer.vue'

export class MdaModuleHook implements ModuleHook {
  onModuleLoaded(vueOptions: ComponentOptions<any>): any {
    FrameworkComponentsRegistry.mdDataCodeListRenderer = CmpMdDataCodeListRenderer
  }
}

export default new MdaModuleHook()
