




import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import { findAttribute } from '@/module/master-data/md-util'

declare interface IExplorerMdAttrCellRendererComp extends ICellRendererParams {
  attributesField?: string,
  mdFieldId?: number | string
}

/**
 * 对应两种格式的值:
 * 1. 整个attributes + attributesField + mdFieldId, 需要通过gridOptions找到列定义colDef, 并将里面并调整其中的`cellRendererParams`
 * 2. MdAttribute
 */
@Component
export default class CmpMdAttributeCellRenderer extends Vue implements ICellRendererComp {
  private params!: IExplorerMdAttrCellRendererComp
  private mValue: any = null

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: IExplorerMdAttrCellRendererComp): boolean {
    if (this.params.attributesField) {
      const attributes = this.params.data[this.params.attributesField]
      this.mValue = findAttribute(attributes, this.params.mdFieldId!) || {}
    } else {
      this.mValue = this.params.data || {}
    }
    return true
  }
}
