














































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/module/butler/views/page-user.vue"
}
