




import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import _ from 'lodash'
import { DATE_FORMAT } from '@/module/common/constants'

declare interface IExplorerDateCellRendererComp extends ICellRendererParams {
  format?: string
}

@Component
export default class LolthDateCellRenderer extends Vue implements ICellRendererComp {
  private params: IExplorerDateCellRendererComp
  private mFormat?: string = null
  private mValue: string | Date = null
  get format() {
    return this.mFormat || DATE_FORMAT
  }

  get formattedDate() {
    if (!this.mValue) return ''
    return this.$moment(this.mValue).format(this.format)
  }

  created() {
    this.refresh(this.params)
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  refresh(params: IExplorerDateCellRendererComp): boolean {
    this.mFormat = params.format
    this.mValue = params.value
    return true
  }
}
