
















import { Component, Prop } from 'vue-property-decorator'
import { FilterCondition, FilterType } from '@/module/graphql'
import BaseFieldFilter from './base-field-filter'
import LocalDbDao from '@/module/common/local-db-dao'
import util from '@/d2admin/libs/util'
import _ from 'lodash'

@Component
export default class EnumFlagFilter extends BaseFieldFilter {
  @Prop({ required: true }) customOperator: string;
  @Prop({ required: true }) readonly enumGroup?: string

  get valuedEnumItems() {
    if (!this.enumGroup) return []
    return LocalDbDao.getValuedEnum(this.enumGroup)
  }

  get isValid() {
    return !(!this.filterCondition.filterType ||
      _.isNil(this.filterCondition.value))
  }

  public initFilterCondition(filterCondition: FilterCondition) {
    filterCondition.filterType = FilterType.Custom
    filterCondition.field = null
    filterCondition.operator = this.customOperator
    util.objects.reactive(filterCondition)
  }

  clearContent() {
    this.filterCondition.value = null
    this.internalOnFilterChanged()
  }
}
