































import { Component, Prop, Vue } from 'vue-property-decorator'
import { MdDataBomEntry, MdDataBomEntryFragmentDoc } from '@/module/graphql'
import gql from 'graphql-tag'
import LocalDbDao from '@/module/common/local-db-dao'
import { showMdDataViewer } from '@/module/master-data/md-util'
import { FixedField } from '@/module/master-data/types'
import uniqolor from 'uniqolor'
import _ from 'lodash'

declare type MdDataBomEntryWithLevel = MdDataBomEntry & {
  level?: number
}
@Component
export default class CmpMdDataBomEntryViewer extends Vue {
  @Prop({ required: true }) readonly mdDataCode!: string

  private bomEntries: MdDataBomEntryWithLevel[] = []
  private mFixedField0!: FixedField
  private mFixedFields!: FixedField[]
  private mRowStyleCache: { [indent:number]: {} } = {}

  created(): void {
    this.initBomEntries()
    this.mFixedFields = LocalDbDao.getMdDomainFixedFields('entryData')
    this.mFixedField0 = this.mFixedFields[0]
    this.mFixedFields.splice(0, 1)
  }

  private rowStyle(row: any, rowIndex: number) {
    const indent = row.row.level
    if (this.mRowStyleCache[indent]) return this.mRowStyleCache[indent]

    const bgColor = uniqolor((indent + 1) * 15, {
      saturation: 50,
      lightness: 90
    })
    this.mRowStyleCache[indent] = {
      'padding-bottom': '10px',
      'padding-top': '10px',
      'border-bottom': '1px solid gainsboro',
      'background-color': bgColor.color
    }
    return this.mRowStyleCache[indent]
  }

  private initBomEntries() {
    this.fetchMdDataBomEntries(this.mdDataCode).then(bomEntries => {
      this.bomEntries = bomEntries
      this.bomEntries.forEach(entry => { entry.level = 0 })
    })
  }

  private load(treeNodeData: any, treeNode: any, resolve: Function):void {
    this.fetchMdDataBomEntries(treeNodeData.entryData.code).then(bomEntries => {
      bomEntries.forEach((entry: any) => { entry.level = treeNodeData.level + 1 })
      resolve(bomEntries)
    })
  }

  private fetchMdDataBomEntries(mdDataCode: string) {
    return this.$apollo.query({
      query: gql`query fetchMdData($id: ID, $code: String) {
        MdData(id: $id, code: $code) {
          bomEntries {
            id, uid, bomTemplateEntryKey
            entryData {
              id, uid, ver, code, field0, field1, field2, field3, field4, field5, field6, field7, field8, field9, structureKey
              bomEntryCount
            },
            count, countBase, unit, seq, extra
          }
        }
      }`,
      variables: {
        code: mdDataCode
      }
    }).then(data => {
      data.data.MdData.bomEntries.forEach((bomEntry: any) => {
        if (bomEntry.entryData.bomEntryCount) {
          bomEntry.hasChildren = true
        }
      })
      return data.data.MdData.bomEntries
    })
  }

  private onRowDoubleClick(row: MdDataBomEntry) {
    showMdDataViewer(this, row.entryData.code)
  }

  private getField0Value(row: MdDataBomEntry) {
    return _.get(row, this.mFixedField0.field)
  }
}
