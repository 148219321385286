import { ModuleHook } from '@/d2admin/module/types'
import LocalDbDao from '@/module/common/local-db-dao'
import axios from '@/d2admin/plugin/axios/index'
import { MdField, MdFieldFragmentDoc, MdoEndProductInfoTemplateFragmentDoc } from '@/module/graphql'
import { hasError } from '@/module/common/util/error-util'
import CmpMdoConfigTemplate from '@/module/plugin-md-product-order/views/components/config/cmp-mdo-config-template.vue'
import { ComponentOptions } from 'vue'
import { FrameworkComponentsRegistry } from '@/module/components/lolth-explorer/supports'
import CmpMdoProductOrderCodeCellRenderer
  from '@/module/plugin-md-product-order/views/components/explorer-support/cmp-mdo-product-order-code-cell-renderer.vue'
import CmpMdoEndProductsPropRenderer
  from '@/module/plugin-md-product-order/views/components/explorer-support/cmp-mdo-end-products-prop-renderer.vue'
import CmpUndeliveredBoxQtyCellRenderer
  from '@/module/plugin-md-product-order/views/components/explorer-support/cmp-undelivered-box-qty-cell-renderer.vue'

export const LOCAL_DB_CUSTOM_DATA_MDO_FIELDS = 'mdoFields'
export const LOCAL_DB_CUSTOM_DATA_MDO_EP_INFO_TEMPLATES = 'mdoEpInfoTemplates'

function syncMdoFields(): Promise<MdField[]> {
  return axios.post('/graphql', {
    query: `query {
      getMdoEpInfoFields {
        ... mdField payload { context }
      }
    }
    ${MdFieldFragmentDoc.loc.source.body}`
  }).then((response: any) => {
    if (!hasError(response.data.errors)) {
      return response.data.data.getMdoEpInfoFields
    } return []
  })
}

function syncMdoTemplates(): Promise<CmpMdoConfigTemplate[]> {
  return axios.post('/graphql', {
    query: `query {
      MdoEndProductInfoTemplates {
          ... mdoEndProductInfoTemplate
      }
    }
    ${MdoEndProductInfoTemplateFragmentDoc.loc.source.body}`
  }).then((response: any) => {
    if (!hasError(response.data.errors)) {
      return response.data.data.MdoEndProductInfoTemplates
    } return []
  })
}

export class MdoModuleHook implements ModuleHook {
  onModuleLoaded(vueOptions: ComponentOptions<any>) {
    // 注册ag-grid frameworkComponents
    FrameworkComponentsRegistry.mdoProductOrderCodeRenderer = CmpMdoProductOrderCodeCellRenderer
    FrameworkComponentsRegistry.mdoEndProductsPropRenderer = CmpMdoEndProductsPropRenderer
    FrameworkComponentsRegistry.mdoUndeliveredBoxQtyCellRenderer = CmpUndeliveredBoxQtyCellRenderer
  }
  onLoggedIn = () => {
    return Promise.all([syncMdoFields().then(mdFields => {
      return LocalDbDao.saveCustomData(LOCAL_DB_CUSTOM_DATA_MDO_FIELDS, mdFields)
    }), syncMdoTemplates().then(epInfoTemplates => {
      return LocalDbDao.saveCustomData(LOCAL_DB_CUSTOM_DATA_MDO_EP_INFO_TEMPLATES, epInfoTemplates)
    })])
  }
}

export default new MdoModuleHook()
