






































import { Component, Ref, Vue } from 'vue-property-decorator'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import axios from '@/d2admin/plugin/axios/index'
import qs from 'qs'
import copy from 'copy-to-clipboard'
import { ColDef, GetContextMenuItemsParams, GridOptions, RowDoubleClickedEvent } from 'ag-grid-community'
import UserInfoDialog from '@/module/butler/views/components/cmp-user-info-dialog.vue'
import { MessageBoxInputData } from 'element-ui/types/message-box'
import CmpJoiningApplicationDialog from '@/module/butler/views/components/cmp-joining-application-dialog.vue'
import { MenuItemDef } from 'ag-grid-community/dist/lib/entities/gridOptions'

@Component({
  name: 'PageJoiningApplication',
  components: {
    CmpJoiningApplicationDialog,
    UserInfoDialog
  }
})
export default class PageJoiningApplication extends Vue {
  @Ref() readonly vExplorer: LolthExplorer
  @Ref() readonly approveFormRef!: any
  @Ref() readonly inviteFormRef!: any
  private mSelectedData: any = []
  private dialogVisible: boolean = false
  private mInviteDialogVisible: boolean = false
  private approved: string = ''
  private accountId: number = 0
  private userInfoDialogVisible:boolean = false
  private formApprove = {
    rejectReason: '',
    approved: ''
  }

  private onRowDoubleClicked(event: RowDoubleClickedEvent) {
    if (!event.data.account || !event.data.account.id) {
      this.$message({
        message: '被邀请的用户还未注册账号', type: 'info', showClose: true
      })
      return
    }
    this.userInfoDialogVisible = true
    this.accountId = event.data.account.id
  }

  private approveFormRules = {
    rejectReason: [
      {
        validator: (rule: any, value: any, callback: any) => {
          if (this.formApprove.approved === '0' && this.formApprove.rejectReason === '') {
            callback(new Error('请输入不通过原因'))
          } else {
            callback()
          }
        },
        trigger: 'blur'
      }
    ]
  }

  onInvitationSaved() {
    this.vExplorer.refresh()
  }

  approve() {
    if (!this.mSelectedData || this.mSelectedData[0].approvalStatus !== 1) {
      this.$message({
        message: '请选择待审批的记录', type: 'error', showClose: true
      })
      return
    }
    this.formApprove.approved = ''
    this.dialogVisible = true
  }

  commitApprove() {
    this.approveFormRef.validate((valid: boolean) => {
      if (valid) {
        this.$confirm('确认审批' + (this.formApprove.approved === '1' ? '通过' : '不通过'), '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post('/butler/joining-application/approve',
            qs.stringify({ id: this.mSelectedData[0].id, approved: this.formApprove.approved === '1', rejectReason: this.formApprove.rejectReason })
          ).then((resp: any) => {
            this.$message({
              message: '提交成功', type: 'success', showClose: true
            })
            this.vExplorer.refresh()
            this.dialogVisible = false
          })
        })
      } else {
        this.$message({
          message: '提交信息有误,请修正', type: 'error', showClose: true
        })
      }
    })
  }

  private getContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
    const vue = this
    let menus: MenuItemDef[] = []
    menus.push({
      name: '邀请注册',
      action() {
        vue.mInviteDialogVisible = true
      }
    })
    if (!params.node) return menus
    if (!params.node.data.account || !params.node.data.account.identity) {
      menus.push({
        name: '复制邀请链接',
        action() {
          copy(vue.$baseUrl + '/#/invitation/' + params.node.data.invitationCode)
          vue.$notify({
            type: 'info',
            title: '消息',
            message: '邀请链接已复制到剪贴板'
          })
        }
      })
    } else if (params.node.data.approvalStatus === 1) {
      menus = menus.concat([{
        name: '审批通过',
        action() {
          vue.$confirm('确认审批通过', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            axios.post('/butler/joining-application/approve',
              qs.stringify({ id: params.node.data.id, approved: true })
            ).then((resp: any) => {
              vue.$message({
                message: '提交成功',
                type: 'success',
                showClose: true
              })
              vue.vExplorer.refresh()
              vue.dialogVisible = false
            })
          })
        }
      }, {
        name: '审批不通过',
        action() {
          vue.$prompt('不通过原因', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputValidator: value => {
              if (value === null || value === '') {
                return '请输入不通过原因'
              } else {
                return true
              }
            }
          }).then((data: MessageBoxInputData) => {
            axios.post('/butler/joining-application/approve',
              qs.stringify({ id: params.node.data.id, approved: false, rejectReason: data.value })
            ).then((resp: any) => {
              vue.$message({
                message: '提交成功',
                type: 'success',
                showClose: true
              })
              vue.vExplorer.refresh()
              vue.dialogVisible = false
            })
          }).catch(() => {
            vue.$message({
              type: 'info',
              message: '取消提交',
              showClose: true
            })
          })
        }
      }])
    }
    return menus
  }

  private extendGridOptions(explorer: LolthExplorer, gridOptions: GridOptions) {
    gridOptions.columnDefs?.forEach((colDef: ColDef) => {
      if (colDef.field === 'account.username') {
        colDef.headerName = '用户名'
      }
      if (colDef.field === 'account.mobilePhone') {
        colDef.headerName = '电话'
      }
      if (colDef.field === 'approvedBy.displayName') {
        colDef.headerName = '审批人'
      }
    })
  }
}
