







































































































export default function (Component) {
  Component.options.__source = "src/module/plugin-md-product-order/views/components/product-order/icmp-mdo-end-product-dialog.vue"
}
