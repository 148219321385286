import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import {
  AggregationColumn,
  ChartView,
  Column,
  ExplorerView,
  FilterCondition,
  FilterType,
  SortColumn,
  SortType
} from '@/module/graphql'
import json5 from 'json5'
import { ColumnApi, ColumnState, GridApi, GridOptions, ProcessCellForExportParams, SideBarDef } from 'ag-grid-community'
import LocalDbDao, { DEFAULT_VIEW_HASH } from '@/module/common/local-db-dao'
import util from '@/d2admin/libs/util'
import _ from 'lodash'
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef'
import { FilterViewType } from '@/module/components/lolth-filter/types'
import { AgGridVue } from 'ag-grid-vue'
import ExplorerSelectionWidget from '@/module/components/lolth-explorer/explorer-selection-widget.vue'
import ExplorerViewSwitchWidget from '@/module/components/lolth-explorer/explorer-view-switch-widget.vue'
import {
  convertFilterColumn,
  convertSortColumns,
  DefaultAgGridServerSideDataOptions,
  FrameworkComponentsRegistry,
  suppressSelectAllEvent
} from '@/module/components/lolth-explorer/supports'
import { ToolPanelVisibleChangedEvent } from 'ag-grid-community/dist/lib/events'
import ExplorerChartViewSaver from '@/module/components/lolth-explorer/explorer-chart-view-saver.vue'
import { base64ToFile } from '@/module/common/util/common-util'

export type ExtraColumnDef = {
  columnName: string
  columnType: 'stringColumn' | 'numberColumn' | 'dateColumn' | 'booleanColumn'
}

@Component
export default class MixinLolthExplorerView extends Vue {
  @Ref() readonly vAgGrid!: AgGridVue
  @Ref() readonly vSelectionWidget!: ExplorerSelectionWidget
  @Ref() readonly vViewWidget!: ExplorerViewSwitchWidget

  @Prop() readonly fixedFilterCondition?: FilterCondition
  @Prop() readonly defaultFilterConditions?: FilterCondition[]
  @Prop() readonly defaultSort?: SortColumn[]

  @Prop() readonly chartViewId?: number

  @Prop({ default: false }) readonly keepSelectionOnPaging: boolean
  @Prop() readonly extendStateSaving?: (currentView: ExplorerView, gridOptions: GridOptions,
                                        gridApi: GridApi, columnApi: ColumnApi) => void
  @Prop() readonly extendStateApplying?: (currentView: ExplorerView, gridOptions: GridOptions,
                                          gridApi: GridApi, columnApi: ColumnApi) => void
  @Prop() readonly extendColumnDefApplying?: (currentView: ExplorerView, gridOptions: GridOptions) => boolean

  protected mGridOptions: GridOptions
  private mRedrawSwitch: boolean = false

  protected mFixedFilterConditions: FilterCondition[] = []
  protected mExternalFilterCondition: FilterCondition
  protected mColumnFilterConditions: FilterCondition[] = []
  private mMergedFilterCondition: FilterCondition = {
    filterType: FilterType.And,
    conditions: []
  }

  private mCurrentView: ExplorerView = null // 当前视图
  private mExplorerViews: ExplorerView[] = []
  private mGridMounted = false

  protected mChartViews: ChartView[] = []
  protected mShowingChartView: ChartView = null
  protected mChartViewSaverVisible = false

  protected mNumericCellSum: number = 0

  /******************************************************************************/
  /* Abstraction */
  /******************************************************************************/

  protected getExplorerViewModelName(): string {
    throw new Error('Not Implemented')
  }

  protected setupDataSource() {
    throw new Error('Not Implemented')
  }

  protected suspendDataSource() {
    throw new Error('Not Implemented')
  }

  protected getExplorerTypeDefaultSort(): SortColumn {
    throw new Error('Not Implemented')
  }

  protected clearSelections() {
    throw new Error('Not Implemented')
  }

  /******************************************************************************/
  /* Public Api */
  /******************************************************************************/

  public refresh() {
    this.gridApi()?.purgeServerSideCache()
    this.clearSelections()
  }

  public refreshHard() {
    this.mRedrawSwitch = false
    this.$nextTick(() => {
      this.mRedrawSwitch = true
      this.$nextTick(() => {
        this.attachAdditionalWidget()
      })
    })
    this.clearSelections()
  }

  /******************************************************************************/
  /* Grid Setting */
  /******************************************************************************/

  public get gridOptions(): GridOptions {
    return this.mGridOptions
  }

  public gridApi(): GridApi {
    if (!this.mGridOptions.api) throw new Error('ag-grid is not ready yet. call this getter in mounted()')
    return this.mGridOptions.api
  }

  public columnApi(): ColumnApi {
    if (!this.mGridOptions.columnApi) throw new Error('ag-grid is not ready yet. call this getter in mounted()')
    return this.mGridOptions.columnApi
  }

  public isGridReady(): boolean {
    return !!this.mGridOptions.api
  }

  created() {
    // 先生成一个空的defaultView, 用于保存初始默认视图
    this.currentView = LocalDbDao.buildDefaultExplorerView(this.getExplorerViewModelName())

    // 加载视图列表, 此时grid还未初始化, 没有gridApi, 先把columnDef改好, mounted以后就没机会改了
    this.loadViews().then(() => {
      const currentView = this.loadUsingView()
      if (currentView) {
        this.applyColumnDefFromView(currentView)
        this.mRedrawSwitch = true
      }
    })
  }

  protected onMyGridMounted() {
    this.onGridMounted()
    this.attachAdditionalWidget()
  }

  protected buildBasicGridOptions(): GridOptions {
    // 以默认设置为模板设置Grid
    const gridOptions = _.assign(_.cloneDeep(DefaultAgGridServerSideDataOptions), {
      onFilterChanged: () => {
        this.onFilterChanged()
        this.saveStateToView()
      },
      onToolPanelVisibleChanged: (event: ToolPanelVisibleChangedEvent) => {
        if (event.source === 'adv-filter') {
          document.querySelectorAll('.ag-tool-panel-wrapper:not(.ag-hidden)').forEach((el: HTMLElement) => {
            el.style.width = '700px'
          })
        }
      },
      onSortChanged: this.saveStateToView,
      onColumnRowGroupChanged: this.saveStateToView,
      onRangeSelectionChanged: () => {
        let gridApi = this.gridApi()
        const cellRanges = gridApi.getCellRanges()
        let sum = 0
        cellRanges.forEach(function (range) {
          let startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
          let endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
          for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
            range.columns.forEach(function (column) {
              let rowModel = gridApi.getModel()
              let rowNode = rowModel.getRow(rowIndex)
              let value = gridApi.getValue(column, rowNode)
              if (typeof value === 'number') {
                sum += value
              }
            })
          }
        })
        this.mNumericCellSum = sum.round(3)
      },
      onColumnEverythingChanged: (event) => {
        if (event.source === 'gridInitializing') return
        this.saveStateToView()
      },
      onDragStopped: (event) => {
        event.columnApi.getValueColumns()
          .filter(column => column.getAggFunc() === 'count')
          .forEach(column => {
            const colDef = event.api.getColumnDef(column.getColId())
            colDef.chartDataType = 'series'
          })
        this.saveStateToView()
      },
      onGridReady: this.onMyGridMounted
    } as GridOptions)
    gridOptions.defaultColDef.suppressKeyboardEvent =
            suppressSelectAllEvent(gridOptions)

    // setup chart save button
    if (this.$perm('SysChartViewEdit')) {
      gridOptions.onChartCreated = () => {
        const saveBtn = document.querySelector('#btn_chart_save').cloneNode(true) as Element
        saveBtn.classList.remove('hidden')
        saveBtn.addEventListener('click', this.showSaveChartDialog)
        document.querySelector('.ag-chart-menu').append(saveBtn)
        if (!this.mShowingChartView) {
          this.gridApi().getChartModels()
          this.mShowingChartView = {} as ChartView
        }
      }
    }
    gridOptions.onChartDestroyed = () => {
      this.mShowingChartView = null
    }

    // 注册额外的cellRenderer
    Object.assign(gridOptions.frameworkComponents, FrameworkComponentsRegistry)

    // 注册自定义查询ToolPanel
    const sideBarDef = gridOptions?.sideBar as SideBarDef
    sideBarDef?.toolPanels?.push({
      id: 'adv-filter',
      labelDefault: '高级查询',
      labelKey: 'explorerFilterPanel',
      iconKey: 'filter',
      toolPanel: 'explorerFilterPanel',
      toolPanelParams: {
        explorer: this,
        gridOptions: gridOptions
      }
    }, {
      id: 'setting',
      labelDefault: '表格设置',
      labelKey: 'explorerSettingPanel',
      iconKey: 'menu',
      toolPanel: 'explorerSettingPanel',
      toolPanelParams: {
        explorer: this,
        gridOptions: gridOptions,
        showSharedLink: true
      }
    })
    return gridOptions
  }

  private attachAdditionalWidget() {
    const pagingPanel: HTMLElement = this.vAgGrid.$el.querySelector('.ag-paging-panel')
    this.vViewWidget.attachToParent(pagingPanel)
    this.vSelectionWidget.attachToParent(pagingPanel)
  }

  private resetToDefault() {
    // 设置默认排序
    this.resetSortModel()
    // 设置默认查询条件
    this.applyDefaultFilterConditions()
  }

  /******************************************************************************/
  /* FilterCondition */
  /******************************************************************************/

  // 给filter-panel专用的, 其它地方别用
  public getFixedFilterConditions(): FilterCondition[] {
    const conditions = []
    if (this.fixedFilterCondition) conditions.push(this.fixedFilterCondition)
    if (this.mExternalFilterCondition) conditions.push(this.mExternalFilterCondition)
    return conditions
  }

  // 给filter-panel专用的, 其它地方别用
  public get columnFilterConditions(): FilterCondition[] {
    return this.mColumnFilterConditions
  }

  protected onFilterChanged() {
    const columnConditions = _.values(this.gridApi().getFilterModel()).filter(f => f)
    this.updateMergedFilterConditions(this.mColumnFilterConditions, columnConditions)
  }

  public get mergedFilterCondition(): FilterCondition {
    return this.mMergedFilterCondition
  }

  protected resetFilterConditions() {
    this.updateMergedFilterConditions(this.mFixedFilterConditions,
      [this.fixedFilterCondition])
    this.updateMergedFilterConditions(this.mColumnFilterConditions,
      [])
    // 重置默认查询条件
    this.applyDefaultFilterConditions()
  }

  public onFixedConditionChanged(newVal: FilterCondition) {
    this.updateMergedFilterConditions(this.mFixedFilterConditions,
      this.getFixedFilterConditions())
  }

  public get extraFilterConditions(): FilterCondition[] {
    return this.mMergedFilterCondition.conditions
      .filter(condition => {
        return this.getFixedFilterConditions().indexOf(condition) < 0 &&
                      this.columnFilterConditions.indexOf(condition) < 0
      })
  }

  public setExternalFilterCondition(filterCondition: FilterCondition) {
    this.mExternalFilterCondition = filterCondition
    this.updateMergedFilterConditions(this.mFixedFilterConditions,
      this.getFixedFilterConditions())
  }

  public applyDefaultFilterConditions(defaultFilterConditions: FilterCondition[] = null) {
    defaultFilterConditions = defaultFilterConditions || this.defaultFilterConditions
    this.gridApi().setFilterModel(convertFilterColumn(defaultFilterConditions))
  }

  protected updateMergedFilterConditions(updatingFilterConditions: FilterCondition[],
                                         newConditions: FilterCondition[]) {
    // 先把老的conditions拿掉
    _.pullAll(this.mMergedFilterCondition.conditions, updatingFilterConditions)
    // 更新conditions
    updatingFilterConditions.splice(0, updatingFilterConditions.length)
    updatingFilterConditions.push(...newConditions.filter(c => c))
    // 重新装入mergedFilterCondition
    this.mMergedFilterCondition.conditions!.push(...newConditions.filter(c => c))
  }

  protected setExtraFilterConditions(conditions: FilterCondition[]) {
    util.objects.remove(this.mMergedFilterCondition.conditions, condition => {
      return this.getFixedFilterConditions().indexOf(condition) < 0 &&
              this.columnFilterConditions.indexOf(condition) < 0
    })
    this.mMergedFilterCondition.conditions!.push(...conditions)
  }

  protected resetSortModel(defaultSort: SortColumn[] = null,
                           groupByCols: Column[] = null,
                           aggCols: AggregationColumn[] = null) {
    // 设置默认排序
    defaultSort = defaultSort || this.defaultSort
    if (!defaultSort && this.getExplorerTypeDefaultSort()) {
      defaultSort = [this.getExplorerTypeDefaultSort()]
    }
    defaultSort = defaultSort || [{
      field: 'updatedAt',
      sort: SortType.Desc
    }]
    defaultSort.forEach(sort => {
      if (!this.columnApi().getColumn(sort.field)) {
        console.error(`sort field ${sort.field} is not define in this grid`)
      }
    })
    const columnState = convertSortColumns(defaultSort)
    if (groupByCols) {
      groupByCols.forEach(col => {
        const colState = _.find(columnState.state, state => state.colId === col.field)
        if (colState) {
          colState.rowGroup = true
        } else {
          columnState.state.push({
            colId: col.field,
            rowGroup: true
          })
        }
      })
    }

    if (aggCols) {
      aggCols.forEach(col => {
        const colState = _.find(columnState.state, state => state.colId === col.field)
        if (colState) {
          colState.aggFunc = col.aggFunc
        } else {
          columnState.state.push({
            colId: col.field,
            aggFunc: col.aggFunc
          })
        }
      })
    }
    this.columnApi().applyColumnState(columnState)

    this.columnApi().getValueColumns()
      .filter(column => column.getAggFunc() === 'count')
      .forEach(column => {
        const colDef = this.gridApi().getColumnDef(column.getColId())
        colDef.chartDataType = 'series'
      })
  }

  /******************************************************************************/
  /* Chart */
  /******************************************************************************/

  protected showChartView(chartView: ChartView) {
    this.mShowingChartView = chartView

    if (!this.mGridOptions.context.onDataLoadedOnce) {
      this.mGridOptions.context.onDataLoadedOnce = {}
    }
    this.mGridOptions.context.onDataLoadedOnce.showChartView = () => {
      if (!_.isEmpty(chartView.chartOptions.chartOptions)) {
        chartView.chartOptions.processChartOptions = () => {
          return chartView.chartOptions.chartOptions
        }
      }
      this.gridApi().createRangeChart(chartView.chartOptions)
    }

    // FIXME chartView.rowRequest已经是合并过的, 里面包括了columnFilter, 高级查询的filter, 程序写死的fixedFilter
    // 如果这里包含了后两种, 修改视图时有可能还原不出来
    this.applyDefaultFilterConditions(
            chartView.rowRequest?.filter &&
              chartView.rowRequest?.filter.filterType === FilterType.And
              ? chartView.rowRequest?.filter.conditions : [])
    this.resetSortModel(chartView.rowRequest?.sort,
            chartView.rowRequest?.groupByCols, chartView.rowRequest?.aggCols)
    this.refresh()
  }

  private showSaveChartDialog() {
    const chartModel = this.gridApi().getChartModels()[0]
    const snapshotFile = chartModel.getChartImageDataURL({ type: 'image/jpeg' })
    ExplorerChartViewSaver.initChartView(this.getExplorerViewModelName(),
      ExplorerChartViewSaver.convertChartModel(this.gridApi().getChartModels()[0], this.gridOptions.context.currentRowRequest),
      this.gridOptions.context.currentRowRequest,
      base64ToFile(snapshotFile, 'chart_snapshot.jpg'),
      this.mShowingChartView)
    util.objects.reactive(this.mShowingChartView)
    this.mChartViewSaverVisible = true
  }

  protected onChartViewSaved(chartView: ChartView) {
    if (!util.objects.replace(this.mChartViews, item => {
      return item.hash === chartView.hash
    }, chartView)) {
      this.mChartViews.push(chartView)
    }
  }

  protected onChartViewDeleted(chartView: ChartView) {
    util.objects.remove(this.mChartViews,
      item => item.hash === chartView.hash)
    this.mChartViewSaverVisible = false
  }

  /******************************************************************************/
  /* ExplorerView */
  /******************************************************************************/

  get explorerViews() {
    return this.mExplorerViews
  }

  get currentView() {
    return this.mCurrentView
  }

  set currentView(view: ExplorerView) {
    this.mCurrentView = _.cloneDeep(view)
  }

  private onGridMounted() {
    this.$emit('ready')
    if (!this.mGridMounted) {
      // 只有第一次加载的时候需要进来
      this.resetToDefault()
      this.saveStateToView() // save default state to default view
      this.currentView = this.loadUsingView()
      this.mGridMounted = true
    }
    this.applyStateFromView()
    this.setupDataSource()
  }

  private async loadViews() {
    // setup ExplorerView list
    let views = await LocalDbDao.getExplorerViews(this.getExplorerViewModelName()) || []
    const savedDefaultView = _.find(views, view => view.hash === DEFAULT_VIEW_HASH)
    if (!savedDefaultView) {
      // now mCurrentView is default
      views = [this.mCurrentView, ...views]
    }
    util.objects.refill(views, this.mExplorerViews)
  }

  private loadUsingView() {
    // find given hash || using hash || default
    const query = this.$route.query || {}
    return LocalDbDao.getUsingExplorerView(
      this.getExplorerViewModelName(), this.mExplorerViews,
      query.view as string)
  }

  private switchColumnDefFromView(): boolean {
    return this.applyColumnDefFromView(this.mCurrentView, true)
  }

  /**
   * ColumnDef changes must be set before mounted
   */
  private applyColumnDefFromView(explorerView: ExplorerView, switchView = false): boolean {
    if (!this.gridOptions) return
    let needHardReload = false

    // apply grid settings
    const previousAutoRowHeightEnabled = !!this.gridOptions.context.autoRowHeightEnabled
    if (explorerView.options.gridOptions) {
      this.gridOptions.context.autoRowHeightEnabled =
        _.isNil(explorerView.options.gridOptions.autoRowHeightEnabled)
          ? false : explorerView.options.gridOptions.autoRowHeightEnabled
    }
    // apply autoRowHeight
    if (this.gridOptions.context.autoRowHeightEnabled !== previousAutoRowHeightEnabled) {
      this.gridOptions.columnDefs
        .filter((colDef: ColDef) => colDef.type === 'stringColumn' || colDef.cellRendererParams?.wrapText)
        .forEach((colDef: ColDef) => {
          colDef.autoHeight = this.gridOptions.context.autoRowHeightEnabled
          colDef.wrapText = this.gridOptions.context.autoRowHeightEnabled
        })
      needHardReload = true
    }

    // apply extra columns
    const previousExtraColumns = this.gridOptions.context.extraColumns || []
    this.gridOptions.context.extraColumns = _.cloneDeep(explorerView.options.extraColumns) || []
    if (!_.isEqual(_.cloneDeep(previousExtraColumns), _.cloneDeep(this.gridOptions.context.extraColumnss))) {
      if (!_.isEmpty(previousExtraColumns)) {
        util.objects.remove(this.gridOptions.columnDefs, colDef => {
          return !!_.find(previousExtraColumns, extraCol => extraCol.columnName === colDef.headerName)
        })
      }
      if (!_.isEmpty(this.gridOptions.context.extraColumns)) {
        this.gridOptions.columnDefs.push(...this.gridOptions.context.extraColumns
          .map((extraCol: ExtraColumnDef) => {
            const extraFieldName = this.gridOptions.context.extraFieldName || 'extra'
            return {
              headerName: extraCol.columnName,
              type: extraCol.columnType,
              field: extraFieldName + '.' + extraCol.columnName,
              filterParams: {
                viewDef: {
                  fieldKey: '∀' + extraCol.columnName,
                  fieldName: extraCol.columnName,
                  filterViewType: extraCol.columnType === 'numberColumn' ? FilterViewType.NumberFilter
                    : extraCol.columnType === 'booleanColumn' ? FilterViewType.BooleanFilter
                      : extraCol.columnType === 'dateColumn' ? FilterViewType.DateFilter
                        : FilterViewType.StringFilter
                }
              }
            }
          }))
      }
      needHardReload = true
    }
    const needHardReload2 = this.extendColumnDefApplying && this.extendColumnDefApplying(explorerView, this.gridOptions)
    needHardReload = needHardReload || needHardReload2
    if (switchView && needHardReload) {
      (this as any).refreshHard()
    }
    return needHardReload
  }

  /**
   * 这些设置依赖于gridApi, 需要等grid初始化完成后才能设进去
   * 1. on mounted, call me after gridOptions is built
   * 2. ExplorerView switched
   */
  private applyStateFromView() {
    // 把dataSource关掉, 防止调用gridApi的时候反复加载数据
    this.suspendDataSource()

    // apply grid settings
    if (this.mCurrentView.options.gridOptions) {
      this.gridOptions.paginationPageSize =
        this.mCurrentView.options.gridOptions.paginationPageSize || 50
      this.gridOptions.cacheBlockSize =
        this.mCurrentView.options.gridOptions.cacheBlockSize || 50
      this.gridOptions.context.autoColumnWidthEnabled =
        _.isNil(this.mCurrentView.options.gridOptions.autoColumnWidthEnabled)
          ? true : this.mCurrentView.options.gridOptions.autoColumnWidthEnabled
      this.gridOptions.context.keepSelectionOnPaging =
        _.isNil(this.mCurrentView.options.gridOptions.keepSelectionOnPaging)
          ? this.keepSelectionOnPaging : this.mCurrentView.options.gridOptions.keepSelectionOnPaging
    }
    // apply column state
    if (this.mCurrentView.options.columnState) {
      let columnStates: ColumnState[] = this.mCurrentView.options.columnState
      // remove width option if autoColumnWidth is enabled
      if (this.mCurrentView.options.gridOptions.autoColumnWidthEnabled) {
        columnStates = columnStates.map(state => {
          const copiedState = _.cloneDeep(state)
          delete copiedState.width
          return copiedState
        })
      }
      this.columnApi().applyColumnState({
        state: columnStates,
        applyOrder: true
      })
    }
    // apply extra grid settings
    this.extendStateApplying && this.extendStateApplying(
      this.mCurrentView, this.gridOptions,
      this.gridApi(), this.columnApi())
    // apply filters
    this.setExtraFilterConditions(
      json5.parse(this.mCurrentView.options.extraConditions || null) || [])

    const filterModel = json5.parse(this.mCurrentView.options.filter || null)
    if (_.isEmpty(filterModel) && _.isEmpty(this.gridApi().getFilterModel())) {
      this.applyDefaultFilterConditions(null)
      this.onFilterChanged() // empty filterModel wouldn't trigger onFilterChanged
    } else {
      this.gridApi().setFilterModel(filterModel)
    }
  }

  public saveStateToView() {
    // save grid settings
    this.mCurrentView.options.gridOptions.paginationPageSize = this.gridOptions.paginationPageSize
    this.mCurrentView.options.gridOptions.cacheBlockSize = this.gridOptions.cacheBlockSize
    // save column state
    this.mCurrentView.options.columnState = this.columnApi().getColumnState()
    // save extra grid setting
    this.extendStateSaving && this.extendStateSaving(
      this.mCurrentView, this.gridOptions,
      this.gridApi(), this.columnApi())
    // save filters
    this.mCurrentView.options.filter =
      json5.stringify(this.gridApi().getFilterModel())
    this.mCurrentView.options.extraConditions =
      json5.stringify(this.extraFilterConditions)
  }

  public saveOrdinaryOptionsToView(autoColumnWidthEnabled: boolean,
                                   autoRowHeightEnabled: boolean,
                                   keepSelectionOnPaging: boolean,
                                   extraColumns: ExtraColumnDef[]) {
    this.mCurrentView.options.gridOptions.keepSelectionOnPaging = keepSelectionOnPaging
    this.mCurrentView.options.gridOptions.autoColumnWidthEnabled = autoColumnWidthEnabled
    this.mCurrentView.options.gridOptions.autoRowHeightEnabled = autoRowHeightEnabled
    this.mCurrentView.options.extraColumns = extraColumns
  }

  public switchView(view: ExplorerView) {
    this.currentView = view
    util.objects.reactive(this.mCurrentView)
    if (!this.switchColumnDefFromView()) {
      // 不需要强制刷新(也会连接数据源), 则重新连接数据源
      this.applyStateFromView()
      this.setupDataSource()
    }
    LocalDbDao.saveUsingExplorerView(view)
  }
}
