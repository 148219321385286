



































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import {
  FilterType,
  MdCategory,
  MdCategoryInfo,
  MdcDataSearch,
  MdcDataSearchBomEntryItem,
  MdField,
  MdFieldFragmentDoc
  , FilterCondition
} from '@/module/graphql'
import LocalDbDao from '@/module/common/local-db-dao'
import _ from 'lodash'
import { FilterViewDef } from '@/module/components/lolth-filter/types'
import FieldFilter from '@/module/components/lolth-filter/field-filter.vue'
import CmpMdcDataSearchItem from '@/module/plugin-md-category/views/components/data-search/cmp-mdc-data-search-item.vue'
import { FilterConditionWrapper } from '@/module/plugin-md-structure/views/types'
import { isValid_ } from '@/module/common/filter-condition-extend'

import { FixedField } from '@/module/master-data/types'
import { fetchPresetMdCategory, mdCategoryMdFieldId } from '@/module/plugin-md-category/mdc-util'
import { mdAttrAgGridFilterViewDef } from '@/module/master-data/md-util'

declare type SearchConditionWrapper = {
  filterConditionWrappers: FilterConditionWrapper[],
  fieldDefs: FilterViewDef[],
  uid?: string
}

@Component({
  components: {
    FieldFilter, CmpMdcDataSearchItem
  }
})
export default class CmpMdcDataSearch extends Vue {
  @Prop() readonly category! : any
  @Prop({ default: false }) readonly hideOperator : boolean
  @Prop({ default: true }) readonly editable : boolean
  @Prop({ default: 24 }) colSpan: number

  @PropSync('visible')
  syncedVisible!: boolean

  private addEntryDialogVisible: boolean = false
  private fuzzy: string = ''
  private moreCondition: boolean = false
  private mainCategory: any = {}
  private newSearchGroupCategory: MdCategory | null = null
  private hasBom: number = 0
  private referencedByBom: number = 0
  private dataSearch: MdcDataSearch | undefined = undefined
  private mdDataSearchConditionWrapper: SearchConditionWrapper = {
    filterConditionWrappers: [],
    fieldDefs: []
  }
  private bomEntrySearchConditionWrappers: SearchConditionWrapper[] = []
  private tags: any[] = []

  get moreBtnIcon() {
    if (!this.moreCondition) {
      return 'fa fa-angle-double-down'
    } else {
      return 'fa fa-angle-double-up'
    }
  }

  handleClose(tag: any) {
    this.tags.splice(this.tags.indexOf(tag), 1)
  }

  handleInputConfirm() {
    if (this.fuzzy) {
      this.tags.push({ uid: _.uniqueId('tag'), label: this.fuzzy })
      this.fuzzy = ''
    }
  }

  search() {
    const condition = {
      filterType: FilterType.And,
      conditions: []
    } as FilterCondition
    this.mdDataSearchConditionWrapper.filterConditionWrappers.forEach((conditionWrapper: FilterConditionWrapper) => {
      if (isValid_(conditionWrapper.condition)) {
        condition.conditions.push(_.cloneDeep(conditionWrapper.condition))
      }
    })
    if (this.moreCondition) {
      this.bomEntrySearchConditionWrappers.forEach((searchConditionWrapper: SearchConditionWrapper) => {
        searchConditionWrapper.filterConditionWrappers.forEach((conditionWrapper: FilterConditionWrapper) => {
          if (isValid_(conditionWrapper.condition)) {
            const cloneCondition = _.cloneDeep(conditionWrapper.condition)
            cloneCondition.field = cloneCondition.field.replace('@', '^')
            cloneCondition.extra = {
              joinGroup: searchConditionWrapper.uid
            }
            condition.conditions.push(cloneCondition)
          }
        })
      })
    }
    if (this.hasBom !== 0) {
      const hasBomCondition = {
        filterType: FilterType.Custom,
        operator: 'hasBom',
        not: false
      }
      if (this.hasBom === 2) {
        hasBomCondition.not = true
      }
      condition.conditions.push(hasBomCondition)
    }
    if (this.referencedByBom !== 0) {
      const referencedByBomCondition = {
        filterType: FilterType.Custom,
        operator: 'referredBom',
        not: false
      }
      if (this.referencedByBom === 2) {
        referencedByBomCondition.not = true
      }
      condition.conditions.push(referencedByBomCondition)
    }
    const tagCondition = this.getTagConditions()
    if (tagCondition) {
      condition.conditions.push(tagCondition)
    }
    this.$emit('query', condition)
  }

  getTagConditions() {
    const fixedFields: FixedField[] = LocalDbDao.getMdDomainFixedFields()
    if (this.tags.length > 0) {
      const tagConditions: {filterType: FilterType, conditions: FilterCondition[]} = {
        filterType: FilterType.And,
        conditions: []
      }
      this.tags.forEach(tag => {
        const tagCondition: {filterType: FilterType, conditions: FilterCondition[]} = {
          filterType: FilterType.Or,
          conditions: []
        }
        fixedFields.forEach(field => {
          tagCondition.conditions.push({
            filterType: FilterType.String,
            field: field.field,
            operator: 'contains',
            value: tag.label
          })
        })
        tagConditions.conditions.push(tagCondition)
      })
      return tagConditions
    }
  }

  doFetchPresetValue(items: MdCategory[]) {
    return fetchPresetMdCategory(this, items)
  }

  getCategoryFields(category: MdCategory) {
    if (category) {
      this.$apollo.query({
        query: gql`query {
          MdcDataSearch(categoryKey: "${category.key}"){
            category{ dataId, key, keyValue } searchFields {
              id name dataType option
            }
            bomEntrySearchItems {
              category{ dataId, key, keyValue } entrySearchFields {
                id name dataType option
              }
            }
          }
        }`
      }).then(data => {
        this.mdDataSearchConditionWrapper = this.initSearchItemData(category, data.data.MdcDataSearch?.searchFields)
        this.bomEntrySearchConditionWrappers.splice(0)
        data.data.MdcDataSearch?.bomEntrySearchItems.forEach((bomEntrySearchItem: MdcDataSearchBomEntryItem) => {
          this.bomEntrySearchConditionWrappers.push(this.initSearchItemData(bomEntrySearchItem.category, bomEntrySearchItem?.entrySearchFields || []))
        })
      })
    } else {
      this.mdDataSearchConditionWrapper.filterConditionWrappers.splice(0)
      this.bomEntrySearchConditionWrappers.splice(0)
      this.search()
    }
  }

  initSearchItemData(category: MdCategory | MdCategoryInfo, fields: MdField[]) {
    const searchConditionWrapper: SearchConditionWrapper = {
      filterConditionWrappers: [],
      fieldDefs: [],
      uid: _.uniqueId('group')
    }
    this.$apollo.query({
      query: gql`query {
        MdCategory(key: "${category.key}") {
          mdFields { ...mdField }
        }
      }
      ${MdFieldFragmentDoc}`
    }).then(data => {
      data.data.MdCategory.mdFields.forEach((field: MdField) =>
        searchConditionWrapper.fieldDefs.push(mdAttrAgGridFilterViewDef(field)))
    })
    const categoryConditionWrapper = {
      condition: {
        field: '@' + mdCategoryMdFieldId(),
        value: category.dataId,
        filterType: FilterType.Number,
        operator: 'in'
      },
      disabled: true,
      uid: _.uniqueId()
    }
    searchConditionWrapper.filterConditionWrappers.push(categoryConditionWrapper)
    if (fields && fields.length > 0) {
      fields.forEach((field: MdField) => {
        const conditionWrapper = {
          uid: _.uniqueId(),
          condition: { field: '@' + field.id },
          disabled: false
        }
        if (parseInt(field.id) !== mdCategoryMdFieldId()) {
          searchConditionWrapper.filterConditionWrappers.push(conditionWrapper)
        }
      })
    }
    return searchConditionWrapper
  }

  removeConditionGroup(groupId: string) {
    let idx = _.findIndex(this.bomEntrySearchConditionWrappers, (entryGroup: SearchConditionWrapper) => entryGroup.uid === groupId)
    if (idx >= 0) {
      this.bomEntrySearchConditionWrappers?.splice(idx, 1)
    }
  }

  addConditionGroup() {
    this.newSearchGroupCategory = null
    this.addEntryDialogVisible = true
  }

  addConditionGroupSubmit() {
    if (!this.newSearchGroupCategory) return
    this.addEntryDialogVisible = false
    this.bomEntrySearchConditionWrappers.push(this.initSearchItemData(this.newSearchGroupCategory!, []))
  }
}
