





































export default function (Component) {
  Component.options.__source = "src/module/master-data/views/table/cmp-md-table-data-input/md-table-input-attachment.vue"
}
