









































































































export default function (Component) {
  Component.options.__source = "src/module/plugin-md-category/views/components/data-search/cmp-mdc-data-search-item.vue"
}
