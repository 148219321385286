import { Module } from 'vuex'
import { MdcStore, SettingSection } from '@/module/plugin-md-category/store/index'

const module: Module<MdcStore, any> = {
  namespaced: true,
  state: {
    settingSections: [{
      sectionName: '主数据基础设置',
      customComponentName: 'cmp-mdc-setting-md-basic',
      open: true
    }, {
      sectionName: 'Bom排序设置(全类别通用)',
      customComponentName: 'cmp-mdc-setting-bom-sort',
      global: true
    }, {
      sectionName: '产品库检索字段设置',
      customComponentName: 'cmp-mdc-setting-data-search'
    }, {
      sectionName: '图档字段设置',
      customComponentName: 'cmp-mdc-setting-drawing-field'
    }]
  },
  getters: {
    settingPanels(state) {
      return state.settingSections
    }
  },
  mutations: {
    registerSettingSections(state, sections:SettingSection[]) {
      sections.forEach(i => state.settingSections.push(i))
    }
  }
}

export default module
