<template>
  <el-dropdown size="small" class="d2-mr">
    <span class="btn-text">{{info.name ? `你好 ${info.name}` : '未登录'}}</span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item @click.native="logOff">
        <d2-icon name="power-off" class="d2-mr-5"/>
        注销
      </el-dropdown-item>
      <el-dropdown-item @click.native="userInfo">
        <d2-icon name="user-circle-o" class="d2-mr-5"/>
        个人信息
      </el-dropdown-item>
      <el-dropdown-item @click.native="passwordDialogVisible = true">
        <d2-icon name="user-circle-o" class="d2-mr-5"/>
        修改密码
      </el-dropdown-item>
    </el-dropdown-menu>
    <user-password-dialog v-if="passwordDialogVisible"
                          :visible.sync="passwordDialogVisible" />
  </el-dropdown>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import util from '@/d2admin/libs/util'
import UserPasswordDialog from '@/module/butler/views/components/cmp-user-password-dialog.vue'

export default {
  data() {
    return {
      passwordDialogVisible: false
    }
  },
  components: { UserPasswordDialog },
  computed: {
    ...mapState('d2admin/user', [
      'info'
    ]),
    ...mapState('d2admin/account', [
      'needResetPassword'
    ])
  },
  watch: {
    '$store.state.d2admin.account.needResetPassword': {
      handler(val) {
        this.passwordDialogVisible = val
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('d2admin/account', [
      'logout'
    ]),
    /**
     * @description 登出
     */
    logOff() {
      this.logout({
        confirm: true
      })
    },

    userInfo() {
      util.views.openTab(this, {
        path: '/user-info'
      })
    }
  }
}
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/d2admin/layout/header-aside/components/header-user/index.vue"
}
</vue-filename-injector>
