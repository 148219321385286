let buildProfile: any = null

if (process.env.VUE_APP_PROFILES === 'ESTATE') {
  buildProfile = require('./estate').default
} else if (process.env.VUE_APP_PROFILES === 'SY') {
  buildProfile = require('./sy').default
} else if (process.env.VUE_APP_PROFILES === 'DM') {
  buildProfile = require('./dm').default
} else if (process.env.VUE_APP_PROFILES === 'MEETING') {
  buildProfile = require('./meeting').default
} else if (process.env.VUE_APP_PROFILES === 'ALL') {
  buildProfile = require('./all').default
} else {
  buildProfile = require('./mini').default
}

export default buildProfile

