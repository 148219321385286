





import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp } from 'ag-grid-community'
import { ExternalDataSource } from '@/module/graphql'
import gql from 'graphql-tag'
import { ICustomRendererCellRendererParams } from '@/module/components/lolth-explorer/supports'

@Component
export default class CmpExternalDataSourceCellRenderer extends Vue implements ICellRendererComp {
  private params!: ICustomRendererCellRendererParams
  private dataSource: ExternalDataSource = null

  created(): void {
    this.refresh(this.params)
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  refresh(params: ICustomRendererCellRendererParams): boolean {
    const dataSourceId = this.params.value
    if (!dataSourceId) return

    this.$apollo.query({
      query: gql`query {
        ExternalDataSource(id: ${dataSourceId}) { name }
      }`
    }).then(resp => {
      this.dataSource = resp.data.ExternalDataSource?.name
    })
    return true
  }
}
