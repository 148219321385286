





import { Component, Vue } from 'vue-property-decorator'
import { FilterChangedEvent, IFloatingFilterComp, IFloatingFilterParams } from 'ag-grid-community'
import _ from 'lodash'

@Component
export default class LolthTextFloatingFilter extends Vue implements IFloatingFilterComp {
  private params: IFloatingFilterParams | null = null
  private mValue: string = null

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent): void {
    this.mValue = !parentModel ? '' : parentModel.value
  }

  onValueChanged() {
    if (_.isNil(this.mValue)) return
    this.params?.parentFilterInstance(instance => {
      (instance as any).onFloatingFilterChanged({
        value: this.mValue
      })
    })
  }
}
