




import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'

@Component
export default class LolthJsonCellRenderer extends Vue implements ICellRendererComp {
  private params: ICellRendererParams
  private mValue: any

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: ICellRendererParams): boolean {
    this.mValue = params.value
    return true
  }
}
