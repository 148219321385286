






import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import { showMdDataViewer } from '@/module/master-data/md-util'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

declare interface IExplorerMdDataCodeListRendererComp extends ICellRendererParams {
  mdDataCodeField?: string,
}

@Component
export default class CmpMdDataCodeListRenderer extends Vue implements ICellRendererComp {
  private params!: IExplorerMdDataCodeListRendererComp
  private mdDataCodes: string[] = null

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  getUid() {
    return uuidv4()
  }

  refresh(params: IExplorerMdDataCodeListRendererComp): boolean {
    if (_.isArray(params.value)) {
      let value = params.value
      if (params.mdDataCodeField) {
        this.mdDataCodes = value.map((item: any) => _.get(item, params.mdDataCodeField) || '')
      }
      return true
    }
    let mdDataCodes = this.params.mdDataCodeField ? this.params.data[this.params.mdDataCodeField] : this.params.value
    if (mdDataCodes) {
      this.mdDataCodes = mdDataCodes.split(',')
    } else {
      this.mdDataCodes = []
    }
    return true
  }

  onCodeClick(code: any) {
    showMdDataViewer(this, code)
  }
}
