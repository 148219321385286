export default [
  {
    path: '/mdx/template',
    name: 'PageMdxTemplate',
    meta: {
      title: '产品模板管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mdx-template')
  },
  {
    path: '/mdx/naming-rule',
    name: 'PageMdxNamingRule',
    meta: {
      title: '编码规则管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mdx-naming-rule')
  },
  {
    path: '/mdx/product',
    name: 'PageMdxData',
    props: { engineering: false },
    meta: {
      title: '正式品号管理',
      auth: true
    },
    component: () => import('./views/page-mdx-data')
  },
  {
    path: '/mdx/e-product',
    name: 'PageMdxEData',
    meta: {
      title: '工程品号管理',
      auth: true
    },
    component: () => import('./views/page-mdx-e-data')
  },
  {
    path: '/mdx/modify-product',
    name: 'PageMdxModifyProduct',
    meta: {
      title: '品号变更管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mdx-data-modify')
  }
]
