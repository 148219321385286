





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { SysDashboardGauge } from '@/module/graphql'
import util from '@/d2admin/libs/util'
import LocalDbDao from '@/module/common/local-db-dao'

@Component
export default class CmpDashboardGauges extends Vue {
  @Prop({ required: true }) readonly color!: string
  @Prop({ required: true }) readonly gauges!: SysDashboardGauge[]

  private mCounter: number = 0
  private mMetricsInterval: any = null
  private mMoreDialogVisible: boolean = false

  created() {
    this.start()
  }

  getRealmName(key: string) {
    return LocalDbDao.getValuedEnumItem('Realm', key)?.value || key
  }

  openTab(metric: SysDashboardGauge) {
    if (metric && metric.pageUrl) {
      util.views.openTab(this, { path: metric.pageUrl })
    }
  }

  getContent(idx: number) {
    if (this.gauges.length <= 7) {
      return this.gauges[idx]
    }
    return this.gauges[idx + this.mCounter < this.gauges.length ? idx + this.mCounter : idx + this.mCounter - this.gauges.length]
  }

  pause() {
    clearInterval(this.mMetricsInterval)
  }

  start() {
    if (this.gauges.length <= 7) return
    this.mMetricsInterval = setInterval(() => {
      if (this.mCounter >= this.gauges.length) {
        this.mCounter = 0
      } else {
        this.mCounter += 1
      }
    }, 1000)
  }
}
