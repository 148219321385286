








































export default function (Component) {
  Component.options.__source = "src/module/master-data/views/table/icmp-md-table-data-viewer.vue"
}
