



































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { GridOptions, IToolPanel, IToolPanelParams } from 'ag-grid-community'
import { VueFrameworkComponentWrapper } from 'ag-grid-vue/lib/VueFrameworkComponentWrapper'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import _ from 'lodash'
import LocalDbDao from '@/module/common/local-db-dao'
import { MessageBoxInputData } from 'element-ui/types/message-box'
import { ErrorInfo, ExplorerView } from '@/module/graphql'
import util from '@/d2admin/libs/util'
import copy from 'copy-to-clipboard'
import { ExtraColumnDef } from './mixin-lolth-explorer-view'

// 操作-共享 不是默认视图 and Owner是自己
// 操作-删除 不是默认视图 and Owner是自己
// 操作-保存 默认视图 or Owner是自己
// 操作-另存为

declare interface IExplorerToolPanelParams extends IToolPanelParams {
  frameworkComponentWrapper: VueFrameworkComponentWrapper,
  explorer: LolthExplorer,
  gridOptions: GridOptions,
  showSharedLink: boolean
}

@Component
export default class ExplorerSettingPanel extends Vue implements IToolPanel {
  private params!: IExplorerToolPanelParams

  private mPageSize: number = null
  private mAutoColumnWidth = true
  private mAutoRowHeight = false
  private mExtraColumns: ExtraColumnDef[] = []
  private mKeepSelectionOnPaging = false

  private mEditingExtraColumns: ExtraColumnDef[] = []
  private mExtraColumnDialogVisible = false
  private mExtraColumnErrorInfos: { [row: number]: ErrorInfo } = {}

  get explorerViews() {
    return this.params.explorer.explorerViews
  }

  get currentView() {
    return this.params.explorer.currentView
  }

  set currentView(view) {
    this.params.explorer.currentView = view
  }

  get currentViewOwnerId() {
    return this.currentView.owner?.id
  }

  get sharedLink() {
    const paramsSym = this.$route.fullPath.indexOf('?') > 0 ? '&' : '?'
    return `${this.$baseUrl}#${this.$route.fullPath}${paramsSym}view=${this.currentView.hash}`
  }

  get extraColumnSummary() {
    return this.mExtraColumns.map(col => col.columnName).join(',')
  }

  created() {
    this.setupView()
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  refresh(): void {
    this.$forceUpdate()
  }

  saveToView() {
    this.params.explorer.saveOrdinaryOptionsToView(
      this.mAutoColumnWidth,
      this.mAutoRowHeight,
      this.mKeepSelectionOnPaging,
      this.mExtraColumns)
  }

  applyWithoutRefresh() {
    this.params.gridOptions.context.keepSelectionOnPaging = this.mKeepSelectionOnPaging
    this.saveToView()
  }

  applyAndRefresh() {
    this.params.gridOptions.paginationPageSize = this.mPageSize
    this.params.gridOptions.cacheBlockSize = this.mPageSize
    this.params.explorer.saveStateToView()
    this.params.explorer.refresh()
  }

  async saveExplorerView() {
    let viewName
    if (this.currentView.hash !== '∆') {
      const promptData: MessageBoxInputData = await this.$prompt('请输入视图名称', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.currentView.name,
        inputValidator: (val) => {
          if (!val) return '必填'
          return true
        }
      }) as MessageBoxInputData
      viewName = promptData.value
    } else {
      viewName = '默认视图'
    }

    const savingView = _.clone(this.currentView)
    savingView.name = viewName
    await LocalDbDao.saveExplorerView(savingView).then(view => {
      this.$message({
        message: '保存成功', type: 'success', showClose: true
      })
      this.currentView.name = view.name
      this.currentView.id = view.id
      util.objects.replaceOrPush(this.explorerViews,
        view => view.id === this.currentView.id, this.currentView)
    })
  }

  async saveNewExplorerView() {
    const promptData: MessageBoxInputData = await this.$prompt('请输入视图名称', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputValidator: (val) => {
        if (!val) return '必填'
        return true
      }
    }) as MessageBoxInputData

    const savingView = _.clone(this.currentView)
    delete savingView.hash
    savingView.name = promptData.value
    const savedView = await LocalDbDao.saveExplorerView(savingView)
    this.explorerViews.push(savedView)
    this.currentView = savedView
    LocalDbDao.saveUsingExplorerView(savedView)

    this.$message({
      message: '保存成功', type: 'success', showClose: true
    })
  }

  async deleteExplorerView() {
    await LocalDbDao.deleteExplorerView(this.currentView)
    util.objects.remove(this.explorerViews,
      view => view.id === this.currentView.id)
    this.currentView = _.find(this.params.explorer.explorerViews,
      view => view.hash === '∆')
    this.onExplorerViewChanged(this.currentView)
    this.$message({
      message: '删除成功, 已恢复到默认视图', type: 'success', showClose: true
    })
  }

  async restoreDefaultExplorerView() {
    await LocalDbDao.deleteExplorerView(this.currentView)
    const defaultView = LocalDbDao.buildDefaultExplorerView(this.currentView.modelName)
    this.explorerViews.splice(0, 1, defaultView)
    this.currentView = defaultView
    this.onExplorerViewChanged(this.currentView)
    this.$message({
      message: '已恢复到初始状态', type: 'success', showClose: true
    })
  }

  @Watch('currentView')
  public setupView() {
    this.mPageSize = this.params.gridOptions.paginationPageSize
    this.mAutoColumnWidth = _.isNil(this.params.gridOptions.context.autoColumnWidthEnabled)
      ? true : this.params.gridOptions.context.autoColumnWidthEnabled
    this.mAutoRowHeight = _.isNil(this.params.gridOptions.context.autoRowHeightEnabled)
      ? false : this.params.gridOptions.context.autoRowHeightEnabled
    this.mKeepSelectionOnPaging = _.isNil(this.params.gridOptions.context.keepSelectionOnPaging)
      ? false : this.params.gridOptions.context.keepSelectionOnPaging
    this.mExtraColumns = this.params.gridOptions.context.extraColumns || []
  }

  private onExplorerViewChanged(view: ExplorerView) {
    this.params.explorer.switchView(view)
  }

  copyToClipboard() {
    copy(this.sharedLink)
    this.$notify({
      type: 'info',
      title: '消息',
      message: '共享视图链接已复制到剪贴板'
    })
  }

  private editExtraColumns() {
    this.mEditingExtraColumns = _.cloneDeep(this.mExtraColumns)
    this.mExtraColumnDialogVisible = true
  }

  private saveExtraColumns() {
    util.objects.clear(this.mExtraColumnErrorInfos)
    for (let i = 0; i < this.mEditingExtraColumns.length; i++) {
      const extraColumn = this.mEditingExtraColumns[i]
      if (!extraColumn.columnName || !extraColumn.columnType) {
        this.$set(this.mExtraColumnErrorInfos, i, { msg: '必填' })
      }
    }
    if (!_.isEmpty(this.mExtraColumnErrorInfos)) return

    this.mExtraColumns = this.mEditingExtraColumns
    this.params.explorer.saveOrdinaryOptionsToView(
      this.mAutoColumnWidth, this.mAutoRowHeight,
      this.mKeepSelectionOnPaging, this.mExtraColumns)
    this.mExtraColumnDialogVisible = false
  }
}
