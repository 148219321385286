


















import { Component, Vue } from 'vue-property-decorator'
import util from '@/d2admin/libs/util'

@Component
export default class CmpHeaderRecycleBin extends Vue {
  private onClick() {
    util.views.openTab(this, { path: '/recycle-bin' })
  }
}
