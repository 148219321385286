


























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { FileType, UploadedFile } from '@/module/common/file-service/types'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import fileService from '@/module/common/file-service'
import copy from 'copy-to-clipboard'
import json5 from 'json5'

@Component
export default class LolthFile extends Vue {
  @Prop({ required: true }) readonly file!: UploadedFile
  @Prop() readonly disabled?: boolean
  @Prop() readonly size: 'mini' | 'small' | 'medium' | 'large'

  @Prop() readonly onPreview?: (file: UploadedFile) => {}
  @Prop() readonly onDownload?: (file: UploadedFile) => {}
  @Prop() readonly onRemove?: (file: UploadedFile) => {}

  private mViewerVisible = false
  private mEditingNote = false

  get fileLabel() {
    let label = this.file.name
    if (label === '﹡﹡﹡﹡﹡﹡') return '无权限查看'
    if (this.file.note) {
      label = this.file.note + ' | ' + label
    }
    return label
  }

  private doCopyFileKey() {
    this.$message({
      message: '文件标识已复制到剪贴板', type: 'success', showClose: true
    })
    return copy(json5.stringify(this.file))
  }

  private doPreview() {
    if (this.onPreview) {
      this.onPreview(this.file)
      return
    }
    if (this.getFileType(this.file) === FileType.IMAGE) {
      this.mViewerVisible = true
    }
  }

  private doDownload() {
    if (this.onDownload) {
      this.onDownload(this.file)
      return
    }
    window.open(this.file.downloadUrl, '_blank')
  }

  private applyStyle(event: any) {
    const elImg = event.path ? event.path[0] : event.target
    const imgWidth = elImg.width
    const imgHeight = elImg.height
    if (imgWidth >= imgHeight) {
      elImg.style.maxHeight = '100%'
    } else {
      elImg.style.maxWidth = '100%'
    }
  }

  private getFileType(file: UploadedFile) {
    return fileService.getFileService().getFileType(file.name)
  }

  private isImage(file: UploadedFile) {
    return this.getFileType(file) === FileType.IMAGE || this.getFileType(file) === FileType.PDF
  }

  saveFileNote() {
    this.$emit('on-note-updated', this.file)
    this.mEditingNote = false
  }
}
