import { Component, Ref, Vue } from 'vue-property-decorator'
import { ColDef, GridOptions } from 'ag-grid-community'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import { mdAttrAgGridFilterViewDef, mdAttrAgGridFloatingFilter } from '@/module/master-data/md-util'
import _ from 'lodash'
import { ExplorerView, MdField } from '@/module/graphql'

@Component
export default class MixinExplorerMdAttributeExtend extends Vue {
  @Ref() readonly vExplorer: LolthExplorer

  buildMdAttributeColumnDefs(mdFields: MdField[], attributesField: string = 'attributes'): ColDef[] {
    const attrColumnDefs = mdFields.map(mdField => {
      return {
        headerName: mdField.name,
        field: '@' + mdField.id,
        filterParams: {
          viewDef: mdAttrAgGridFilterViewDef(mdField)
        },
        filter: 'explorerFilter',
        floatingFilterComponent: mdAttrAgGridFloatingFilter(mdField),
        cellRenderer: 'mdAttributeRenderer',
        cellRendererParams: {
          attributesField: attributesField,
          mdFieldId: mdField.id
        }
      }
    })
    this.vExplorer.setContextParam('attrColumnDefs', attrColumnDefs)
    return attrColumnDefs
  }

  applyMdAttributeColumnDefs(columnDefs: ColDef[]) {
    _.remove(columnDefs!, col => {
      const columnFieldKey = (col as ColDef).field
      return columnFieldKey && columnFieldKey.indexOf('@') >= 0
    })
    const attrColumnDefs : ColDef[] = this.vExplorer.getContextParam('attrColumnDefs')
    if (attrColumnDefs) {
      columnDefs.push(...attrColumnDefs)
    }
  }

  buildExtraFetchFields(mdFields: MdField[], attributesField: string): string {
    const extraFetchFields = attributesField + '(mdFieldIds: [' + mdFields.map(field => field.id).join(',') +
      `]) {
          field { id, name, dataType, option },
          alias, feature, bomFeature, hidden, value, tbd, extra
        }`
    this.vExplorer.setContextParam('extraFetchFields', extraFetchFields)
    return extraFetchFields
  }

  updateColumns(columnDefs: ColDef[], mdFields: MdField[], attributesField: string) {
    this.buildMdAttributeColumnDefs(mdFields, attributesField)
    this.applyMdAttributeColumnDefs(columnDefs)
    this.buildExtraFetchFields(mdFields, attributesField)
    this.extendColumnDefSaving(this.vExplorer.currentView, this.vExplorer.gridOptions)
    this.vExplorer.refreshHard()
    this.$emitToGlobal('grid-options-changed')
  }

  protected extendColumnDefSaving(currentView: ExplorerView, gridOptions: GridOptions) {
    currentView.options.attrColumnDefs = gridOptions.context.attrColumnDefs
    currentView.options.extraFetchFields = gridOptions.context.extraFetchFields
  }

  protected extendColumnDefApplying(currentView: ExplorerView, gridOptions: GridOptions) {
    gridOptions.context.attrColumnDefs = currentView.options.attrColumnDefs
    gridOptions.context.extraFetchFields = currentView.options.extraFetchFields
    this.applyMdAttributeColumnDefs(gridOptions.columnDefs)
    return true
  }
}
