
































































export default function (Component) {
  Component.options.__source = "src/module/master-data/views/components/icmp-md-extend-detail-panel.vue"
}
