export default [
  {
    path: '/sandbox/demo-graphql',
    name: 'GraphQL',
    meta: {
      title: 'GraphQL',
      auth: true
    },
    component: () => import('./views/page-demo-graphql')
  },
  {
    path: '/sandbox/demo-explorer',
    name: 'Explorer',
    meta: {
      title: 'Explorer',
      auth: true
    },
    component: () => import('./views/page-demo-explorer-chooser')
  },
  {
    path: '/sandbox/demo-input',
    name: 'Input',
    meta: {
      title: 'Input',
      auth: true
    },
    component: () => import('./views/page-demo-input')
  },
  {
    path: '/sandbox/demo-md-table',
    name: 'MdTable',
    meta: {
      title: 'MdTable',
      auth: true
    },
    component: () => import('./views/page-demo-md-table')
  },
  {
    path: '/sandbox/demo-filter',
    name: 'Filter',
    meta: {
      title: 'Filter',
      auth: true
    },
    component: () => import('./views/page-demo-filter')
  },
  {
    path: '/sandbox/demo-ui-conversion',
    name: 'PageUiConversion',
    meta: {
      title: 'UI规范',
      auth: false
    },
    component: () => import('./views/page-ui-conversion')
  },
  {
    path: '/sandbox/handsometable',
    name: 'PageHandsomeTable',
    meta: {
      title: 'HandsomeTable',
      auth: false
    },
    component: () => import('./views/page-handsometable')
  },
  {
    path: '/sandbox/demo-binding',
    name: 'PageBinding',
    meta: {
      title: '多层双向绑定示例',
      auth: false
    },
    component: () => import('./views/page-demo-binding')
  }
]
