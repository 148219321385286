import layoutHeaderAside from '@/d2admin/layout/header-aside'
import ModuleLoader from '@/d2admin/module'
import _ from 'lodash'

/**
 * 在主框架内显示
 */
const frameIn = [
  {
    path: '/',
    redirect: { name: 'index' },
    component: layoutHeaderAside,
    children: [
      // 首页
      {
        path: '/index',
        name: 'index',
        meta: {
          title: '首页',
          auth: true
        },
        component: require('@/module/butler/views/page-index').default
      },
      // 系统 前端日志
      {
        path: '/log',
        name: 'log',
        meta: {
          title: '前端日志'
        },
        component: require('@d2views/log').default
      },
      // 刷新页面 必须保留
      {
        path: '/refresh',
        name: 'refresh',
        hidden: true,
        component: require('@d2views/function/refresh').default
      },
      // 页面重定向 必须保留
      {
        path: '/redirect/:route*',
        name: 'redirect',
        hidden: true,
        component: require('@d2views/function/redirect').default
      },
      // 系统 回收站
      {
        path: '/recycle-bin',
        name: 'PageRecycleBin',
        meta: {
          title: '回收站',
          auth: true
        },
        component: require('@/module/butler/views/page-recycle-bin').default
      },
      {
        path: '/user-info',
        name: 'UserInfo',
        meta: {
          title: '个人信息',
          auth: true
        },
        component: require('@/module/butler/views/page-user-info').default
      },
      {
        path: '/user/user',
        name: 'user',
        meta: {
          title: '用户信息管理',
          auth: true
        },
        component: require('@/module/butler/views/page-user').default
      },
      {
        path: '/invitation/joining-application',
        name: 'PageJoiningApplication',
        meta: {
          title: '加入申请',
          auth: true
        },
        component: require('@/module/butler/views/page-joining-application').default
      }
    ]
  }
]
frameIn[0].children = _.concat(frameIn[0].children, ModuleLoader.routes)

/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    props: true,
    component: require('@/module/butler/views/page-sign-in').default
  },
  // 注册
  {
    path: '/sign-up',
    name: 'sign-up',
    props: true,
    component: require('@/module/butler/views/page-sign-up').default
  },
  {
    path: '/user-info-preview',
    name: 'UserInfoPreview',
    meta: {
      title: '个人信息',
      auth: false
    },
    component: require('@/module/butler/views/page-user-info-preview').default
  },
  {
    path: '/invitation/:code',
    name: 'invitation',
    meta: {
      title: '邀请注册',
      auth: false
    },
    component: require('@/module/butler/views/page-invitation').default
  },
  {
    path: '/ewx-sign-in',
    meta: {
      title: '企业微信回调',
      auth: false
    },
    component: require('@/module/butler/views/page-ewx-sign-in').default
  }
]

/**
 * 错误页面
 */
const errorPage = [
  {
    path: '*',
    name: '404',
    component: require('@d2views/error/404').default
  }
]

// 导出需要显示菜单的
export const frameInRoutes = frameIn

// 重新组织后导出
export default [
  ...frameIn,
  ...frameOut,
  ...errorPage
]
