





















import { Component, Vue } from 'vue-property-decorator'
import { ProgressEvent, ProgressState } from '@/module/common/types'
import StompClient from '@/module/common/api/stomp-client'
import _ from 'lodash'
import util from '@/d2admin/libs/util'

@Component
export default class IcmpProgress extends Vue {
  private mVisible = true
  private mOpenedPanes: { [executionId: string]: string[] } = {}

  private mProgressStates: ProgressState[] = []

  created() {
    StompClient.subscribe('/user/topic/progress', message => {
      const progressEvent: ProgressEvent = JSON.parse(message.body)
      let progressState = _.find(this.mProgressStates,
        state => state.executionId === progressEvent.executionId)
      if (!progressState) {
        progressState = {
          executionId: progressEvent.executionId,
          title: progressEvent.title || '未知任务',
          total: 0,
          progress: 0,
          processCount: 0,
          errors: {},
          duration: ''
        }
        this.mProgressStates.push(progressState)
      }
      if (progressEvent.total) {
        progressState.total = progressEvent.total
      }
      if (progressEvent.progress) {
        progressState.progress = ((progressEvent.progress || 0) * 100).round(2)
        progressState.processCount = progressEvent.processCount || 0
      }
      if (!_.isEmpty(progressEvent.errors) && progressEvent.failedEntitySummary) {
        this.$set(progressState.errors, progressEvent.failedEntitySummary, progressEvent.errors)
      }
      if (progressEvent.duration) {
        progressState.progress = 100
        progressState.processCount = progressState.total
        progressState.duration = progressEvent.duration || ''
      }
    })
  }

  destroyed() {
    StompClient.unsubscribe('/user/topic/progress')
  }

  completionSummary(progressState: ProgressState) {
    if (!progressState.duration) return ''
    return `. 已完成, 耗时${progressState.duration}.`
  }
}
