





















































import { Component, Watch } from 'vue-property-decorator'
import BaseAttrInput from './base-attr-input'
import { FilterCondition, FilterType, MdDictionaryEntry, SortColumn, SortType } from '@/module/graphql'
import _ from 'lodash'
import copy from 'copy-to-clipboard'
import LocalDbDao from '@/module/common/local-db-dao'
import gql from 'graphql-tag'
import { CascaderNode } from '@/module/components/types'
import LolthTypedInputSelect from '@/module/components/typed/lolth-typed-input-select'
import { isValid_ } from '@/module/common/filter-condition-extend'

@Component
export default class AttrInputMultiDict extends BaseAttrInput {
  private mGroup: CascaderNode[] = null
  private mChooserVisible = false

  get syncedValue(): MdDictionaryEntry[] {
    if (!this.attribute.value) return []

    if (!_.isArray(this.attribute.value)) {
      throw new Error('多选选项表属性格式不正确')
    }

    return this.attribute.value.map((attrValue: any) => {
      if (_.isObject(attrValue)) {
        return attrValue as MdDictionaryEntry
      } else if (_.isInteger(attrValue)) {
        const dictId = _.toString(attrValue)
        return {
          id: dictId,
          key: this.attribute.extra[dictId].dataKey,
          value: this.attribute.extra[dictId].dataValue
        } as MdDictionaryEntry
      } else {
        throw new Error('多选选项表属性格式不正确')
      }
    })
  }

  set syncedValue(val: MdDictionaryEntry[]) {
    this.attribute.value = !val ? null : val
  }

  get filterCondition(): FilterCondition {
    if (!this.mdField.option.dictId) {
      throw new Error('无效的选项表字段, 未指定选项表')
    }

    const filterCondition = {
      filterType: FilterType.Number,
      field: 'dictionary.id',
      operator: 'equals',
      value: this.mdField.option.dictId
    }

    return isValid_(this.mdField.option?.dictFilter)
      ? {
        filterType: FilterType.And,
        conditions: [
          filterCondition,
          this.mdField.option.dictFilter
        ]
      } : filterCondition
  }

  get sortByColumn(): SortColumn[] {
    return [{
      field: 'seq',
      sort: SortType.Asc
    }]
  }

  get explorerType() {
    return LocalDbDao.getExplorerType('MdDictionaryEntry')
  }

  mounted() {
    if (!this.mdField.option.chooser) {
      this.$apollo.query({
        query: gql`query {
          MdDictionary(id: ${this.mdField.option.dictId}) {
            groups
          }
        }`
      }).then(data => {
        this.mGroup = data.data.MdDictionary.groups || null
      })
    }
  }

  @Watch('mValue')
  onValueChanged(val: MdDictionaryEntry) {
    this.attribute.value = val
    this.$emit('attr-value-changed', this.attribute.value)
  }

  copyToClipboard() {
    if (!this.syncedValue) return
    copy(this.syncedValue
      .map(value => this.explorerType.formatLabel(value))
      .join(','))
  }

  onChooserSelect(dictEntries: MdDictionaryEntry[]) {
    this.syncedValue = dictEntries
  }

  resetSelectOptions() {
    const vSelect = this.$refs.vSelect as LolthTypedInputSelect
    vSelect?.resetOptionsLoaded()
  }
}
