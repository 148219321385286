












import { Component, Prop } from 'vue-property-decorator'
import BaseFilter from './base-filter'
import AndOrFilter from './and-or-filter.vue'
import FieldFilter from './field-filter.vue'
import ReadonlyFilter from './readonly-filter.vue'
import { FilterCondition, FilterType } from '@/module/graphql'
import { FilterViewDef } from '@/module/components/lolth-filter/types'

@Component({
  name: 'lolth-filter',
  components: {
    AndOrFilter, FieldFilter, ReadonlyFilter
  }
})
export default class LolthFilter extends BaseFilter {
  @Prop() readonly fixed!:boolean
  @Prop() readonly external!:boolean
  @Prop() readonly deletable?: boolean
  @Prop() readonly fieldOptions?: FilterViewDef[]

  @Prop({ default: true }) readonly allowSwitchAndOr: boolean

  // 固定查询条件, 不能动
  @Prop({ default: ():FilterCondition[] => [] }) readonly fixedConditions!: FilterCondition[]
  // 外部组件设置的查询条件, 只能删不能动
  @Prop({ default: ():FilterCondition[] => [] }) readonly externalConditions!: FilterCondition[]

  private isAndOrField() {
    return this.filterCondition.filterType === FilterType.And ||
        this.filterCondition.filterType === FilterType.Or
  }
}
