




































































































































export default function (Component) {
  Component.options.__source = "src/module/butler/views/components/cmp-create-user-dialog.vue"
}
