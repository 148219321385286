








import { Component } from 'vue-property-decorator'
import BaseFilter from './base-filter'
import { FilterConditionView } from '@/module/components/lolth-filter/types'
import { FilterType } from '@/module/graphql'
import locale from './filter-locale'
import _ from 'lodash'

@Component
export default class ReadonlyFilter extends BaseFilter {
  format(condition: FilterConditionView): string {
    if (condition.filterType === FilterType.And ||
        condition.filterType === FilterType.Or) {
      if (_.isEmpty(condition.conditions)) return ''
      return '( ' + condition.conditions?.map(cond => this.format(cond))
        .join(' ' + locale(condition.filterType) + ' ') + ' )'
    } else {
      return this.formatFieldNamePart(condition) +
        this.formatOperatorValuePart(condition)
    }
  }

  formatFieldNamePart(condition: FilterConditionView): string {
    if (condition.filterType === FilterType.Custom) return ''
    if (condition.filterViewDef) {
      return condition.filterViewDef.fieldName || condition.filterViewDef.fieldKey
    } else {
      return condition.field || locale('unknown')
    }
  }

  formatOperatorValuePart(condition: FilterConditionView): string {
    const op = condition.operator || condition.filterType
    if (!op) return locale('all')
    let val = ''
    if (!condition.value ||
      condition.filterType === FilterType.IsNull ||
      condition.filterType === FilterType.IsNotNull) {
      val = ''
    } else {
      val = locale(condition.value)
    }
    return locale(op, condition.not) + val
  }
}
