














import { Component, Vue } from 'vue-property-decorator'
import CmpDashboardGauges from './components/cmp-dashboard-gauges.vue'
import { gql } from 'apollo-boost'
import LocalDbDao from '@/module/common/local-db-dao'
import { SysDashboardGauge } from '@/module/graphql'
import CmpDashboardGaugePriorityDialog from '@/module/butler/views/components/cmp-dashboard-gauge-priority-dialog.vue'
import { APP_REALM_CODE } from '@/module/common/constants'
import _ from 'lodash'
import { isSame } from '@/module/common/util/common-util'

@Component({
  components: { CmpDashboardGaugePriorityDialog, CmpDashboardGauges }
})
export default class PageIndex extends Vue {
  private mGauges: SysDashboardGauge[] = []
  private mDialogVisible: boolean = false
  private mSetting = LocalDbDao.getUserSetting(APP_REALM_CODE, 'dashboardMetrics')?.value
  private mShow: boolean = true

  get highPriorityDashboardMetrics() {
    if (!this.mSetting) return []
    return this.mGauges.filter((gauge: any) => {
      if (!gauge.count) return
      return _.find(this.mSetting, (setting: { id: string; level: string }) =>
        isSame(setting.id, gauge.id))?.level === 'high'
    })
  }

  get middlePriorityDashboardMetrics() {
    if (!this.mSetting) return []
    return this.mGauges.filter((gauge: any) => {
      if (!gauge.count) return
      return _.find(this.mSetting, (setting: { id: string; level: string }) =>
        isSame(setting.id, gauge.id))?.level === 'middle'
    })
  }

  get lowPriorityDashboardMetrics() {
    if (!this.mSetting) return this.mGauges
    return this.mGauges.filter((gauge: any) => {
      if (!gauge.count) return
      const level = _.find(this.mSetting, (setting: { id: string; level: string }) =>
        isSame(setting.id, gauge.id))?.level
      return level !== 'middle' && level !== 'high'
    })
  }

  created(): void {
    this.$apollo.query({
      query: gql`query {
        listUserDashboardGauges(userId: "${LocalDbDao.getPrincipal().identity}") {
          name id pageUrl realm
        }
      }`
    }).then(data => {
      this.mGauges = data.data.listUserDashboardGauges
      this.mGauges.forEach(m => {
        this.$apollo.query({
          query: gql`query {
            getDashboardGauge(gaugeId: "${m.id}")
          }`
        }).then(data => {
          this.$set(m, 'count', data.data.getDashboardGauge)
          this.mShow = false
          this.$nextTick(() => {
            this.mShow = true
          })
        })
      })
    })
  }

  refresh() {
    this.mSetting = LocalDbDao.getUserSetting(APP_REALM_CODE, 'dashboardMetrics')?.value
  }
}
