



































import { Component, Emit, Prop, PropSync, Ref, Vue } from 'vue-property-decorator'
import { FilterCondition, SortColumn } from '@/module/graphql'
import LocalDbDao from '@/module/common/local-db-dao'
import { Message } from 'element-ui'
import util from '@/d2admin/libs/util'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import _ from 'lodash'
import { SelectionMode } from '@/module/common/types'

@Component
export default class LolthTypedChooser extends Vue {
  @Ref() readonly vExplorer!: LolthExplorer

  @Prop() readonly explorerTypeKey!: string
  @Prop({ default: SelectionMode.Single }) readonly selectionMode!: SelectionMode
  @Prop() readonly defaultFilterConditions?: FilterCondition[]
  @Prop() readonly fixedFilterCondition?: FilterCondition
  @Prop() readonly defaultSort?: SortColumn[]
  @Prop() readonly itemLabelFormatter!: (item: any) => string
  @Prop() readonly selectedItems?: any

  @PropSync('visible')
  syncedVisible!: boolean

  private mSelectedData: any[] = []

  get explorerType() {
    return LocalDbDao.getExplorerType(this.explorerTypeKey)
  }

  created() {
    if (this.selectedItems) {
      _.isArray(this.selectedItems)
        ? this.mSelectedData.push(...this.selectedItems)
        : this.mSelectedData.push(this.selectedItems)
    }
  }

  @Emit('select')
  confirm() {
    if (this.mSelectedData.length <= 0) {
      Message({
        message: '至少选择一项', type: 'error', showClose: true
      })
      return
    }

    this.syncedVisible = false
    return this.mSelectedData
  }

  onRowDoubleClicked() {
    if (this.selectionMode === SelectionMode.Single) this.confirm()
  }

  private formatItemLabel(item: any) {
    return this.itemLabelFormatter
      ? this.itemLabelFormatter(item)
      : this.explorerType.formatLabel(item)
  }

  removeSelectedItem(item: any) {
    util.objects.remove(this.mSelectedData, (i: any) => i === item)
    this.vExplorer.deselect(item)
  }
}
