















































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { MdDataDiff, MdDataDiffBomEntry, MdDataDiffType } from '../../types'

declare interface BomEntry{
  code?: string,
  count?: number,
  countBase?: number,
  unit?: string,
  diffType: MdDataDiffType,
  countDiffType: MdDataDiffType,
  countBaseDiffType: MdDataDiffType,
  unitDiffType: MdDataDiffType
}

@Component
export default class CmpMdDataDiffViewer extends Vue {
  @Prop() readonly mdDataDiff!: MdDataDiff
  @Prop({ default: '变更' }) diffTypeColumnName!: string
  @Prop({ default: '变更前' }) diffLeftColumnName!: string
  @Prop({ default: '变更后' }) diffRightColumnName!: string

  diffTypeFormatter(row: any, column: any, cellValue: any, index: number) {
    if (cellValue === MdDataDiffType.changed) {
      return this.diffTypeColumnName
    } else if (cellValue === MdDataDiffType.added) {
      return '增加'
    } else if (cellValue === MdDataDiffType.removed) {
      return '删除'
    } else {
      return '不变'
    }
  }

  get lhsEntries(): BomEntry[] {
    let entries: BomEntry[] = []
    this.mdDataDiff.bomDiff?.forEach((entryDiff: MdDataDiffBomEntry) => {
      entries.push({
        code: entryDiff.oldMdData?.code,
        count: entryDiff.oldCount,
        countBase: entryDiff.oldCountBase,
        unit: entryDiff.oldUnit,
        diffType: entryDiff.oldMdData ? entryDiff.diffType : MdDataDiffType.same,
        countDiffType: entryDiff.countDiffType,
        countBaseDiffType: entryDiff.countBaseDiffType,
        unitDiffType: entryDiff.unitDiffType
      })
    })
    return entries
  }

  get rhsEntries(): BomEntry[] {
    let entries: BomEntry[] = []
    this.mdDataDiff.bomDiff?.forEach((entryDiff: MdDataDiffBomEntry) => {
      entries.push({
        code: entryDiff.newMdData?.code,
        count: entryDiff.newCount,
        countBase: entryDiff.newCountBase,
        unit: entryDiff.newUnit,
        diffType: entryDiff.newMdData ? entryDiff.diffType : MdDataDiffType.same,
        countDiffType: entryDiff.countDiffType,
        countBaseDiffType: entryDiff.countBaseDiffType,
        unitDiffType: entryDiff.unitDiffType
      })
    })
    return entries
  }

  private getBgClass(diffType: MdDataDiffType): string {
    switch (diffType) {
      case (MdDataDiffType.added):
        return 'bg-success'
      case (MdDataDiffType.changed):
        return 'bg-warning'
      case (MdDataDiffType.removed):
        return 'bg-danger'
      default:
        return ''
    }
  }

  attrDiffTypeCellClass(cell: any): string {
    if (cell.column.property === 'diffType') {
      return this.getBgClass(cell.row.diffType)
    }
    return ''
  }

  bomDiffTypeCellClass(cell: any): string {
    if (cell.column.property === 'code') {
      return this.getBgClass(cell.row.diffType)
    }
    if (cell.row.diffType === MdDataDiffType.changed) {
      const diffType = cell.row[cell.column.property + 'DiffType']
      return this.getBgClass(diffType)
    } else {
      return ''
    }
  }

  @Emit()
  showMdDataDetail(row: any) {
    return row
  }
}
