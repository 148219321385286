














import { Component, Vue } from 'vue-property-decorator'
import { GridOptions, IToolPanel, IToolPanelParams } from 'ag-grid-community'
import { FilterCondition } from '@/module/graphql'
import { FilterViewDef } from '@/module/components/lolth-filter/types'
import { VueFrameworkComponentWrapper } from 'ag-grid-vue/lib/VueFrameworkComponentWrapper'
import _ from 'lodash'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import EventBus from '@/d2admin/plugin/event/event-bus'

declare interface IExplorerToolPanelParams extends IToolPanelParams {
  frameworkComponentWrapper: VueFrameworkComponentWrapper,
  explorer: LolthExplorer,
  gridOptions: GridOptions
}

@Component
export default class ExplorerFilterPanel extends Vue implements IToolPanel {
  private params!: IExplorerToolPanelParams
  private fieldOptions: FilterViewDef[] = []

  get fixedFilterConditions() {
    return this.params.explorer.getFixedFilterConditions()
  }

  get columnFilterConditions() {
    return this.params.explorer.columnFilterConditions
  }

  get mergedFilterCondition() {
    return this.params.explorer.mergedFilterCondition
  }

  created() {
    this.fieldOptions = this.params.explorer.getFilterViewDefs()
    EventBus.$on('grid-options-changed', this.onGridOptionsChanged)
  }

  destroyed() {
    EventBus.$off('grid-options-changed', this.onGridOptionsChanged)
  }

  private onGridOptionsChanged() {
    this.fieldOptions.splice(0, this.fieldOptions.length)
    this.fieldOptions.push(...this.params.explorer.getFilterViewDefs())
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  refresh(): void {
    this.$forceUpdate()
  }

  onFilterConditionRemoved(filterCondition: FilterCondition) {
    const removingIndex = _.indexOf(this.columnFilterConditions, filterCondition)
    if (removingIndex >= 0) {
      const filterView = this.params.gridOptions.api?.getFilterInstance(filterCondition.field || '')
      filterView?.setModel(null)
      this.params.gridOptions.api?.onFilterChanged()
    }
  }

  query() {
    this.params.gridOptions.api?.onFilterChanged()
  }
}
