import axios from 'axios'
import setting from '@/d2admin/setting'
import delegate from '@/d2admin/delegate/axios'

function applyAxiosInterceptors(axios) {
  // 请求拦截器
  axios.interceptors.request.use(
    config => {
      return delegate.get().beforeRequest(config)
    },
    error => {
      // 发送失败
      delegate.get().onRequestError(error)
      return Promise.reject(error)
    }
  )

  // 响应拦截器
  axios.interceptors.response.use(
    response => {
      return delegate.get().beforeResponse(response)
    },
    error => {
      const retry = delegate.get().onResponseError(error)
      if (retry) return retry
      return Promise.reject(error)
    }
  )
  return axios
}

export function buildAxios(config) {
  return applyAxiosInterceptors(axios.create(config))
}

// 创建一个 axios 实例
const service = applyAxiosInterceptors(axios.create(setting.axios))
export default service
