import Vue from 'vue'

/** 属性输入组件, Model: attribute, 必填Props: mdField, 可选Props: disabled/alias/feature/bomFeature/extraFeature/errors */
Vue.component('cmp-md-attr-input', require('../cmp-md-attr-input').default)
/** 以表格形式显示属性组, 必填Props: attributes */
Vue.component('cmp-md-attr-viewer', require('../cmp-md-attr-viewer').default)
/** 显示属性值, 除多选选项和附件外不加样式. 必填Props: attribute, 可选Props: mdField/showDictKey */
Vue.component('cmp-md-attr-value', require('../cmp-md-attr-viewer/cmp-md-attr-value').default)
/** 以<l-tag包围显示属性值. 必填Props: attribute, 可选Props: mdField/showFieldName/tagType/showDictKey */
Vue.component('cmp-md-attr-value-viewer', require('../cmp-md-attr-viewer/cmp-md-attr-value-viewer').default)
/** 在一行里显示属性组. 必填Props: attributes, 可选Props: tagType/showDictKey */
Vue.component('cmp-md-attrs-inline-viewer', require('../cmp-md-attr-viewer/cmp-md-attrs-inline-viewer').default)
/** 属性图标. 必填Props: attribute, 可选Props: attrIconsConfig */
Vue.component('cmp-md-attr-icons', require('./cmp-md-attr-icons').default)

/** 主数据详情. 必填Props: mdData, 可选Props: attributes/fetch */
Vue.component('cmp-md-data-viewer', require('./cmp-md-data-viewer').default)
/** 主数据选择器. 可选Props: selectionMode/fixedFilterCondition/defaultFilterConditions/defaultSort/explorerViewSuffix */
Vue.component('cmp-md-data-chooser', require('./cmp-md-data-chooser').default)

Vue.component('cmp-md-dict-filter', require('./explorer-support/cmp-md-dict-filter').default)
Vue.component('cmp-md-dict-attr-filter', require('./explorer-support/cmp-md-dict-attr-filter').default)
Vue.component('cmp-md-attr-keywords-filter', require('./explorer-support/cmp-md-attr-keywords-filter').default)
Vue.component('cmp-md-extend-data-filter', require('./explorer-support/cmp-md-extend-data-filter').default)
Vue.component('cmp-md-extend-entry-filter', require('./explorer-support/cmp-md-extend-entry-filter').default)

Vue.component('cmp-md-dictionary-entry-input-select', require('./input/cmp-md-dictionary-entry-input-select.vue').default)
