













import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

@Component
export default class LolthMoreTextViewer extends Vue {
  @Prop({ required: true }) readonly content: string
  @Prop({ default: 3 }) readonly line: 1|2|3|4|5

  private mCheckBoxId = _.uniqueId()
}
