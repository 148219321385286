














import { Component, Model, Vue } from 'vue-property-decorator'

@Component
export default class AttrTbd extends Vue {
  @Model('change') readonly tbd!:boolean

  get syncTbd() {
    return this.tbd
  }

  set syncTbd(value) {
    this.$emit('change', value)
  }

  get tbdIcon(): string {
    return this.tbd ? 'el-icon-s-flag text-warning' : ''
  }
}
