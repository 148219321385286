











import { Component, Vue } from 'vue-property-decorator'
import CmpPortal from './components/cmp-portal.vue'
import qs from 'qs'
import axios from '@/d2admin/plugin/axios/index'
import { GLOBAL_ENV } from '@/d2admin/setting'

@Component({
  name: 'PageInvitation',
  components: {
    CmpPortal
  }
})
export default class PageInvitation extends Vue {
  private mExwUser: any = {
    ewxUserMobile: null,
    ewxUserId: null,
    invitationCode: null
  }

  async created() {
    this.mExwUser.invitationCode = this.$route.params.code
    const ua = window.navigator.userAgent.toLowerCase()
    if (ua.indexOf('micromessenger') !== -1 && !this.$route.query.code) {
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?' + qs.stringify({
        appid: GLOBAL_ENV.EWX_CORP_ID,
        agentid: GLOBAL_ENV.EWX_AGENT_ID,
        redirect_uri: encodeURIComponent(GLOBAL_ENV.PUBLIC_URL),
        scope: 'snsapi_privateinfo',
        state: 'ewx-invitation.' + this.$route.params.code,
        response_type: 'code'
      }) + '#wechat_redirect'
    }
    if (this.$route.query.code) {
      const ewxUserInfo:any = await axios.get('/auth/ewx-user-info?code=' + this.$route.query.code)
      this.mExwUser.ewxUserId = ewxUserInfo.ewxUserId
      this.mExwUser.ewxUserMobile = ewxUserInfo.ewxUserMobile
    }
  }
}
