














import { Component, Vue } from 'vue-property-decorator'
import CmpPortal from './components/cmp-portal.vue'
import UserInfo from '@/module/butler/views/components/cmp-user-info.vue'
import LocalDbDao from '@/module/common/local-db-dao'

@Component({
  name: 'page-sign-info-preview',
  components: {
    UserInfo,
    CmpPortal
  }
})
export default class PageUserInfoPreview extends Vue {
  private principal: any

  logOff() {
    this.$store.dispatch('d2admin/account/logout', { confirm: false })
  }

  get accountId() {
    return this.principal.userProfile ? this.principal.userProfile.accountId : this.principal.identity
  }

  created(): void {
    this.principal = LocalDbDao.getPrincipal()
  }
}
