













import { Component, Vue } from 'vue-property-decorator'
import { FilterChangedEvent, IFloatingFilterComp, IFloatingFilterParams } from 'ag-grid-community'
import { FilterType, User } from '@/module/graphql'
import _ from 'lodash'

@Component
export default class CmpKtiTalentFloatingFilter extends Vue implements IFloatingFilterComp {
  private params: IFloatingFilterParams | null = null
  private mValue: User = null

  get customProps() {
    return (this.params?.filterParams as any).viewDef?.customProps || {}
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent): void {
    if (!parentModel) {
      this.mValue = null
    } else if (parentModel.extra?.dictKey) {
      this.mValue = {
        id: parentModel.value,
        displayName: parentModel.extra?.userName ?? parentModel.value
      } as User
    }
  }

  onValueChanged() {
    if (_.isNil(this.mValue)) return
    let filterType = this.customProps.refType === 'single' ? FilterType.Number : FilterType.Array
    let operator = this.customProps.refType === 'single' ? 'equals' : 'in'
    this.params?.parentFilterInstance(instance => {
      (instance as any).onFloatingFilterChanged({
        filterType: filterType,
        operator: operator,
        value: this.mValue.id
      })
    })
  }
}
