import { Component } from 'vue-property-decorator'
import { GridOptions, SideBarDef, ToolPanelVisibleChangedEvent } from 'ag-grid-community'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import MixinMdDataExplorer from '@/module/master-data/views/components/explorer-support/mixin-md-data-explorer'
import MdcDataSearchPanel from '@/module/plugin-md-category/views/components/data-search/cmp-mdc-data-search-panel.vue'
import CmpMdpDataViewPanel from '@/module/plugin-md-product-structure/views/components/cmp-mdp-data-view-panel.vue'
import LocalDbDao from '@/module/common/local-db-dao'

@Component
export default class MixinMdcAdvSearch extends MixinMdDataExplorer {
  extendAdvSearchGridOptions(explorer: LolthExplorer, gridOptions: GridOptions) {
    this.extendGridOptions(explorer, gridOptions)

    gridOptions.frameworkComponents.mdcDataSearchPanel = MdcDataSearchPanel
    gridOptions.frameworkComponents.mdpDataViewPanel = CmpMdpDataViewPanel
    const sideBarDef = gridOptions.sideBar as SideBarDef
    sideBarDef?.toolPanels?.push({
      id: 'md-data-search-panel',
      labelDefault: '按BOM查询',
      labelKey: 'mdcDataSearchPanel',
      iconKey: 'filter',
      toolPanel: 'mdcDataSearchPanel',
      toolPanelParams: {
        explorer: explorer,
        gridOptions: gridOptions
      }
    })
    sideBarDef?.toolPanels?.push({
      id: 'mdp-data-view-panel',
      labelDefault: '结构化视图',
      labelKey: 'mdpDataViewPanel',
      iconKey: 'group',
      toolPanel: 'mdpDataViewPanel',
      toolPanelParams: {
        explorer: explorer,
        gridOptions: gridOptions,
        attrExtend: this,
        attributesField: 'attributes'
      }
    })
    const originalOnToolPanelVisibleChanged = gridOptions.onToolPanelVisibleChanged
    gridOptions.onToolPanelVisibleChanged = (event: ToolPanelVisibleChangedEvent) => {
      if (originalOnToolPanelVisibleChanged) originalOnToolPanelVisibleChanged(event)
      if (event.source === 'md-data-search-panel') {
        document.querySelectorAll('.ag-tool-panel-wrapper:not(.ag-hidden)').forEach((el: HTMLElement) => {
          el.style.width = '600px'
        })
      }
      if (event.source === 'mdp-data-view-panel') {
        document.querySelectorAll('.ag-tool-panel-wrapper:not(.ag-hidden)').forEach((el: HTMLElement) => {
          el.style.width = '300px'
        })
      }
    }
  }

  onReady() {
    if (LocalDbDao.getCustomData('mdpMdDataView' + this.vExplorer.getExplorerViewModelName(), true)) {
      this.vExplorer.gridOptions.api.openToolPanel('mdp-data-view-panel')
    }
  }
}
