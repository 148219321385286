
















import { Component, Prop, Watch } from 'vue-property-decorator'
import { FilterCondition, FilterType } from '@/module/graphql'
import LocalDbDao from '@/module/common/local-db-dao'
import util from '@/d2admin/libs/util'
import BaseFieldFilter from '@/module/components/lolth-filter/base-field-filter'
import _ from 'lodash'

@Component
export default class CmpDiagStateFilter extends BaseFieldFilter {
  @Prop({ required: true }) readonly enumGroup?: string
  @Prop({ default: ():string[] => [] }) readonly realmCodes!: string[]
  @Prop({ default: ():string[] => [] }) readonly ruleNames!: string[]

  private mValues: any[] = []

  get valuedEnumItems() {
    if (!this.enumGroup) return []
    return LocalDbDao.getValuedEnum(this.enumGroup)
  }

  get isValid() {
    return !_.isEmpty(this.filterCondition.value)
  }

  public initFilterCondition(filterCondition: FilterCondition) {
    filterCondition.filterType = FilterType.Custom
    filterCondition.field = null
    filterCondition.extra = {}
    if (!_.isEmpty(this.realmCodes)) {
      filterCondition.extra.realmCodes = this.realmCodes
    }
    if (!_.isEmpty(this.ruleNames)) {
      filterCondition.extra.ruleNames = this.ruleNames
    }
    if (!filterCondition.operator) filterCondition.operator = 'diagState'
    if (_.isNil(filterCondition.not)) filterCondition.not = false
    util.objects.reactive(filterCondition)
  }

  @Watch('mValues')
  onValueChanged(val: string): void {
    this.filterCondition.value = this.mValues.join(',')
  }

  clearContent() {
    this.filterCondition.value = null
    this.internalOnFilterChanged()
  }
}
