import { ModuleHook } from '@/d2admin/module/types'
import { ComponentOptions } from 'vue'
import store from '@/store'
import { AttrIcon } from '@/module/master-data/store'

export class MdxModuleHook implements ModuleHook {
  onModuleLoaded(vueOptions: ComponentOptions<any>): any {
    const attrIcons: AttrIcon[] = [{
      matcher: 'materialField',
      description: '原材料',
      color: '#0782BF',
      icon: 'cubes'
    }, {
      matcher: 'productTypeField',
      description: '产品型号',
      color: '#37DBE7',
      icon: 'product-hunt'
    }]
    store.commit('master-data/md-store/defineIcons', attrIcons, { root: true })
  }
}

export default new MdxModuleHook()
