































import { Component, Vue, PropSync, Ref, Prop } from 'vue-property-decorator'
import copy from 'copy-to-clipboard'
import axios from '@/d2admin/plugin/axios/index'
import qs from 'qs'
import { User } from '@/module/graphql'

@Component
export default class CmpJoiningApplicationDialog extends Vue {
  @Ref() readonly inviteFormRef!: any
  @Prop() user: User

  @PropSync('visible')
  syncedVisible!: boolean

  onShow() {
    if (this.user) {
      this.formInvite.displayName = this.user.displayName
      this.formInvite.employeeNo = this.user.employeeNo
      this.formInvite.userId = this.user.id
    }
  }

  private formInvite = {
    displayName: '',
    employeeNo: '',
    inviteUrl: '',
    invitationCode: '',
    userId: ''
  }

  private inviteFormRules = {
    displayName: [
      { required: true, message: '请输入员工姓名', trigger: 'blur' }
    ]
  }

  clearInviteForm() {
    this.formInvite.inviteUrl = ''
    this.formInvite.displayName = ''
    this.formInvite.employeeNo = ''
  }

  commitInvite() {
    this.inviteFormRef.validate((valid: boolean) => {
      if (valid) {
        this.$confirm('确认提交?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post('/butler/invitation/create',
            qs.stringify({ ...this.formInvite })
          ).then((resp: any) => {
            this.formInvite.inviteUrl = this.$baseUrl + '/#/invitation/' + resp.joiningApplication.invitationCode
            this.$message({
              message: '提交成功', type: 'success', showClose: true
            })
            this.$emit('saved')
          })
        })
      } else {
        this.$message({
          message: '提交信息有误,请修正', type: 'error', showClose: true
        })
      }
    })
  }

  copyToClipboard() {
    if (!this.formInvite.inviteUrl) {
      this.$message({
        message: '请先生成邀请链接', type: 'error', showClose: true
      })
      return
    }
    copy(this.formInvite.inviteUrl)
    this.$notify({
      type: 'info',
      title: '消息',
      message: '邀请链接已复制到剪贴板'
    })
  }
}
