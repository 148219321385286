




import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import _ from 'lodash'

declare interface IExplorerIsNullCellRendererComp extends ICellRendererParams {
  nullToTrue?: boolean,
}

@Component
export default class LolthIsNullCellRenderer extends Vue implements ICellRendererComp {
  private params: IExplorerIsNullCellRendererComp
  private misValueNull: boolean = null

  getGui() {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: IExplorerIsNullCellRendererComp): boolean {
    this.misValueNull = params.nullToTrue ? _.isNil(params.value) : !_.isNil(params.value)
    return true
  }
}
