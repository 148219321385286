export default [
  {
    path: '/mde/currency',
    name: 'PageMdeCurrency',
    meta: {
      title: '币种管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mde-currency')
  }, {
    path: '/mde/warehouse',
    name: 'PageMdeWarehouse',
    meta: {
      title: '仓库管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mde-warehouse')
  }, {
    path: '/mde/customer',
    name: 'PageMdeCustomer',
    meta: {
      title: '客户管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mde-customer')
  }, {
    path: '/mde/supplier',
    name: 'PageMdeSupplier',
    meta: {
      title: '供应商管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mde-supplier')
  }, {
    path: '/mde/incoterms',
    name: 'PageMdeIncoterms',
    meta: {
      title: '外贸条款管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-mde-incoterms')
  }
]
