







import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import IcmpMdAdjustTalliesDialog from '@/module/master-data/views/tally/icmp-md-adjust-tallies-dialog.vue'
import { MdTally } from '@/module/graphql'

@Component({
  components: {
    IcmpMdAdjustTalliesDialog
  }
})
export default class CmpMdAdjustTalliesCellRenderer extends Vue implements ICellRendererComp {
  private params!:ICellRendererParams
  private mRowData: MdTally
  private mDialogVisible: boolean = false
  private mTargetTallyId: number = null

  created() {
    this.refresh(this.params)
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  refresh(params: ICellRendererParams): boolean {
    this.mRowData = params.data
    return true
  }

  private onClick() {
    this.mDialogVisible = true
    this.mTargetTallyId = this.params.data.id
    const editor = new IcmpMdAdjustTalliesDialog({
      router: this.$router,
      store: this.$store,
      i18n: this.$i18n,
      apolloProvider: this.$apolloProvider,
      propsData: {
        targetTallyId: this.params.data.id
      },
      parent: this
    })
    editor.$mount()
    document.body.appendChild(editor.$el)
  }
}
