



















import { Component } from 'vue-property-decorator'
import BaseInput from './base-input'

@Component
export default class CmpMdTableDataInputNumber extends BaseInput<number> {
  get vInput(): any {
    if (this.columnOption.percentage) return this.$refs.vPercentageInput
    else return this.$refs.vNumberInput
  }

  focus() {
    this.vInput.focus()
  }
}
