










































































































































































































export default function (Component) {
  Component.options.__source = "src/module/master-data/views/tally/page-md-tally.vue"
}
