











import { Component, Prop, Vue } from 'vue-property-decorator'
import { MdAttribute } from '@/module/graphql'
import _ from 'lodash'
import { TagType } from '@/module/common/types'

@Component
export default class CmpMdAttrsInlineViewer extends Vue {
  @Prop({ default: ():MdAttribute[] => [] }) readonly attributes!: MdAttribute[]
  @Prop() readonly tagType: TagType
  @Prop({ default: true }) readonly showDictKey: boolean
  @Prop() readonly showFieldName?: boolean
  @Prop({ default: false }) readonly distinct?: boolean

  get nonEmptyAttrs() {
    let attrs = this.attributes || []
    if (this.distinct) attrs = _.uniqBy(attrs, attr => attr.field.id + '∆' + attr.value)
    return attrs.sort((lhs, rhs) => {
      return _.toNumber(lhs.field.id) - _.toNumber(rhs.field.id)
    }).filter(attr => {
      if (_.isArray(attr)) return !_.isEmpty(attr)
      return !_.isNil(attr.value) || attr.field.dataType === '是否'
      // bool类型的属性, 空值作为false处理
    })
  }
}
