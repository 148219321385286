// 加法
// eslint-disable-next-line no-extend-native
Number.prototype.add = function (arg) {
  let r1, r2, m
  try { r1 = this.toString().split('.')[1].length } catch (e) { r1 = 0 }
  try { r2 = arg.toString().split('.')[1].length } catch (e) { r2 = 0 }
  m = Math.pow(10, Math.max(r1, r2))
  return (this.mul(m) + arg.mul(m)) / m
}

// 减法
// eslint-disable-next-line no-extend-native
Number.prototype.sub = function (arg) {
  return this.add(-arg)
}

// 乘法
// eslint-disable-next-line no-extend-native
Number.prototype.mul = function (arg) {
  let m = 0; let s1 = this.toString(); let s2 = arg.toString()
  try { m += s1.split('.')[1].length } catch (e) {}
  try { m += s2.split('.')[1].length } catch (e) {}
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}

// 除法
// eslint-disable-next-line no-extend-native
Number.prototype.div = function (arg) {
  let t1 = 0; let t2 = 0; let r1; let r2
  try { t1 = this.toString().split('.')[1].length } catch (e) {}
  try { t2 = arg.toString().split('.')[1].length } catch (e) {}
  r1 = Number(this.toString().replace('.', ''))
  r2 = Number(arg.toString().replace('.', ''))
  return (r1 / r2) * Math.pow(10, t2 - t1)
}

// 四舍五入
// eslint-disable-next-line no-extend-native
Number.prototype.round = function (bit) {
  const shift = Math.pow(10, bit)
  return Math.round(this * shift) / shift
}

// 格式化: 1 -> 0001
// eslint-disable-next-line no-extend-native
Number.prototype.formatInt = function (bit = 1) {
  const factor = Math.pow(10, bit)
  const intVal = this.round(0)
  if (intVal >= factor) return intVal + ''
  return ((factor + intVal) + '').substr(1, bit)
}

// 格式化: 1234567.1234 -> 1,234,567.1234
// eslint-disable-next-line no-extend-native
Number.prototype.formatWithKiloSymbol = function () {
  return this.toString().replace(/\d+/, function (n) { // 先提取整数部分
    return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
      return $1 + ','
    })
  })
}

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

// eslint-disable-next-line no-extend-native
Number.prototype.formatSize = function () {
  if (!this) return 0

  let l = 0; let n = parseInt(this + '', 10) || 0
  while (n >= 1024 && ++l) {
    n = n / 1024
  }
  return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l])
}
