




import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import _ from 'lodash'

@Component
export default class LolthFilesCellRenderer extends Vue implements ICellRendererComp {
  private params: ICellRendererParams
  private mValue: string[] | string = null

  get value() {
    return _.isNil(this.mValue) ? []
      : !_.isArray(this.mValue) ? [this.mValue]
        : this.mValue
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: ICellRendererParams): boolean {
    this.mValue = params.value
    return true
  }
}
