






















import { Component, Vue } from 'vue-property-decorator'
import { GridOptions, IToolPanel, IToolPanelParams } from 'ag-grid-community'
import { VueFrameworkComponentWrapper } from 'ag-grid-vue/lib/VueFrameworkComponentWrapper'
import { FilterType, MdField, RowRequest } from '@/module/graphql'
import _ from 'lodash'
import { Maybe } from 'graphql/jsutils/Maybe'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import MixinExplorerMdAttributeExtend
  from '@/module/master-data/views/components/explorer-support/mixin-explorer-md-attribute-extend'
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef'
import gql from 'graphql-tag'

declare interface IExplorerToolPanelParams extends IToolPanelParams {
  frameworkComponentWrapper: VueFrameworkComponentWrapper,
  explorer: LolthExplorer,
  gridOptions: GridOptions,
  attrExtend: MixinExplorerMdAttributeExtend,
  attributesField: string
}

@Component
export default class ExplorerMdAttrPanel extends Vue implements IToolPanel {
  private params!: IExplorerToolPanelParams
  private mdFields: MdField[] = []
  private selectedMdField: Maybe<MdField> = null

  created() {
    const attrColumnDefs: ColDef[] = this.params.explorer.getContextParam('attrColumnDefs') || []
    const attrMdFields: MdField[] = attrColumnDefs.map(colDef => {
      return {
        id: colDef.field.replace('@', ''),
        name: colDef.headerName
      } as MdField
    })
    this.$apollo.query({
      query: gql`query ($rowRequest: RowRequest!) {
        MdFields(rowRequest: $rowRequest) {
          rows {
            id ver name dataType option
          }
        }
      }`,
      variables: {
        rowRequest: {
          filter: {
            filterType: FilterType.InIds,
            value: attrMdFields.map(m => m.id).join(',')
          }
        } as RowRequest
      }
    }).then(resp => {
      this.mdFields = resp.data.MdFields.rows
    })
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  refresh(): void {
    this.$forceUpdate()
  }

  private onMdFieldSelected() {
    if (!_.find(this.mdFields,
      mdField => mdField.id === this.selectedMdField!.id)) {
      this.mdFields.push(this.selectedMdField!)
    }
    this.selectedMdField = null
  }

  updateColumns() {
    this.params.attrExtend.updateColumns(this.params.gridOptions.columnDefs,
      this.mdFields, this.params.attributesField)
  }
}
