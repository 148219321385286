






import { Component, Prop, Vue } from 'vue-property-decorator'
import { ICellRendererComp } from 'ag-grid-community'
import { DIAG_STATE } from '@/module/common/constants'
import { showDiagnosisDialog } from '@/module/common/util/view-util'
import { getRowId, ICustomRendererCellRendererParams } from '@/module/components/lolth-explorer/supports'
import LocalDbDao from '@/module/common/local-db-dao'
import { ID } from '@/module/common/types'

interface IDiagStateRendererCellRendererParams extends ICustomRendererCellRendererParams {
  onClick?: (row: any) => {}
}

@Component
export default class LolthDiagnosisCellRenderer extends Vue implements ICellRendererComp {
  @Prop() readonly explorerTypeKey: string
  @Prop() readonly value: string
  @Prop() readonly id: ID
  @Prop() readonly diagnosisPermission: string

  private params!: IDiagStateRendererCellRendererParams
  private mValue: any = null

  get resolvedExplorerTypeKey() {
    return this.explorerTypeKey || this.params?.explorerTypeKey
  }

  get resolvedDataId() {
    return this.id || (this.params && getRowId(this.params.data)) || 0
  }

  get resolvedPermission() {
    return this.diagnosisPermission || this.params?.context?.diagnosisPermission
  }

  get iconName() {
    switch (this.mValue) {
      case DIAG_STATE.OK: return 'check'
      case DIAG_STATE.IGNORED: return 'ban'
      case DIAG_STATE.WARNING: return 'exclamation-triangle'
      case DIAG_STATE.ERROR: return 'times-circle-o'
      case DIAG_STATE.FATAL: return 'times-circle'
      case DIAG_STATE.CRASH: return 'ambulance'
      default: return ''
    }
  }

  get iconClass() {
    switch (this.mValue) {
      case DIAG_STATE.OK: return 'text-success'
      case DIAG_STATE.IGNORED: return 'text-primary'
      case DIAG_STATE.WARNING: return 'text-warning'
      case DIAG_STATE.ERROR: return 'text-danger'
      case DIAG_STATE.FATAL: return 'text-danger'
      case DIAG_STATE.CRASH: return 'text-danger'
      default: return ''
    }
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    if (this.value) this.mValue = this.value
    else this.refresh(this.params)
  }

  refresh(params: IDiagStateRendererCellRendererParams): boolean {
    this.mValue = this.params?.value
    return true
  }

  private onClick() {
    if (this.params?.onClick) {
      this.params.onClick(this.params.data)
    } else {
      const explorerType = LocalDbDao.getExplorerType(this.resolvedExplorerTypeKey)
      showDiagnosisDialog(this,
        explorerType.key,
        this.resolvedDataId,
        this.resolvedPermission)
    }
  }
}
