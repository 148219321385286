import { Client } from '@stomp/stompjs'
import LocalDbDao from '@/module/common/local-db-dao'
import { refreshToken } from '@/module/butler/api/auth'
import { messageCallbackType } from '@stomp/stompjs/src/types'
import _ from 'lodash'
import { GLOBAL_ENV } from '@/d2admin/setting'

interface StompClientProxy {
  connect(): void
  disconnect(): void
  subscribe(topic: string, callback: messageCallbackType): void
  unsubscribe(topic: string): void
}

const WEB_SOCKET_PATH = 'socket'
let stompClient: Client
let subscribers: { [topic: string]: messageCallbackType } = {}

export default {
  connect() {
    if (stompClient) {
      stompClient.deactivate()
    }

    const wsUrl = (GLOBAL_ENV.API_URL || process.env.VUE_APP_API)
      .replace('http://', 'ws://').replace('https://', 'wss://') + WEB_SOCKET_PATH
    stompClient = new Client({
      brokerURL: wsUrl,
      connectHeaders: {
        'access-token': LocalDbDao.getAccessToken()
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      onConnect: receipt => {
        if (!stompClient || !stompClient.active) return // in case stompClient is not ready
        _.keys(subscribers).forEach(topic => {
          stompClient.subscribe(topic, subscribers[topic])
        })
      },
      onStompError: frame => {
        if ((frame.headers.message || '').indexOf('[1102]') >= 0) {
          refreshToken().then(() => {
            this.disconnect()
            setTimeout(() => this.connect(), 0)
          })
        } else {
          console.error('websocket error : ', frame)
        }
      }
    })
    if (process.env.NODE_ENV === 'development') {
      stompClient.debug = str => console.log(str)
    }
    stompClient.activate()
  },
  disconnect() {
    stompClient && stompClient.deactivate()
  },
  subscribe(topic: string, callback: messageCallbackType) {
    subscribers[topic] = callback
    if (stompClient && stompClient.active) {
      stompClient.subscribe(topic, callback)
    }
  },
  unsubscribe(topic: string) {
    delete subscribers[topic]
    if (stompClient && stompClient.active) {
      stompClient.unsubscribe(topic)
    }
  }
} as StompClientProxy
