








import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { MetCustomer, MetIntroducer } from '@/module/graphql'
import gql from 'graphql-tag'

@Component
export default class CmpMetIntroducersCellRenderer extends Vue implements ICellRendererComp {
  private params: ICellRendererParams
  private mIntroducers: MetIntroducer[] = []

  getGui() {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: ICellRendererParams): boolean {
    const customer = params.data as MetCustomer
    this.$apollo.query({
      query: gql`query  {
        MetCustomer(id: "${customer.id}") {
          introducers {
            introducingType {
              id key value
            } cookedInfo
          }
        }
      }`
    }).then(resp => {
      this.mIntroducers = resp.data.MetCustomer.introducers
    })
    return true
  }
}
