








import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp } from 'ag-grid-community'
import { FilterType } from '@/module/graphql'
import gql from 'graphql-tag'
import { ICustomRendererCellRendererParams } from '@/module/components/lolth-explorer/supports'
import util from '@/d2admin/libs/util'
import _ from 'lodash'

@Component
export default class CmpKtiTalentCellRenderer extends Vue implements ICellRendererComp {
  private params!: ICustomRendererCellRendererParams
  private talents: any[] = []

  created(): void {
    this.refresh(this.params)
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  refresh(params: ICustomRendererCellRendererParams): boolean {
    let users = this.params.value
    if (!users || users.length <= 0) return
    if (_.isPlainObject(users)) {
      // user object
      this.talents.push(users)
    } else if (_.isArray(users) && _.isPlainObject(users[0])) {
      // user array
      this.talents.push(...users)
    } else {
      // user id / ids
      if (!_.isArray(users)) users = [users]

      this.$apollo.query({
        query: gql`query talents($rowRequest: RowRequest!) {
        KtiTalents(rowRequest: $rowRequest) {
          rows { id name }
        }
      }`,
        variables: {
          rowRequest: {
            filter: {
              filterType: FilterType.Number,
              field: 'id',
              operator: 'in',
              value: users.join(',')
            }
          }
        }
      }).then(data => {
        util.objects.refill(data.data.KtiTalents.rows, this.talents)
      })
    }
    return true
  }
}
