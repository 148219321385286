export default {
  namespaced: true,
  modules: {
    account: require('./modules/account').default,
    color: require('./modules/color').default,
    db: require('./modules/db').default,
    fullscreen: require('./modules/fullscreen').default,
    gray: require('./modules/gray').default,
    loading: require('./modules/loading').default,
    log: require('./modules/log').default,
    menu: require('./modules/menu').default,
    page: require('./modules/page').default,
    releases: require('./modules/releases').default,
    search: require('./modules/search').default,
    size: require('./modules/size').default,
    theme: require('./modules/theme').default,
    transition: require('./modules/transition').default,
    ua: require('./modules/ua').default,
    user: require('./modules/user').default
  }
}
