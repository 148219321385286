































































export default function (Component) {
  Component.options.__source = "src/module/components/lolth-filter/readonly-filter.vue"
}
