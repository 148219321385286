


















import { Component } from 'vue-property-decorator'
import BaseAttrInput from './base-attr-input'
import _ from 'lodash'

@Component
export default class AttrInputNumber extends BaseAttrInput {
  private mValue: string = null

  get syncValue() {
    if (_.isNil(this.mValue)) {
      this.mValue = this.attribute.value
    }
    return this.mValue
  }

  set syncValue(val: string) {
    this.mValue = val
    this.attribute.value = parseFloat(val)
    if (_.isNaN(this.attribute.value)) this.attribute.value = null
    this.$emit('attr-value-changed', this.attribute.value)
  }
}
