










import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator'
import VueTagsInput from '@johmun/vue-tags-input'

export interface TagsInputItem {
  text: string,
  tiClasses?: string[]
}

@Component({
  components: { VueTagsInput }
})
export default class LolthInputTags extends Vue {
  @Ref() private readonly vInput: any

  @Prop({ default: '输入标签, 回车确定' }) readonly placeholder?: string
  @Prop({ default: ():TagsInputItem[] => [] }) readonly autoCompleteCandidates?: TagsInputItem[]
  @Prop() readonly disabled?: boolean

  @Model('change', { required: true })
  readonly tags: string[]

  public get syncTags(): TagsInputItem[] {
    return this.tags.map(tag => {
      return {
        text: tag
      }
    })
  }

  public set syncTags(value: TagsInputItem[]) {
    this.$emit('change', (value || []).map(v => v.text))
  }

  private mTag: string = ''

  protected get filteredItems() {
    return this.autoCompleteItems.filter(item => {
      return item.text.toLowerCase().indexOf(this.mTag.toLowerCase()) !== -1
    })
  }

  protected get autoCompleteItems(): any[] {
    return this.autoCompleteCandidates
  }

  public focus() {
    this.$nextTick(() => {
      this.vInput?.focus()
    })
  }
}
