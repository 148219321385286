


























import { Component, Prop, Vue } from 'vue-property-decorator'
import { FilterCondition, FilterType, MdField } from '@/module/graphql'
import { FilterViewDef } from '@/module/components/lolth-filter/types'
import FieldFilter from '@/module/components/lolth-filter/field-filter.vue'
import _ from 'lodash'
import { FilterConditionWrapper } from '@/module/plugin-md-structure/views/types'

@Component({
  components: {
    FieldFilter
  }
})
export default class CmpMdcDataSearchItem extends Vue {
  @Prop() readonly hideOperator : boolean
  @Prop() readonly editable : boolean
  @Prop() readonly field!: MdField
  @Prop() filterConditions: FilterConditionWrapper[];
  @Prop() fieldDefs: FilterViewDef[]
  @Prop() moreFields: boolean
  @Prop() closable: boolean
  @Prop() colSpan: number

  get rows() {
    const cols = 24 / this.colSpan
    return !this.filterConditions ? 0 : ((this.filterConditions.length + cols - this.filterConditions.length % cols) / cols)
  }

  getColConditions(rowIdx: number) {
    const cols = 24 / this.colSpan
    const start = cols * (rowIdx - 1)
    const end = start + cols
    if (end < this.filterConditions.length) {
      return this.filterConditions.slice(start, end)
    } else {
      return this.filterConditions.slice(start)
    }
  }

  private removeCondition(condition: FilterCondition) {
    let removingIndex = _.indexOf(this.filterConditions.map(wrapper => wrapper.condition), condition)
    if (removingIndex >= 0) {
      this.filterConditions?.splice(removingIndex, 1)
      this.$emit('remove-condition', condition)
    }
  }

  private addCondition() {
    this.filterConditions.push({
      uid: _.uniqueId(),
      condition: { filterType: FilterType.Custom },
      disabled: false
    })
  }
}
