



















import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { ErrorInfo, MdExtendData, MdTableColumn, MdTableData } from '@/module/graphql'
import { MdExtendDataDetailPanel } from '@/module/master-data/store'
import { t } from 'element-ui/lib/locale'
import _ from 'lodash'
import CmpMdTableDataInput from '@/module/master-data/views/table/cmp-md-table-data-input/index.vue'

@Component({
  components: {
    CmpMdTableDataInput
  }
})
export default class IcmpMdTableDataViewer extends Vue {
  @Prop({ required: true }) columns: MdTableColumn[]
  @Prop({ required: true }) tableData!: MdTableData

  private mVisible = true
}
