





import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import { showMdDataViewer } from '@/module/master-data/md-util'
import _ from 'lodash'

declare interface IExplorerMdDataCodeRendererComp extends ICellRendererParams {
  mdDataCodeField?: string,
}

@Component
export default class CmpMdDataCodeRenderer extends Vue implements ICellRendererComp {
  private params!: IExplorerMdDataCodeRendererComp
  private mValue: string
  private mMdDataCode: string

  created() {
    this.refresh(this.params)
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  refresh(params: IExplorerMdDataCodeRendererComp): boolean {
    this.mValue = params.value
    if (!params.data) return false
    this.mMdDataCode = params.mdDataCodeField ? _.get(params.data, params.mdDataCodeField) : params.value
    if (!this.mMdDataCode) {
      // TODO hardcode
      this.mMdDataCode = params.data?.entryData?.code
    }
    return true
  }

  onCodeClick() {
    showMdDataViewer(this, this.mMdDataCode)
  }
}
