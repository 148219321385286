







































import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator'
import {
  FilterType,
  MdUnit, MdUnitConversion
} from '@/module/graphql'
import gql from 'graphql-tag'
import { v4 as uuidv4 } from 'uuid'
import LolthEditableTable from '@/module/components/lolth-editable-table.vue'
import { submitMutation } from '@/module/common/util/graphql-util'

@Component
export default class CmpMdUnitConversionDialog extends Vue {
  @Ref() readonly vAllEditableTable: LolthEditableTable
  @Prop() readonly: boolean
  @Prop() mdUnitCode: string

  @PropSync('visible')
  syncedVisible!: boolean

  private mMdUnitConversions: MdUnitConversion[] = []

  created() {
    this.$apollo.query({
      query: gql`query MdUnitConversions($rowRequest: RowRequest!) {
          MdUnitConversions(rowRequest: $rowRequest) {
            rows {
              uid sourceUnit { code } targetUnit { code } factor
            }
          }
        }`,
      variables: {
        rowRequest: {
          filter: {
            filterType: FilterType.Or,
            conditions: [{
              filterType: FilterType.String,
              field: 'sourceUnit.code',
              operator: 'equals',
              value: this.mdUnitCode
            }, {
              filterType: FilterType.String,
              field: 'targetUnit.code',
              operator: 'equals',
              value: this.mdUnitCode
            }]
          }
        }
      }
    }).then(data => {
      this.mMdUnitConversions = data.data.MdUnitConversions.rows
      this.mMdUnitConversions.forEach(conversion => {
        if (conversion.targetUnit.code === this.mdUnitCode) {
          conversion.targetUnit = conversion.sourceUnit
          conversion.factor = 1 / conversion.factor
        }
      })
    })
  }

  initMdUnit(mdUnit: MdUnit) {
    if (!mdUnit.code) mdUnit.code = ''
    if (!mdUnit.code) mdUnit.note = ''
    return mdUnit
  }

  saveMdUnit() {
    let targetUnits: any = {}
    this.mMdUnitConversions.forEach(conversion => {
      if (conversion.targetUnit) {
        targetUnits[conversion.targetUnit.code] = conversion.factor ? conversion.factor : 1
      }
    })
    submitMutation(this, {
      mutationOptions: {
        mutation: gql`mutation saveMdUnitConversions($sourceUnit: ID!, $targetUnits: Map!) {
          saveMdUnitConversions(sourceUnit: $sourceUnit, targetUnits: $targetUnits)
        }`,
        variables: {
          sourceUnit: this.mdUnitCode,
          targetUnits: targetUnits
        }
      }
    }).then(() => {
      this.$message({
        message: '保存成功', type: 'success', showClose: true
      })
      this.$emit('saved')
      this.syncedVisible = false
    })
  }

  private onRowSaved(index:number, row:any, editingRow: any) {
    return true
  }

  addConversion() {
    this.vAllEditableTable.addRow({
      uid: uuidv4()
    })
  }
}
