import store from '@/store'
import util from '@/d2admin/libs/util'
import { DB_KEYS } from '@/module/common/local-db-dao'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { delegate } from '@/d2admin/delegate/index'
import AxiosDelegate = delegate.AxiosDelegate

// 创建一个错误
export function throwError(msg: string,
                           config: AxiosRequestConfig,
                           response?: AxiosResponse) {
  const error: AxiosError = {
    config,
    name: 'AxiosError',
    message: msg,
    isAxiosError: true,
    toJSON: function (this) { return this }
  }
  if (response) {
    error.code = response.status + ''
    error.response = response
  }
  logError(error)
  throw error
}

export function logError(error: AxiosError) {
  // 添加到日志
  store.dispatch('d2admin/log/push', {
    message: '数据请求异常',
    type: 'danger',
    meta: {
      error
    }
  })
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    util.log.danger('>>>>>> Error >>>>>>')
    console.error(error)
  }
}

export function translateHttpStatus(error: AxiosError): string {
  if (!error || !error.response) return '未知错误'
  switch (error.response.status) {
    case 400: return `请求错误. ${error.response.data?.message || ''}`
    case 401: return '未授权，请登录'
    case 403: return `拒绝访问. ${error.response.data?.message || ''}`
    case 404: return `请求地址出错: ${error.response.config.url}`
    case 408: return '请求超时'
    case 500: return `服务器内部错误. ${error.response.data?.message || ''}`
    case 501: return '服务未实现'
    case 502: return '网关错误'
    case 503: return '服务不可用'
    case 504: return '网关超时'
    case 505: return 'HTTP版本不受支持'
  }
  return '未知错误'
}

export class AxiosDelegateDefault implements AxiosDelegate {
  beforeRequest(config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> {
    // 让每个请求携带access-token
    const token = util.cookies.get('token')
    config.headers[DB_KEYS.DB_KEY_ACCESS_TOKEN] = token
    return config
  }

  onRequestError(error: AxiosError): void {
    logError(error)
  }

  beforeResponse(response: AxiosResponse): any {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data
    // 这个状态码是和后端约定的
    const { code } = dataAxios
    // 根据 code 进行判断
    if (code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
      return dataAxios
    } else {
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (code) {
        case 0:
          // [ 示例 ] code === 0 代表没有错误
          return dataAxios.data
        case 'xxx':
          // [ 示例 ] 其它和后台约定的 code
          throwError(`[ code: xxx ] ${dataAxios.msg}: ${response.config.url}`,
            response.config, response)
          break
        default:
          // 不是正确的 code
          throwError(`${dataAxios.msg}: ${response.config.url}`,
            response.config, response)
          break
      }
    }
  }

  onResponseError(error: AxiosError): any {
    error.message = translateHttpStatus(error)
    logError(error)
  }
}

let INSTANCE = new AxiosDelegateDefault()
export default {
  get() {
    return INSTANCE
  },
  set(delegate: AxiosDelegate) {
    INSTANCE = delegate
  }
}
