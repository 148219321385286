export interface FileService {
  resolveFileKey(value: string): string
  getUploadUrl(): string
  getThumbnailUrl(value: string): string
  getPreviewUrl(value: string): string
  getDownloadUrl(value: string): string
  uploadedFileToValue(file: UploadedFile): string
  valueToUploadedFile(value: string): UploadedFile
  getFileType(filename: string): FileType
  downloadFile(file: UploadedFile, filename: string): void
  upload?: (file: File) => Promise<string>
}

export enum FileType {
  IMAGE = 'image',
  PDF = 'pdf',
  DESIGN = 'design',
  WORD = 'word',
  EXCEL = 'excel',
  OTHER = 'other'
}

export interface UploadedFile extends File {
  key: string,
  specificFileService?: string,
  note?: string,
  url?: string,
  previewUrl?: string,
  downloadUrl?: string
}

export const FILE_KEY_SEPARATOR = '‡'

export const GV_FILE_DEFAULT_SVC_VENDOR = 'file_service_default_vendor'
export const GV_FILE_SVC_NGX_HOST = 'file_service_ngx_host'
export const GV_FILE_SVC_ATS_HOST = 'file_service_ats_host'
export const GV_FILE_SVC_TENCENT_COS_HOST = 'file_service_tencent_cos_host'
export const GV_FILE_SVC_TENCENT_COS_POLICY = 'file_service_tencent_cos_policy'
export const GV_FILE_SVC_TENCENT_COS_Q_AK = 'file_service_tencent_cos_q_ak'
export const GV_FILE_SVC_TENCENT_COS_Q_KEY_TIME = 'file_service_tencent_cos_q_key_time'
export const GV_FILE_SVC_TENCENT_COS_Q_SIGNATURE = 'file_service_tencent_cos_q_signature'
