



































































































































































export default function (Component) {
  Component.options.__source = "src/module/plugin-md-product-order/views/components/product-order/cmp-mdo-product-order-form.vue"
}
