















































import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator'
import { setupSortable } from '@/module/common/util/view-util'
import _ from 'lodash'
import util from '@/d2admin/libs/util'

@Component
export default class LolthEditableTable extends Vue {
  @Ref() readonly vTable: Vue

  @Prop() readonly readonly: boolean
  @Prop() readonly allEdit: boolean
  @Prop({ default: '对象' }) readonly entityName: string
  @Prop() readonly sortable: boolean
  @Prop({ default: true }) readonly showIndex: boolean
  @Prop({ default: 'uid' }) readonly rowKey: string
  @Prop({ default: '720' }) readonly maxHeight: string
  @Prop({ default: '100' }) readonly opColumnWidth: string

  @Prop({ required: false }) onRowSaved: (index:number, row:any, editingRow: any) => boolean
  @Prop({ default: (row: any) => _.cloneDeep(row) }) doCopyRow: (row:any) => any

  @Model('change', { required: true })
  readonly data: any

  private mEditingRow: any = null
  private mEditingRowIndex: number = null

  mounted() {
    if (this.sortable) {
      setupSortable(
        (this.vTable.$el as HTMLElement).querySelector('.el-table__body-wrapper tbody') as HTMLElement,
        this.onRowSeqChanged)
    }
  }

  private onRowSeqChanged(newSeq: number = -1, oldSeq: number = -1) {
    if (newSeq < 0 || oldSeq < 0 || oldSeq === newSeq) return
    const movingRow = this.data[oldSeq]

    this.data.splice(oldSeq, 1) &&
    this.data.splice(newSeq, 0, movingRow)
    this.$emit('seq-changed')
  }

  public get editing():boolean {
    return !!this.mEditingRow
  }

  public endRowEditing() {
    this.mEditingRow = null
    this.mEditingRowIndex = null
  }

  private resetEditing() {
    if (!this.mEditingRow) return
    if (!this.mEditingRow.id) {
      const addingRowIndex = _.indexOf(this.data, this.mEditingRow)
      if (addingRowIndex >= 0) {
        this.data.splice(addingRowIndex, 1)
      }
    }
    this.endRowEditing()
  }

  public addRow(newRow: any) {
    if (!this.allEdit) {
      if (this.mEditingRow) return
      this.mEditingRow = newRow
      this.mEditingRowIndex = this.data.length || 0
    }
    this.data.push(newRow)
  }

  editRow(index: number, row: any) {
    if (this.mEditingRow) return
    this.mEditingRow = util.objects.reactive(_.cloneDeep(row))
    this.mEditingRowIndex = _.indexOf(this.data, row)
  }

  copyRow(index: number, row: any) {
    this.addRow(this.doCopyRow(row))
  }

  deleteRow(index: number, row: any) {
    util.objects.remove(this.data, row)
    this.$emit('delete-row', row)
  }

  saveRow(index: number, row: any) {
    if (this.onRowSaved(index, row, this.mEditingRow)) {
      this.endRowEditing()
    }
  }
}
