




























































import { Component, Prop, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { buildGraphQLQueryPartInput } from '@/module/common/util/graphql-util'
import { MdDiagnosis, MutationIgnoreMdDiagnosisArgs, QueryMdDiagnosisArgs } from '@/module/graphql'

@Component
export default class LolthDiagnosisDialog extends Vue {
  @Prop() readonly targetModel!: string
  @Prop() readonly dataId!: string
  @Prop() readonly diagnosis?: MdDiagnosis
  @Prop() readonly permissionCode?: string

  private mVisible = true
  private mDiagnosis: MdDiagnosis | any = {}

  get diagState() {
    return this.$enum('VlDiagState', this.mDiagnosis.diagState)
  }

  created() {
    if (this.diagnosis) {
      this.mDiagnosis = this.diagnosis
      return
    }

    let queryVariables:QueryMdDiagnosisArgs = {
      targetModel: this.targetModel,
      dataId: this.dataId
    }

    this.$apollo.query({
      query: gql(`query {
        MdDiagnosis(${buildGraphQLQueryPartInput(queryVariables)}) {
            dataKey, firstDiagAt, lastDiagAt, lastFailedAt,
            diagState, details
        }
      }`)
    }).then(data => {
      this.mDiagnosis = data.data.MdDiagnosis || {}
    })
  }

  ignoreError(diagDetail: any) {
    let mutateVariables:MutationIgnoreMdDiagnosisArgs = {
      targetModel: this.targetModel,
      dataId: this.dataId,
      ruleName: diagDetail.ruleName
    }

    this.$apollo.mutate({
      mutation: gql(`mutation {
        ignoreMdDiagnosis(${buildGraphQLQueryPartInput(mutateVariables)}) {
          dataKey, firstDiagAt, lastDiagAt, lastFailedAt,
          diagState, details
        }
      }`)
    }).then(data => {
      this.mDiagnosis = data.data.mutation || {}
      this.$emitToGlobal('refresh-explorer')
    })
  }
}
