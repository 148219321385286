



































































































































export default function (Component) {
  Component.options.__source = "src/module/master-data/views/editor/icmp-md-data-editor-dialog.vue"
}
