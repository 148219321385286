












import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { ExplorerView } from '@/module/graphql'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'

@Component
export default class ExplorerViewSwitchWidget extends Vue {
  @Prop({ required: true }) view: ExplorerView
  @Prop({ required: true }) explorerViews: ExplorerView[]

  @Inject()
  readonly explorer: LolthExplorer

  get currentView() {
    return this.explorer.currentView
  }

  public attachToParent(parent: HTMLElement) {
    this.$el.classList.remove('hidden')
    parent.prepend(this.$el)
  }

  private onExplorerViewChanged(view: ExplorerView) {
    this.explorer.switchView(view)
  }
}
