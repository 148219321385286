












































import { Component, Prop, Vue } from 'vue-property-decorator'
import { FilterCondition, MdAttributeFragmentDoc, MdData, QueryMdDataArgs, FilterType } from '@/module/graphql'
import gql from 'graphql-tag'
import CmpMdDataBomViewer from './cmp-md-data-bom-viewer.vue'
import { buildGraphQLQueryPartInput, submitMutation } from '@/module/common/util/graphql-util'
import fileService from '@/module/common/file-service'
import { Data } from '@/d2admin/delegate'
import { IMAGE_FILE_TYPES } from '@/module/common/constants'
import { UploadedFile } from '@/module/common/file-service/types'
import PageMdTally from '@/module/master-data/views/tally/page-md-tally.vue'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import { GridOptions } from 'ag-grid-community'

@Component({
  components: {
    CmpMdDataBomViewer, PageMdTally
  }
})
export default class MdDataDialog extends Vue {
  @Prop() readonly mdDataId!: number
  @Prop() readonly mdDataCode!: string
  @Prop() readonly mdData!: MdData

  private visible = true

  private mMdData: Data<MdData> = {}
  private IMAGE_FILE_TYPES = Object.freeze(IMAGE_FILE_TYPES)
  private mTallyFixedFilterCondition: FilterCondition = undefined
  private mTallySurplusFixedFilterCondition: FilterCondition = undefined

  get code() {
    return this.mdDataCode || this.mdData?.code
  }

  created() {
    this.mTallyFixedFilterCondition = {
      filterType: FilterType.String,
      field: 'identity',
      operator: 'equals',
      value: this.mdDataCode
    }
    this.mTallySurplusFixedFilterCondition = {
      filterType: FilterType.String,
      field: '@\\$identity',
      operator: 'equals',
      value: this.mdDataCode
    }
    if (this.mdData) this.mMdData = this.mdData
    else this.loadData()
  }

  private extendGridOptions(explorer: LolthExplorer, gridOptions: GridOptions) {
    explorer.setContextParam('extraFieldName', 'info')
    explorer.setContextParam('extraQueryParameters', {
      topic: '库存'
    })
  }

  private loadData() {
    let queryVariables:QueryMdDataArgs
    if (this.mdDataId) {
      queryVariables = {
        id: this.mdDataId.toString()
      }
    } else if (this.mdDataCode) {
      queryVariables = {
        code: this.mdDataCode
      }
    } else return

    this.$apollo.query({
      query: gql`query fetchMdData($id: ID, $code: String) {
        MdData(id: $id, code: $code) {
          id, code, ver, createdAt, createdBy, updatedAt, updatedBy,
          field0, field1, field2, field3, field4,
          field5, field6, field7, field8, field9,
          mainPicture, pictures, tags, extra,
          attributes(mdFieldIds: null) {
            ...mdAttribute
          }
        }
      }
      ${MdAttributeFragmentDoc}`,
      variables: queryVariables
    }).then(data => {
      this.mMdData = data.data.MdData
      if (this.mMdData.mainPicture === undefined) this.mMdData.mainPicture = null
      if (!this.mMdData.pictures) this.mMdData.pictures = []
      return this.mMdData
    })
  }

  uploadedFileToValue(file: UploadedFile) {
    return fileService.getFileService().uploadedFileToValue(file)
  }

  savePictures() {
    submitMutation(this, {
      mutationOptions: {
        mutation: gql`mutation {
          saveMdDataPictures(
            mdDataId: "${this.mMdData.id}",
            mainPicture: "${this.mMdData.mainPicture || ''}",
            pictures: ${buildGraphQLQueryPartInput(this.mMdData.pictures, true)}
          ) { id ver }
        }`
      }
    }).then(data => {
      this.mMdData.ver = data.data.saveMdDataPictures.ver
    })
  }
}
