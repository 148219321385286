







import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ExplorerSelectionWidget extends Vue {
  @Prop({ required: true })
  selectedItems!: any[]
  @Prop()
  cellSum: number

  public attachToParent(parent: HTMLElement) {
    this.$el.classList.remove('hidden')
    parent.prepend(this.$el)
  }
}
