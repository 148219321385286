export default {
  namespaced: true,
  state: {
    // 全局loading状态
    loading: false,
    // loading栈0锁, startLoading+1, stopLoading-1, 只有归0时才取消loading状态
    retainCount: 0
  },
  mutations: {
    /**
     * @description 设置 登录加载状态
     * @param {Object} state state
     * @param {Boolean} text 加载状态文字
     */
    startLoading(state, text) {
      state.loading = true
      state.retainCount += 1
    },
    /**
     * @description 关闭 登录加载状态
     * @param {Object} state state
     */
    stopLoading(state) {
      state.retainCount -= 1
      if (state.retainCount < 0) state.retainCount = 0
      if (state.retainCount === 0) state.loading = false
    }
  }
}
