


























































import { Component } from 'vue-property-decorator'
import { FilterCondition, FilterType } from '@/module/graphql'
import { DatePickerOptions } from 'element-ui/types/date-picker'
import { formatDate } from 'element-ui/lib/utils/date-util'
import BaseFieldFilter from './base-field-filter'
import { buildDatePickerOption } from '@/module/common/util/view-util'

import util from '@/d2admin/libs/util'
import _ from 'lodash'

@Component
export default class DateFilter extends BaseFieldFilter {
  private mMode: 'empty' | 'picker' | 'range' | 'now' | 'period' = 'now'
  private mPickerOptions = buildDatePickerOption()
  private mRangePickerOptions: DatePickerOptions = {
    shortcuts: [{
      text: '最近一周',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '最近一个月',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '最近三个月',
      onClick(picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        picker.$emit('pick', [start, end])
      }
    }]
  }
  private operators = [
    'byDay', 'byWeek', 'byMonth', 'byQuarter', 'byYear', 'lt', 'gt', 'ltNow', 'gtNow', 'betweenDate', 'empty'
  ]

  get syncedValue() {
    if (!this.filterCondition.value) return null
    return this.$moment(this.filterCondition?.value).toDate()
  }

  set syncedValue(val: Date) {
    if (_.isNil(val)) this.filterCondition.value = null
    else {
      this.filterCondition.value = formatDate(val)
    }
  }

  get syncedRangeValue() {
    if (!this.filterCondition.value) return []
    return this.filterCondition.value.split(',')
      .map(v => formatDate(v))
  }

  set syncedRangeValue(val: Date[]) {
    if (_.isEmpty(val)) this.filterCondition.value = null
    else {
      this.filterCondition.value = val.map(v => formatDate(v)).join(',')
    }
  }

  get syncedIntervalValue() {
    const parts = (this.filterCondition?.value || ' ').split(' ')
    return parts.length > 0 ? _.toNumber(parts[0]) : 0
  }

  set syncedIntervalValue(val: number) {
    const parts = (this.filterCondition?.value || ' ').split(' ')
    const unit = parts.length > 1 ? parts[1] : 'day'
    this.filterCondition.value = val + ' ' + unit
  }

  get syncedIntervalUnit() {
    const parts = (this.filterCondition?.value || ' ').split(' ')
    return (parts.length > 1 && parts[1]) || 'day'
  }

  set syncedIntervalUnit(val: string) {
    const parts = (this.filterCondition?.value || ' ').split(' ')
    const interval = parts.length > 0 ? _.toNumber(parts[0]) : null
    this.filterCondition.value = interval + ' ' + val
  }

  get syncedPeriodValue() {
    return _.toNumber(this.filterCondition?.value) || 0
  }

  set syncedPeriodValue(val: number) {
    this.filterCondition.value = _.toString(val)
  }

  public initFilterCondition(filterCondition: FilterCondition) {
    if (!filterCondition.operator) filterCondition.operator = 'betweenDate'
    if (_.isNil(filterCondition.value)) filterCondition.value = null
    util.objects.reactive(filterCondition)
  }

  public updateFilterType(operator: string) {
    if (operator === 'empty') {
      this.filterCondition.filterType = FilterType.IsNull
      this.mMode = 'empty'
    } else {
      this.filterCondition.filterType = FilterType.Date
      this.mMode = (operator === 'lt' || operator === 'gt') ? 'picker'
        : (operator === 'betweenDate') ? 'range'
          : (operator === 'ltNow' || operator === 'gtNow') ? 'now'
            : (['byDay', 'byWeek', 'byMonth', 'byQuarter', 'byYear'].indexOf(operator) >= 0) ? 'period' : 'empty'
    }
  }

  onOperatorChanged(operator: string): void {
    this.updateFilterType(operator)
    this.filterCondition.value = null
    if (this.mMode === 'picker' && !this.syncedValue) return
    if (this.mMode === 'range' && !this.syncedRangeValue) return
    if (this.mMode === 'now') this.filterCondition.value = '0 day'
    if (this.mMode === 'period') this.filterCondition.value = '0'
    this.internalOnFilterChanged()
  }

  onValueChanged(val: Date): void {
    if (val) this.internalOnFilterChanged()
  }

  onRangeValueChanged(val: Date[]): void {
    if (!_.isEmpty(val)) this.internalOnFilterChanged()
  }

  clearContent() {
    this.filterCondition.operator = 'betweenDate'
    this.filterCondition.value = null
    this.internalOnFilterChanged()
  }
}
