








import { Component, ModelSync, Prop, Ref, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { ElInput } from 'element-ui/types/input'

@Component
export default class LolthInputPercentage extends Vue {
  @Ref() readonly vInput: ElInput & any

  @Prop() readonly size?: string
  @Prop() readonly disabled?: boolean
  @Prop({ default: '输入整数自动转换' }) readonly placeholder?: string;

  @ModelSync('value', 'change')
  syncValue: number

  private convertPercentage: Function

  get syncPercentage() {
    return this.disabled && !_.isNil(this.syncValue)
      ? `${(_.toNumber(this.syncValue) * 100).round(2)}%`
      : _.toString(this.syncValue)
  }

  set syncPercentage(val: string) {
    this.syncValue = (val as any)
    this.convertPercentage(val)
  }

  created() {
    const vue = this
    this.convertPercentage = _.debounce((val: string | number) => {
      let value: number = _.toNumber(val)
      if (Math.abs(value) <= 1 || !_.isNumber(value)) return
      vue.syncValue = value / 100
    }, 300)
  }

  public focus() {
    this.vInput.focus()
  }
}
