


















import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'
import { ErrorInfo } from '@/module/graphql'
import _ from 'lodash'
import util from '@/d2admin/libs/util'

@Component
export default class LolthInputExtra extends Vue {
  @Prop() readonly errorInfos: { [key: string]: ErrorInfo }

  @Model('change', { required: true })
  extra: { [key : string]: any }

  private mEntries: any[] = []

  created() {
    if (this.extra) this.mEntries = _.toPairs(this.extra)
  }

  @Watch('mEntries', { deep: true })
  onEntriesChanged() {
    util.objects.refill(_.fromPairs(this.mEntries), this.extra)
  }

  get convertedErrorInfos(): { [row: number]: ErrorInfo } {
    if (_.isEmpty(this.errorInfos)) return null
    const converted: { [row: number]: ErrorInfo } = {}
    _.keys(this.errorInfos).forEach(key => {
      const idx = _.indexOf(this.mEntries, (pair: any[]) => pair[0] === key)
      converted[idx] = this.errorInfos[key]
    })
    return converted
  }
}
