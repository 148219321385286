















import { Component, Ref, Vue } from 'vue-property-decorator'
import { GetContextMenuItemsParams, MenuItemDef, RowDoubleClickedEvent } from 'ag-grid-community'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import { deleteEntity } from '@/module/butler/butler-util'
import CmpMdUnitEditorDialog from '@/module/master-data/views/components/cmp-md-unit-editor-dialog.vue'
import CmpMdUnitConversionDialog from '@/module/master-data/views/components/cmp-md-unit-conversion-dialog.vue'
import { APP_REALM_CODE } from '@/module/common/constants'
import { checkMenuAccessibility } from '@/module/common/util/view-util'
import CmpMdInvoiceEditorDialog from '@/module/master-data/views/components/cmp-md-invoice-editor-dialog.vue'
import { FilterType } from '@/module/graphql'
import LocalDbDao from '@/module/common/local-db-dao'
import _ from 'lodash'

@Component({
  components: { CmpMdInvoiceEditorDialog, CmpMdUnitEditorDialog, CmpMdUnitConversionDialog }
})
export default class PageMdInvoice extends Vue {
  @Ref() readonly vExplorer: LolthExplorer

  private mInvoiceId: number = null
  private mInvoiceFileKey: string = null
  private mEditingDialogVisible: boolean = false
  private mFormReadonly: boolean = false

  created() {
    LocalDbDao.prefetchPermissions([
      'MdInvoiceEdit', 'MdInvoiceNew'
    ])
  }

  private getContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
    const vue = this
    return [{
      name: '新建',
      action() {
        vue.mEditingDialogVisible = true
        vue.mInvoiceId = null
        vue.mInvoiceFileKey = null
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requirePermissions: ['MdInvoiceEdit', 'MdInvoiceNew']
      })
    }, {
      name: '修改',
      action() {
        vue.mInvoiceId = params.node.data.id
        vue.mInvoiceFileKey = !_.isEmpty(params.node.data.files) ? params.node.data.files[0] : null
        vue.mEditingDialogVisible = true
        vue.mFormReadonly = false
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true,
        requirePermissionsOrOwner: ['MdInvoiceEdit']
      }, 'createdBy')
    }, {
      name: '删除',
      action() {
        deleteEntity({
          vue: vue,
          modelName: 'MdInvoice',
          dataIds: [params.node.data.id],
          confirmPrompt: `确认删除发票${params.node.data.code}?`,
          reloadAction: () => vue.vExplorer.refresh()
        })
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true,
        requirePermissionsOrOwner: ['MdInvoiceEdit']
      }, 'createdBy')
    }, {
      name: '相同文件发票',
      action() {
        vue.vExplorer.setExternalFilterCondition({
          filterType: FilterType.Array,
          field: 'files',
          operator: 'in',
          value: params.node.data.files[0]
        })
        vue.vExplorer.refresh()
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true,
        customCheckFunc: invoice => {
          return !invoice.files || invoice.files.length === 0
        }
      })
    }, {
      name: '清除查询条件',
      action() {
        vue.vExplorer.setExternalFilterCondition(null)
        vue.vExplorer.refresh()
      }
    }]
  }

  private onRowDoubleClicked(event: RowDoubleClickedEvent) {
    this.mInvoiceId = event.data.id
    this.mInvoiceFileKey = !_.isEmpty(event.data.files) ? event.data.files[0] : null
    this.mFormReadonly = true
    this.mEditingDialogVisible = true
  }

  private onSaved() {
    this.vExplorer.refresh()
  }
}
