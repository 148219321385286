















import { Component, Prop, Watch } from 'vue-property-decorator'
import { FilterType, FilterCondition } from '@/module/graphql'
import BaseFieldFilter from './base-field-filter'

import util from '@/d2admin/libs/util'
import _ from 'lodash'

@Component
export default class NullFilter extends BaseFieldFilter {
  @Prop({ required: true }) field: string;
  @Prop({ default: '未设置' }) yesLabel: string;
  @Prop({ default: '已设置' }) noLabel: string;

  get syncedValue() {
    if (!this.filterCondition?.field) return null
    return !this.filterCondition.not
  }

  set syncedValue(val: boolean | null) {
    if (_.isNil(val)) this.filterCondition.field = null
    else {
      this.filterCondition.field = this.field
      this.filterCondition.not = !val
    }
  }

  get isValid() {
    return !(!this.filterCondition.filterType || !this.filterCondition.field)
  }

  public initFilterCondition(filterCondition: FilterCondition) {
    filterCondition.filterType = FilterType.IsNull
    if (!filterCondition.field) filterCondition.field = null
    if (_.isNil(filterCondition.not)) filterCondition.not = false
    util.objects.reactive(filterCondition)
  }

  // bind value from floating filter
  @Watch('filterCondition.value')
  onValueChanged(value: boolean) {
    this.syncedValue = value
  }

  clearContent() {
    this.filterCondition.field = null
    this.internalOnFilterChanged()
  }
}
