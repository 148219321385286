






import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp } from 'ag-grid-community'
import { ICustomRendererCellRendererParams } from '@/module/components/lolth-explorer/supports'

export interface ColorContext {
  color0: string
  color1: string
  currentColor?: string
  currentGroupKey?: string
}

interface StripeBgRendererCellRendererParams extends ICustomRendererCellRendererParams {
  colorContext?: ColorContext
  groupKeyGetter?: (rowData: any) => string
}

@Component
export default class LolthStripeBgCellRenderer extends Vue implements ICellRendererComp {
  private params!: StripeBgRendererCellRendererParams
  private mValue: any = null
  private mData: any = null

  private cellStyle(rowData: any) {
    if (!this.params.colorContext || !this.params.groupKeyGetter) return ''
    const groupKey = this.params.groupKeyGetter(rowData)
    if (groupKey !== this.params.colorContext.currentGroupKey) {
      // switch group
      this.params.colorContext.currentGroupKey = groupKey
      this.params.colorContext.currentColor = this.params.colorContext.currentColor === this.params.colorContext.color0
        ? this.params.colorContext.color1 : this.params.colorContext.color0
    }
    return {
      'background-color': this.params.colorContext.currentColor
    }
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: StripeBgRendererCellRendererParams): boolean {
    this.mValue = params.value
    this.mData = params.data
    return true
  }
}
