

























import { Component, Prop, Vue } from 'vue-property-decorator'
import { MdAttributeFragmentDoc, MdAttributeInput, MdData, QueryMdDataArgs } from '@/module/graphql'
import LocalDbDao from '@/module/common/local-db-dao'
import { FixedField } from '@/module/master-data/types'
import gql from 'graphql-tag'

@Component
export default class CmpMdDataViewer extends Vue {
  @Prop({ required: true }) readonly mdData!: MdData
  @Prop() readonly attributes!: MdAttributeInput[]
  @Prop() readonly fetch!: boolean

  private mOpenedPanes = ['1', '2']
  private mFixedFields!: FixedField[]
  private mFetched = false

  get attrs() {
    return this.mFetched ? this.mdData.attributes
      : this.attributes || (this.mdData && this.mdData.attributes) || []
  }

  created(): void {
    if (this.fetch) this.loadData()
    this.mFixedFields = LocalDbDao.getMdDomainFixedFields()
  }

  private loadData() {
    let queryVariables:QueryMdDataArgs
    if (this.mdData.id) {
      queryVariables = {
        id: this.mdData.id
      }
    } else if (this.mdData.code) {
      queryVariables = {
        code: this.mdData.code
      }
    } else return

    this.$apollo.query({
      query: gql`query fetchMdData($id: ID, $code: String) {
        MdData(id: $id, code: $code) {
          id, code, ver, createdAt, createdBy, updatedAt, updatedBy,
          field0, field1, field2, field3, field4,
          field5, field6, field7, field8, field9,
          mainPicture, pictures, tags, extra,
          attributes(mdFieldIds: null) {
            ...mdAttribute
          }
        }
      }
      ${MdAttributeFragmentDoc}`,
      variables: queryVariables
    }).then(data => {
      Object.assign(this.mdData, data.data.MdData)
      this.mFetched = true
      return this.mdData
    })
  }
}
