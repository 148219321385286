import Vue from 'vue'
import axios from '@/d2admin/plugin/axios'
import gql from 'graphql-tag'
import { ID } from '@/module/common/types'
import qs from 'qs'
import LocalDbDao from '@/module/common/local-db-dao'
import { FilterCondition } from '@/module/graphql'
import _ from 'lodash'
import { FilterConditionView } from '@/module/components/lolth-filter/types'
import { APP_REALM_CODE } from '@/module/common/constants'

export type CountDownOption = {
  sendCaptchaBtnDisable: boolean,
  captchaCountDownText: string,
  timer?: any
}

export async function deleteEntity(params: {
    vue: Vue,
    realmCode?: string,
    modelName: string,
    dataIds: ID[],
    mutation?: string,
    confirmPrompt?: string,
    reloadAction?: Function
  }) {
  if (params.confirmPrompt) {
    await params.vue.$confirm(params.confirmPrompt, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
  }
  params.realmCode = APP_REALM_CODE // 历史原因, realmCode写列
  const mutation = params.mutation || 'delete' + params.modelName
  await params.vue.$apollo.mutate({
    mutation: gql(`mutation deleteEntity($ids: [ID!]!) {
      ${mutation}(ids: $ids)
    }`),
    variables: {
      ids: params.dataIds
    }
  })
  params.reloadAction && params.reloadAction()

  const explorerType = LocalDbDao.getExplorerType(params.modelName)
  if (!explorerType || !explorerType.deletable) return

  const h = params.vue.$createElement
  params.vue.$message({
    dangerouslyUseHTMLString: true,
    duration: 5000,
    showClose: true,
    type: 'success',
    message: h('span', null, [
      h('span', {
        class: 'text-success'
      }, '删除成功'),
      h('a', {
        style: 'margin-left: 200px;',
        class: 'el-link el-link--success',
        on: {
          click: () => {
            axios.post('/butler/recycle-bin/restore', qs.stringify({
              realmCode: params.realmCode,
              modelName: params.modelName,
              dataIds: params.dataIds
            }, { indices: false })).then(() => {
              params.vue.$message({
                message: '删除操作已撤销', type: 'success', showClose: true
              })
              params.reloadAction && params.reloadAction()
            })
          }
        }
      }, '撤销')
    ])
  })
}

export function countDownTimer(duration: number, countDownOption: CountDownOption) {
  countDownOption.sendCaptchaBtnDisable = true
  countDownOption.captchaCountDownText = '(' + duration + ')秒后重新获取'
  let set = setInterval(function () {
    countDownOption.captchaCountDownText = '(' + --duration + ')秒后重新获取'
  }, 1000)
  countDownOption.timer = set
  setTimeout(function () {
    countDownOption.sendCaptchaBtnDisable = false
    countDownOption.captchaCountDownText = '获取验证码'
    clearInterval(set)
  }, duration * 1000)
}

export function setCountDownTimer(countDownOption: CountDownOption, mobile: string) {
  let smsCaptchaStartTime = LocalDbDao.getCustomData('smsCaptchaStartTime-' + mobile)
  if (smsCaptchaStartTime && (new Date().getTime() - smsCaptchaStartTime) / 1000 < 120) {
    countDownTimer(120 - (Math.round((new Date().getTime() - smsCaptchaStartTime) / 1000)), countDownOption)
  } else {
    clearInterval(countDownOption.timer)
    countDownOption.sendCaptchaBtnDisable = false
    countDownOption.captchaCountDownText = '获取验证码'
  }
}

export function sendCaptchaSms(vue: Vue, mobile: string, countDownOption: CountDownOption) {
  axios.get('/auth/send-captcha', {
    params: {
      mobile: mobile
    }
  }).then(() => {
    LocalDbDao.saveCustomData('smsCaptchaStartTime-' + mobile, new Date().getTime())
    countDownTimer(120, countDownOption)
  })
}

export function stripFilterViewDef(filterCondition: FilterCondition) {
  if (!filterCondition) return
  if (_.has(filterCondition, 'filterViewDef')) {
    delete (filterCondition as FilterConditionView).filterViewDef
  }
  if (!_.isEmpty(filterCondition.conditions)) {
    filterCondition.conditions.forEach(subCondition => stripFilterViewDef(subCondition))
  }
  return filterCondition
}
