<template>
  <div class="page-login">
    <div class="page-login--layer page-login--layer-area">
      <ul class="circles">
        <li v-for="n in bgSquareCount" :key="n" :style="randomAniStyle(n)"></li>
      </ul>
    </div>
    <div
      class="page-login--layer page-login--layer-time"
      flex="main:center cross:center">
      {{time}}
    </div>
    <div class="page-login--layer">
      <div
        class="page-login--content"
        flex="dir:top main:justify cross:center box:justify">
        <div class="page-login--content-header">
          <p class="page-login--content-header-motto">
          </p>
        </div>
        <div
          class="page-login--content-main"
          flex="dir:top main:center cross:center">
          <slot />
        </div>
        <div class="page-login--content-footer">
          <p class="page-login--content-footer-copyright">
            <a href="https://beian.miit.gov.cn/">{{ icpNo }}</a> Copyright <d2-icon name="copyright"/> {{ copyright }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import _ from 'lodash'
import { GLOBAL_ENV } from '@/d2admin/setting'

export default {
  name: 'cmp-portal',

  data() {
    return {
      timeInterval: null,
      time: dayjs().format('HH:mm:ss'),

      bgSquareCount: 50,
      bgAniSize: [30, 100],
      bgAniDelay: [0, 40],
      bgAniDuration: [5, 20],
      bgSquareStyleCache: {}
    }
  },

  computed: {
    icpNo: () => GLOBAL_ENV.TEXT_ICP_NO,
    copyright: () => GLOBAL_ENV.TEXT_COPYRIGHT
  },

  async mounted() {
    this.timeInterval = setInterval(() => {
      this.refreshTime()
    }, 1000)
  },

  beforeDestroy() {
    clearInterval(this.timeInterval)
  },

  methods: {
    refreshTime() {
      this.time = dayjs().format('HH:mm:ss')
    },
    randomAniStyle(n) {
      if (this.bgSquareStyleCache[n]) return this.bgSquareStyleCache[n]

      const size = _.random(this.bgAniSize[0], this.bgAniSize[1])
      this.bgSquareStyleCache[n] = {
        left: _.random(100) + '%',
        width: size + 'px',
        height: size + 'px',
        'animation-delay': _.random(this.bgAniDelay[0], this.bgAniDelay[1]) + 's',
        'animation-duration': _.random(this.bgAniDuration[0], this.bgAniDuration[1]) + 's'
      }
      return this.bgSquareStyleCache[n]
    }
  }
}
</script>

<style lang="scss">
.page-login {
  user-select: none;
  $backgroundColor: -webkit-linear-gradient(90deg, #F0F2F5 0%, #87d2ff 100%);
  // ---
  background: $backgroundColor;
  height: 100%;
  position: relative;
  // 层
  .page-login--layer {
    @extend %full;
    overflow: auto;
  }
  .page-login--layer-area {
    overflow: hidden;
  }
  // 时间
  .page-login--layer-time {
    top: 70vh;
    font-size: 15em;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.07);
    overflow: hidden;
  }
  // 登陆页面控件的容器
  .page-login--content {
    height: 100%;
    min-height: 500px;
  }
  // header
  .page-login--content-header {
    padding: 1em 0;
    .page-login--content-header-motto {
      margin: 0px;
      padding: 0px;
      color: $color-text-normal;
      text-align: center;
      font-size: 12px;
      span {
        color: $color-text-sub;
      }
    }
  }
  // footer
  .page-login--content-footer {
    padding: 1em 0;
    .page-login--content-footer-options {
      padding: 0px;
      margin: 0px;
      margin-bottom: 10px;
      font-size: 14px;
      text-align: center;
      a {
        color: $color-text-normal;
        margin: 0 1em;
      }
    }
    .page-login--content-footer-copyright {
      padding: 0px;
      margin: 0px;
      font-size: 12px;
      color: $color-text-normal;
      a {
        color: $color-text-normal;
      }
    }
  }
  // 背景
  .circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    li {
      position: absolute;
      display: block;
      list-style: none;
      width: 20px;
      height: 20px;
      background: #22c4e920;
      animation: animate 25s linear infinite;
      bottom: -200px;
      @keyframes animate {
        0% {
          transform: translateY(0) rotate(0deg);
          opacity: 1;
          border-radius: 0;
        }
        100% {
          transform: translateY(-1000px) rotate(720deg);
          opacity: 0;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/module/butler/views/components/cmp-portal.vue"
}
</vue-filename-injector>
