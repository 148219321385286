














import { Component } from 'vue-property-decorator'
import BaseAttrInput from './base-attr-input'
import _ from 'lodash'

@Component
export default class AttrInputBoolean extends BaseAttrInput {
  get syncValue() {
    if (!_.isNil(this.attribute.value)) {
      return this.attribute.value
    } else {
      return false
    }
  }

  set syncValue(val: boolean) {
    if (this.attribute.value === val) return
    this.attribute.value = val
    this.$emit('attr-value-changed', this.attribute.value)
  }

  mounted() {
    this.setupBoolBindingFields()
  }

  updated() {
    this.setupBindingFields()
    this.setupBoolBindingFields()
  }

  private setupBoolBindingFields() {
    if (_.isNil(this.attribute.value)) {
      this.$set(this.attribute, 'value', _.isBoolean(this.attribute.value)
        ? this.attribute.value : false)
    }
  }
}
