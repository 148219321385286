export default [
  {
    path: '/mdc/settings',
    name: 'PageMdcSettings',
    meta: {
      title: '类别参数设置',
      auth: true
    },
    component: () => import('./views/page-mdc-settings')
  }
]
