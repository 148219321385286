




























































































export default function (Component) {
  Component.options.__source = "src/module/master-data/views/components/cmp-md-data-batch-diff-viewer-dialog.vue"
}
