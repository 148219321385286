






















































































import _ from 'lodash'
import { Component, Inject, Mixins, Model, Prop, Vue } from 'vue-property-decorator'
import { FilterCondition, FilterType, RowRequest, SortColumn } from '@/module/graphql'
import { ElFormItem } from 'element-ui/types/form-item'
import { ElForm } from 'element-ui/types/form'
import { t } from 'element-ui/lib/locale'
import { Indexed } from '@/d2admin/delegate'
import { addResizeListener, removeResizeListener } from 'element-ui/lib/utils/resize-event'
import gql from 'graphql-tag'
import LocalDbDao from '@/module/common/local-db-dao'
import LolthSelectReadonlyMask from '@/module/components/lolth-select-readonly-mask'
import { SelectionMode } from '@/module/common/types'

@Component
export default class LolthTypedInputChooser extends Mixins(LolthSelectReadonlyMask) {
  @Prop() readonly explorerTypeKey!: string
  @Prop({ default: SelectionMode.Single }) readonly selectionMode!: SelectionMode
  @Prop() readonly fixedFilterCondition?: FilterCondition
  @Prop() readonly defaultSort?: SortColumn[]

  @Prop() readonly id?: string
  @Prop() readonly name?: string
  @Prop() readonly size?: string
  @Prop({ default: true }) readonly clearable?: boolean
  @Prop() readonly itemLabelFormatter!: (item: any) => string
  @Prop({
    default: () => { return t('el.select.placeholder') }
  }) readonly placeholder!: string
  @Prop() readonly collapseTags?: boolean

  @Prop() readonly fetchPresetValue?: boolean
  @Prop() readonly presetValueFetcher?: (items: any[]) => any | Promise<any>

  @Model('change')
  value: any

  get syncValue(): any {
    return this.resolveValue(this.value)
  }

  set syncValue(val: any) {
    if (this.multiple) this.resetInputHeight()
    this.$emit('change', this.resolveValue(val))
  }

  get selectedArray() {
    if (_.isNil(this.value)) return []
    if (this.multiple) {
      if (!_.isArray(this.value)) {
        throw new Error('v-model should be array')
      }
      return this.value
    } else {
      if (_.isArray(this.value)) {
        throw new Error('v-model should not be array')
      }
      return [this.value]
    }
  }

  @Inject({ default: {} }) readonly elForm!: ElForm | any
  @Inject({ default: {} }) readonly elFormItem!: ElFormItem | any

  private mDialogVisible: boolean = false
  private mInputWidth = 0
  private mInitialInputHeight = 0
  private mInputHovering = false

  get _elFormItemSize() {
    return this.elFormItem?.elFormItemSize
  }

  get selectSize() {
    return this.size || this._elFormItemSize || ((this as any).$ELEMENT || {}).size
  }

  get selectDisabled() {
    return this.disabled || (this.elForm || {}).disabled
  }

  get showClose() {
    let hasValue = _.isArray(this.selectedArray) && this.selectedArray.length > 0
    return this.clearable &&
      !this.selectDisabled &&
      this.mInputHovering &&
      hasValue
  }

  get selectedLabel() {
    if (this.selectedArray.length <= 0 || this.multiple) return ''
    return this.formatItemLabel(this.selectedArray[0])
  }

  get placeholderProxy() {
    if (this.multiple && this.selectedArray.length > 0) return ''
    return this.placeholder
  }

  get collapseTagSize() {
    return ['small', 'mini'].indexOf(this.selectSize) > -1
      ? 'mini' : 'small'
  }

  get multiple() {
    return this.selectionMode === SelectionMode.Multiple
  }

  get explorerType() {
    return LocalDbDao.getExplorerType(this.explorerTypeKey)
  }

  created() {
    this.doFetchPresetValue()
  }

  updated() {
    this.doFetchPresetValue()
  }

  mounted() {
    addResizeListener(this.$el, this.handleResize)

    if (this.elSelect && this.elSelect.$el) {
      const sizeMap: Indexed = {
        medium: 36,
        small: 32,
        mini: 28
      }
      const input = this.elSelect.$el.querySelector('input')
      this.mInitialInputHeight = input?.getBoundingClientRect().height || sizeMap[this.selectSize]
    }
    if (this.multiple) this.resetInputHeight()
    this.$nextTick(() => {
      if (this.elSelect && this.elSelect.$el) {
        this.mInputWidth = this.elSelect.$el.getBoundingClientRect().width
      }
    })
  }

  beforeDestroy() {
    if (this.$el && this.handleResize) removeResizeListener(this.$el, this.handleResize)
  }

  private doFetchPresetValue() {
    if (this.multiple && this.selectedArray.length > 0) {
      if (this.formatItemLabel(this.selectedArray[0])) {
        this.resetInputHeight()
        return
      }
    } else {
      if (this.selectedLabel) return
    }

    if (!_.isEmpty(this.selectedArray) && this.fetchPresetValue) {
      const remoteValueFetcher = this.presetValueFetcher || this.defaultRemoteValueLoader
      Promise.resolve(remoteValueFetcher(this.selectedArray))
        .then(remoteValues => {
          this.syncValue = remoteValues
        })
    }
  }

  private resolveValue(val: any) {
    let convertedVal: any
    if (!val) convertedVal = val
    else {
      if (this.multiple) { // 多选, this.value应该是array
        convertedVal = _.isArray(val) ? val : [val]
      } else { // 单选, this.value应该是object
        if (_.isArray(val)) {
          convertedVal = (val.length > 0 && val[0]) ? val[0] : null
        } else {
          convertedVal = val
        }
      }
    }
    return convertedVal
  }

  private onSelect(selectedData: any[]) {
    this.syncValue = selectedData
    this.$nextTick(() => {
      this.$emit('select', selectedData)
    })
  }

  private formatItemLabel(item: any) {
    return this.itemLabelFormatter
      ? this.itemLabelFormatter(item)
      : this.explorerType.formatLabel(item)
  }

  private getItemKey(item: any) {
    return _.isPlainObject(item) ? (item.id || item.code || item.key || item.dataId) : item
  }

  private showDialog() {
    if (!this.selectDisabled) {
      this.mDialogVisible = true
    }
  }

  private clearSelected(event: Event) {
    event.stopPropagation()
    this.syncValue = []
    this.$emit('clear')
  }

  private deleteTag(event: Event, item: any) {
    event.stopPropagation()
    const clonedArray = [...this.selectedArray]
    let index = clonedArray.indexOf(item)
    if (index > -1 && !this.selectDisabled) {
      clonedArray.splice(index, 1)
      this.syncValue = clonedArray
      this.$emit('remove-tag', item)
    }
  }

  private handleResize() {
    this.resetInputWidth()
    if (this.multiple) this.resetInputHeight()
  }

  private resetInputWidth() {
    this.mInputWidth = (this.elSelect as Vue).$el.getBoundingClientRect().width
  }

  private resetInputHeight() {
    if (this.collapseTags) return
    this.$nextTick(() => {
      if (!this.elSelect) return
      let inputChildNodes = (this.elSelect as Vue).$el.childNodes
      let input: any = [].filter.call(inputChildNodes, (item: Element) => item.tagName === 'INPUT')[0]
      const tags = this.$refs.tags as Element
      const sizeInMap = this.mInitialInputHeight || 40
      input.style.height = this.selectedArray.length === 0
        ? sizeInMap + 'px'
        : Math.max(
          tags ? (tags.clientHeight + (tags.clientHeight > sizeInMap ? 6 : 0)) : 0,
          sizeInMap
        ) + 'px'
    })
  }

  private defaultRemoteValueLoader(items: any[]) {
    const fields: string = this.explorerType.graphQLFetchFields()
    const rowRequest: RowRequest = {
      filter: {
        filterType: FilterType.InIds,
        value: items.map(item => { return item.id || item.code }).join(',')
      }
    }

    return this.$apollo.query({
      query: gql(`query ExplorerFetchOne($rowRequest: RowRequest!) {
        ${this.explorerType.queryType}(rowRequest: $rowRequest) {
          rows { ${fields} }
        }
      }`),
      variables: {
        rowRequest
      }
    }).then(data => {
      const remoteValues = data.data[this.explorerType.queryType].rows
      if (this.multiple) return remoteValues
      else if (remoteValues && remoteValues.length > 0) return remoteValues[0]
      return null
    })
  }
}
