






























import { Component } from 'vue-property-decorator'
import BaseInput from './base-input'
import CmpMdTableDataInputNumber
  from '@/module/master-data/views/table/cmp-md-table-data-input/md-table-input-number.vue'
import CmpMdTableDataInputText from '@/module/master-data/views/table/cmp-md-table-data-input/md-table-input-text.vue'
import CmpMdTableDataInputBoolean from './md-table-input-boolean.vue'
import CmpMdTableDataInputAttachment
  from '@/module/master-data/views/table/cmp-md-table-data-input/md-table-input-attachment.vue'
import CmpMdTableDataInputDate from '@/module/master-data/views/table/cmp-md-table-data-input/md-table-input-date.vue'
import CmpMdTableDataInputDict from '@/module/master-data/views/table/cmp-md-table-data-input/md-table-input-dict.vue'
import { ElInput } from 'element-ui/types/input'
import CmpMdTableDataInputMultiDict
  from '@/module/master-data/views/table/cmp-md-table-data-input/md-table-input-multi-dict.vue'
import CmpMdTableDataInputExtend
  from '@/module/master-data/views/table/cmp-md-table-data-input/md-table-input-extend.vue'

@Component({
  components: {
    CmpMdTableDataInputText,
    CmpMdTableDataInputNumber,
    CmpMdTableDataInputBoolean,
    CmpMdTableDataInputDate,
    CmpMdTableDataInputAttachment,
    CmpMdTableDataInputDict,
    CmpMdTableDataInputMultiDict,
    CmpMdTableDataInputExtend
  }
})
export default class CmpMdTableDataInput extends BaseInput<any> {
  get vInput() {
    switch (this.dataType) {
      case '文字': return this.$refs.vInputText
      case '数字': return this.$refs.vInputNumber
      case '是否': return this.$refs.vInputBoolean
      case '日期': return this.$refs.vInputDate
      case '附件': return this.$refs.vInputAttachment
      case '选项表': return this.$refs.vInputDict
      case '多选选项表': return this.$refs.vInputMultiDict
      case '扩展表': return this.$refs.vInputExtend
    }
  }

  public focus() {
    (this.vInput as ElInput).focus()
  }
}
