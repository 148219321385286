import util from '@/d2admin/libs/util'

export default {
  data() {
    return {
      ctrlHold: false
    }
  },
  created() {
    window.addEventListener('keyup', e => {
      this.ctrlHold = e.ctrlKey || e.metaKey
    })
    window.addEventListener('keydown', e => {
      this.ctrlHold = e.ctrlKey || e.metaKey
    })
  },
  methods: {
    handleMenuSelect(index, indexPath) {
      if (this.ctrlHold || /^https:\/\/|http:\/\//.test(index)) {
        util.views.openNewPage(index)
      } else if (/^d2-menu-empty-\d+$/.test(index) || index === undefined) {
        this.$message.warning('临时菜单')
      } else {
        util.views.openTab(this, {
          path: index
        })
      }
    }
  }
}
