



















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  FilterType,
  MdAttributeFragmentDoc,
  MdField,
  MdoPackingOption,
  MdoPackingOptionFragmentDoc,
  MutationInsertMdoPackingOptionArgs,
  MutationUpdateMdoPackingOptionArgs
} from '@/module/graphql'
import gql from 'graphql-tag'
import { buildGraphQLQueryPartInput, submitMutation } from '@/module/common/util/graphql-util'
import _ from 'lodash'
import LocalDbDao from '@/module/common/local-db-dao'
import { fetchMdFields, filterEmptyAttr } from '@/module/master-data/md-util'
import CmpMdAttrForm from '@/module/master-data/views/editor/cmp-md-attr-form.vue'
import util from '@/d2admin/libs/util'
import { MDO_REALM_CODE } from '@/module/common/constants'

@Component({
  components: { CmpMdAttrForm }
})
export default class CmpMdoPackingOptionFormDialog extends Vue {
  @Prop() readonly mode: 'new' | 'edit' | 'copy' | 'readonly'
  @Prop() readonly inboundCode: string
  @Prop() readonly productOrderCode: number
  @Prop() readonly packingOption: MdoPackingOption
  @Prop() readonly packingOptionId: number

  private mVisible: boolean = true

  private mPackingOption: MdoPackingOption = this.initPackingOption()
  private mPackingFields: MdField[] = []
  private mProductFields: MdField[] = []
  private mInnerBoxFields: MdField[] = []
  private mOuterBoxFields: MdField[] = []
  private mTitle: string = ''
  private mReadonly:boolean = false

  private mPackingFieldFilterCondition = {
    filterType: FilterType.InIds,
    value: LocalDbDao.getSysSettingValue(MDO_REALM_CODE, 'mdo_packing_option_packing_fields')?.join(',') || ''
  }
  private mProductFieldFilterCondition = {
    filterType: FilterType.InIds,
    value: LocalDbDao.getSysSettingValue(MDO_REALM_CODE, 'mdo_packing_option_product_fields')?.join(',') || ''
  }

  get innerBoxSize() {
    return null
  }

  set innerBoxSize(value: string) {
    const parts = value.split('*')
    if (parts.length !== 3) return
    this.$set(this.mPackingOption, 'innerBoxLength', _.toNumber(parts[0]))
    this.$set(this.mPackingOption, 'innerBoxWidth', _.toNumber(parts[1]))
    this.$set(this.mPackingOption, 'innerBoxHeight', _.toNumber(parts[2]))
  }

  get outerBoxSize() {
    return null
  }

  set outerBoxSize(value: string) {
    const parts = value.split('*')
    if (parts.length !== 3) return
    this.mPackingOption.outerBoxLength = _.toNumber(parts[0])
    this.mPackingOption.outerBoxWidth = _.toNumber(parts[1])
    this.mPackingOption.outerBoxHeight = _.toNumber(parts[2])
  }

  created() {
    if (this.mode === 'new') {
      if (this.packingOption) {
        this.mPackingOption = _.cloneDeep(this.packingOption)
      } else {
        this.mPackingOption = this.initPackingOption()
      }
      this.mTitle = '新增装箱方式'
      this.initAttributes()
    } else if (this.mode === 'edit') {
      this.findOnePackingOption(this.packingOptionId).then(packingOption => {
        this.mPackingOption = packingOption
        this.mTitle = '编辑装箱方式'
        this.initAttributes()
      })
    } else if (this.mode === 'copy') {
      this.findOnePackingOption(this.packingOptionId).then(packingOption => {
        packingOption.id = 0
        this.mPackingOption = packingOption
        this.mTitle = '复制装箱方式'
        this.initAttributes()
      })
    } else {
      this.findOnePackingOption(this.packingOptionId).then(packingOption => {
        this.mReadonly = true
        this.mPackingOption = packingOption
        this.mTitle = '装箱方式详情'
        this.initAttributes()
      })
    }

    fetchMdFields(this, LocalDbDao.getSysSettingValue(MDO_REALM_CODE, 'mdo_packing_option_inner_box_fields'))
      .then(mdField => { this.mInnerBoxFields = mdField })
    fetchMdFields(this, LocalDbDao.getSysSettingValue(MDO_REALM_CODE, 'mdo_packing_option_outer_box_fields'))
      .then(mdField => { this.mOuterBoxFields = mdField })
  }

  initAttributes() {
    if (!this.mPackingOption.innerBoxAttributes) {
      this.mPackingOption.innerBoxAttributes = []
    }
    if (!this.mPackingOption.outerBoxAttributes) {
      this.mPackingOption.outerBoxAttributes = []
    }
    if (!this.mPackingOption.packingAttributes) {
      this.mPackingOption.packingAttributes = []
    } else {
      this.mPackingFields = this.mPackingOption.packingAttributes.map(attr => attr.field)
    }
    if (!this.mPackingOption.productAttributes) {
      this.mPackingOption.productAttributes = []
    } else {
      this.mProductFields = this.mPackingOption.productAttributes.map(attr => attr.field)
    }
    if (!_.has(this.mPackingOption, 'innerHorizontalSeparatorQty')) {
      this.mPackingOption.innerHorizontalSeparatorQty = null
    }
    if (!_.has(this.mPackingOption, 'horizontalSeparatorQty')) {
      this.mPackingOption.horizontalSeparatorQty = null
    }
    util.objects.reactive(this.mPackingOption)
  }

  findOnePackingOption(packingOptionId: number) {
    // noinspection GraphQLUnexpectedType
    return this.$apollo.query({
      query: gql`query {
        MdoPackingOption(id: ${packingOptionId}) {
          id, ver, name, note, needInnerBox,
          innerBoxQty, innerBoxLength, innerBoxWidth, innerBoxHeight,
          outerBoxQty, outerBoxLength, outerBoxWidth, outerBoxHeight,
          grossWeight, netWeight customer { name code } horizontalSeparator horizontalSeparatorQty
          innerHorizontalSeparator innerHorizontalSeparatorQty
          packingAttributes { ...mdAttribute }, productAttributes { ...mdAttribute },
          innerBoxAttributes { ...mdAttribute }, outerBoxAttributes { ...mdAttribute }
        }
      }
      ${MdAttributeFragmentDoc} ${MdAttributeFragmentDoc}${MdAttributeFragmentDoc}${MdAttributeFragmentDoc}`
    }).then(data => {
      return data.data.MdoPackingOption
    })
  }

  generateName() {
    if (!this.mPackingOption.needInnerBox) {
      this.mPackingOption.name = this.mPackingOption.outerBoxQty ? this.mPackingOption.outerBoxQty.toString() : null
    } else {
      this.mPackingOption.name = this.mPackingOption.innerBoxQty && this.mPackingOption.outerBoxQty
        ? this.mPackingOption.innerBoxQty + '*' + _.toInteger(this.mPackingOption.outerBoxQty / this.mPackingOption.innerBoxQty) + '/' + this.mPackingOption.outerBoxQty.toString() : null
    }
  }

  onNeedInnerBoxChange() {
    if (!this.mPackingOption.needInnerBox) {
      this.mPackingOption.innerBoxQty = null
      this.mPackingOption.innerBoxLength = null
      this.mPackingOption.innerBoxWidth = null
      this.mPackingOption.innerBoxHeight = null
      this.mPackingOption.innerHorizontalSeparator = false
      this.mPackingOption.innerHorizontalSeparatorQty = 0
      this.mPackingOption.innerBoxAttributes.forEach(attr => {
        attr.value = null
      })
    }
    this.generateName()
  }

  onInnerHorizontalSeparatorChange() {
    if (this.mPackingOption.innerHorizontalSeparator) {
      this.mPackingOption.innerHorizontalSeparatorQty = 1
    } else {
      this.mPackingOption.innerHorizontalSeparatorQty = 0
    }
  }

  onHorizontalSeparatorChange() {
    if (this.mPackingOption.horizontalSeparator) {
      this.mPackingOption.horizontalSeparatorQty = 1
    } else {
      this.mPackingOption.horizontalSeparatorQty = 0
    }
  }

  initPackingOption(packingOption: MdoPackingOption | any = {
    id: '',
    name: '',
    customer: { code: '', name: '', fullName: '' },
    needInnerBox: false,
    innerBoxQty: null,
    innerBoxLength: null,
    innerBoxWidth: null,
    innerBoxHeight: null,
    outerBoxQty: null,
    outerBoxLength: null,
    outerBoxWidth: null,
    outerBoxHeight: null,
    grossWeight: null,
    netWeight: null,
    packingAttributes: [],
    productAttributes: [],
    innerBoxAttributes: [],
    outerBoxAttributes: [],
    note: '',
    horizontalSeparator: false,
    horizontalSeparatorQty: null,
    innerHorizontalSeparator: false,
    innerHorizontalSeparatorQty: null
  }) {
    return packingOption
  }

  savePackingOption() {
    let mutation:string
    let postValues: any = {
      name: this.mPackingOption.name ? this.mPackingOption.name : '',
      customer: this.mPackingOption.customer ? this.mPackingOption.customer.code : null,
      note: this.mPackingOption.note,
      needInnerBox: this.mPackingOption.needInnerBox,
      innerBoxQty: this.mPackingOption.innerBoxQty,
      innerBoxLength: this.mPackingOption.innerBoxLength,
      innerBoxWidth: this.mPackingOption.innerBoxWidth,
      innerBoxHeight: this.mPackingOption.innerBoxHeight,
      outerBoxQty: this.mPackingOption.outerBoxQty ? this.mPackingOption.outerBoxQty : null,
      outerBoxLength: this.mPackingOption.outerBoxLength,
      outerBoxWidth: this.mPackingOption.outerBoxWidth,
      outerBoxHeight: this.mPackingOption.outerBoxHeight,
      grossWeight: this.mPackingOption.grossWeight,
      netWeight: this.mPackingOption.netWeight,
      horizontalSeparator: this.mPackingOption.horizontalSeparator,
      horizontalSeparatorQty: this.mPackingOption.horizontalSeparatorQty,
      innerHorizontalSeparator: this.mPackingOption.innerHorizontalSeparator,
      innerHorizontalSeparatorQty: this.mPackingOption.innerHorizontalSeparatorQty,
      packingAttributes: filterEmptyAttr(this.mPackingOption.packingAttributes),
      productAttributes: filterEmptyAttr(this.mPackingOption.productAttributes),
      innerBoxAttributes: filterEmptyAttr(this.mPackingOption.innerBoxAttributes),
      outerBoxAttributes: filterEmptyAttr(this.mPackingOption.outerBoxAttributes)
    }
    if (this.inboundCode) {
      submitMutation(this, {
        mutationOptions: {
          mutation: gql(`mutation {
            saveMdoPackingOption(${buildGraphQLQueryPartInput({ ...postValues })}) { uid @contextual(name: "packingOptionUid")}
            insertPackingOptionInboundRecord(${buildGraphQLQueryPartInput({ packingOptionUid: '@packingOptionUid', inboundCode: this.inboundCode })})
          }`)
        }
      }).then(data => {
        this.$message({
          message: '迁移成功', type: 'success', showClose: true
        })
        this.mVisible = false
        this.$emit('submit', this.mPackingOption)
      })
    } else {
      let mutationArgs: MutationInsertMdoPackingOptionArgs | MutationUpdateMdoPackingOptionArgs
      if (this.mPackingOption.id) {
        mutation = 'updateMdoPackingOption'
        delete postValues.code
        mutationArgs = {
          id: this.mPackingOption.id,
          ver: this.mPackingOption.ver,
          values: {
            ...postValues
          }
        }
      } else {
        mutation = 'insertMdoPackingOption'
        mutationArgs = {
          ...postValues
        }
      }
      const mutationOptions: any = {
        mutation: gql`mutation {
          ${mutation}(${buildGraphQLQueryPartInput(mutationArgs)}) {
            ... mdoPackingOption
          }
        }
        ${MdoPackingOptionFragmentDoc}`
      }
      submitMutation(this, {
        mutationOptions,
        fullValidate: true
      }).then(data => {
        this.$message({
          message: '保存成功', type: 'success', showClose: true
        })
        this.mVisible = false
        this.$emit('submit', data.data[mutation])
      })
    }
  }
}
