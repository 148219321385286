<template>
  <p></p>
</template>
<script>

import axios from '@/d2admin/plugin/axios/index'
import LocalDbDao from '@/module/common/local-db-dao'
import { UserNotApprovedError } from '@/module/butler/hook'
import { mapActions } from 'vuex'
import { Loading } from 'element-ui'

export default {
  data() {
    return {
      state: 'state='
    }
  },

  async created() {
    let loadingInstance = Loading.service({
      text: '登录中...'
    })
    const ewxUserInfo = await axios.get('/auth/ewx-user-info?code=' + this.$route.query.code)
    this.login({
      ewxUserId: ewxUserInfo.ewxUserId,
      ewxUserMobile: ewxUserInfo.ewxUserMobile,
      byEwx: true
    }).then(() => {
      // 重定向对象不存在则返回顶层路径
      this.$router.replace(this.$route.query.redirect || '/')
      loadingInstance.close()
    }).catch(error => {
      loadingInstance.close()
      if (error instanceof UserNotApprovedError) {
        let principal = LocalDbDao.getPrincipal()
        if (!principal.rejectReason) {
          this.$alert('您的加入申请尚未通过, 请联系系统管理员', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              this.$store.dispatch('d2admin/account/logout', { confirm: false })
            }
          })
        } else {
          this.$confirm('您的加入申请未通过审批, 原因:`' + principal.rejectReason + '`<br/>' +
              '是否重新提交加入申请?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
            type: 'warning'
          }).then(() => {
            this.$router.push({ name: 'sign-up', params: { accountId: principal.identity } })
          }).catch(() => {
            this.$store.dispatch('d2admin/account/logout', { confirm: false })
          })
        }
      } else {
        this.$confirm('企业微信尚未关联账户', '提示', {
          confirmButtonText: '关联已有账户',
          cancelButtonText: '注册新账户',
          dangerouslyUseHTMLString: true,
          type: 'warning'
        }).then(() => {
          this.$router.push({ name: 'login', params: ewxUserInfo })
        }).catch(() => {
          this.$router.push({ name: 'sign-up', params: ewxUserInfo })
        })
      }
    })
  },
  methods: {
    ...mapActions('d2admin/account', [
      'login'
    ])
  }
}
</script>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/module/butler/views/page-ewx-sign-in.vue"
}
</vue-filename-injector>
