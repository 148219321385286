










import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import CmpMdoBoxingSheetEntryDialog
  from '@/module/plugin-md-product-order/views/components/boxing/cmp-mdo-boxing-sheet-entry-dialog.vue'
import { FilterCondition, FilterType } from '@/module/graphql'

declare interface IExplorerListItemCountCellRendererComp extends ICellRendererParams {
  onClick?: (list: []) => {}
}

@Component({
  components: { CmpMdoBoxingSheetEntryDialog }
})
export default class CmpUndeliveredBoxQtyCellRenderer extends Vue implements ICellRendererComp {
  private params!: IExplorerListItemCountCellRendererComp
  private mValue: any = null
  private mBoxingSheetEntryDialogVisible = false
  private mFixedFilterCondition: FilterCondition = null

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: IExplorerListItemCountCellRendererComp): boolean {
    this.mValue = params.value
    return true
  }

  private onClick(data: any) {
    this.mFixedFilterCondition = {
      filterType: FilterType.String,
      field: 'sheet.code',
      operator: 'equals',
      value: this.params.data.code
    }
    this.mBoxingSheetEntryDialogVisible = true
  }
}
