



























export default function (Component) {
  Component.options.__source = "src/d2admin/components/d2-container/components/d2-container-ghost-bs.vue"
}
