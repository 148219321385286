























import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'

export type DataResult = {
  rows: any,
  total?: number
}

export type FetchDataOption = {
  currentPage: number
  pageSize: number
  fetchTotal?: boolean
}

@Component
export default class LolthPaginationGrid extends Vue {
  @Prop({ default: 20 }) pageSize: number
  @Prop({ default: '没有符合条件的数据' }) readonly emptyText: string
  @Prop({ required: true }) dataFetcher: (option: FetchDataOption) => Promise<DataResult>
  @Prop({ default: 'id' }) readonly valueKey!: string

  private mCurrentPageData: any = null // 需要null来判断初次加载状态
  private mLoading = false
  private mCurrentPage = 1
  private mPageSize = 30
  private mTotal: number = null

  created() {
    this.mPageSize = this.pageSize
  }

  mounted() {
    this.loadData()
  }

  get currentPageData() {
    return this.mCurrentPageData || []
  }

  onSizeChange(size: number) {
    this.mPageSize = size
    this.loadData(1)
  }

  onCurrentChange(currentPage: number) {
    this.loadData(currentPage)
  }

  public loadData(page: number = null, fetchTotal = false) {
    if (!_.isNil(page)) this.mCurrentPage = page

    this.dataFetcher({
      currentPage: this.mCurrentPage,
      pageSize: this.pageSize,
      fetchTotal: _.isNil(this.mTotal) || fetchTotal
    }).then(({ rows, total }) => {
      this.mCurrentPageData = rows
      if (!_.isNil(total)) this.mTotal = total
      this.mLoading = false
      this.$nextTick(() => {
        this.$el.querySelector('.grid-layout').scrollIntoView()
      })
    })
  }
}
