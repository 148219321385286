import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { ElSelect } from 'element-ui/types/select'
import { addResizeListener, removeResizeListener } from 'element-ui/lib/utils/resize-event'

@Component
export default class LolthSelectReadonlyMask extends Vue {
  @Ref() readonly elSelect: ElSelect | any

  @Prop() readonly disabled?: boolean

  private mSelectWidth: number = 0
  private mSelectHeight: number = 0

  mounted() {
    addResizeListener(this.elSelect.$el, this.onInputResize)
  }

  beforeDestroy() {
    if (this.elSelect.$el) removeResizeListener(this.elSelect.$el, this.onInputResize)
  }

  private onInputResize() {
    this.mSelectWidth = this.elSelect.$el.clientWidth
    this.mSelectHeight = this.elSelect.$el.clientHeight
  }
}
