





















import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator'
import { promptServerError } from '@/module/common/util/error-util'
import _ from 'lodash'
import { FILE_KEY_SEPARATOR, UploadedFile } from '@/module/common/file-service/types'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import FileService from '@/module/common/file-service'
import json5 from 'json5'

@Component
export default class LolthInputFile extends Vue {
  @Ref() readonly elPasteInput: HTMLInputElement
  @Ref() readonly elFileInput: HTMLInputElement

  @PropSync('visible')
  syncedVisible!: boolean

  @Prop() readonly accept?: string
  @Prop() readonly limit?: number
  @Prop() readonly vendor?: string
  @Prop() readonly fileSizeLimit?: number
  @Prop({ default: true }) readonly pasteModalAppendToBody: boolean
  @Prop({ default: true }) readonly multiple: boolean

  private mLoading = false

  get fileService() {
    return FileService.getFileService(this.vendor)
  }

  mounted() {
    this.$nextTick(() => {
      this.elPasteInput.focus()
      this.elPasteInput.addEventListener('paste', this.handlePaste)
    })
  }

  beforeDestroy() {
    this.elPasteInput.removeEventListener('paste', this.handlePaste)
  }

  private gainFocus() {
    this.elPasteInput.focus()
  }

  private handlePaste(event: any) {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items
    if (items?.length === 1 && items[0].kind === 'string') {
      // 上传复制文件引用
      items[0].getAsString((content: string) => {
        let file: UploadedFile
        try {
          file = json5.parse(content)
        } catch (ignored) {}
        if (!file && content.indexOf(FILE_KEY_SEPARATOR) > 0) {
          file = this.fileService.valueToUploadedFile(content)
        }
        if (file && file.key && file.name) {
          this.$emit('uploaded', [file])
          this.syncedVisible = false
        } else {
          this.$message({ message: '无效的文件', type: 'warning', showClose: true })
        }
      })
    } else if (items?.length) {
      // 上传截图/文件
      const files = []
      for (let item of items) {
        if (item.kind !== 'file') continue
        const file = item.getAsFile()
        if (!file.name) {
          // noinspection JSConstantReassignment
          file.name = uuidv4().substr(0, 8) +
              '-' + moment(Date.now()).format('yyMMddHHmmss') + '.png'
        }
        files.push(file)
      }
      this.uploadFiles(files).then(uploadedFiles => {
        this.$emit('uploaded', uploadedFiles)
        this.syncedVisible = false
      }).catch(error => {
        promptServerError(error)
      })
    }
  }

  onUploadClick() {
    this.elFileInput.value = null
    this.elFileInput.click()
  }

  onUploadChanged(event: InputEvent) {
    let files = this.elFileInput.files
    if (!files) return
    if (this.fileSizeLimit && _.find(files, file => file.size > this.fileSizeLimit)) {
      this.$message({ message: '文件大小超出限制' + this.fileSizeLimit.formatSize(), type: 'warning', showClose: true })
      return
    }

    this.uploadFiles(Array.prototype.slice.call(files))
      .then(uploadedFiles => {
        this.$emit('uploaded', uploadedFiles)
        this.syncedVisible = false
      }).catch(error => {
        promptServerError(error)
      })
  }

  private uploadFiles(files: File[]): Promise<UploadedFile[]> {
    this.mLoading = true
    if (_.isEmpty(files)) return Promise.resolve([])

    return Promise.all(files.map(file => {
      return this.fileService.upload(file)
        .then(rawKey => {
          return {
            key: rawKey,
            name: file.name
          } as UploadedFile
        })
    }))
  }
}
