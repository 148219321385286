






import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import UserInfo from '@/module/butler/views/components/cmp-user-info.vue'
import axios from '@/d2admin/plugin/axios/index'
import LocalDbDao from '@/module/common/local-db-dao'

@Component({
  name: 'page-user-info',
  components: {
    UserInfo
  }
})
export default class PageUserInfo extends Vue {
  private principal: any

  get accountId() {
    return this.principal.userProfile ? this.principal.userProfile.accountId : this.principal.identity
  }

  created(): void {
    this.principal = LocalDbDao.getPrincipal()
  }
}
