















import { Component, Prop, Watch } from 'vue-property-decorator'
import { FilterType, FilterCondition } from '@/module/graphql'
import BaseFieldFilter from './base-field-filter'

import util from '@/d2admin/libs/util'
import _ from 'lodash'

@Component
export default class FlagFilter extends BaseFieldFilter {
  @Prop({ required: true }) customOperator: string;
  @Prop({ default: '是' }) yesLabel: string;
  @Prop({ default: '否' }) noLabel: string;

  get syncedValue() {
    if (!this.filterCondition?.value) return null
    return !this.filterCondition.not
  }

  set syncedValue(val: boolean | null) {
    if (_.isNil(val)) this.filterCondition.value = null
    else if (_.isBoolean(val)) {
      this.filterCondition.value = '1'
      this.filterCondition.not = !val
    }
  }

  get isValid() {
    return !(!this.filterCondition.filterType ||
      _.isNil(this.filterCondition.value))
  }

  public initFilterCondition(filterCondition: FilterCondition) {
    filterCondition.filterType = FilterType.Custom
    filterCondition.field = null
    filterCondition.operator = this.customOperator
    if (_.isNil(filterCondition.not)) filterCondition.not = false
    util.objects.reactive(filterCondition)
  }

  clearContent() {
    this.filterCondition.value = null
    this.internalOnFilterChanged()
  }
}
