






import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import { Component, Vue } from 'vue-property-decorator'
import { Indexed } from '@/d2admin/delegate'
import { t } from 'element-ui/lib/locale'
import _ from 'lodash'

export interface IExtraRendererCellRendererParams extends ICellRendererParams {
  extraField?: string
}

const labelCache: {[key: string]: string } = {}

@Component
export default class LolthExtraCellRenderer extends Vue implements ICellRendererComp {
  private params!: IExtraRendererCellRendererParams
  private extraField = 'extra'
  private mValue: Indexed = null

  get sortedValues(): any {
    return _(this.mValue).toPairs().sortBy(1).fromPairs().value()
  }

  getGui() {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: IExtraRendererCellRendererParams): boolean {
    this.extraField = params.extraField || 'extra'
    this.mValue = params.value || {}
    return true
  }

  extraKeyFormatter(key: string) {
    const labelKey = this.extraField + '.' + key
    if (!labelCache[labelKey]) {
      const result = t(labelKey)
      labelCache[labelKey] = result.indexOf(this.extraField) === -1 ? result : key
    }
    return labelCache[labelKey]
  }
}
