import { Component, Prop, Vue } from 'vue-property-decorator'
import { AgChart, AgChartType, AgChartOptions } from 'ag-charts-community'

@Component({
  props: {
    options: {}
  }
})
export default class AgChartsVue extends Vue {
  public chart?: AgChartType<AgChartOptions>;

  @Prop({ required: true })
  readonly options: AgChartOptions

  private isCreated = false;
  private isDestroyed = false;

  public render(h: any) {
    return h('div', { style: { height: '100%' } })
  }

  public mounted() {
    const options = this.applyContainerIfNotSet(this.options)

    this.chart = AgChart.create(options)

    this.$watch('options', (newValue, oldValue) => {
      this.processChanges(newValue, oldValue)
    }, { deep: true })

    this.isCreated = true

    if ((this.chart as any).chart) {
      (this.chart as any).chart.waitForUpdate()
        .then(() => this.$emit('onChartReady', this.chart))
    }
  }

  public destroyed() {
    if (this.isCreated) {
      if (this.chart) {
        this.chart.destroy()
      }

      this.isDestroyed = true
    }
  }

  private processChanges(currentValue: any, previousValue: any) {
    if (this.isCreated && this.chart) {
      AgChart.update(this.chart, this.applyContainerIfNotSet(this.options))
    }
  }

  private applyContainerIfNotSet(propsOptions: AgChartOptions): AgChartOptions {
    if (propsOptions.container) {
      return propsOptions
    }

    return { ...propsOptions, container: this.$el as HTMLElement }
  }
}
