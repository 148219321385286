






























































export default function (Component) {
  Component.options.__source = "src/module/components/lolth-filter/flag-filter.vue"
}
