import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator'
import { ErrorInfo, MdTableColumn } from '@/module/graphql'
import _ from 'lodash'
import { ICellEditorParams } from 'ag-grid-community/dist/lib/interfaces/iCellEditor'

export interface IMdTableCellEditorParams extends ICellEditorParams {
  mdTableColumn: MdTableColumn
}

@Component
export default class BaseInput<T> extends Vue {
  @Prop({ required: true }) readonly column: MdTableColumn
  @Prop() readonly errors?: ErrorInfo[]
  @Prop() readonly disabled?: boolean

  @ModelSync('value', 'change')
  protected syncValue!: T

  get hasError(): boolean {
    return !_.isNull(this.errors) &&
      _.isArray(this.errors) &&
      (this.errors?.length || 0) > 0
  }

  get fieldName() {
    return this.column.name
  }

  get dataType() {
    return this.column.dataType
  }

  get columnOption() {
    return this.column.option || {}
  }

  created() {
    if (!this.column) throw new Error('字段未设置')
    this.onInputCreated()
  }

  protected onInputCreated() {
  }

  public focus() {
  }
}
