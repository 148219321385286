

























import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import { Component, Ref, Vue } from 'vue-property-decorator'
import LolthTagsEditor from '@/module/components/typed/lolth-tags-editor'
import { getRowId, ICustomRendererCellRendererParams } from '@/module/components/lolth-explorer/supports'
import { ID } from '@/module/common/types'
import _ from 'lodash'

@Component({
  components: {
    LolthTagsEditor
  }
})
export default class LolthTagsCellRenderer extends Vue implements ICellRendererComp {
  private params!: ICustomRendererCellRendererParams
  private editorDialogVisible: boolean = false
  private mTags: string[] = []
  private mEntityId: ID = null

  private mEditingTags: string[] = []

  @Ref()
  readonly elTagsEditor!: LolthTagsEditor

  get entityId(): string {
    return getRowId(this.params?.data)
  }

  getGui() {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: ICellRendererParams): boolean {
    this.mTags = params.value || []
    this.mEntityId = getRowId(this.params.data)
    return true
  }

  private editTags(event: Event) {
    event.stopPropagation()
    this.mEditingTags = _.cloneDeep(this.mTags)
    this.editorDialogVisible = true
  }

  private saveTags() {
    const savingTags = this.mEditingTags
    this.elTagsEditor.saveTags().then(() => {
      this.editorDialogVisible = false

      this.params.node.setDataValue(this.params.column, savingTags)
      // 闪一下更新的单元格
      this.params.api.flashCells({
        rowNodes: [this.params.node],
        columns: [this.params.column.getColId()]
      })
    })
  }
}
