




import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import { ErrorInfo } from '@/module/graphql'

@Component
export default class LolthErrorInfoCellRenderer extends Vue implements ICellRendererComp {
  private params: ICellRendererParams
  private mErrors: ErrorInfo[] = null

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: ICellRendererParams): boolean {
    this.mErrors = params.value
    return true
  }
}
