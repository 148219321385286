export default [
  {
    path: '/mda/lens-colour',
    name: 'PageMdaLensColour',
    meta: {
      title: '镜片材质颜色',
      auth: true
    },
    component: () => import('./views/page-mda-lens-colour')
  }, {
    path: '/mda/lens-product-model-colour',
    name: 'PageMdaLensProductModelColour',
    meta: {
      title: '镜片型号颜色',
      auth: true
    },
    component: () => import('./views/page-mda-lens-product-model-colour')
  }, {
    path: '/mda/lens-electroplate-colour',
    name: 'PageMdaElectroplateColour',
    meta: {
      title: '镜片电镀颜色',
      auth: true
    },
    component: () => import('./views/page-mda-lens-electroplate-colour')
  }, {
    path: '/mda/customer-catalog',
    name: 'PageMdaCustomerCatalog',
    meta: {
      title: '客户产品库',
      auth: true
    },
    component: () => import('./views/page-mda-customer-catalog')
  }, {
    path: '/mda/customer-end-product',
    name: 'PageMdaCustomerEndProduct',
    meta: {
      title: '客户采购历史',
      auth: true
    },
    component: () => import('./views/page-mda-customer-end-product')
  }, {
    path: '/mda/structure',
    name: 'PageMdaStructure',
    meta: {
      title: '产品结构分析',
      auth: true
    },
    component: () => import('./views/page-mda-structure')
  }
]
