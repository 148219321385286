export default [
  {
    path: '/perm/role-group',
    name: '工作组管理',
    meta: {
      title: '工作组管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-perm-role-group')
  }, {
    path: '/perm/role',
    name: '角色管理',
    meta: {
      title: '角色管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-perm-role')
  }, {
    path: '/perm/permission',
    name: '权限管理',
    meta: {
      title: '权限管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-perm-permission')
  }, {
    path: '/perm/special',
    name: '权限特例管理',
    meta: {
      title: '权限特例管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-perm-permission-special')
  }
]
