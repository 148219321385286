import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { GetContextMenuItemsParams, MenuItemDef, RowNode } from 'ag-grid-community'
import gql from 'graphql-tag'
import {
  MdAttribute,
  MdAttributeFragmentDoc,
  MdField,
  MdoBoxingEndProduct,
  MdoBoxingSheetEntry,
  MdTallyFlowSurplus
} from '@/module/graphql'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import _ from 'lodash'
import LocalDbDao from '@/module/common/local-db-dao'
import { GridOptions } from 'ag-grid-community/dist/lib/entities/gridOptions'
import { checkMenuAccessibility, findAgGridColumnDef, patchMultiSelectOnContextMenu } from '@/module/common/util/view-util'
import { FilterViewType } from '@/module/components/lolth-filter/types'
import { LOCAL_DB_CUSTOM_DATA_MDO_FIELDS } from '@/module/plugin-md-product-order/hook'

@Component({
  template: `
    <lolth-icmp-dialog title="成品详情"
                       v-if="mVisible"
                       :visible.sync="mVisible"
                       width="70%"
                       top="5vh">
      <el-table :data="mdoBoxingEndProducts" size="mini" border>
        <el-table-column width="100" property="endProduct.productOrderCode" label="订单号"></el-table-column>
        <el-table-column width="100" property="endProduct.category.keyValue" label="类别"></el-table-column>
        <el-table-column width="100" property="endProduct.productModel" label="型号"></el-table-column>
        <el-table-column width="120" property="endProduct.mdDataCode" label="品号"></el-table-column>
        <el-table-column width="120" property="endProduct.mdDataName" label="成品品名"></el-table-column>
        <el-table-column width="120" property="endProduct.mdDataSpec" label="成品规格"></el-table-column>
        <el-table-column width="100" property="splitNote" label="目的港"></el-table-column>
        <el-table-column width="100" property="splitKey" label="客户PO"></el-table-column>
        <el-table-column width="100" property="boxingQty" label="装箱数量"></el-table-column>
        <el-table-column width="100" property="endProduct.orderQty" label="订单数量"></el-table-column>
      </el-table>
    </lolth-icmp-dialog>
  `
})
export class ICmpMdoBoxingEndProducts extends Vue {
  @Prop({ required: true }) mdoBoxingEndProducts: MdoBoxingEndProduct[]
  private mVisible = true
}

@Component
export default class MixinMdoBoxingSheetEntry extends Vue {
  @Ref() readonly vExplorer: LolthExplorer

  protected mSelectedBoxingSheetEntries: MdoBoxingSheetEntry[] = []

  created() {
    LocalDbDao.prefetchPermissions([
      'MdoBoxingSheetEntryManageMatchUsed'
    ])
  }

  getContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
    const vue = this
    return [{
      name: '*允许匹配曾经用过',
      action() {
        patchMultiSelectOnContextMenu(params.node, vue.vExplorer, vue.mSelectedBoxingSheetEntries)
        vue.setEntryMatchUsed(vue.mSelectedBoxingSheetEntries.map(entry => entry.id), true)
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true,
        requirePermissions: ['MdoBoxingSheetEntryManageMatchUsed']
      })
    }, {
      name: '*禁止匹配曾经用过',
      action() {
        patchMultiSelectOnContextMenu(params.node, vue.vExplorer, vue.mSelectedBoxingSheetEntries)
        vue.setEntryMatchUsed(vue.mSelectedBoxingSheetEntries.map(entry => entry.id), false)
      },
      disabled: checkMenuAccessibility(params.node, vue, {
        requireSelection: true,
        requirePermissions: ['MdoBoxingSheetEntryManageMatchUsed']
      })
    }]
  }

  setEntryMatchUsed(ids: string[], disabled: boolean) {
    // noinspection GraphQLUnexpectedType
    this.$apollo.mutate({
      mutation: gql`mutation {
        setMdoBoxingSheetEntryMatchUsed(
          entryIds: ${JSON.stringify(ids)},
          matchUsed: ${disabled}
        )
      }`
    }).then(() => {
      this.$message({ message: '设置成功', type: 'success', showClose: true })
      this.vExplorer.refresh()
      this.$emit('changed')
    })
  }

  protected extendGridOptions(explorer: LolthExplorer, gridOptions: GridOptions) {
    gridOptions.columnDefs.splice(3, 0, {
      headerName: '类别',
      field: 'endProducts',
      cellRenderer: 'multiLineTextRenderer',
      cellRendererParams: {
        distinct: true,
        mapNestedField: 'endProduct.category.keyValue'
      },
      autoHeight: true,
      floatingFilterComponent: 'textFloatingFilter',
      filter: 'explorerFilter',
      filterParams: {
        viewDef: {
          customFilter: 'cmp-md-attr-keywords-filter',
          customProps: {
            customOperator: 'endProducts'
          },
          fieldKey: 'categoryKeyValue',
          fieldName: '类别',
          filterViewType: FilterViewType.CustomFilter
        }
      }
    }, {
      headerName: '型号',
      field: 'endProducts',
      cellRenderer: 'multiLineTextRenderer',
      cellRendererParams: {
        distinct: true,
        mapNestedField: 'endProduct.productModel'
      },
      autoHeight: true,
      floatingFilterComponent: 'textFloatingFilter',
      filter: 'explorerFilter',
      filterParams: {
        viewDef: {
          customFilter: 'cmp-md-attr-keywords-filter',
          customProps: {
            customOperator: 'endProducts'
          },
          fieldKey: 'productModel',
          fieldName: '型号',
          filterViewType: FilterViewType.CustomFilter
        }
      }
    }, {
      headerName: '品号',
      field: 'endProducts',
      cellRenderer: 'mdDataCodeListRenderer',
      cellRendererParams: {
        mdDataCodeField: 'endProduct.mdDataCode'
      },
      autoHeight: true,
      floatingFilterComponent: 'textFloatingFilter',
      filter: 'explorerFilter',
      filterParams: {
        viewDef: {
          customFilter: 'cmp-md-attr-keywords-filter',
          customProps: {
            customOperator: 'endProducts'
          },
          fieldKey: 'mdDataCode',
          fieldName: '品号',
          filterViewType: FilterViewType.CustomFilter
        }
      }
    }, {
      headerName: '品名',
      field: 'endProducts',
      cellRenderer: 'multiLineTextRenderer',
      cellRendererParams: {
        distinct: true,
        mapNestedField: 'endProduct.mdDataName'
      },
      autoHeight: true,
      floatingFilterComponent: 'textFloatingFilter',
      filter: 'explorerFilter',
      filterParams: {
        viewDef: {
          customFilter: 'cmp-md-attr-keywords-filter',
          customProps: {
            customOperator: 'endProducts'
          },
          fieldKey: 'mdDataName',
          fieldName: '品名',
          filterViewType: FilterViewType.CustomFilter
        }
      }
    }, {
      headerName: '规格',
      field: 'endProducts',
      cellRenderer: 'multiLineTextRenderer',
      cellRendererParams: {
        distinct: true,
        mapNestedField: 'endProduct.mdDataSpec'
      },
      autoHeight: true,
      floatingFilterComponent: 'textFloatingFilter',
      filter: 'explorerFilter',
      filterParams: {
        viewDef: {
          customFilter: 'cmp-md-attr-keywords-filter',
          customProps: {
            customOperator: 'endProducts'
          },
          fieldKey: 'mdDataSpec',
          fieldName: '规格',
          filterViewType: FilterViewType.CustomFilter
        }
      }
    })

    const mdFields: MdField[] = LocalDbDao.getCustomData(LOCAL_DB_CUSTOM_DATA_MDO_FIELDS)?.filter(
      (mdField: MdField) => mdField.payload.context.mdoConfig.showInBoxingSheetEntryTable)
    if (!gridOptions.context.onDataLoaded) {
      gridOptions.context.onDataLoaded = {}
    }
    mdFields.forEach(field => {
      gridOptions.columnDefs.splice(8, 0, {
        headerName: field.name,
        field: field.name,
        cellRenderer: 'mdAttributesInlineRenderer',
        cellRendererParams: {
          showFieldName: false,
          distinct: true
        },
        autoHeight: true,
        floatingFilterComponent: 'textFloatingFilter',
        filter: 'explorerFilter',
        filterParams: {
          viewDef: {
            customFilter: 'cmp-md-attr-keywords-filter',
            customProps: {
              customOperator: 'endProductsAttr'
            },
            fieldKey: field.id,
            fieldName: field.name,
            filterViewType: FilterViewType.CustomFilter
          }
        }
      })
    })
    gridOptions.context.onDataLoaded.fetchExtraColumnData = () => {
      gridOptions.api.forEachNode((rowNode) => {
        if (!rowNode.data) return
        this.$apollo.query({
          query: gql`query MdoBoxingSheetEntry($id: ID!,  $mdFieldIds: [ID!]) {
            MdoBoxingSheetEntry(id: $id) {
              endProducts {
                endProduct {
                  category { keyValue } productModel mdDataCode mdDataName mdDataSpec
                  attributes(mdFieldIds: $mdFieldIds) { ...mdAttribute}
                }
              }
            }
          }${MdAttributeFragmentDoc}`,
          variables: {
            id: rowNode.data.id,
            mdFieldIds: mdFields.map(f => f.id)
          }
        }).then(data => {
          const nodeData = rowNode.data
          nodeData.endProducts = data.data.MdoBoxingSheetEntry.endProducts
          mdFields.forEach(field => {
            nodeData[field.name] = []
            data.data.MdoBoxingSheetEntry.endProducts.forEach((ep: any) =>
              nodeData[field.name].push(...ep.endProduct.attributes.filter((attr: MdAttribute) => attr.field.id === field.id))
            )
          })
        })
      })
    }
  }

  showBoxingEndProductsDialog(endProducts: MdoBoxingEndProduct[]) {
    const dialog = new ICmpMdoBoxingEndProducts({
      router: this.$router,
      store: this.$store,
      i18n: this.$i18n,
      apolloProvider: this.$apolloProvider,
      propsData: {
        mdoBoxingEndProducts: endProducts
      },
      parent: this
    })
    dialog.$mount()
    document.body.appendChild(dialog.$el)
  }
}
