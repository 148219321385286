import { ErrorInfo, MdData, MdAttribute, MdAttributeInput, MdField } from '@/module/graphql'
import { isAttrEquals, isEmptyAttr } from '@/module/master-data/md-util'
import _ from 'lodash'

export interface MdFieldTuple {
  mdField: MdField,
  attribute: MdAttribute | MdAttributeInput,
  errors?: ErrorInfo[]
}

export enum MdDataDiffType {
  same = 'same',
  added = 'added',
  changed = 'changed',
  removed = 'removed'
}

export type FixedField = {
  disable?: boolean
  field: string,
  name: string,
  width: number,
  seq: number
}

export interface MdDataDiff {
  code: string
  mdData?: MdData
  diffType: MdDataDiffType
  bomChanged?: boolean

  fixedFieldsDiff?: MdDataDiffFixedField[]
  attributesDiff?: MdDataDiffAttribute[]
  bomDiff?: MdDataDiffBomEntry[]
}

export class MdDataDiffFixedField {
  fieldKey: string
  fieldName: string
  oldFieldValue?: string
  newFieldValue?: string

  get diffType(): MdDataDiffType {
    if (!this.oldFieldValue && this.newFieldValue) return MdDataDiffType.added
    if (this.oldFieldValue && !this.newFieldValue) return MdDataDiffType.removed
    if (_.isEqual(this.oldFieldValue, this.newFieldValue)) return MdDataDiffType.changed
    return MdDataDiffType.same
  }
}

export class MdDataDiffAttribute {
  mdField: MdField
  oldAttr?: MdAttribute
  newAttr?: MdAttribute

  get diffType(): MdDataDiffType {
    if (isEmptyAttr(this.oldAttr) && !isEmptyAttr(this.newAttr)) return MdDataDiffType.added
    if (!isEmptyAttr(this.oldAttr) && isEmptyAttr(this.newAttr)) return MdDataDiffType.removed
    if (!isAttrEquals(this.oldAttr, this.newAttr)) return MdDataDiffType.changed
    return MdDataDiffType.same
  }
}

export class MdDataDiffBomEntry {
  uid: string
  oldMdData?: MdData
  newMdData?: MdData

  oldCount?: number
  newCount?: number

  oldCountBase?: number
  newCountBase?: number

  oldUnit?: string
  newUnit?: string

  get diffType(): MdDataDiffType {
    if (!this.oldMdData && this.newMdData) return MdDataDiffType.added
    if (this.oldMdData && !this.newMdData) return MdDataDiffType.removed
    if (this.oldMdData?.code !== this.newMdData?.code) return MdDataDiffType.changed
    return MdDataDiffType.same
  }

  get countDiffType(): MdDataDiffType {
    if (this.oldCount !== this.newCount) return MdDataDiffType.changed
    return MdDataDiffType.same
  }

  get countBaseDiffType(): MdDataDiffType {
    if (this.oldCountBase !== this.newCountBase) return MdDataDiffType.changed
    return MdDataDiffType.same
  }

  get unitDiffType(): MdDataDiffType {
    if (this.oldUnit !== this.newUnit) return MdDataDiffType.changed
    return MdDataDiffType.same
  }
}
