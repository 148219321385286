































import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { MdAttribute, MdField } from '@/module/graphql'
import { ElForm } from 'element-ui/types/form'
import { ElFormItem } from 'element-ui/types/form-item'
import _ from 'lodash'
import { convertAttrToMdExtendData, showMdExtendDataDetail } from '@/module/master-data/md-util'
import { TagType } from '@/module/common/types'

@Component
export default class CmpMdAttrValue extends Vue {
  @Prop({ required: true }) readonly attribute!: MdAttribute
  @Prop() readonly mdField!: MdField
  @Prop({ default: true }) readonly showDictKey: boolean

  @Inject({ default: {} }) readonly elForm!: ElForm | any
  @Inject({ default: {} }) readonly elFormItem!: ElFormItem | any

  private get actualMdField() {
    return this.attribute.field || this.mdField
  }

  private get tagType(): TagType {
    return this.attribute.extra.emphasis ? 'primary' : 'info' as TagType
  }

  private get textValue() {
    return this.attribute.value || ''
  }

  private get numberValue() {
    if (_.isNil(this.attribute.value)) return ''
    if (this.actualMdField.option?.percentage) {
      return (this.attribute.value * 100).round(2) + '%'
    }
    return this.attribute.value.round(3)
  }

  private getDictKey() {
    return this.attribute.extra?.dataKey || this.attribute.value?.key || ''
  }

  private getDictValue() {
    return this.attribute.extra?.dataValue || this.attribute.value?.value || ''
  }

  private getDictItemLabel(dictItem: any, attribute: MdAttribute) {
    let key = ''
    let value = ''
    if (_.isPlainObject(dictItem) && 'value' in dictItem) {
      value = dictItem.value
      key = dictItem.key
    } else {
      const dictId = _.toString(dictItem)
      if (attribute.extra[dictId]) {
        value = attribute.extra[dictItem].dataValue
        key = attribute.extra[dictItem].dataKey
      }
    }
    if (this.showDictKey) {
      return (key && value) ? `${value}(${key})` : ''
    } else {
      return value || ''
    }
  }

  showExtendDetail(attribute: MdAttribute) {
    const extendData = convertAttrToMdExtendData(attribute)
    if (!extendData) return
    showMdExtendDataDetail(this, this.actualMdField.option?.extra?.modelName || this.attribute.extra?.modelName,
      extendData)
  }
}
