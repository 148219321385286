








import { Component, Vue } from 'vue-property-decorator'
import { FilterChangedEvent, IFloatingFilterComp, IFloatingFilterParams } from 'ag-grid-community'
import LocalDbDao from '@/module/common/local-db-dao'

@Component
export default class LolthEnumFloatingFilter extends Vue implements IFloatingFilterComp {
  private params: IFloatingFilterParams | null = null
  private mValues: string = ''

  get enumGroup() {
    return (this.params?.filterParams as any).viewDef?.enumGroup ||
      (this.params?.filterParams as any).viewDef?.customProps?.enumGroup
  }

  get valuedEnumItems() {
    if (!this.enumGroup) return []
    return LocalDbDao.getValuedEnum(this.enumGroup)
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent): void {
    this.mValues = !parentModel ? '' : parentModel.value
  }

  onValueChanged() {
    this.params?.parentFilterInstance(instance => {
      (instance as any).onFloatingFilterChanged({
        value: this.mValues
      })
    })
  }
}
