

























import { Component, Prop, Watch } from 'vue-property-decorator'
import { FilterCondition, FilterType } from '@/module/graphql'
import BaseFieldFilter from './base-field-filter'
import LocalDbDao from '@/module/common/local-db-dao'
import util from '@/d2admin/libs/util'
import _ from 'lodash'

@Component
export default class EnumFilter extends BaseFieldFilter {
  @Prop({ required: true }) readonly enumGroup?: string

  private mValueDisabled: boolean = false
  private operators = [
    'in', 'empty'
  ]

  get syncedValue() {
    return this.filterCondition?.value?.split(',') || []
  }

  set syncedValue(val: any[]) {
    if (_.isNil(val)) this.filterCondition.value = null
    else {
      this.filterCondition.value = val.join(',')
    }
  }

  get valuedEnumItems() {
    if (!this.enumGroup) return []
    return LocalDbDao.getValuedEnum(this.enumGroup)
  }

  public initFilterCondition(filterCondition: FilterCondition) {
    if (!filterCondition.operator) filterCondition.operator = 'in'
    util.objects.reactive(filterCondition)
  }

  public updateFilterType(operator: string) {
    if (operator === 'empty') {
      this.filterCondition.filterType = FilterType.IsNull
      this.mValueDisabled = true
    } else {
      this.filterCondition.filterType = FilterType.VEnum
      this.mValueDisabled = false
    }
  }

  onOperatorChanged(operator: string) {
    this.updateFilterType(operator)
    if (this.filterCondition.value) this.internalOnFilterChanged()
  }

  clearContent() {
    this.filterCondition.operator = 'in'
    this.filterCondition.value = null
    this.internalOnFilterChanged()
  }
}
