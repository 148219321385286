export default [
  {
    path: '/butler/sys-setting/list',
    name: '系统设置',
    meta: {
      title: '系统设置',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-sys-settings')
  }, {
    path: '/butler/user-setting/list',
    name: '用户设置',
    meta: {
      title: '用户设置',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-user-settings')
  }, {
    path: '/plugin',
    name: 'PagePlugin',
    meta: {
      title: '插件管理',
      auth: true
    },
    component: () => import('../butler/views/page-plugin')
  }, {
    path: '/butler/jieba-dict',
    name: 'PageJiebaDict',
    meta: {
      title: '分词管理',
      auth: true
    },
    component: () => import('../butler/views/page-jieba-dict')
  }, {
    path: '/user/auth-log',
    name: 'PageAuthLog',
    meta: {
      title: '登录历史',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-auth-log')
  }, {
    path: '/butler/quartz-job',
    name: 'PageQuartzJob',
    meta: {
      title: '定时批处理任务',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-quartz-job')
  }, {
    path: '/butler/yuque-doc',
    name: 'PageYuQueDoc',
    meta: {
      title: '文档管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-yuque-doc')
  }, {
    path: '/butler/external-data-source',
    name: 'PageExternalDataSource',
    meta: {
      title: '外部数据源管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/page-external-data-source')
  }, {
    path: '/butler/chart-dashboard',
    name: 'PageChartDashboard',
    meta: {
      title: '看板管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/chart/page-chart-dashboard')
  }, {
    path: '/butler/chart-my-dashboard',
    name: 'PageCharMyDashboard',
    meta: {
      title: '我的看板',
      cache: true,
      auth: true
    },
    component: () => import('./views/chart/page-chart-my-dashboard')
  }, {
    path: '/butler/chart-view',
    name: 'PageCharView',
    meta: {
      title: '图表管理',
      cache: true,
      auth: true
    },
    component: () => import('./views/chart/page-chart-view.vue')
  }

]
