export default {

  // for filter panel
  page: '页',
  more: '更多',
  to: '-',
  of: '共',
  next: '>',
  previous: '<',
  loadingOoo: '加载中',

  // for set filter
  selectAll: '全选',
  searchOoo: '查找...',
  blanks: '空',

  // for number filter and text filter
  filterOoo: '过滤...',
  applyFilter: '应用过滤条件',

  // for number filter
  equals: '等于',
  notEqual: '不等于',
  lessThan: '小于',
  lessThanOrEqual: '小于等于',
  greaterThan: '大于',
  greaterThanOrEqual: '大于等于',
  inRange: '范围',
  notContains: '不包含',
  clearFilter: '清除条件',

  // for text filter
  contains: '包含',
  startsWith: '以...起始',
  endsWith: '以...结束',

  // the header of the default group column
  group: '分组',

  // tool panel
  columns: '列设置',
  rowGroupColumns: '轴变换列',
  rowGroupColumnsEmptyMessage: '拖放列头到此处分组合并',
  valueColumns: '聚合计算列',
  pivotMode: '轴变换模式',
  groups: '分组列',
  values: '计算列',
  pivots: '变换列',
  valueColumnsEmptyMessage: '拖放列头到此处聚合计算',
  pivotColumnsEmptyMessage: '拖放列头到此处轴变换',

  // other
  noRowsToShow: '无数据',

  // enterprise menu
  pinColumn: '固定列',
  valueAggregation: '聚合计算',
  autosizeThiscolumn: '自动调整列宽',
  autosizeAllColumns: '自动调整所有列宽',
  groupBy: '分组合并',
  ungroupBy: '取消分组合并',
  resetColumns: '恢复初始设置',
  expandAll: '展开所有',
  collapseAll: '收起所有',
  toolPanel: '工具栏',
  export: '导出',
  csvExport: '导出到CSV',
  excelExport: '导出到Excel',

  // enterprise menu pinning
  pinLeft: '固定左侧 <<',
  pinRight: '固定右侧 >>',
  noPin: '不固定 <>',

  // enterprise menu aggregation and status panel
  sum: '总和',
  min: '最小值',
  max: '最大值',
  none: '无',
  count: '数量',
  avg: '平均值',
  last: '最后',
  first: '最初',

  // standard menu
  copy: '复制行',
  copyWithHeaders: '复制行(附上列头)',
  ctrlC: 'Ctrl+C',
  paste: '粘贴',
  ctrlV: 'Ctrl+V',

  // Enterprise Menu (Charts)
  chartRange: '制作图表',

  columnChart: '柱状图',
  groupedColumn: '分组',
  stackedColumn: '堆叠',
  normalizedColumn: '全高度堆叠',

  barChart: '条型图',
  groupedBar: '分组',
  stackedBar: '堆叠',
  normalizedBar: '全高度堆叠',

  pieChart: '饼状图',
  pie: '饼状',
  doughnut: '环状',

  line: '折线图',

  xyChart: '离散图',
  scatter: '点状',
  bubble: '气泡',

  areaChart: '区域图',
  area: '分组',
  stackedArea: '堆叠',
  normalizedArea: '全高度堆叠',

  histogramChart: '直方图',
  histogramFrequency: '频率',

  combinationChart: 'Combination',
  columnLineCombo: 'Column & Line',
  AreaColumnCombo: 'Area & Column',

  // Charts
  pivotChartTitle: 'Pivot Chart',
  rangeChartTitle: 'Range Chart',
  settings: 'Settings',
  data: 'Data',
  format: 'Format',
  categories: 'Categories',
  defaultCategory: '(None)',
  series: 'Series',
  xyValues: 'X Y Values',
  paired: 'Paired Mode',
  axis: 'Axis',
  navigator: 'Navigator',
  color: 'Color',
  thickness: 'Thickness',
  xType: 'X Type',
  automatic: 'Automatic',
  category: 'Category',
  number: 'Number',
  time: 'Time',
  autoRotate: 'Auto Rotate',
  xRotation: 'X Rotation',
  yRotation: 'Y Rotation',
  ticks: 'Ticks',
  width: 'Width',
  height: 'Height',
  length: 'Length',
  padding: 'Padding',
  spacing: 'Spacing',
  chart: 'Chart',
  title: 'Title',
  titlePlaceholder: 'Chart title - double click to edit',
  background: 'Background',
  font: 'Font',
  top: 'Top',
  right: 'Right',
  bottom: 'Bottom',
  left: 'Left',
  labels: 'Labels',
  size: 'Size',
  minSize: 'Minimum Size',
  maxSize: 'Maximum Size',
  legend: 'Legend',
  position: 'Position',
  markerSize: 'Marker Size',
  markerStroke: 'Marker Stroke',
  markerPadding: 'Marker Padding',
  itemSpacing: 'Item Spacing',
  itemPaddingX: 'Item Padding X',
  itemPaddingY: 'Item Padding Y',
  layoutHorizontalSpacing: 'Horizontal Spacing',
  layoutVerticalSpacing: 'Vertical Spacing',
  strokeWidth: 'Stroke Width',
  lineDash: 'Line Dash',
  offset: 'Offset',
  offsets: 'Offsets',
  tooltips: 'Tooltips',
  callout: 'Callout',
  markers: 'Markers',
  shadow: 'Shadow',
  blur: 'Blur',
  xOffset: 'X Offset',
  yOffset: 'Y Offset',
  lineWidth: 'Line Width',
  normal: 'Normal',
  bold: 'Bold',
  italic: 'Italic',
  boldItalic: 'Bold Italic',
  predefined: 'Predefined',
  fillOpacity: 'Fill Opacity',
  strokeOpacity: 'Line Opacity',
  histogramBinCount: 'Bin count',
  columnGroup: 'Column',
  barGroup: 'Bar',
  pieGroup: 'Pie',
  lineGroup: 'Line',
  scatterGroup: 'X Y (Scatter)',
  areaGroup: 'Area',
  histogramGroup: 'Histogram',
  combinationGroup: 'Combination',
  groupedColumnTooltip: 'Grouped',
  stackedColumnTooltip: 'Stacked',
  normalizedColumnTooltip: '100% Stacked',
  groupedBarTooltip: 'Grouped',
  stackedBarTooltip: 'Stacked',
  normalizedBarTooltip: '100% Stacked',
  pieTooltip: 'Pie',
  doughnutTooltip: 'Doughnut',
  lineTooltip: 'Line',
  groupedAreaTooltip: 'Area',
  stackedAreaTooltip: 'Stacked',
  normalizedAreaTooltip: '100% Stacked',
  scatterTooltip: 'Scatter',
  bubbleTooltip: 'Bubble',
  histogramTooltip: 'Histogram',
  columnLineComboTooltip: 'Column & Line',
  areaColumnComboTooltip: 'Area & Column',
  customComboTooltip: 'Custom Combination',
  noDataToChart: 'No data available to be charted.',
  pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
  chartSettingsToolbarTooltip: 'Menu',
  chartLinkToolbarTooltip: 'Linked to Grid',
  chartUnlinkToolbarTooltip: 'Unlinked from Grid',
  chartDownloadToolbarTooltip: 'Download Chart',
  seriesChartType: 'Series Chart Type',
  seriesType: 'Series Type',
  secondaryAxis: 'Secondary Axis'
}
