import './views/components'
import { ModuleHook } from '@/d2admin/module/types'
import { ComponentOptions } from 'vue'
import { FrameworkComponentsRegistry } from '@/module/components/lolth-explorer/supports'
import CmpKtiTalentFloatingFilter from '@/module/plugin-tech-invest/views/components/cmp-kti-talent-floating-filter.vue'
import CmpKtiTalentCellRenderer from '@/module/plugin-tech-invest/views/components/cmp-kti-talent-cell-renderer.vue'

export const REALM_CODE_MET = 'PluginKti'

export class KtiModuleHook implements ModuleHook {
  onModuleLoaded(vueOptions: ComponentOptions<any>) {
    FrameworkComponentsRegistry.ktiTalentFloatingFilter = CmpKtiTalentFloatingFilter
    FrameworkComponentsRegistry.ktiTalentsRenderer = CmpKtiTalentCellRenderer
  }
}

export default new KtiModuleHook()
