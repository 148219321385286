



























































































export default function (Component) {
  Component.options.__source = "src/module/master-data/views/editor/cmp-md-bom-template-chooser.vue"
}
