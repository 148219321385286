











































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import LocalDbDao from '@/module/common/local-db-dao'
import { FixedField, MdDataDiff, MdDataDiffType } from '../../types'
import CmpMdDataDiffViewer from './cmp-md-data-diff-viewer.vue'

@Component({
  components: {
    CmpMdDataDiffViewer
  }
})
export default class CmpMdDataBatchDiffViewer extends Vue {
  @Prop() readonly mdDataDiffs!: MdDataDiff[]
  @Prop({ default: '变更' }) diffTypeColumnName!: string

  @PropSync('visible')
  syncedVisible!: boolean

  private mFixedFields!: FixedField[]

  created(): void {
    this.mFixedFields = LocalDbDao.getMdDomainFixedFields()
  }

  diffTypeFormatter(row: any, column: any, cellValue: any, index: number) {
    if (cellValue === MdDataDiffType.changed) {
      return this.diffTypeColumnName
    } else if (cellValue === MdDataDiffType.added) {
      return '增加'
    } else {
      return '删除'
    }
  }

  diffTypeCellClass(cell: any): string {
    if (cell.column.property === 'diffType') {
      if (cell.row.diffType === MdDataDiffType.added) {
        return 'bg-success'
      } else if (cell.row.diffType === MdDataDiffType.changed) {
        return 'bg-warning'
      } else if (cell.row.diffType === MdDataDiffType.removed) {
        return 'bg-danger'
      }
    }
    return ''
  }
}
