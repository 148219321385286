



























































































































































import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import {
  ErrorInfo,
  ErrorInfoFragmentDoc,
  MdAttribute,
  MdAttributeFragmentDoc,
  MdAttributeInput,
  MdData,
  MdDataBomEntry,
  MdDataBomEntryFragmentDoc,
  MdDataBomEntryInput,
  MdDataBomTemplate,
  MdDataBomTemplateEntry,
  MdDataFragmentDoc,
  MdDataSavingResult,
  MdDataValues
} from '@/module/graphql'
import { buildGraphQLQueryPartInput, submitMutation } from '@/module/common/util/graphql-util'
import LocalDbDao from '@/module/common/local-db-dao'
import _ from 'lodash'
import gql from 'graphql-tag'
import util from '@/d2admin/libs/util'
import {
  attrInputDump,
  attrValueDump,
  convertAttrInputToValue,
  convertAttrValueToInput,
  findAttribute,
  formatAttribute,
  isAttrEquals,
  isEmptyAttr,
  showMdDataViewer,
  simulateMdField
} from '@/module/master-data/md-util'
import { AttrIcon } from '@/module/master-data/store'
import CmpMdDataEditor from './cmp-md-data-editor.vue'
import CmpMdBomTemplateChooser from './cmp-md-bom-template-chooser.vue'
import CmpMdBomTemplateOptionalEntryChooser from './cmp-md-bom-template-optional-entry-chooser.vue'
import { Message } from 'element-ui'
import MixinSaveMdData from '@/module/master-data/views/mixin-save-md-data'
import { v4 as uuidv4 } from 'uuid'
import {
  FixedField,
  MdDataDiff,
  MdDataDiffAttribute,
  MdDataDiffBomEntry,
  MdDataDiffFixedField,
  MdDataDiffType
} from '@/module/master-data/types'
import CmpMdDataBatchDiffViewer from '@/module/master-data/views/components/cmp-md-data-batch-diff-viewer-dialog.vue'

declare function assert(value: unknown): asserts value

enum SkeletonType {
  root = 'root', // 不能移除
  fixed = 'fixed', // 不能移除
  optional = 'optional', // 可以移除
  wild = 'wild' // 可以移除
}

enum MdDataEditingState {
  isNew = 'isNew',
  modified = 'modified',
  readonly = 'readonly'
}

type RecurseConsumer = (skeleton: Skeleton) => boolean | void

class Skeleton {
  rowKey: String = _.uniqueId()
  mdDataCode: string = ''
  mdDataUid: string = ''
  bomTemplate: MdDataBomTemplate | null = null // 当前节点正在使用的Bom模板
  bomTemplateEntry: MdDataBomTemplateEntry | null = null// 正在使用的Bom模板对应的模板元件
  bomTemplateEntryKey?: string // 正在使用的Bom模板对应的模板元件Key
  bomTemplateEntryMatchKey?: string // 更换Bom模板时, 用于自动回填的匹配Key
  candidateBomTemplates: MdDataBomTemplate[] = [] // 当前节点匹配的Bom模板
  bomEntryUid: string = uuidv4()
  bomEntries: Skeleton[] = []
  bomStrict: boolean = false // 元件对应的Bom模板是否是严格模式
  entryStrict: boolean = false
  count: number = 1
  countBase: number = 1
  unit: string = ''
  countEditable: boolean = false

  type: SkeletonType = SkeletonType.root
  // states
  bomEditing: boolean = false
  bomChanged: boolean = false
  errors: ErrorInfo[] = []

  get bomTemplateConflict() {
    // 如果已经设置过bom模板, 检查设置过的bom模板是否还在candidate里面
    return this.candidateBomTemplates.length > 0 &&
      !_.find(this.candidateBomTemplates, bt => bt.structureKey === this.bomTemplate?.structureKey)
  }

  get isOptionalBomEntryAddable(): boolean {
    return this.candidateBomTemplates.length <= 0 ||
      _.findIndex(this.bomTemplate?.bomEntries, bte => bte.optional || false) >= 0
  }

  updateFromBomTemplateEntry(btEntry: MdDataBomTemplateEntry) {
    this.bomTemplateEntryKey = btEntry.templateEntryKey
    this.bomTemplateEntryMatchKey = btEntry.templateEntryMatchKey || undefined
    this.bomTemplateEntry = btEntry
    this.count = btEntry.count || 1
    this.countBase = btEntry.countBase || 1
    this.unit = btEntry.unit || ''
    this.countEditable = btEntry.countEditable || false
  }

  updateFromBomEntry(btEntry: MdDataBomEntry) {
    this.count = btEntry.count || 1
    this.countBase = btEntry.countBase || 1
    this.unit = btEntry.unit || ''
    this.countEditable = true
  }

  recurse(preConsumer: RecurseConsumer,
          postConsumer?: RecurseConsumer): boolean | void {
    if (preConsumer(this)) return true

    if (this.bomEditing) {
      for (let bomEntry of this.bomEntries) {
        if (bomEntry.recurse(preConsumer, postConsumer)) return true
      }
    }
    if (postConsumer && postConsumer(this)) return true
  }

  validate(editor: CmpMdDataBomEditor): boolean {
    let isValid = true
    util.objects.clear(this.errors)

    if (!this.mdDataCode) {
      this.errors.push({
        ec: 1,
        msg: '未创建或选择主数据',
        elv: 'error'
      })
      isValid = false
    } else {
      const mdData = editor.findMdData(this)
      if (mdData) {
        if (this.bomTemplateEntry && this.bomTemplateEntry.attributes) {
          const missedAttrs = _.differenceWith(this.bomTemplateEntry.attributes, mdData.attributes,
            (bomEntryAttr: MdAttribute, mdDataAttr: MdAttribute) => {
              return attrValueDump(bomEntryAttr) === attrValueDump(mdDataAttr)
            })
          missedAttrs && missedAttrs.forEach(missedAttr => {
            this.errors.push({
              ec: 1,
              msg: '未包含Bom模板元件属性: ' + formatAttribute(missedAttr),
              elv: 'error'
            })
            isValid = false
          })
        }

        mdData.attributes.filter((attr: MdAttribute) => !isEmptyAttr(attr)).forEach((attr: MdAttribute) => {
          const bomCascadingAttr = editor.bomCascadingCache[_.toNumber(attr.field.id)]
          if (!bomCascadingAttr) return
          if (attrInputDump(bomCascadingAttr) !== attrValueDump(attr)) {
            this.errors.push({
              ec: 1,
              msg: '与Bom级联属性不一致: ' + formatAttribute(attr),
              elv: 'error'
            })
            isValid = false
          }
        })
      } else {
        this.errors.push({
          ec: 1,
          msg: '主数据编辑状态异常, 请刷新页面后重试',
          elv: 'error'
        })
        isValid = false
      }
    }

    if (this.type !== SkeletonType.root) {
      if (this.count <= 0 || this.countBase <= 0) {
        this.errors.push({
          ec: 1,
          msg: '用量/用量须大于0',
          elv: 'error'
        })
        isValid = false
      }
      if (!this.unit) {
        this.errors.push({
          ec: 1,
          msg: '未指定单位',
          elv: 'error'
        })
        isValid = false
      }
    }

    // TODO 有未保存草稿

    this.bomEntries.forEach(bomEntry => {
      isValid = bomEntry.validate(editor) && isValid
    })
    return isValid
  }
}

@Component({
  components: {
    CmpMdDataEditor, CmpMdBomTemplateChooser, CmpMdBomTemplateOptionalEntryChooser, CmpMdDataBatchDiffViewer
  }
})
export default class CmpMdDataBomEditor extends Mixins(MixinSaveMdData) {
  @Ref() readonly vDiffViewer!: CmpMdDataBatchDiffViewer

  @Prop({ required: true }) readonly rootMdData!: MdData
  @Prop() readonly isRootMdDataNew!: boolean

  private mFixedFields: FixedField[]
  private mSkeleton: Skeleton = new Skeleton()

  private mDraftLocalCache: { [uid:string]: MdData } = {}
  private mMdDataLocalCopyCache: { [code:string]: MdData } = {}
  private mMdDataLocalCache: { [code:string]: MdData } = {}
  private mMdDataEditingStates: { [code:string]: MdDataEditingState } = {}
  private mBomCascadingCache: { [fieldId:number] : MdAttributeInput } = {}
  private sIconConfig: readonly AttrIcon[] = Object.freeze([{
    matcher: this.isReadonly,
    description: '只读',
    class: 'text-default',
    icon: 'eye'
  }, {
    matcher: this.isNew,
    description: '新建',
    class: 'text-success',
    icon: 'file-o'
  }, {
    matcher: this.isModified,
    description: '属性变更',
    class: 'text-warning',
    icon: 'pencil-square-o'
  }, {
    matcher: this.hasDraft,
    description: '草稿',
    class: 'text-success',
    icon: 'adjust'
  }, {
    matcher: 'bomChanged',
    description: 'Bom变更',
    class: 'text-warning',
    icon: 'pencil-square'
  }, {
    matcher: (skeleton) => skeleton?.type === SkeletonType.root,
    description: '根元件',
    class: 'text-info',
    icon: 'arrow-up'
  }, {
    matcher: (skeleton) => skeleton?.type === SkeletonType.fixed,
    description: '必选元件',
    class: 'text-info',
    icon: 'arrow-circle-right'
  }, {
    matcher: (skeleton) => skeleton?.type === SkeletonType.optional,
    description: '可选元件',
    class: 'text-info',
    icon: 'arrow-circle-o-right'
  }, {
    matcher: (skeleton) => skeleton?.type === SkeletonType.wild,
    description: '自定义元件',
    class: 'text-info',
    icon: 'angle-double-right'
  }])

  // editor data
  private mMdEditorVisible = false
  private mEditingSkeleton: Skeleton | null = null
  private mEditorPresetAttrs: { [fieldId:number] : MdAttributeInput } = {}
  private mEditorMdData: MdData | null = null
  private generalErrors: ErrorInfo[] = []

  // chooser bom template
  private mBomTemplateChooserVisible = false
  private mOptionalBomEntryChooserVisible = false
  private mOptionalBomEntries: MdDataBomTemplateEntry[] = []

  // diff Viewer
  private mDiffViewerVisible = false
  private mDiff: MdDataDiff[] = []

  get bomCascadingCache() {
    return this.mBomCascadingCache
  }

  created() {
    this.mFixedFields = LocalDbDao.getMdDomainFixedFields()
    this.saveMdDataToLocalCache(this.rootMdData)
    this.mSkeleton.mdDataCode = this.rootMdData.code
    this.mSkeleton.bomEditing = true
    this.mMdDataEditingStates[this.mSkeleton.mdDataCode] =
        this.isRootMdDataNew ? MdDataEditingState.isNew : MdDataEditingState.modified
    this.fetchBomTemplate(this.mSkeleton)
    this.fetchMdData(this.rootMdData.code)
  }

  private saveMdDataToLocalCache(mdData: MdData) {
    this.mMdDataLocalCache[mdData.code] = mdData
    mdData.attributes?.forEach(attr => {
      if (isEmptyAttr(attr)) return
      if (attr.field.option.bomCascading && !this.mBomCascadingCache[_.toNumber(attr.field.id)]) {
        this.$set(this.mBomCascadingCache, _.toNumber(attr.field.id), convertAttrValueToInput(attr))
      }
    })
  }

  @Watch('rootMdData.code')
  private onRootMdDataChanged(newCode: string) {
    this.mSkeleton.mdDataCode = newCode
  }

  findMdData(skeleton: Skeleton): MdData | any {
    if (skeleton.mdDataCode) {
      return this.findMdDataByCode(skeleton.mdDataCode)
    } else if (skeleton.mdDataUid) {
      return this.mDraftLocalCache[skeleton.mdDataUid] || {}
    }
    return {}
  }

  private findMdDataByCode(mdDataCode: string): MdData | any {
    if (mdDataCode) {
      return this.mMdDataLocalCache[mdDataCode] || this.mMdDataLocalCopyCache[mdDataCode] || null
    }
    return {}
  }

  private isNew(skeleton?: Skeleton): boolean {
    if (!skeleton) return false
    return this.mMdDataEditingStates[skeleton.mdDataCode] === MdDataEditingState.isNew
  }

  private isModified(skeleton?: Skeleton): boolean {
    if (!skeleton) return false
    return this.mMdDataEditingStates[skeleton.mdDataCode] === MdDataEditingState.modified
  }

  private isReadonly(skeleton?: Skeleton): boolean {
    if (!skeleton) return false
    return !_.isEmpty(skeleton.mdDataCode) &&
        this.mMdDataEditingStates[skeleton.mdDataCode] === MdDataEditingState.readonly
  }

  private hasDraft(skeleton?: Skeleton): boolean {
    if (!skeleton) return false
    return skeleton.mdDataUid in this.mDraftLocalCache
  }

  private async fetchBomTemplate(skeleton: Skeleton) {
    let mdData = this.findMdData(skeleton)
    if (!mdData.code) return
    if (!mdData.attributes) {
      mdData = await this.fetchMdData(mdData.code)
    }
    const attrInputs = mdData.attributes.map((attr: MdAttribute) => convertAttrValueToInput(attr))

    this.$apollo.query({
      query: gql`query {
        matchMdDataBomTemplate(attributes: ${buildGraphQLQueryPartInput(attrInputs, true)}) {
          structureKey, name, note, strict, summary, bomEntries {
            templateEntryKey, templateEntryMatchKey, count, countBase, unit, optional, countEditable, attributes { ...mdAttribute }
          }
        }
      }
      ${MdAttributeFragmentDoc}`
    }).then(data => {
      util.objects.refill(data.data.matchMdDataBomTemplate, skeleton.candidateBomTemplates)
      if (skeleton.candidateBomTemplates?.length > 0) {
        // 如果还没有设置过bom模板, 则自动设置Bom模板
        if (!skeleton.bomTemplate) {
          let bomTemplate = null
          const mdData = this.findMdData(skeleton)
          // 先尝试加载主数据先前使用过的Bom模板
          if (mdData && mdData.structureKey) {
            bomTemplate = _.find(skeleton.candidateBomTemplates, bt => bt.structureKey === mdData.structureKey)
          }
          // 如果没用过或不在候选列表中, 则自动加载第一个Bom模板
          if (!bomTemplate) bomTemplate = skeleton.candidateBomTemplates[0]
          if (bomTemplate) this.setupBomTemplate(skeleton, bomTemplate)
        } else {
          // 更新Bom模板
          const latestBomTemplate = _.find(skeleton.candidateBomTemplates,
            bt => bt.structureKey === skeleton.bomTemplate?.structureKey) || skeleton.candidateBomTemplates[0]
          if (latestBomTemplate) this.setupBomTemplate(skeleton, latestBomTemplate)
        }
      } else {
        // 没有匹配到bom模板, 放开自由编辑
        this.setupWildBomEntries(skeleton, mdData)
        skeleton.bomStrict = false
      }
    })
  }

  private setupBomTemplate(skeleton: Skeleton, bomTemplate: MdDataBomTemplate) {
    // 更新skeleton上的信息
    skeleton.bomTemplate = bomTemplate
    skeleton.bomStrict = bomTemplate.strict || false
    const mdData = this.findMdData(skeleton)

    // 更新skeleton的bom
    const previousBomEntries = (skeleton.bomEntries && _.cloneDeep(skeleton.bomEntries)) || []
    util.objects.clear(skeleton.bomEntries)
    bomTemplate.bomEntries?.forEach(btEntry => {
      // 从先前的skeleton.bomEntries里面找是否有templateBomEntryKey一致的skeleton
      const matchPreviousBomEntries = previousBomEntries.filter(
        previousEntry => (previousEntry.bomTemplateEntryKey === btEntry.templateEntryKey) ||
            util.objects.equalExceptNil(previousEntry.bomTemplateEntryMatchKey, btEntry.templateEntryMatchKey))
      if (matchPreviousBomEntries.length > 0) {
        if (!btEntry.optional) {
          // fixed只拿一条
          const reusedSkeleton = matchPreviousBomEntries[0]
          reusedSkeleton.updateFromBomTemplateEntry(btEntry)
          skeleton.bomEntries.push(reusedSkeleton)
        } else {
          // optional拿所有
          matchPreviousBomEntries.forEach(entry => entry.updateFromBomTemplateEntry(btEntry))
          skeleton.bomEntries.push(...matchPreviousBomEntries)
        }

      // 新建一条新的skeleton
      } else if (!btEntry.optional) {
        const newSkeleton = CmpMdDataBomEditor.buildBomEntrySkeleton(bomTemplate, btEntry)

        // 如果主阶skeleton的mdData有bom, 则从MdData.bomEntries里面找是否有和templateBomEntryKey一致的bomEntry, 把mdDataCode填进来
        let matchedBomEntry: MdDataBomEntry | null = null
        if (mdData.code && mdData.bomEntries && mdData.bomEntries.length > 0) {
          matchedBomEntry = _.find(mdData.bomEntries,
            (persistedBomEntry: MdDataBomEntry) => (persistedBomEntry.bomTemplateEntryKey === btEntry.templateEntryKey) ||
                util.objects.equalExceptNil((persistedBomEntry.extra || {}).bomTemplateEntryMatchKey,
                  btEntry.templateEntryMatchKey))
        }
        if (matchedBomEntry) {
          this.mMdDataLocalCopyCache[matchedBomEntry.entryData.code] = matchedBomEntry.entryData
          newSkeleton.bomEntryUid = matchedBomEntry.uid || ''
          newSkeleton.mdDataCode = matchedBomEntry.entryData.code
          newSkeleton.mdDataUid = ''
        }
        skeleton.bomEntries.push(newSkeleton)
      } else {
        // 把之前的optional搬过来
        if (mdData.code && mdData.bomEntries && mdData.bomEntries.length > 0) {
          const matchedBomEntries: MdDataBomEntry[] = mdData.bomEntries.filter(
            (mdBomEntry: MdDataBomEntry) => (mdBomEntry.bomTemplateEntryKey === btEntry.templateEntryKey) ||
                  util.objects.equalExceptNil((mdBomEntry.extra || {}).bomTemplateEntryMatchKey,
                    btEntry.templateEntryMatchKey))

          matchedBomEntries.forEach(matchedBomEntry => {
            const newSkeleton = CmpMdDataBomEditor.buildBomEntrySkeleton(bomTemplate, btEntry)

            this.mMdDataLocalCopyCache[matchedBomEntry.entryData.code] = matchedBomEntry.entryData
            newSkeleton.bomEntryUid = matchedBomEntry.uid || ''
            newSkeleton.mdDataCode = matchedBomEntry.entryData.code
            newSkeleton.mdDataUid = ''
            skeleton.bomEntries.push(newSkeleton)
          })
        }
      }
    })

    // 把MdData的野生元件装回来
    this.setupWildBomEntries(skeleton, mdData)
  }

  private setupWildBomEntries(skeleton: Skeleton, mdData: MdData) {
    if (mdData.code && mdData.bomEntries && mdData.bomEntries.length > 0) {
      mdData.bomEntries.filter((mdbomEntry: MdDataBomEntry) => !mdbomEntry.bomTemplateEntryKey)
        .forEach((mdBomEntry: MdDataBomEntry) => {
          const newSkeleton: Skeleton = new Skeleton()
          newSkeleton.type = SkeletonType.wild
          newSkeleton.updateFromBomEntry(mdBomEntry)

          this.mMdDataLocalCopyCache[mdBomEntry.entryData.code] = mdBomEntry.entryData
          newSkeleton.bomEntryUid = mdBomEntry.uid || ''
          newSkeleton.mdDataCode = mdBomEntry.entryData.code
          newSkeleton.mdDataUid = ''

          skeleton.bomEntries.push(newSkeleton)
        })
    }
  }

  private static buildBomEntrySkeleton(bomTemplate: MdDataBomTemplate,
                                       btEntry: MdDataBomTemplateEntry): Skeleton {
    const newSkeleton: Skeleton = new Skeleton()
    newSkeleton.type = btEntry.optional ? SkeletonType.optional : SkeletonType.fixed
    newSkeleton.updateFromBomTemplateEntry(btEntry)
    newSkeleton.entryStrict = bomTemplate.strict
    return newSkeleton
  }

  private filterFeatureAttrs(skeleton: Skeleton) {
    if (skeleton.bomTemplateEntry?.attributes) return skeleton.bomTemplateEntry?.attributes
    const mdData = this.findMdData(skeleton)
    if (mdData.attributes) {
      return mdData.attributes.filter((attr: MdAttribute) => attr.feature)
    }
    if (skeleton.bomTemplateEntry) return skeleton.bomTemplateEntry.attributes
  }

  private saveDraft(mdData: MdData) {
    this.mDraftLocalCache[mdData.uid!] = mdData
    this.mEditingSkeleton!.mdDataUid = mdData.uid!
    this.mMdEditorVisible = false
  }

  private async editMdData(skeleton: Skeleton) {
    this.mEditorMdData = this.findMdData(skeleton)
    if (this.mEditorMdData!.code && !this.mEditorMdData!.attributes) {
      this.mEditorMdData = await this.fetchMdData(this.mEditorMdData!.code)
    }

    const presetAttrs: { [fieldId:number] : MdAttributeInput } = {}
    skeleton.bomTemplateEntry?.attributes?.forEach(attr => {
      presetAttrs[_.toNumber(attr.field.id)] = convertAttrValueToInput(attr)
    })
    this.mEditingSkeleton = skeleton
    this.mEditorPresetAttrs = presetAttrs
    this.mMdEditorVisible = true
  }

  private editorSubmit(mdData: MdData, attributes: MdAttributeInput[], isNew: boolean) {
    if (!(mdData.code in this.mMdDataEditingStates) ||
        this.mMdDataEditingStates[mdData.code] === MdDataEditingState.readonly) {
      this.mMdDataEditingStates[mdData.code] =
          isNew ? MdDataEditingState.isNew : MdDataEditingState.modified
    }

    const afterSubmit = (savedMdData: MdData) => {
      this.saveMdDataToLocalCache(savedMdData)
      this.mEditingSkeleton!.mdDataCode = savedMdData.code
      this.mEditingSkeleton!.mdDataUid = ''
      if (!this.mEditingSkeleton!.unit) { // 如果单位没有设置, 则从品号带出默认单位
        this.mEditingSkeleton!.unit = findAttribute(mdData.attributes, '单位')?.extra.dataValue
      }
      this.mMdEditorVisible = false
      if (this.mEditingSkeleton!.bomEditing) this.fetchBomTemplate(this.mEditingSkeleton!)
    }

    if (this.mMdDataEditingStates[mdData.code] === MdDataEditingState.isNew) {
      this.saveMdData(mdData, attributes).then(savedMdData => {
        afterSubmit(savedMdData)
      })
    } else {
      afterSubmit(mdData)
    }
  }

  private onMdDataSelected(mdData: MdData) {
    this.fetchMdData(mdData.code).then((fetchedMdData: MdData) => {
      this.mEditingSkeleton!.mdDataCode = mdData.code
      this.mEditingSkeleton!.mdDataUid = ''
      this.mEditingSkeleton!.unit = findAttribute(fetchedMdData.attributes, '单位')?.extra.dataValue
      // update local cache
      this.mMdDataEditingStates[mdData.code] = MdDataEditingState.readonly
      // revert to readonly state
      delete this.mMdDataLocalCache[mdData.code]
      this.mMdEditorVisible = false
    })
  }

  private fetchMdData(mdDataCode: string) {
    return this.$apollo.query({
      query: gql`query fetchMdData($id: ID, $code: String) {
        MdData(id: $id, code: $code) {
          ...mdData,
          attributes(mdFieldIds: null) {
            ...mdAttribute
          },
          bomEntries {
            ...mdDataBomEntry
          }
        }
      }
      ${MdDataFragmentDoc}
      ${MdAttributeFragmentDoc}
      ${MdDataBomEntryFragmentDoc}`,
      variables: {
        code: mdDataCode
      }
    }).then(data => {
      this.mMdDataLocalCopyCache[mdDataCode] = data.data.MdData
      return data.data.MdData
    })
  }

  private chooseBomTemplate(skeleton: Skeleton) {
    this.mEditingSkeleton = skeleton
    this.mBomTemplateChooserVisible = true
  }

  private onBomTemplateSelected(bomTemplate: MdDataBomTemplate) {
    this.setupBomTemplate(this.mEditingSkeleton!, bomTemplate)
  }

  private startSetBom(skeleton: Skeleton) {
    skeleton.bomEditing = true
    this.fetchBomTemplate(skeleton)
  }

  private stopSetBom(skeleton: Skeleton) {
    util.objects.clear(skeleton.bomEntries)
    skeleton.bomTemplate = null
    util.objects.clear(skeleton.candidateBomTemplates)
    skeleton.bomEditing = false
  }

  private getCandidateBomTemplateCount(skeleton: Skeleton) {
    return skeleton.candidateBomTemplates?.length || 0
  }

  private async submit() {
    if (!this.mSkeleton.validate(this)) {
      Message({
        message: '提交数据校验失败',
        type: 'error',
        duration: 5 * 1000,
        showClose: true
      })
      return
    }

    const values: MdDataValues[] = []
    const diffs: MdDataDiff[] = []
    this.mSkeleton.recurse(skeleton => {
      if (!this.isNew(skeleton) && !this.isModified(skeleton) && !skeleton.bomEditing) return
      const mdData: MdData = this.findMdData(skeleton)
      const previousMdData: MdData | undefined = this.mMdDataLocalCopyCache[mdData.code]
      const postValue: MdDataValues = {
        ver: mdData.ver,
        uid: mdData.uid,
        code: mdData.code
      }
      if (this.isNew(skeleton) || this.isModified(skeleton)) {
        postValue.field0 = mdData.field0
        postValue.field1 = mdData.field1
        postValue.field2 = mdData.field2
        postValue.field3 = mdData.field3
        postValue.field4 = mdData.field4
        postValue.field5 = mdData.field5
        postValue.field6 = mdData.field6
        postValue.field7 = mdData.field7
        postValue.field8 = mdData.field8
        postValue.field9 = mdData.field9
        postValue.attributes = mdData.attributes?.map(attr => convertAttrValueToInput(attr)) || []
      }
      if (skeleton.bomEditing) {
        postValue.structureKey = skeleton.bomTemplate?.structureKey
        postValue.bomEntries = skeleton.bomEntries.map(bomEntry => {
          return {
            uid: bomEntry.bomEntryUid,
            bomTemplateEntryKey: bomEntry.bomTemplateEntryKey,
            entryDataCode: bomEntry.mdDataCode,
            count: bomEntry.count,
            countBase: bomEntry.countBase,
            unit: bomEntry.unit,
            extra: {
              bomTemplateEntryMatchKey: bomEntry.bomTemplateEntryMatchKey
            }
          }
        })
      }
      values.push(postValue)

      // 加载localCopyCache中没有的MdData, 为预览变更做准备
      values.forEach(async postValue => {
        if (!this.mMdDataLocalCopyCache[postValue.code]) {
          await this.fetchMdData(postValue.code)
        }
      })

      // 生成差异比较对象
      const diff: MdDataDiff = {
        code: mdData.code,
        mdData: mdData,
        diffType: this.isNew(skeleton) ? MdDataDiffType.added
          : this.isModified(skeleton) ? MdDataDiffType.changed : MdDataDiffType.same
      }
      if (skeleton.bomEditing) diff.bomChanged = true

      if (this.isModified(skeleton)) {
        // 比较主数据固定字段差异
        diff.fixedFieldsDiff = []
        LocalDbDao.forEachMdDomain((fieldKey, option) => {
          if (!option.enabled) return
          if (!_.isEqual(_.get(mdData, fieldKey), _.get(previousMdData, fieldKey))) {
            const fixedFieldDiff = new MdDataDiffFixedField()
            fixedFieldDiff.fieldKey = fieldKey
            fixedFieldDiff.fieldName = option.name || fieldKey
            fixedFieldDiff.oldFieldValue = _.get(previousMdData, fieldKey)
            fixedFieldDiff.newFieldValue = _.get(mdData, fieldKey)
            diff.fixedFieldsDiff?.push(fixedFieldDiff)
          }
        })
      }

      // 比较Attributes差异
      diff.attributesDiff = []
      previousMdData && previousMdData.attributes?.forEach(oldAttr => {
        const newAttr = _.find(postValue.attributes, attr => attr.fieldId === oldAttr.field.id)
        if (newAttr && isAttrEquals(oldAttr, newAttr)) return

        const attrDiff = new MdDataDiffAttribute()
        attrDiff.mdField = oldAttr.field
        attrDiff.oldAttr = oldAttr
        if (newAttr) {
          attrDiff.newAttr = convertAttrInputToValue({
            mdField: oldAttr.field,
            attribute: newAttr,
            errors: []
          })
        }
        diff.attributesDiff?.push(attrDiff)
      })
      postValue.attributes?.forEach(newAttr => {
        const oldAttr: MdAttribute | undefined = _.find(previousMdData?.attributes, attr => attr.field.id === newAttr.fieldId)
        if (!oldAttr) {
          const attrDiff = new MdDataDiffAttribute()
          attrDiff.mdField = simulateMdField(newAttr)
          attrDiff.newAttr = convertAttrInputToValue({
            mdField: attrDiff.mdField,
            attribute: newAttr,
            errors: []
          })
          diff.attributesDiff?.push(attrDiff)
        }
      })

      // 比较Bom差异
      if (skeleton.bomEditing) {
        diff.bomChanged = true
        diff.bomDiff = []
        util.objects.diffIndex(previousMdData?.bomEntries, postValue.bomEntries,
          (bomEntry: MdDataBomEntry | MdDataBomEntryInput | any) => bomEntry?.uid,
          (previousBomEntry?: MdDataBomEntry, newBomEntry?: MdDataBomEntryInput) => {
            const bomDiff = new MdDataDiffBomEntry()
            if (previousBomEntry) {
              bomDiff.uid = previousBomEntry.uid!
              bomDiff.oldMdData = previousBomEntry.entryData
              bomDiff.oldCount = previousBomEntry.count
              bomDiff.oldCountBase = previousBomEntry.countBase
              bomDiff.oldUnit = previousBomEntry.unit
            }
            if (newBomEntry) {
              bomDiff.uid = newBomEntry.uid
              bomDiff.newMdData = this.findMdDataByCode(newBomEntry.entryDataCode!)
              bomDiff.newCount = newBomEntry.count
              bomDiff.newCountBase = newBomEntry.countBase || 1
              bomDiff.newUnit = newBomEntry.unit
            }
            diff.bomDiff?.push(bomDiff)
          })
      }
      diffs.push(diff)
    })

    this.mDiff = diffs
    this.mDiffViewerVisible = true

    const onDiffPreviewConfirmed = () => {
      this.mDiffViewerVisible = false
      this.vDiffViewer.$off('confirm', onDiffPreviewConfirmed)

      const ctxHeader: any = {
        'ctx-mdx-data-fix-field-regenerate': JSON.stringify(['all'])
      }
      if (LocalDbDao.getSysSetting('MasterDataStructure', 'setting_mdx_structure_remove_extra_attr')) {
        ctxHeader['mdx-data-remove-attributes-not-in-template'] = true
      }
      submitMutation(this, {
        mutationOptions: {
          mutation: gql`mutation batchSaveMdData($values: [MdDataValues!]) {
            batchSaveMdData(createIfNotExists: true, values: $values) {
              code, errors { ...errorInfo }, id
            }
          }
          ${ErrorInfoFragmentDoc}`,
          variables: { values },
          context: { headers: ctxHeader }
        },
        fullValidate: true,
        collectErrors: data => {
          const errors: { [entityCode: string]: ErrorInfo[] } = {}
          data.data.batchSaveMdData.forEach((result: MdDataSavingResult) => {
            errors[result.code] = result.errors || []
          })
          return errors
        },
        handleErrors: errors => {
          util.objects.clear(this.generalErrors)
          if (_.isArray(errors)) {
            util.objects.refill(errors, this.generalErrors)
          } else if (_.isObject(errors)) {
            this.mSkeleton.recurse(skeleton => {
              util.objects.refill(errors[skeleton.mdDataCode] || [], skeleton.errors)
            })
          }
        }
      }).then(data => {
        if (!data) return
        Message({
          message: '保存成功',
          type: 'success',
          duration: 5 * 1000,
          showClose: true
        })
        this.$emit('submit', this.rootMdData)
        return data.data.batchSaveMdData
      })
    }

    this.vDiffViewer.$on('confirm', onDiffPreviewConfirmed)
  }

  public addOptionalBomEntry(skeleton: Skeleton) {
    this.mEditingSkeleton = skeleton
    this.mOptionalBomEntries = skeleton.bomTemplate?.bomEntries?.filter(bomEntry => bomEntry.optional) || []
    this.mOptionalBomEntryChooserVisible = true
  }

  public onOptionalBomEntrySelected(optionalBomEntry: MdDataBomTemplateEntry) {
    const newSkeleton = CmpMdDataBomEditor.buildBomEntrySkeleton(
      this.mEditingSkeleton.bomTemplate, optionalBomEntry)
    this.mEditingSkeleton!.bomEntries.push(newSkeleton)
  }

  public addWildBomEntry(skeleton: Skeleton) {
    const newSkeleton: Skeleton = new Skeleton()
    newSkeleton.type = SkeletonType.wild
    newSkeleton.countEditable = true
    skeleton.bomEntries.push(newSkeleton)
  }

  private removeBomEntry(removingSkeleton: Skeleton) {
    this.mSkeleton.recurse(skeleton => {
      const removingIndex = _.findIndex(skeleton.bomEntries, bomEntry => bomEntry === removingSkeleton)
      if (removingIndex < 0) return false
      skeleton.bomEntries.splice(removingIndex, 1)
      return true
    })
  }

  private showMdDataDetail(mdData: MdData) {
    let showingMdData = this.findMdDataByCode(mdData.code)
    if (!showingMdData?.code) {
      showingMdData = this.mMdDataLocalCopyCache[mdData.code]
    }
    if (!showingMdData) {
      showMdDataViewer(this, mdData.code)
    } else {
      showMdDataViewer(this, mdData)
    }
  }
}
