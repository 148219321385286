




import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import _ from 'lodash'
import { MdoDeliveryEp } from '@/module/graphql'

declare interface IExplorerMdoEndProductsRendererParams extends ICellRendererParams {
  propName: string,
}

@Component
export default class CmpMdoEndProductsPropRenderer extends Vue implements ICellRendererComp {
  private params!: IExplorerMdoEndProductsRendererParams
  private mValue: MdoDeliveryEp[]
  private mPropName: string

  created() {
    this.refresh(this.params)
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  get propValues() {
    if (!this.mValue || this.mValue.length === 0) return ''
    return _.uniq(this.mValue.map((ep: MdoDeliveryEp) => _.get(ep, this.params.propName))).join('\r\n')
  }

  refresh(params: IExplorerMdoEndProductsRendererParams): boolean {
    this.mValue = params.data.endProducts
    return true
  }
}
