























import { Component, Prop, Vue } from 'vue-property-decorator'
import fileService from '@/module/common/file-service'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

@Component({
  components: {
    ElImageViewer
  }
})
export default class LolthFileItem extends Vue {
  @Prop({ required: true }) readonly item!: any
  @Prop({ default: 'small' }) readonly size: 'tiny' | 'mini' | 'small' | 'medium' | 'large' | string
  @Prop({ default: 'crop' }) readonly mode: 'crop' | 'fit'
  @Prop({ default: false }) readonly clickable?: boolean
  @Prop({ default: true }) readonly border: boolean

  private mRatio = 1.0

  get imgUrl() {
    if (this.size.indexOf('*') > 0) {
      const parts = this.size.split('*')
      const width = parseInt(parts[0])
      const height = parseInt(parts[1])
      if (width >= 480 && height >= 480) {
        return this.file.previewUrl
      }
    }
    return this.file.url
  }

  mounted() {
    if (this.size.indexOf('*') > 0) {
      const parts = this.size.split('*')
      const width = parseInt(parts[0])
      const height = parseInt(parts[1])
      if (!width || !height) return

      const vFileImg = this.$el.querySelector('.file-img') as HTMLElement
      vFileImg.style.width = width + 'px'
      vFileImg.style.height = height + 'px'
      this.mRatio = width / height
    }
  }

  get file() {
    return fileService.getFileService(undefined, this.item)
      .valueToUploadedFile(this.item)
  }

  get fileType() {
    return fileService.getFileService().getFileType(this.file.name)
  }

  private getSizeClass() {
    let safeSize = this.size
    if (safeSize !== 'tiny' && safeSize !== 'mini' && safeSize !== 'small' && safeSize !== 'medium' && safeSize !== 'large') {
      safeSize = 'small'
    }
    return 'size-' + safeSize
  }

  private applyStyle(event: any) {
    const elImg = event.path ? event.path[0] : event.target
    const imgWidth = elImg.width
    const imgHeight = elImg.height
    if (imgWidth >= imgHeight / this.mRatio) {
      // horizontal
      if (this.mode === 'crop') {
        elImg.style.maxHeight = '100%'
      } else {
        elImg.style.maxWidth = '100%'
      }
    } else {
      // vertical
      if (this.mode === 'crop') {
        elImg.style.maxWidth = '100%'
      } else {
        elImg.style.maxHeight = '100%'
      }
    }
  }
}
