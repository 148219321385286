export default [
  {
    path: '/est/account-book',
    name: 'PageEstAccountBook',
    meta: {
      title: '小Y记账',
      auth: true
    },
    component: () => import('./views/page-est-account-book.vue')
  }
]
