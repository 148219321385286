






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { MdExtendData } from '@/module/graphql'
import { MdExtendDataDetailPanel } from '@/module/master-data/store'
import { t } from 'element-ui/lib/locale'
import _ from 'lodash'

@Component
export default class IcmpMdExtendDetailPanel extends Vue {
  @Prop({ required: true }) readonly modelName!: string
  @Prop({ required: true }) readonly mdExtendData: MdExtendData

  private mVisible = true

  get detailPanel(): MdExtendDataDetailPanel {
    return _.find(this.$store.getters['master-data/md-store/mdExtendDataDetailPanels'], panel => {
      return panel.modelName === this.modelName
    })
  }

  get title() {
    return this.detailPanel?.dialogTitle || t('model.' + this.modelName)
  }

  get width() {
    return this.detailPanel?.dialogWidth || '30%'
  }

  get customComponentName() {
    return this.detailPanel?.customComponentName
  }
}
