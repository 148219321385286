

























export default function (Component) {
  Component.options.__source = "src/d2admin/views/system/error/404/index.vue"
}
