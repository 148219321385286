














import { Component } from 'vue-property-decorator'
import moment from 'moment'
import { buildDatePickerOption } from '@/module/common/util/view-util'
import BaseInput from '@/module/master-data/views/table/cmp-md-table-data-input/base-input'
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@/module/common/constants'

@Component
export default class CmpMdTableDataInputDate extends BaseInput<string> {
  get syncDateValue(): Date | null {
    if (this.syncValue) {
      return moment(this.syncValue).toDate()
    } else {
      return null
    }
  }

  set syncDateValue(val: Date | null) {
    this.syncValue = (val && this.$formatDateTime(val)) || null
  }

  get formattedValue() {
    if (!this.syncValue) return ''
    switch (this.columnOption.pickMode) {
      case undefined:
      case 'date':
        return moment(this.syncValue).format(DATE_FORMAT)
      case 'datetime':
        return moment(this.syncValue).format(DATE_TIME_FORMAT)
      case 'month':
        return moment(this.syncValue).format('YYYY-MM')
      case 'year':
        return moment(this.syncValue).format('YYYY')
    }
  }

  private mPickerOptions = buildDatePickerOption()

  protected onInputCreated() {
    if (this.columnOption.futureDateOnly) {
      this.mPickerOptions.disabledDate = (time) => {
        return time.getTime() < Date.now()
      }
    }
  }
}
