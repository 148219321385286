






import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import { MdExtendData } from '@/module/graphql'
import gql from 'graphql-tag'
import _ from 'lodash'
import { showMdExtendDataDetail } from '@/module/master-data/md-util'

declare interface IExplorerMdExtendDataRendererComp extends ICellRendererParams {
  modelName: string
}

@Component
export default class CmpMdExtendDataCellRenderer extends Vue implements ICellRendererComp {
  private params!: IExplorerMdExtendDataRendererComp
  private modelName: string = null
  private mValue: MdExtendData = null

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: IExplorerMdExtendDataRendererComp): boolean {
    this.modelName = params.modelName
    if (!params.value) return true

    if (_.isPlainObject(params.value)) {
      this.mValue = params.value as MdExtendData
    } else {
      this.$apollo.query({
        query: gql`query {
          MdExtendData(modelName: "${params.modelName}", dataId: "${params.value}") {
            dataId dataKey dataValue dataNote
          }
        }`
      }).then(resp => {
        this.mValue = resp.data.MdExtendData
      })
    }
    return true
  }

  showExtendDetail() {
    showMdExtendDataDetail(this, this.modelName, this.mValue)
  }
}
