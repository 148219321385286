





































import { Component, Prop, Watch } from 'vue-property-decorator'
import { FilterCondition, FilterType, MdExtendData, MdFieldFragmentDoc, RowRequest } from '@/module/graphql'
import BaseFieldFilter from '@/module/components/lolth-filter/base-field-filter'
import util from '@/d2admin/libs/util'
import _ from 'lodash'
import LocalDbDao from '@/module/common/local-db-dao'
import gql from 'graphql-tag'

@Component
export default class CmpMdExtendDataFilter extends BaseFieldFilter {
  @Prop({ required: true }) readonly modelName!: number

  private mValueMode = 'select' // select | text | empty
  private mSelectValues: MdExtendData[] = []
  private mTextValue = ''
  private operators = [
    'keyEquals', 'keyContains', 'keyStartsWith', 'keyEndsWith',
    'valueEquals', 'valueContains', 'valueStartsWith', 'valueEndsWith',
    'in', 'empty'
  ]
  private mOptions: MdExtendData[] = []

  get syncSelectValue() {
    if (this.filterCondition.operator !== 'in') return []
    return this.mSelectValues
  }

  set syncSelectValue(selections: MdExtendData[]) {
    this.mSelectValues.splice(0, this.mSelectValues.length, ...selections)
    this.filterCondition.value = selections.map(s => s.dataId).join(',')
  }

  get syncTextValue() {
    if (this.filterCondition.operator === 'in' || this.filterCondition.operator === 'empty') return ''
    return this.mTextValue
  }

  set syncTextValue(textVal: string) {
    this.mTextValue = textVal
    this.filterCondition.value = textVal
  }

  get syncOperator() {
    if (!this.filterCondition) return 'unknown'
    if (this.filterCondition.operator === 'in' || this.filterCondition.operator === 'empty') {
      return this.filterCondition.operator
    } else if (this.filterCondition.extra?.path) {
      switch (this.filterCondition.operator) {
        case 'equals':
          return this.filterCondition.extra.path.indexOf('Key') >= 0 ? 'keyEquals' : 'valueEquals'
        case 'contains':
          return this.filterCondition.extra.path.indexOf('Key') >= 0 ? 'keyContains' : 'valueContains'
        case 'startsWith':
          return this.filterCondition.extra.path.indexOf('Key') >= 0 ? 'keyStartsWith' : 'valueStartsWith'
        case 'endsWith':
          return this.filterCondition.extra.path.indexOf('Key') >= 0 ? 'keyEndsWith' : 'valueEndsWith'
      }
    }
    return 'unknown'
  }

  set syncOperator(operator: string) {
    if (operator === 'in' || operator === 'empty') {
      if (this.filterCondition.extra) this.filterCondition.extra = null
      this.filterCondition.operator = operator
    } else {
      if (!this.filterCondition.extra) this.filterCondition.extra = {}
      const extraKeyPath = 'extra.dataKey'
      const extraValuePath = 'extra.dataValue'
      switch (operator) {
        case 'keyEquals':
          this.filterCondition.operator = 'equals'
          this.filterCondition.extra.path = extraKeyPath
          break
        case 'keyContains':
          this.filterCondition.operator = 'contains'
          this.filterCondition.extra.path = extraKeyPath
          break
        case 'keyStartsWith':
          this.filterCondition.operator = 'startsWith'
          this.filterCondition.extra.path = extraKeyPath
          break
        case 'keyEndsWith':
          this.filterCondition.operator = 'endsWith'
          this.filterCondition.extra.path = extraKeyPath
          break
        case 'valueEquals':
          this.filterCondition.operator = 'equals'
          this.filterCondition.extra.path = extraValuePath
          break
        case 'valueContains':
          this.filterCondition.operator = 'contains'
          this.filterCondition.extra.path = extraValuePath
          break
        case 'valueStartsWith':
          this.filterCondition.operator = 'startsWith'
          this.filterCondition.extra.path = extraValuePath
          break
        case 'valueEndsWith':
          this.filterCondition.operator = 'endsWith'
          this.filterCondition.extra.path = extraValuePath
          break
      }
    }
  }

  get isValid() {
    return !(!this.filterCondition.filterType ||
      !this.filterCondition.field ||
      !this.filterCondition.operator ||
      !this.filterCondition.value ||
      this.filterCondition.value.length <= 0)
  }

  created(): void {
    this.initFilterCondition(this.filterCondition)
    this.updateFilterType(this.filterCondition.operator!)
    if (this.filterCondition.value) {
      if (this.filterCondition.operator === 'in') {
        this.syncSelectValue = this.filterCondition.value.split(',').map(dataId => {
          return { dataId, dataKey: dataId, dataValue: dataId }
        })
      } else if (this.filterCondition.operator !== 'empty') {
        this.syncTextValue = this.filterCondition.value
      }
    }
  }

  public initFilterCondition(filterCondition: FilterCondition) {
    if (!filterCondition.operator) filterCondition.operator = 'in'
    if (!filterCondition.value) filterCondition.value = null
    util.objects.reactive(filterCondition)
  }

  public updateFilterType(operator: string) {
    if (operator === 'empty') {
      this.filterCondition.filterType = FilterType.IsNull
      this.mValueMode = 'empty'
    } else if (operator === 'in') {
      this.filterCondition.filterType = FilterType.String
      this.mValueMode = 'select'
    } else {
      this.filterCondition.filterType = FilterType.String
      this.mValueMode = 'text'
    }
  }

  onOperatorChanged(operator: string) {
    this.updateFilterType(operator)
    if (this.filterCondition.value) this.internalOnFilterChanged()
  }

  clearContent() {
    this.filterCondition.operator = 'in'
    this.filterCondition.value = null
  }

  fetchPresetMdExtendDatas(items: MdExtendData[]) {
    if (!_.find(items, item => !item.dataId)) return items

    const rowRequest: RowRequest = {
      filter: {
        filterType: FilterType.String,
        field: 'dataId',
        operator: 'in',
        value: items.map(item => { return item.dataId }).join(',')
      }
    }

    return this.$apollo.query({
      query: gql`query ExplorerFetchOne($rowRequest: RowRequest!) {
        MdExtendDatas(modelName: "${this.modelName}", rowRequest: $rowRequest) {
          rows { dataId, dataKey, dataValue, dataNote }
        }
      }`,
      variables: {
        rowRequest
      }
    }).then((data: any) => {
      return data.data.MdExtendDatas.rows
    })
  }

  fetchOptions() {
    return this.$apollo.query({
      query: gql`query {
        MdExtendDatas(modelName: "${this.modelName}", rowRequest: {}) {
            rows { dataId, dataKey, dataValue, dataNote }
        }
      }`
    }).then(data => {
      return data.data.MdExtendDatas.rows
    })
  }
}
