import LocalDbDao from '@/module/common/local-db-dao'
import {
  MdAttribute,
  MdCategory,
  MdField,
  MdFieldWithDictionaryFragmentDoc,
  RowRequest
  , FilterCondition, FilterType
} from '@/module/graphql'
import gql from 'graphql-tag'
import Vue from 'vue'
import _ from 'lodash'
import { findAttribute } from '@/module/master-data/md-util'

export function fetchPresetMdCategory(vue: Vue, items: MdCategory[]): any {
  if (!_.find(items, item => !item.dataId)) return items

  const explorerType = LocalDbDao.getExplorerType('MdCategory')
  const fields: string = explorerType.graphQLFetchFields()
  const rowRequest: RowRequest = {
    filter: {
      filterType: FilterType.String,
      field: 'key',
      operator: 'in',
      value: items.map(item => { return item.key }).join(',')
    }
  }

  return vue.$apollo.query({
    query: gql`query ExplorerFetchOne($rowRequest: RowRequest!) {
      MdCategories(rowRequest: $rowRequest) {
        rows { ${fields} }
      }
    }`,
    variables: {
      rowRequest
    }
  }).then((data: any) => {
    return data.data[explorerType.queryType].rows
  })
}

export function mdCategoryMdFieldId(): number {
  return LocalDbDao.getGlobalVariable('mdc_category_md_field_id')
}

export function getMdCategoryKeyFromAttributes(attributes: MdAttribute[]): string {
  const categoryAttr = findAttribute(attributes, mdCategoryMdFieldId())
  return categoryAttr?.extra.dataKey
}

export function fetchMdCategoryFields(vue: Vue, categoryKey: string): Promise<MdField[]> {
  return vue.$apollo.query({
    query: gql`query {
        MdCategory(key: "${categoryKey}") {
            mdFields { ...mdFieldWithDictionary }
        }
    }
    ${MdFieldWithDictionaryFragmentDoc}`
  }).then(data => {
    return data.data.MdCategory.mdFields
  })
}

export function buildMdCategoryAttrFilter(categoryKey: string): FilterCondition {
  return {
    filterType: FilterType.String,
    field: '@' + mdCategoryMdFieldId(),
    operator: 'equals',
    value: categoryKey,
    extra: {
      path: 'extra.dataKey'
    }
  }
}
