
































































































































export default function (Component) {
  Component.options.__source = "src/module/butler/views/components/explorer-support/cmp-br-user-filter.vue"
}
