


























































































































export default function (Component) {
  Component.options.__source = "src/module/master-data/views/components/explorer-support/cmp-explorer-md-attr-panel.vue"
}
