




import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'

declare interface IExplorerListItemCountCellRendererComp extends ICellRendererParams {
  onClick?: (rowData: any) => {}
}

@Component
export default class LolthClickableCellRenderer extends Vue implements ICellRendererComp {
  private params: IExplorerListItemCountCellRendererComp
  private mValue: any = null
  private mRow: any = null

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: IExplorerListItemCountCellRendererComp): boolean {
    this.mValue = params.value
    this.mRow = params.node.data
    return true
  }

  private onClick() {
    this.params.onClick && this.params.onClick(this.mRow)
  }
}
