import { Component, Prop } from 'vue-property-decorator'
import { FilterType, RowRequest, User } from '@/module/graphql'
import gql from 'graphql-tag'
import LocalDbDao from '@/module/common/local-db-dao'
import LolthInputSelect from '@/module/components/lolth-input-select.vue'
import _ from 'lodash'
import { getRowId } from '@/module/components/lolth-explorer/supports'

@Component
export default class LolthUserInputSelect extends LolthInputSelect {
  @Prop() readonly meOption?: boolean
  @Prop() readonly fetchPresetValue?: boolean

  private mMeOption = Object.freeze({
    id: '@me',
    displayName: '@我'
  } as User)

  get explorerType() {
    return LocalDbDao.getExplorerType('User')
  }

  protected getValueKey() {
    return 'id'
  }

  protected formatItemLabel(item:any) {
    return this.explorerType.formatLabel(item)
  }

  protected needFetchPresetValue(): boolean {
    return !_.isNil(this.fetchPresetValue) && this.fetchPresetValue
  }

  protected doFetchPresetValue(items: any[]): any {
    const containsMe = !!_.find(items, item => item.id === '@me')

    const fields: string = this.explorerType.graphQLFetchFields()
    const rowRequest: RowRequest = {
      filter: {
        filterType: FilterType.InIds,
        value: items
          .filter(item => item.id !== '@me')
          .map(item => { return item.id || item.code }).join(',')
      }
    }

    return this.$apollo.query({
      query: gql`query UserFetchOne($rowRequest: RowRequest!) {
        Users(rowRequest: $rowRequest) {
          rows { ${fields} }
        }
      }`,
      variables: {
        rowRequest
      }
    }).then(data => {
      const result = []
      if (this.meOption && containsMe) result.push(this.mMeOption)
      result.push(..._.sortBy(data.data.Users.rows, row => {
        const index = _.findIndex([...(items || [])], (item: any) => {
          return getRowId(row) === getRowId(item)
        })
        return index >= 0 ? index : Number.MAX_VALUE
      }))
      return result
    })
  }

  protected doFetchOptions(): any {
    const fields: string = this.explorerType.graphQLFetchFields()
    const rowRequest: RowRequest = {
      sort: [this.explorerType.defaultSort]
    }

    return this.$apollo.query({
      query: gql`query UserFetchData($rowRequest: RowRequest!) {
        Users(rowRequest: $rowRequest) {
          rows { ${fields} }
        }
      }`,
      variables: {
        rowRequest
      }
    }).then(data => {
      const result = []
      if (this.meOption) result.push(this.mMeOption)
      result.push(...data.data.Users.rows)
      return result
    })
  }
}
