











































export default function (Component) {
  Component.options.__source = "src/module/master-data/views/cmp-md-attr-viewer/index.vue"
}
