









import { Component, Prop, Vue } from 'vue-property-decorator'
import { ErrorInfo } from '@/module/graphql'
import _ from 'lodash'

/**
 * @property error 支持以下几种数据格式
 * * 单个ErrorInfo
 * * ErrorInfo数据
 * * Error类型
 */
@Component
export default class LolthInputErrorInfo extends Vue {
  @Prop() readonly error!: ErrorInfo | ErrorInfo[] | Error

  get hasError(): boolean {
    if (_.isNil(this.error)) return false
    return !(_.isArray(this.error) && this.error.length <= 0)
  }

  get errorMsg(): string {
    if (!this.hasError) return ''
    if (_.isArray(this.error)) { // ErrorInfo[]
      return (this.error as ErrorInfo[]).map(err => err.msg).join(' / ')
    } else {
      if (_.has(this.error, 'msg')) {
        return (this.error as ErrorInfo).msg // ErrorInfo
      } else {
        return (this.error as Error).message // Error
      }
    }
  }
}
