import store from '@/store'
import { AxiosDelegateDefault, logError, throwError, translateHttpStatus } from '@/d2admin/delegate/axios'
import { refreshToken } from '@/module/butler/api/auth'
import axios from '@/d2admin/plugin/axios'
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import LocalDbDao, { DB_KEYS } from '@/module/common/local-db-dao'
import { Maybe } from 'graphql/jsutils/Maybe'
import * as Vue from 'vue/types/umd'
import { VNode } from 'vue'
import * as CommonConstants from '@/module/common/constants.js'
import { ErrorInfo } from '@/module/graphql'
import _ from 'lodash'

function fixConfig(axios: AxiosInstance, config: AxiosRequestConfig) {
  if (axios.defaults.httpAgent === config.httpAgent) {
    delete config.httpAgent
  }
  if (axios.defaults.httpsAgent === config.httpsAgent) {
    delete config.httpsAgent
  }
}

/** @deprecated */
export function promptWarning(errors: ErrorInfo[], warningMsgContainer: string[], errorsContainer: ErrorInfo[], vue:Vue, successCallback: Function, failCallback: Maybe<Function> = null) {
  errors.forEach((error: any) => {
    errorsContainer.push(error)
    if (error.elv === CommonConstants.ERROR_LEVEL_WARN && warningMsgContainer.filter(warnMsg => warnMsg === error.msg).length === 0) {
      warningMsgContainer.push(error.msg)
    }
  })
  if (warningMsgContainer.length > 0 && errorsContainer.filter(err => err.elv === CommonConstants.ERROR_LEVEL_ERROR).length === 0) {
    const h = vue.$createElement
    let waringMsgVNodes: VNode[] = []
    warningMsgContainer.forEach(msg => {
      waringMsgVNodes.push(h('li', undefined, msg))
    })
    vue.$msgbox({
      title: '警告',
      message: h('div', undefined, [
        h('ul', undefined, waringMsgVNodes)
      ]),
      confirmButtonText: '忽略并保存',
      cancelButtonText: '取消',
      showCancelButton: true,
      type: 'warning'
    }).then(() => successCallback())
  } else if (errorsContainer.filter(err => err.elv === CommonConstants.ERROR_LEVEL_ERROR).length > 0) {
    vue.$message({
      message: '提交信息有误,请修正',
      type: 'error',
      showClose: true
    })
    failCallback && failCallback()
  }
}

export function flattenGraphQLErrors(graphQLErrors: any) {
  let errors: any[] = []
  graphQLErrors.graphQLErrors.forEach((err: any) => {
    if (err.extensions.errors) {
      err.extensions.errors.forEach((error: any) => {
        errors.push(error)
      })
    } else {
      errors.push(err.extensions.error)
    }
  })
  return errors
}

class AxiosDelegateImpl extends AxiosDelegateDefault {
  beforeRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    store.commit('d2admin/loading/startLoading', null, { root: true })
    return LocalDbDao.getAccessTokenAsync().then(accessToken => {
      if (accessToken) {
        config.headers[DB_KEYS.DB_KEY_ACCESS_TOKEN] = accessToken
      }
      return config
    })
  }

  onRequestError(error: AxiosError) {
    store.commit('d2admin/loading/stopLoading', null, { root: true })
    super.onRequestError(error)
  }

  beforeResponse(response: AxiosResponse): any {
    store.commit('d2admin/loading/stopLoading', null, { root: true })

    if (response.headers) {
      const contentType = response.headers['content-type'] || response.headers['Content-Type'] || ''
      if (contentType.indexOf('application/json') < 0) {
        return response
      }
    }

    // dataAxios 是 axios 返回数据中的 data
    const data = response.data

    // 如果是graphQL请求, 直接返回response
    if (response.config.url && response.config.url.endsWith('/graphql')) {
      if (response.data.errors) {
        for (const graphQlError of response.data.errors) {
          // no permission granted, show error messages
          if (graphQlError.extensions?.error?.ec === 1107) {
            logError(graphQlError)
            throw graphQlError
          } else {
            logError(graphQlError.message)
          }
        }
      }
      return response
    }

    // 这个获取后台返回码
    const { ec, msg } = data
    if (ec === undefined) return data
    if (ec !== 0) throwError(msg || '未知错误', response.config, response)

    // TODO check errorLevel, report fatal errors
    return data
  }

  onResponseError(error: AxiosError): any {
    store.commit('d2admin/loading/stopLoading', null, { root: true })
    if (error && error.response && error.response.data) {
      let errorInfo = error.response.data
      if (error.response.status === 403 && _.isArrayBuffer(errorInfo)) {
        errorInfo = JSON.parse(new TextDecoder().decode(new Uint8Array(errorInfo)))
      }

      // access-token过期, 使用refresh-token刷新
      // 1102: ACCESS_TOKEN_EXPIRED
      // 1103: ACCESS_TOKEN_BROKEN
      if (errorInfo.ec === 1102 || errorInfo.ec === 1103) {
        if (error.config) {
          return refreshToken().then((accessToken) => {
            fixConfig(axios, error.config)
            error.config.headers[DB_KEYS.DB_KEY_ACCESS_TOKEN] = accessToken
            return axios(error.config)
          })
        }
      // 1100: AUTHENTICATION_REQUIRED
      // 1104: ACCESS_TOKEN_NOT_PROVIDED
      // 1105: REFRESH_TOKEN_EXPIRED
      } else if (errorInfo.ec === 1100 ||
            errorInfo.ec === 1104 ||
            errorInfo.ec === 1105) {
        // 如果refresh-token也过期, 退出登录
        store.dispatch('d2admin/account/logout')
      }
    }

    if (error && error.response && error.response.data) {
      error.message = error.response.data.msg
    }
    if (!error.message) {
      error.message = translateHttpStatus(error)
    }
    logError(error)
  }
}

export default new AxiosDelegateImpl()
