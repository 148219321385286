































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import axios from '@/d2admin/plugin/axios/index'
import LocalDbDao from '@/module/common/local-db-dao'
import { FilterType } from '@/module/graphql'
import qs from 'qs'
import { ColDef, GridOptions } from 'ag-grid-community'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import { FilterConditionView } from '@/module/components/lolth-filter/types'
import { sendCaptchaSms, setCountDownTimer } from '@/module/butler/butler-util'

@Component({
  name: 'cmp-user-info'
})
export default class CmpUserInfo extends Vue {
  @Ref() readonly bindMobileFormRef!: any
  @Prop() accountId!: any
  @Prop({ default: 8 }) formColSpan: number
  @Prop() isCurrentUser: boolean
  private userInfo: any = {
    username: '',
    mobilePhone: '',
    approvedDate: '',
    approvedBy: '',
    displayName: '',
    employeeNo: '',
    adminLevel: '',
    approvalStatus: 0,
    accountId: ''
  }
  private mFixedFilterCondition: FilterConditionView
  private mobilePhone: string = ''
  private mobileDialogVisible: boolean = false
  private formBindMobile = {
    mobile: '',
    captcha: ''
  }
  private mCountDownOption = {
    captchaCountDownText: '获取验证码',
    sendCaptchaBtnDisable: false
  }
  private mMobileError: string = ''

  get adminLevel() {
    if (this.userInfo.adminLevel !== '') {
      return LocalDbDao.getValuedEnumItem('AdminLevel', this.userInfo.adminLevel)?.value
    }
    return ''
  }

  private bindMobileFormRules = {
    mobile: [
      { message: '手机号格式不正确', trigger: 'blur', pattern: /^1[3456789]\d{9}$/ },
      { required: true, message: '请输入手机号', trigger: 'blur' }
    ],
    captcha: [
      { required: true, message: '请输入验证码', trigger: 'blur' }
    ]
  }

  created(): void {
    this.mFixedFilterCondition = {
      filterType: FilterType.Number,
      filterViewDef: {
        fieldName: '用户'
      },
      operator: 'equals',
      field: 'accountId',
      value: this.accountId
    }

    axios.get('/auth/account-info?accountId=' + this.accountId).then((resp: any) => {
      this.userInfo.mobilePhone = resp.mobilePhone
      this.userInfo.displayName = resp.displayName
      this.userInfo.adminLevel = resp.adminLevel
      this.userInfo.employeeNo = resp.employeeNo
      this.userInfo.approvedDate = resp.approvedDate
      this.userInfo.username = resp.username
      this.userInfo.approvedBy = resp.approvedBy
      this.userInfo.approvalStatus = resp.approvalStatus
      this.userInfo.accountId = resp.accountId
    })
  }

  private extendGridOptions(explorer: LolthExplorer, gridOptions: GridOptions) {
    gridOptions.columnDefs?.forEach((colDef: ColDef) => {
      if (colDef.field === 'account.username') {
        colDef.headerName = '用户名'
      }
      if (colDef.field === 'account.mobilePhone') {
        colDef.headerName = '电话'
      }
      if (colDef.field === 'approvedBy.displayName') {
        colDef.headerName = '审批人'
      }
    })
  }

  async unbindMobile() {
    const loginTokens: any = await LocalDbDao.loadLoginTokens()
    const token = loginTokens[this.userInfo.username]
    this.$confirm('确认解绑?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      axios.post('/auth/unbind-mobile',
        qs.stringify({ loginToken: token, mobile: this.userInfo.mobilePhone })
      ).then((resp: any) => {
        this.userInfo.mobilePhone = ''
        this.$message({
          message: '解绑成功', type: 'success', showClose: true
        })
      })
    })
  }

  clearBindMobileForm() {
    this.formBindMobile.mobile = ''
    this.formBindMobile.captcha = ''
  }

  bindMobileDialog() {
    this.mobileDialogVisible = true
  }

  async bindMobile() {
    const loginTokens: any = await LocalDbDao.loadLoginTokens()
    const token = loginTokens[this.userInfo.username]
    this.bindMobileFormRef.validate((valid: boolean) => {
      if (valid) {
        this.$confirm('确认绑定?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post('/auth/bind-mobile',
            qs.stringify({ loginToken: token, mobile: this.formBindMobile.mobile, captcha: this.formBindMobile.captcha })
          ).then((resp: any) => {
            this.userInfo.mobilePhone = this.formBindMobile.mobile
            this.mobileDialogVisible = false
            this.$message({
              message: '绑定成功', type: 'success', showClose: true
            })
          })
        })
      } else {
        this.$message({
          message: '提交信息有误,请修正', type: 'error', showClose: true
        })
      }
    })
  }

  getCaptcha() {
    this.bindMobileFormRef.validateField(['mobile'], (error: string) => {
      if (!error) {
        axios.get('/auth/is-mobile-registered', {
          params: {
            mobile: this.formBindMobile.mobile,
            accountId: this.accountId
          }
        }).then(resp => {
          if (resp) {
            this.mMobileError = '手机号已注册'
          } else {
            this.mMobileError = ''
            sendCaptchaSms(this, this.formBindMobile.mobile, this.mCountDownOption)
          }
        }).catch()
      }
    })
  }

  onMobileChange() {
    this.mMobileError = ''
    setCountDownTimer(this.mCountDownOption, this.formBindMobile.mobile)
  }
}
