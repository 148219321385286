import { Component, Prop } from 'vue-property-decorator'
import { FilterCondition } from '@/module/graphql'
import BaseFilter from './base-filter'
import _ from 'lodash'
import util from '@/d2admin/libs/util'
import locale from '@/module/components/lolth-filter/filter-locale'

@Component
export default class BaseFieldFilter extends BaseFilter {
  @Prop() readonly fixed!:boolean;
  @Prop() readonly external!:boolean;
  @Prop() readonly onFilterChanged?: (filter: FilterCondition) => void
  @Prop() readonly disabledOperators: string[]
  @Prop({ required: true }) readonly fieldKey?: string
  @Prop({ default: false }) hideOperator: boolean
  @Prop({ default: false }) insideHeader: boolean

  created() {
    this.initFilterCondition(this.filterCondition)
    this.updateFilterType(this.filterCondition.operator!)
  }

  mounted() {
    if (this.fieldKey) this.filterCondition.field = this.fieldKey
  }

  internalOnFilterChanged = _.debounce(() => {
    if (this.onFilterChanged) this.onFilterChanged(this.filterCondition)
  }, 500)

  public initFilterCondition(filterCondition: FilterCondition) {
    util.objects.reactive(filterCondition)
  }

  public updateFilterType(operator: string) {
  }

  getOperatorPairs(operators: string[]) {
    const pairs: { [op: string]: string } = {}
    operators.forEach(op => {
      if (this.disabledOperators && _.find(this.disabledOperators, dis => dis === op)) return
      pairs[op] = locale(op, this.filterCondition.not)
    })
    return pairs
  }

  clearContent() {
    this.filterCondition.value = null
    this.internalOnFilterChanged()
  }
}
