import _ from 'lodash'

export function isSame(v1: any, v2: any) {
  // eslint-disable-next-line eqeqeq
  return v1 == v2
}

export function isTrue(val: any) {
  if (val === '1' || val === 'true' || val === true) return true
  return false
}

export function isNumeric(val: any) {
  return _.isNumber(val) || (_.isString(val) && val !== '' && !_.isNaN(Number(val)))
}

export function isNumericArray(val: any) {
  if (isNumeric(val)) return true
  if (_.isString(val)) {
    const parts = val.split(',')
    return parts.every(isNumeric)
  }
  return false
}

export function base64ToFile(dataUrl: string, filename: string) {
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export function loadFile<T>(file: File,
                            onFileLoaded: (fileContent: Uint8Array) => T): Promise<T> {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = e => {
      const fileContent = new Uint8Array(e.target.result as ArrayBuffer)
      resolve(onFileLoaded(fileContent))
    }
    reader.readAsArrayBuffer(file)
  })
}

