
















































export default function (Component) {
  Component.options.__source = "src/module/components/lolth-explorer/renderer/cmp-object-label-cell-renderer.vue"
}
