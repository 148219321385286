import { fabric } from 'fabric'
import { FabricLineArrow } from '@/module/components/fabric/arrow'

export interface IArrowDrawingOptions {
  onStart?: () => void
  onDrawn?: (lineObj: FabricLineArrow) => void
}

export function bindArrowDrawing(canvas: fabric.Canvas, object: fabric.Object, options: IArrowDrawingOptions) {
  canvas.on('mouse:down', (event: fabric.IEvent<MouseEvent>) => {
    if (event.target !== object) return
    return new ArrowControl(event, canvas, {
      x: object.left + (object.group?.left || 0) + object.width / 2,
      y: object.top + (object.group?.top || 0) + object.height / 2
    }, options)
  })
}

class ArrowControl {
  private canvas;
  private isDrawing = false;
  private startPos: { x: number; y: number } = null;
  private lineObj: FabricLineArrow = null
  private options: IArrowDrawingOptions

  constructor(sourceEvent: fabric.IEvent<MouseEvent>,
              canvas: fabric.Canvas,
              startPos?: { x: number; y: number },
              options?: IArrowDrawingOptions) {
    this.canvas = canvas
    this.startPos = startPos || canvas.getPointer(sourceEvent.e)
    this.options = options
    this.bindEvents()
  }

  bindEvents() {
    this.canvas.on('mouse:move', this.onMouseMove)
    this.canvas.on('mouse:up', this.onMouseUp)
    this.canvas.on('object:moving', this.endDrawing)
  }

  unbindEvents() {
    this.canvas.off('mouse:move', this.onMouseMove)
    this.canvas.off('mouse:up', this.onMouseUp)
    this.canvas.off('object:moving', this.endDrawing)
  }

  endDrawing() {
    this.isDrawing = false
    this.startPos = null
    this.unbindEvents()
  }

  onMouseMove = (event: fabric.IEvent<MouseEvent>) => {
    let pointer = this.canvas.getPointer(event.e)
    if (Math.abs(pointer.x - this.startPos.x) <= 4 &&
      Math.abs(pointer.y - this.startPos.y) <= 4) return // 鼠标移动超过阈值才开始划线

    if (!this.isDrawing) {
      // 开始画线
      let points = [this.startPos.x, this.startPos.y, this.startPos.x, this.startPos.y]
      this.lineObj = new FabricLineArrow(points, {
        strokeWidth: 4,
        fill: 'red',
        stroke: 'red',
        originX: 'center',
        originY: 'center',
        hasBorders: false,
        hasControls: false,
        selectable: false,
        objectCaching: false,
        perPixelTargetFind: true,
        hoverCursor: 'default',
        arrowWeight: 2
      })
      this.canvas.add(this.lineObj)
      this.canvas.setActiveObject(this.lineObj)
      this.isDrawing = true
      this.options?.onStart && this.options?.onStart()
    } else {
      // 移动线条尾端
      let activeObj = this.canvas.getActiveObject()
      if (activeObj === this.lineObj) {
        (activeObj as FabricLineArrow).set({
          x2: pointer.x,
          y2: pointer.y
        })
        activeObj.setCoords()
        this.canvas.renderAll()
      }
    }
  }

  onMouseUp = (event: fabric.IEvent<MouseEvent>) => {
    this.lineObj?.set({
      dirty: true,
      objectCaching: true
    })
    this.canvas.renderAll()
    this.endDrawing()
    this.options?.onDrawn && this.options?.onDrawn(this.lineObj)
  }
}

export function drawArrow(canvas: fabric.Canvas, object: fabric.Object,
                          endPos: { x: number; y: number }) {
  let points = [
    object.left + (object.group?.left || 0) + object.width / 2,
    object.top + (object.group?.top || 0) + object.height / 2,
    endPos.x, endPos.y
  ]
  const lineObj = new FabricLineArrow(points, {
    strokeWidth: 4,
    fill: 'red',
    stroke: 'red',
    originX: 'center',
    originY: 'center',
    hasBorders: false,
    hasControls: false,
    selectable: false,
    objectCaching: false,
    perPixelTargetFind: true,
    hoverCursor: 'default',
    arrowWeight: 2
  })
  canvas.add(lineObj)
  return lineObj
}
