<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style lang="scss">
@import '~@/assets/style/public-class.scss';
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/App.vue"
}
</vue-filename-injector>
