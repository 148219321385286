












































































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { submitMutation } from '@/module/common/util/graphql-util'
import { SysDashboardGauge, FilterType, User } from '@/module/graphql'
import axios from '@/d2admin/plugin/axios/index'
import LocalDbDao from '@/module/common/local-db-dao'
import { APP_REALM_CODE } from '@/module/common/constants'

@Component
export default class CmpDashboardGaugePriorityDialog extends Vue {
  @Prop() readonly roleGroupId!: number
  @Prop() readonly roleId!: number

  @PropSync('visible')
  syncedVisible!: boolean

  private mMetrics: SysDashboardGauge[] = []
  private mHighPriorityDashboardMetrics: SysDashboardGauge[] = []
  private mMiddlePriorityDashboardMetrics: SysDashboardGauge[] = []
  private mUser: any = null
  private mChooseDialogVisible: boolean = false
  private mSavePriorityTarget: string = 'high'
  private mSelections: SysDashboardGauge[] = []
  private mCopyVisible: boolean = false
  private mSourceUser: any = null
  private mDestUser: any = []
  private mSearch: string = ''

  created() {
    this.mUser = {
      id: LocalDbDao.getPrincipal().identity,
      displayName: LocalDbDao.getPrincipal().name
    }
    this.onUserChange(this.mUser)
  }

  get fixedFilterCondition() {
    if (!this.mSourceUser) return null
    return {
      filterType: FilterType.InIds,
      not: true,
      value: this.mSourceUser.id
    }
  }

  getRealmName(key: string) {
    return LocalDbDao.getValuedEnumItem('Realm', key)?.value || key
  }

  onSourceUserChange() {
    this.mDestUser = []
  }

  get candidateMetrics() {
    return this.mMetrics.filter(metrics =>
      (this.mSearch ? (this.getRealmName(metrics.realm).indexOf(this.mSearch) !== -1 || metrics.name.indexOf(this.mSearch) !== -1) : true) &&
      this.mHighPriorityDashboardMetrics.filter(highMetrics => highMetrics.id === metrics.id).length === 0 &&
      this.mMiddlePriorityDashboardMetrics.filter(middleMetrics => middleMetrics.id === metrics.id).length === 0)
  }

  addHighPriority() {
    this.mChooseDialogVisible = true
    this.mSavePriorityTarget = 'high'
  }

  addMiddlePriority() {
    this.mChooseDialogVisible = true
    this.mSavePriorityTarget = 'middle'
  }

  handleSelectionChange(val: any) {
    this.mSelections = val
  }

  savePriority() {
    if (this.mSavePriorityTarget === 'high') {
      this.mHighPriorityDashboardMetrics.push(...this.mSelections)
    } else {
      this.mMiddlePriorityDashboardMetrics.push(...this.mSelections)
    }
    this.mSelections = []
    this.mChooseDialogVisible = false
  }

  onRowDelete(idx: number, row: any, data: any) {
    data.splice(idx, 1)
  }

  save() {
    let config: any[] = []
    this.mHighPriorityDashboardMetrics.forEach(metrics => config.push({ id: metrics.id, level: 'high' }))
    this.mMiddlePriorityDashboardMetrics.forEach(metrics => config.push({ id: metrics.id, level: 'middle' }))
    this.$apollo.mutate({
      mutation: gql`mutation ($config: [DashboardGaugeConfigInput]!) {
        saveUserDashboardGaugeConfig(userId: "${this.mUser.id}", config: $config)
      }`,
      variables: {
        config
      }
    }).then(data => {
      this.$message({
        message: '保存成功', type: 'success', showClose: true
      })
      this.syncedVisible = false
      if (LocalDbDao.getPrincipal().identity === this.mUser.id) {
        LocalDbDao.updateLocalUserSetting([data.data.saveUserDashboardGaugeConfig])
        this.$emit('saved')
      }
    })
  }

  saveCopy() {
    if (!this.mSourceUser) {
      this.$message({
        message: '请选择源账户', type: 'error', showClose: true
      })
      return
    }
    if (!this.mDestUser || this.mDestUser.length === 0) {
      this.$message({
        message: '请选择目标账户', type: 'error', showClose: true
      })
      return
    }
    submitMutation(this, {
      mutationOptions: {
        mutation: gql`mutation copyUserBrdMetricsSettings($sourceUserId: ID!, $destUserIds: [ID]!) {
          copyUserDashboardGaugeSettings(sourceUserId: $sourceUserId, destUserIds: $destUserIds)
        }`,
        variables: {
          sourceUserId: this.mSourceUser.id,
          destUserIds: this.mDestUser.map((user: { id: any }) => user.id)
        }
      }
    }).then(data => {
      let currentUserSetting = data.data.copyUserDashboardGaugeSettings.filter((setting: { userId: string }) => parseInt(setting.userId) === parseInt(LocalDbDao.getPrincipal().identity))
      if (currentUserSetting.length !== 0) {
        LocalDbDao.updateLocalUserSetting([currentUserSetting[0]])
        this.$emit('saved')
      }
      this.$message({
        message: '保存成功', type: 'success', showClose: true
      })
      this.mCopyVisible = false
    })
  }

  onUserChange(user: User) {
    this.$apollo.query({
      query: gql`query {
        listUserDashboardGauges(userId: "${user.id}") {
          name, id, realm
        }
      }`
    }).then(data => {
      this.mMetrics = data.data.listUserDashboardGauges
      let rowRequest = {
        filter: {
          filterType: FilterType.And,
          conditions: [
            {
              filterType: FilterType.Number,
              field: 'userId',
              operator: 'equals',
              value: user.id
            }, {
              filterType: FilterType.String,
              field: 'code',
              operator: 'equals',
              value: 'dashboardMetrics'
            }, {
              filterType: FilterType.String,
              field: 'realm',
              operator: 'equals',
              value: APP_REALM_CODE
            }
          ]
        }
      }
      axios.post('/butler/user-setting/list', rowRequest, { headers: { 'Content-Type': 'application/json' } }).then((resp: any) => {
        this.mHighPriorityDashboardMetrics.splice(0)
        this.mMiddlePriorityDashboardMetrics.splice(0)
        if (!resp || !resp.rows || resp.rows.length === 0) {
          return
        }
        this.mMetrics.forEach(metrics => {
          if (resp.rows[0].value.filter((setting: { id: string; level: string }) => parseInt(setting.id) === parseInt(metrics.id) && setting.level === 'high').length !== 0) {
            this.mHighPriorityDashboardMetrics.push(metrics)
          }
          if (resp.rows[0].value.filter((setting: { id: string; level: string }) => parseInt(setting.id) === parseInt(metrics.id) && setting.level === 'middle').length !== 0) {
            this.mMiddlePriorityDashboardMetrics.push(metrics)
          }
        })
      })
    })
  }
}
