import './views/components'
import { ModuleHook } from '@/d2admin/module/types'
import { ComponentOptions } from 'vue'
import { FrameworkComponentsRegistry, syncExplorerTypes } from '@/module/components/lolth-explorer/supports'
import LocalDbDao from '@/module/common/local-db-dao'
import axios from '@/d2admin/plugin/axios/index'
import { MdDomainCache } from '@/module/graphql'
import { exitIfError } from '@/module/common/util/error-util'
import CmpMdAttributeCellRenderer
  from '@/module/master-data/views/components/explorer-support/cmp-md-attribute-cell-renderer.vue'
import CmpMdAttributesInlineCellRenderer
  from '@/module/master-data/views/components/explorer-support/cmp-md-attributes-inline-cell-renderer.vue'
import CmpMdDataCodeRenderer
  from '@/module/master-data/views/components/explorer-support/cmp-md-data-code-cell-renderer.vue'
import CmpMdDictAttrFloatingFilter
  from '@/module/master-data/views/components/explorer-support/cmp-md-dict-attr-floating-filter.vue'
import CmpMdDictFloatingFilter
  from '@/module/master-data/views/components/explorer-support/cmp-md-dict-floating-filter.vue'
import CmpMdScriptProcessorTargetModelCellRenderer
  from '@/module/master-data/views/components/explorer-support/cmp-md-script-processor-target-model-cell-renderer.vue'
import CmpMdAdjustTalliesCellRenderer
  from '@/module/master-data/views/components/explorer-support/cmp-md-adjust-talliesl-cell-renderer.vue'
import CmpMdTablePresetCellRenderer
  from '@/module/master-data/views/components/explorer-support/cmp-md-table-presets-cell-renderer.vue'
import CmpMdDictionaryEntryCellRenderer
  from '@/module/master-data/views/components/explorer-support/cmp-md-dictionary-entry-cell-renderer.vue'
import CmpMdExtendDataCellRenderer
  from '@/module/master-data/views/components/explorer-support/cmp-md-extend-data-cell-renderer.vue'

function syncMdDomain(): Promise<MdDomainCache> {
  return axios.post('/graphql', {
    query: `query syncMdDomain {
      MdDomain {
        mdDomain {
          name,
          field0Option{ ...optionField },
          field1Option{ ...optionField },
          field2Option{ ...optionField },
          field3Option{ ...optionField },
          field4Option{ ...optionField },
          field5Option{ ...optionField },
          field6Option{ ...optionField },
          field7Option{ ...optionField },
          field8Option{ ...optionField },
          field9Option{ ...optionField },
        }
        etag
      }
    }
    fragment optionField on MdDataFixedFieldOption {
      name, enabled, width, seq
    }`
  }).then((response: any) => {
    exitIfError(response.data.errors)
    return response.data.data.MdDomain
  })
}

export class MasterDataModuleHook implements ModuleHook {
  onModuleLoaded(vueOptions: ComponentOptions<any>) {
    // 注册ag-grid frameworkComponents
    FrameworkComponentsRegistry.mdAttributeRenderer = CmpMdAttributeCellRenderer
    FrameworkComponentsRegistry.mdAttributesInlineRenderer = CmpMdAttributesInlineCellRenderer
    FrameworkComponentsRegistry.mdDataCodeRenderer = CmpMdDataCodeRenderer
    FrameworkComponentsRegistry.mdDictFloatingFilter = CmpMdDictFloatingFilter
    FrameworkComponentsRegistry.mdDictAttrFloatingFilter = CmpMdDictAttrFloatingFilter
    FrameworkComponentsRegistry.mdScriptProcessorTargetModelRenderer = CmpMdScriptProcessorTargetModelCellRenderer
    FrameworkComponentsRegistry.mdAdjustTalliesRenderer = CmpMdAdjustTalliesCellRenderer
    FrameworkComponentsRegistry.mdTablePresetsRenderer = CmpMdTablePresetCellRenderer
    FrameworkComponentsRegistry.mdDictionaryEntryRenderer = CmpMdDictionaryEntryCellRenderer
    FrameworkComponentsRegistry.mdExtendDataRenderer = CmpMdExtendDataCellRenderer
  }

  onLoggedIn = () => {
    LocalDbDao.prefetchPermissions(['MdFileSharedToMe'])
    return Promise.all([
      syncExplorerTypes().then(types => {
        return LocalDbDao.saveExplorerTypes(types)
      }),
      syncMdDomain().then(mdDomainCache => {
        return LocalDbDao.saveMdDomain(mdDomainCache)
      })
    ])
  }
}

export default new MasterDataModuleHook()
