






import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community'
import { FilterType, MdDictionaryEntry } from '@/module/graphql'
import gql from 'graphql-tag'
import _ from 'lodash'

@Component
export default class CmpMdDictionaryEntryCellRenderer extends Vue implements ICellRendererComp {
  private params!: ICellRendererParams
  private mValues: MdDictionaryEntry[] = []

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  created() {
    this.refresh(this.params)
  }

  refresh(params: ICellRendererParams): boolean {
    if (!params.value) return true

    if (_.isPlainObject(params.value)) {
      this.mValues = [params.value as MdDictionaryEntry]
    } else if (_.isArray(params.value)) {
      if (_.isEmpty(params.value)) return true
      if (_.isPlainObject(params.value[0])) {
        this.mValues = params.value as MdDictionaryEntry[]
      } else {
        this.$apollo.query({
          query: gql`query ($rowRequest: RowRequest!) {
            MdDictionaryEntries(rowRequest: $rowRequest) {
              rows { id key value }
            }
          }`,
          variables: {
            rowRequest: {
              filter: {
                filterType: FilterType.InIds,
                value: params.value.join(',')
              }
            }
          }
        }).then(resp => {
          this.mValues = resp.data.MdDictionaryEntries.rows
        })
      }
    } else {
      this.$apollo.query({
        query: gql`query {
          MdDictionaryEntry(id: "${params.value}") {
            id key value
          }
        }`
      }).then(resp => {
        this.mValues = [resp.data.MdDictionaryEntry]
      })
    }
    return true
  }
}
