




















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ErrorInfo } from '@/module/graphql'
import LocalDbDao from '@/module/common/local-db-dao'
import type { GroupedErrors } from '@/module/common'
import _ from 'lodash'

@Component
export default class LolthErrors extends Vue {
  @Prop({ required: true }) readonly errors!: GroupedErrors
  @Prop() readonly compact?: boolean

  get safeGetErrors() {
    if (_.isPlainObject(this.errors)) {
      const errors: ErrorInfo[] = []
      _.keys(this.errors)
        .filter(key => _.isArray(_.get(this.errors, key)))
        .forEach(key => {
          let groupErrors: ErrorInfo[] = _.get(this.errors, key)
          groupErrors = groupErrors
            .filter(error => _.has(error, 'msg'))
            .map(error => {
              const clonedError = _.cloneDeep(error)
              clonedError.msg = key + ': ' + clonedError.msg
              return clonedError
            })
          errors.push(...groupErrors)
        })
      return errors
    } else {
      return this.errors || []
    }
  }

  private worstErrorLevel() {
    return LocalDbDao.worstErrorLevel(this.errors) || 'fatal'
  }

  private getFieldName(error: ErrorInfo) {
    const fieldName = (error.extra && (error.extra.fieldName || error.extra.path)) || ''
    return fieldName + ((fieldName && ': ') || '')
  }

  private getIconName(elv: string) {
    return elv === 'warn' ? 'exclamation-triangle' : 'times-circle'
  }

  private getColorClass(elv: string) {
    return elv === 'warn' ? 'text-warning' : 'text-danger'
  }
}
