












import { Component, Vue } from 'vue-property-decorator'
import { FilterChangedEvent, IFloatingFilterComp, IFloatingFilterParams } from 'ag-grid-community'
import { FilterCondition, FilterType, MdDictionaryEntry } from '@/module/graphql'
import _ from 'lodash'

@Component
export default class CmpMdDictFloatingFilter extends Vue implements IFloatingFilterComp {
  private params: IFloatingFilterParams | null = null
  private mValue: MdDictionaryEntry = null

  get customProps() {
    return (this.params?.filterParams as any).viewDef?.customProps || {}
  }

  get fixedFilterCondition(): FilterCondition {
    if (this.customProps.dictId) {
      return {
        filterType: FilterType.Number,
        field: 'dictionary.id',
        operator: 'equals',
        value: this.customProps.dictId + ''
      }
    } else if (this.customProps.dictName) {
      return {
        filterType: FilterType.String,
        field: 'dictionary.name',
        operator: 'equals',
        value: this.customProps.dictName
      }
    }
    throw new Error('Either `dictId` or `dictName` prop must be specified.')
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent): void {
    if (!parentModel) {
      this.mValue = null
    } else if (parentModel.extra?.dictKey) {
      this.mValue = {
        id: parentModel.value,
        key: parentModel.extra?.dictKey ?? parentModel.value,
        value: parentModel.extra?.dictValue ?? parentModel.value
      }
    }
  }

  onValueChanged() {
    if (_.isNil(this.mValue)) return
    let filterType = this.customProps.forArray ? FilterType.Array : FilterType.Number
    let operator = 'in'
    if (this.customProps.customFilter) {
      filterType = FilterType.Custom
      operator = this.customProps.customFilter
    }

    this.params?.parentFilterInstance(instance => {
      (instance as any).onFloatingFilterChanged({
        value: this.mValue.id,
        filterType,
        operator
      })
    })
  }
}
