

















































import { Component, Model, ModelSync, Prop, Vue } from 'vue-property-decorator'
import copy from 'copy-to-clipboard'
import LocalDbDao from '@/module/common/local-db-dao'
import { CascaderNode } from '@/module/components/types'
import { ErrorInfo, FilterCondition, FilterType, MdDictionaryEntry, SortColumn, SortType } from '@/module/graphql'
import { isValid_ } from '@/module/common/filter-condition-extend'
import gql from 'graphql-tag'
import LolthTypedInputSelect from '@/module/components/typed/lolth-typed-input-select'
import { ID } from '@/module/common/types'
import _ from 'lodash'

@Component
export default class CmpMdDictionaryEntryInputSelect extends Vue {
  @Prop({ required: true }) readonly dictId: ID
  @Prop() readonly dictFilter?: FilterCondition
  @Prop() readonly preferChooser?: boolean
  @Prop() readonly hints?: string
  @Prop() readonly errors?: ErrorInfo[]
  @Prop() readonly disabled?: boolean

  private mGroup: CascaderNode[] = null
  private mChooserVisible = false

  @ModelSync('value', 'change', { required: true })
  syncedValue: MdDictionaryEntry

  get filterCondition(): FilterCondition {
    if (!this.dictId) {
      throw new Error('未指定选项表')
    }

    const filterCondition = {
      filterType: FilterType.Number,
      field: 'dictionary.id',
      operator: 'equals',
      value: _.toString(this.dictId)
    }

    return isValid_(this.dictFilter)
      ? {
        filterType: FilterType.And,
        conditions: [
          filterCondition,
          this.dictFilter
        ]
      } : filterCondition
  }

  get sortByColumn(): SortColumn[] {
    return [{
      field: 'seq',
      sort: SortType.Asc
    }]
  }

  get explorerType() {
    return LocalDbDao.getExplorerType('MdDictionaryEntry')
  }

  mounted() {
    if (!this.preferChooser) {
      this.$apollo.query({
        query: gql`query {
          MdDictionary(id: ${this.dictId}) {
            groups
          }
        }`
      }).then(data => {
        this.mGroup = data.data.MdDictionary.groups || null
      })
    }
  }

  copyToClipboard() {
    if (!this.syncedValue) return
    copy(this.explorerType.formatLabel(this.syncedValue))
  }

  onChooserSelect(dictEntries: MdDictionaryEntry[]) {
    if (!dictEntries || dictEntries.length <= 0) return
    this.$emit('change', dictEntries[0])
  }

  resetSelectOptions() {
    const vSelect = this.$refs.vSelect as LolthTypedInputSelect
    vSelect?.resetOptionsLoaded()
  }
}
