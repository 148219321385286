









import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

@Component({
  components: {
    ElImageViewer
  }
})
export default class LolthImageViewer extends Vue {
  @Prop({ required: true }) readonly urlList!: string[]
  @Prop({ default: 20000 }) readonly zIndex?: number
  @Prop() readonly onSwitch?: (index: number) => {}
  @Prop({ default: 0 }) readonly initialIndex?: number

  @PropSync('visible')
  syncedVisible!: boolean

  mPrevOverflow: string;

  mounted() {
    if (this.syncedVisible) {
      document.body.appendChild(this.$el)
    }
  }

  destroyed() {
    // remove from body
    this.$el?.parentNode?.removeChild(this.$el)
  }

  @Watch('visible')
  private onShown() {
    if (!this.syncedVisible) return

    // append to body
    document.body.appendChild(this.$el)
    this.mPrevOverflow = document.body.style.overflow
    document.body.style.overflow = 'hidden'
    this.syncedVisible = true
  }

  private doClose() {
    document.body.style.overflow = this.mPrevOverflow
    this.syncedVisible = false
  }
}
