









































import { Component, Prop, Watch } from 'vue-property-decorator'
import { FilterCondition, FilterType, MdDictionaryEntry } from '@/module/graphql'
import BaseFieldFilter from '@/module/components/lolth-filter/base-field-filter'

@Component
export default class CmpMdDictFilter extends BaseFieldFilter {
  @Prop() readonly dictId?: number
  @Prop() readonly dictName?: string
  @Prop({ default: true }) readonly emptyOption: boolean
  @Prop() readonly customFilter?: string
  @Prop() readonly useChooser?: boolean
  @Prop() readonly forArray?: boolean
  @Prop() readonly dictRef?: boolean

  private mSelectedValue: MdDictionaryEntry[] = []
  private mOperator = 'in'

  private operators = ['in']

  get syncSelectValue(): MdDictionaryEntry[] {
    if (this.mOperator !== 'in') return null
    return this.mSelectedValue
  }

  set syncSelectValue(selection: MdDictionaryEntry[]) {
    this.mSelectedValue = selection || []
    this.filterCondition.value =
      this.mSelectedValue.map(dictEntry => dictEntry.id).join(',')
  }

  get syncOperator() {
    return this.mOperator
  }

  set syncOperator(operator: string) {
    this.mOperator = operator
    if (operator === 'in') {
      if (this.customFilter) {
        this.filterCondition.operator = this.customFilter
        return
      }
    }
    this.filterCondition.operator = operator
  }

  get fixedFilterCondition(): FilterCondition {
    if (this.dictId) {
      return {
        filterType: FilterType.Number,
        field: 'dictionary.id',
        operator: 'equals',
        value: this.dictId + ''
      }
    } else if (this.dictName) {
      return {
        filterType: FilterType.String,
        field: 'dictionary.name',
        operator: 'equals',
        value: this.dictName
      }
    }
    throw new Error('Either "dictId" or "dictName" prop must be specified.')
  }

  created() {
    this.initData()
    if (this.emptyOption) {
      this.operators.splice(0, 0, 'empty')
    }
  }

  mounted() {
    if (this.dictRef && this.filterCondition.field.indexOf('.id') < 0) {
      this.filterCondition.field += '.id'
    }
  }

  @Watch('filterCondition')
  initData() {
    if (!this.filterCondition.operator) this.syncOperator = 'in'
    if (!this.filterCondition.value) {
      this.mSelectedValue = []
    }
    this.updateFilterType(this.filterCondition.operator!)
    if (this.filterCondition.value) {
      if (this.mOperator === 'in') {
        this.syncSelectValue = this.filterCondition.value
          .split(',').map(dictEntryId => {
            return {
              id: dictEntryId
            } as MdDictionaryEntry
          })
      }
    }
  }

  @Watch('filterCondition.value')
  onValueChanged() {
    if (!this.filterCondition.value) this.mSelectedValue = []
  }

  public updateFilterType(operator: string) {
    if (operator === 'empty') {
      this.filterCondition.filterType = FilterType.IsNull
    } else {
      this.filterCondition.filterType =
              this.customFilter ? FilterType.Custom
                : this.forArray ? FilterType.Array
                  : FilterType.Number
    }
  }

  onOperatorChanged(operator: string) {
    this.updateFilterType(operator)
    if (this.filterCondition.value) this.internalOnFilterChanged()
  }

  clearContent() {
    this.syncOperator = 'in'
    this.filterCondition.value = null
  }
}
