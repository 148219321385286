

























































import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator'
import { MdInvoice, MdInvoiceFragmentDoc } from '@/module/graphql'
import gql from 'graphql-tag'
import { batchSubmitMutations, buildGraphQLInput } from '@/module/common/util/graphql-util'
import LocalDbDao from '@/module/common/local-db-dao'
import _ from 'lodash'
import { APP_REALM_CODE } from '@/module/common/constants'
import fileService from '@/module/common/file-service'
import axios from '@/d2admin/plugin/axios'
import CmpMdInvoiceForm from '@/module/master-data/views/components/cmp-md-invoice-form.vue'
import { UploadedFile } from '@/module/common/file-service/types'
import { v4 as uuidv4 } from 'uuid'
import util from '@/d2admin/libs/util'
import { promptServerError } from '@/module/common/util/error-util'
import { Loading } from 'element-ui'

declare type UploadedFileWithState = UploadedFile & {
  detectFailed?: boolean
  isNew?: boolean
}
@Component({
  components: { CmpMdInvoiceForm }
})
export default class CmpMdInvoiceEditorDialog extends Vue {
  @Prop() readonly: boolean
  @Prop() invoiceId: number
  @Prop() invoiceFileKey: string
  @Ref() private readonly vContextMenu!: any

  @PropSync('visible')
  syncedVisible!: boolean

  private mOpenedPane: string = 'first'
  private mUploadDialogVisible: Boolean = false
  private mMultiple: Boolean = true
  private mFileMaxSize: Number = parseFloat(LocalDbDao.getSysSettingValue(APP_REALM_CODE, 'md_file_size_limit')) * 1024 * 1024
  private mUploadedFiles: UploadedFileWithState[] = []
  private mSelectedFile: UploadedFileWithState = null
  private mFileInvoices: any = {}
  private mErrors: any = {}

  get title() {
    if (this.readonly) return '查看发票'
    return !_.isEmpty(this.invoiceFileKey) || !_.isNil(this.invoiceId) ? '编辑发票' : '新增发票'
  }

  get invoices() {
    return this.mSelectedFile ? this.mFileInvoices[this.mSelectedFile.key] : []
  }

  get errors() {
    return this.mErrors[this.mOpenedPane]
  }

  created() {
    if (this.invoiceFileKey) {
      this.$apollo.query({
        query: gql`query findMdInvoices($fileKey: String) {
          findMdInvoices(fileKey: $fileKey) {
            ...mdInvoice
          }
        }${MdInvoiceFragmentDoc}`,
        variables: {
          fileKey: this.invoiceFileKey
        }
      }).then(data => {
        this.$set(this.mFileInvoices, fileService.getFileService().valueToUploadedFile(data.data.findMdInvoices[0].files[0]).key, data.data.findMdInvoices)
        this.mUploadedFiles.push(fileService.getFileService().valueToUploadedFile(data.data.findMdInvoices[0].files[0]))
        this.onFileSelected(this.mUploadedFiles[0])
      })
    } else if (this.invoiceId) {
      this.$apollo.query({
        query: gql`query findMdInvoice($id: ID!) {
          MdInvoice(id: $id) {
            ...mdInvoice
          }
        }${MdInvoiceFragmentDoc}`,
        variables: {
          id: this.invoiceId
        }
      }).then(data => {
        let placeHolderFile = { key: uuidv4(), name: '无发票' } as UploadedFileWithState
        this.mUploadedFiles.push(placeHolderFile)
        this.$set(this.mFileInvoices, placeHolderFile.key, [data.data.MdInvoice])
        this.onFileSelected(placeHolderFile)
      })
    }
  }

  showContextMenu(event: MouseEvent) {
    if (this.readonly) return
    event.preventDefault()
    this.vContextMenu.show({ top: event.pageY, left: event.pageX })
  }

  newEmptyInvoice() {
    if (!this.mSelectedFile) {
      let placeHolderFile: UploadedFileWithState = null
      if (this.mUploadedFiles.filter(file => file.name === '无发票').length === 0) {
        placeHolderFile = { key: uuidv4(), name: '无发票', isNew: true } as UploadedFileWithState
        this.mUploadedFiles.splice(0, 0, placeHolderFile)
        this.$set(this.mFileInvoices, placeHolderFile.key, [])
      } else {
        placeHolderFile = this.mUploadedFiles.filter(file => file.name === '无发票')[0]
      }
      this.onFileSelected(placeHolderFile)
    }
    const invoice = this.initEmptyInvoice()
    this.mFileInvoices[this.mSelectedFile.key].push(invoice)
    this.mOpenedPane = invoice.uid
    this.$set(this.mSelectedFile, 'isNew', true)
  }
  initEmptyInvoice() {
    return {
      id: null,
      uid: uuidv4(),
      code: '',
      type: '',
      summary: null,
      amount: null,
      taxAmount: null,
      seller: '',
      sellerTaxId: null,
      buyer: null,
      buyerTaxId: null,
      issuedAt: null,
      clzName: null,
      files: this.mSelectedFile.name === '无发票' ? [] : [fileService.getFileService().uploadedFileToValue(this.mSelectedFile)],
      extraFiles: []
    } as MdInvoice
  }

  removeTab(target: string) {
    let tabs = this.mFileInvoices[this.mSelectedFile.key] as MdInvoice[]
    let activeName = this.mOpenedPane
    if (activeName === target) {
      tabs.forEach((tab, index: number) => {
        if (tab.uid === target) {
          let nextTab = tabs[index + 1] || tabs[index - 1]
          if (nextTab) {
            activeName = nextTab.uid
          }
        }
      })
    }
    this.mOpenedPane = activeName
    util.objects.remove(tabs, tab => tab.uid === target)
    if (tabs.length === 0) {
      delete this.mFileInvoices[this.mSelectedFile.key]
      util.objects.remove(this.mUploadedFiles, file => file.key === this.mSelectedFile.key)
      this.mSelectedFile = null
      if (this.mUploadedFiles.length > 0) this.onFileSelected(this.mUploadedFiles[0])
    }
    if (tabs.filter(invoice => !invoice.id).length === 0) {
      this.$set(this.mSelectedFile, 'isNew', false)
    }
  }

  onTabClick() {
    setTimeout(() => {
      this.mFileInvoices[this.mSelectedFile.key].forEach((invoice: MdInvoice) => {
        if (this.mErrors[invoice.uid]) {
          document.querySelector('#tab-' + invoice.uid).classList.add('bg-danger')
        }
      })
    }, 100)
  }

  onFileSelected(file: UploadedFileWithState) {
    this.mSelectedFile = file
    if (this.mFileInvoices[file.key] && this.mFileInvoices[file.key].length > 0) {
      this.mOpenedPane = this.mFileInvoices[file.key][0].uid
    }
  }

  onFileUploaded(uploadedFiles: UploadedFile[]) {
    uploadedFiles.forEach(uploadedFile => {
      const fileKey = fileService.getFileService().uploadedFileToValue(uploadedFile)
      let loadingInstance = Loading.service({})
      axios.get('/util/ocr/detect-invoices', {
        params: {
          fileKey: fileKey
        }
      }).then((resp: any) => {
        resp.forEach((invoice: any) => {
          delete invoice.deleted
          delete invoice.lazyDummy
          delete invoice.tags
          invoice.files = [fileKey]
          invoice.uid = uuidv4()
        })
        this.$set(this.mFileInvoices, uploadedFile.key, resp)
        this.mUploadedFiles.push(uploadedFile)
        this.onFileSelected(uploadedFile)
        this.$set(this.mSelectedFile, 'isNew', true)
        loadingInstance.close()
      }).catch(error => {
        promptServerError(error)
        loadingInstance.close()
        this.$set(uploadedFile, 'detectFailed', true)
        this.mUploadedFiles.push(uploadedFile)
        this.onFileSelected(uploadedFile)
        const invoice = this.initEmptyInvoice()
        this.$set(this.mFileInvoices, uploadedFile.key, [invoice])
        this.mOpenedPane = invoice.uid
      })
    })
  }

  saveMdInvoice() {
    const postInputs: any[] = buildGraphQLInput(this.mFileInvoices[this.mSelectedFile.key])
    postInputs.forEach(input => {
      if (input.amount === '') input.amount = null
      if (input.taxAmount === '') input.taxAmount = null
    })

    batchSubmitMutations(this, postInputs.map(input => {
      return {
        mutation: gql`mutation ($input: MdInvoiceInput) {
          saveMdInvoice(input: $input) {
            id ver
          }
        }`,
        variables: input,
        key: {
          uid: input.uid
        }
      }
    })).then(batchResult => {
      let hasError = false
      batchResult.forEach(result => {
        if (result.errors) {
          hasError = true
          this.$set(this.mErrors, result.key, result.errors)
          document.querySelector('#tab-' + result.key).classList.add('bg-danger')
        } else {
          const invoice = this.mFileInvoices[this.mSelectedFile.key].filter((invoice: MdInvoice) => invoice.uid === result.key)[0]
          invoice.id = result.response.data.saveMdInvoice.id
          invoice.ver = result.response.data.saveMdInvoice.ver
        }
      })
      if (!hasError) {
        this.$message({
          message: '保存成功', type: 'success', showClose: true
        })
        this.$emit('saved')
        this.$set(this.mSelectedFile, 'isNew', false)
      }
    })
  }
}
