




































export default function (Component) {
  Component.options.__source = "src/module/master-data/views/tally/icmp-md-adjust-tallies-dialog.vue"
}
