import '@/module/butler/views/components'
import { ModuleHook } from '@/d2admin/module/types'
import Vue, { ComponentOptions } from 'vue'
import { FrameworkComponentsRegistry, syncExplorerTypes } from '@/module/components/lolth-explorer/supports'
import LocalDbDao from '@/module/common/local-db-dao'
import _ from 'lodash'
import { syncData } from '@/module/butler/api/auth'
import fileService from '@/module/common/file-service'
import CmpUsersCellRenderer from '@/module/butler/views/components/explorer-support/cmp-users-cell-renderer.vue'
import CmpBrUserFloatingFilter from '@/module/butler/views/components/explorer-support/cmp-br-user-floating-filter.vue'
import StompClient from '@/module/common/api/stomp-client'
import store from '@/store'
import { reloadApp, sendNotification } from '@/module/common/util/view-util'
import axios from '@/d2admin/plugin/axios'
import qs from 'qs'
import { Indexed } from '@/d2admin/delegate'
import Cookies from 'js-cookie'
import CmpExternalDataSourceCellRenderer
  from '@/module/butler/views/components/explorer-support/cmp-external-data-source-cell-renderer.vue'
import { APP_REALM_CODE } from '@/module/common/constants'
import VueMatomo from 'vue-matomo'
import { GLOBAL_ENV } from '@/d2admin/setting'
import { Notification as ENotification } from 'element-ui'

export class UserNotApprovedError extends Error {
}

export class ButlerModuleHook implements ModuleHook {
  onModuleLoaded(vueOptions: ComponentOptions<any>) {
    // 注册ag-grid frameworkComponents
    FrameworkComponentsRegistry.usersRenderer = CmpUsersCellRenderer
    FrameworkComponentsRegistry.brUserFloatingFilter = CmpBrUserFloatingFilter
    FrameworkComponentsRegistry.externalDataSourceRenderer = CmpExternalDataSourceCellRenderer

    fileService.init()
    try {
      Notification.requestPermission()
    } catch (ignored) {}
  }

  onLoggedIn = () => {
    LocalDbDao.prefetchPermissions(['SysChartViewEdit'])
    const principal = LocalDbDao.getPrincipal()
    if (!_.has(principal, 'userProfile')) {
      throw new UserNotApprovedError('no user')
    }

    return Promise.all([
      syncExplorerTypes().then(types => {
        return LocalDbDao.saveExplorerTypes(types)
      }),
      syncData()
    ])
  }

  onLoggedOut = (userId: string) => {
    StompClient.disconnect()
    return Promise.all([
      LocalDbDao.clearUserData(userId)
    ])
  }

  onUserEntered = () => {
    // 需要重设密码
    store.dispatch('d2admin/account/resetPassword',
      LocalDbDao.getUserSetting(APP_REALM_CODE, 'needResetPassword')?.value || false)

    // 进入应用后主动取一次ClientCmd
    processClientCmd(true)

    if (GLOBAL_ENV.PIWIK_SITE_ID && GLOBAL_ENV.PIWIK_URL) {
      Vue.use(VueMatomo, {
        host: GLOBAL_ENV.PIWIK_URL,
        siteId: GLOBAL_ENV.PIWIK_SITE_ID,
        userId: LocalDbDao.getPrincipal().name,
        router: require('@/router').default
      })
    }

    if (process.env.VUE_APP_ENABLE_MOCK !== 'true') {
      // 发送给所有用户的广播
      StompClient.subscribe('/topic/broadcast', message => {
        sendNotification(message.body)
      })
      // 发送给特定用户的广播
      StompClient.subscribe('/user/topic/broadcast', message => {
        sendNotification(message.body)
      })
      // 浏览器客户端指令
      StompClient.subscribe('/user/topic/web-cmd', message => {
        processClientCmd()
      })
      // EXTENSION POINT web-cmd
      StompClient.connect()
      return Promise.resolve()
    }
  }
}

export default new ButlerModuleHook()

//* **************************************************************************
// Client CMD
//* **************************************************************************

const CMD_WEB_REFRESH = 'cmd_web_refresh'
const CMD_WEB_LOGOUT = 'cmd_web_logout'
const CMD_WEB_FLUSH_EXPLORER_TYPES = 'cmd_web_flush_explorer_types'
const CMD_WEB_FLUSH_EXPLORER_VIEWS = 'cmd_web_flush_explorer_views'
const CMD_WEB_RELOAD_SYNC_DATA = 'cmd_web_reload_sync_data'

export function processClientCmd(justLogin = false) {
  axios.get('/util/client/get-cmd').then((resp: any) => {
    if (_.isEmpty(resp.cmd)) return
    // 把ClientCmd取下来后就flush掉
    axios.post('/util/client/flush-cmd', qs.stringify({
      userId: LocalDbDao.getPrincipal().identity
    }))
    resp.cmd.forEach((cmd: string) => {
      processIndividualClientCmd(cmd, justLogin)
    })
  })
}

async function processIndividualClientCmd(cmd: string, justLogin = false) {
  console.log(`receive client cmd: ${cmd}`)
  let args: Indexed = {}
  if (cmd.indexOf('?')) {
    args = qs.parse(cmd.substr(cmd.indexOf('?') + 1, cmd.length)) as Indexed
  }

  if (cmd.match(CMD_WEB_LOGOUT + '\\?.*') && !justLogin) { // 刚刚登录, 忽略重新登录的指令
    const notification = ENotification({
      title: '通知',
      dangerouslyUseHTMLString: true,
      message: '应用程序已更新, 需要重新登录. 点击立即重新登录',
      type: 'warning',
      duration: 0,
      onClick: async () => {
        notification.close()
        if (args.clearStorage) {
          localStorage.clear()
          _.keys(Cookies.get()).forEach(cookie => Cookies.remove(cookie))
        }
        await store.dispatch('d2admin/account/logout', { confirm: false })
      }
    })
  }

  if (cmd.match(CMD_WEB_REFRESH + '[\\?.*]?')) {
    reloadApp()
  }

  if (cmd.match(CMD_WEB_FLUSH_EXPLORER_TYPES + '[\\?.*]?')) {
    syncExplorerTypes().then(types => {
      return LocalDbDao.saveExplorerTypes(types)
    })
  }

  if (cmd.match(CMD_WEB_FLUSH_EXPLORER_VIEWS + '[\\?.*]?')) {
    let modelName = null
    LocalDbDao.flushExplorerViewCache(modelName)
  }

  if (cmd.match(CMD_WEB_RELOAD_SYNC_DATA + '[\\?.*]?')) {
    syncData().then(data => {
      reloadApp()
    })
  }
  // EXTENSION POINT
}
