

























































































export default function (Component) {
  Component.options.__source = "src/module/plugin-md-product-structure/views/components/model/cmp-mdp-fin-size-detail.vue"
}
