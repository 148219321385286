

























import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { setupSortable } from '@/module/common/util/view-util'
import { ErrorInfo } from '@/module/graphql'
import { v4 as uuidv4 } from 'uuid'

/**
 * 用于输入列表的组件
 * 如果slot的input组件修改的是数据中的整个元素, 需要监听@change事件, 把对象换进去
 * 参考cmp-mdp-md-data-view-editor-dialog.vue
 */
@Component
export default class LolthInputList extends Vue {
  @Prop() readonly itemCreateFn?: () => any
  @Prop() readonly errorInfos: { [row: number]: ErrorInfo }
  @Prop() readonly disabled?: boolean

  @Model('change', { required: true })
  items!: any[]

  private mRefreshSwitch = uuidv4()

  created() {
    if (!this.items) this.$emit('update', [])
  }

  mounted() {
    this.setupSortable()
  }

  setupSortable() {
    setupSortable((this.$el as HTMLElement).querySelector('.no-dot') as HTMLElement,
      this.onRowSeqChanged, true)
  }

  private onRowSeqChanged(newSeq: number = -1, oldSeq: number = -1) {
    if (newSeq < 0 || oldSeq < 0) return
    const movingRow = this.items[oldSeq]

    this.items.splice(oldSeq, 1) &&
    this.items.splice(newSeq, 0, movingRow)

    // 通过sortable.js改变DOM结构顺序后, vnode与dom的对应关系乱了, 排序结果会出现错乱
    // 所以整个列表需要重画, sortable.js需要重新setup
    this.mRefreshSwitch = uuidv4()
    this.$nextTick(() => {
      this.setupSortable()
    })
  }

  removeItem(index: number) {
    const item = this.items.splice(index, 1)[0]
    this.$emit('item-removed', item)
  }

  addItem() {
    const newItem = this.itemCreateFn ? this.itemCreateFn() : {}
    this.items.push(newItem)
    this.$emit('item-added', newItem)
  }
}
