import './views/components'
import { ModuleHook } from '@/d2admin/module/types'
import { ComponentOptions } from 'vue'
import store from '@/store'

export class MdpModuleHook implements ModuleHook {
  onModuleLoaded(vueOptions: ComponentOptions<any>): any {
    store.commit('master-data/md-store/registerMdExtendDataDetailPanels', [{
      modelName: 'MdpFinModel',
      customComponentName: 'cmp-mdp-fin-model-form',
      dialogTitle: '蛙鞋型号',
      dialogWidth: '50%'
    }, {
      modelName: 'MdpFinSize',
      customComponentName: 'cmp-mdp-fin-size-detail',
      dialogTitle: '蛙鞋码数',
      dialogWidth: '50%'
    }], { root: true })
  }
}

export default new MdpModuleHook()
