import './views/components'
import { ModuleHook } from '@/d2admin/module/types'
import { ComponentOptions } from 'vue'
import store from '@/store'

export class MdeModuleHook implements ModuleHook {
  onModuleLoaded(vueOptions: ComponentOptions<any>): any {
    store.commit('master-data/md-store/registerMdExtendDataDetailPanels', [{
      modelName: 'MdeCurrency',
      customComponentName: 'cmp-mde-currency-detail',
      dialogTitle: '币种',
      dialogWidth: '30%'
    }, {
      modelName: 'MdeWarehouse',
      customComponentName: 'cmp-mde-warehouse-detail',
      dialogTitle: '仓库',
      dialogWidth: '50%'
    }, {
      modelName: 'MdeCustomer',
      customComponentName: 'cmp-mde-customer-detail',
      dialogTitle: '客户',
      dialogWidth: '80%'
    }, {
      modelName: 'MdeSupplier',
      customComponentName: 'cmp-mde-supplier-detail',
      dialogTitle: '供应商',
      dialogWidth: '80%'
    }], { root: true })
  }
}

export default new MdeModuleHook()
