












import { Component, Prop, Vue } from 'vue-property-decorator'
import { FilterCondition, FilterType } from '@/module/graphql'
import { ID } from '@/module/common/types'

@Component
export default class IcmpMdAdjustTalliesDialog extends Vue {
  @Prop({ required: true }) readonly targetTallyId: ID

  private mVisible = true

  private mFixedFilterCondition: FilterCondition = null

  created(): void {
    this.mFixedFilterCondition = {
      filterType: FilterType.Number,
      field: 'adjustTargetTally.id',
      operator: 'equals',
      value: this.targetTallyId.toString()
    }
  }
}
