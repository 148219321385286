


























import { Component, Prop, Watch } from 'vue-property-decorator'
import { FilterType, TagInfo, FilterCondition } from '@/module/graphql'
import BaseFieldFilter from './base-field-filter'
import gql from 'graphql-tag'
import LocalDbDao from '@/module/common/local-db-dao'

import util from '@/d2admin/libs/util'

@Component
export default class TagsFilter extends BaseFieldFilter {
  @Prop({ required: true }) readonly explorerTypeKey!: string

  private mValueDisabled: boolean = false
  private mValues: any[] = []
  private operators = [
    'in', 'contains', 'empty'
  ]
  private mOptionsLoaded = false
  private mOptions: TagInfo[] = []

  public initFilterCondition(filterCondition: FilterCondition) {
    if (!filterCondition.operator) filterCondition.operator = 'in'
    util.objects.reactive(filterCondition)
  }

  public updateFilterType(operator: string) {
    if (operator === 'empty') {
      this.filterCondition.filterType = FilterType.IsNull
      this.mValueDisabled = true
    } else {
      this.filterCondition.filterType = FilterType.Array
      this.mValueDisabled = false
    }
  }

  onOperatorChanged(operator: string) {
    this.updateFilterType(operator)
    this.internalOnFilterChanged()
  }

  @Watch('mValues')
  onValueChanged(val: string): void {
    this.filterCondition.value = this.mValues.join(',')
  }

  clearContent() {
    this.filterCondition.operator = 'in'
    this.filterCondition.value = null
    this.internalOnFilterChanged()
  }

  private loadOptions(visible: boolean) {
    if (!visible || this.mOptionsLoaded) return

    const explorerType = LocalDbDao.getExplorerType(this.explorerTypeKey)
    return this.$apollo.query({
      query: gql`query ExplorerFetchTags {
        ${this.explorerTypeKey}Tags {
          tag, count
        }
      }`
    }).then(data => {
      this.mOptions = data.data[this.explorerTypeKey + 'Tags']
      this.mOptionsLoaded = true
      return this.mOptions
    })
  }

  private formatOption(tagInfo: TagInfo) {
    return tagInfo.tag + '(' + tagInfo.count + ')'
  }
}
