import {
  ColumnApi,
  FirstDataRenderedEvent,
  GridOptions,
  ICellRendererParams,
  PaginationChangedEvent,
  SuppressKeyboardEventParams,
  ViewportChangedEvent
} from 'ag-grid-community'
import axios from '@/d2admin/plugin/axios/index'
import { SortColumn, SortType } from '@/module/graphql'
import { Indexed } from '@/d2admin/delegate'
import agGridLocale from '@/module/components/ag-grid-locale'
import ExplorerFilter from './filter/explorer-filter.vue'
import ExplorerFilterPanel from './explorer-filter-panel.vue'
import { ExplorerType } from './explorer-type'
import _ from 'lodash'
import { FilterConditionView } from '@/module/components/lolth-filter/types'
import { isValid_ } from '@/module/common/filter-condition-extend'
import { exitIfError } from '@/module/common/util/error-util'
import LolthExplorerObjectLabelRenderer
  from '@/module/components/lolth-explorer/renderer/cmp-object-label-cell-renderer.vue'
import LolthStripeBgCellRenderer from '@/module/components/lolth-explorer/renderer/cmp-stripe-bg-cell-renderer.vue'
import LolthBoolCellRenderer from '@/module/components/lolth-explorer/renderer/cmp-bool-cell-renderer.vue'
import LolthDateCellRenderer from '@/module/components/lolth-explorer/renderer/cmp-date-cell-renderer.vue'
import LolthFilesCellRenderer from '@/module/components/lolth-explorer/renderer/cmp-files-cell-renderer.vue'
import LolthJsonCellRenderer from '@/module/components/lolth-explorer/renderer/cmp-json-cell-renderer.vue'
import LolthListItemCountCellRenderer
  from '@/module/components/lolth-explorer/renderer/cmp-list-item-count-cell-renderer.vue'
import LolthErrorInfoCellRenderer from '@/module/components/lolth-explorer/renderer/cmp-error-info-cell-renderer.vue'
import LolthQtyDetailsCellRenderer from '@/module/components/lolth-explorer/renderer/cmp-qty-details-cell-renderer.vue'
import LolthDiagnosisCellRenderer from '@/module/components/validation/cmp-diag-state-cell-renderer.vue'
import LolthTextFloatingFilter from '@/module/components/lolth-explorer/filter/cmp-text-floating-filter.vue'
import LolthBoolFloatingFilter from '@/module/components/lolth-explorer/filter/cmp-bool-floating-filter.vue'
import LolthEnumFloatingFilter from '@/module/components/lolth-explorer/filter/cmp-enum-floating-filter.vue'
import LolthFlagFloatingFilter from '@/module/components/lolth-explorer/filter/cmp-flag-floating-filter.vue'
import LolthTagsCellRenderer from '@/module/components/lolth-explorer/renderer/cmp-tags-cell-renderer.vue'
import LolthRgbColorCellRenderer from '@/module/components/lolth-explorer/renderer/cmp-rgb-color-cell-renderer.vue'
import LolthClickableCellRenderer from '@/module/components/lolth-explorer/renderer/cmp-clickable-cell-renderer.vue'
import ExplorerSettingPanel from '@/module/components/lolth-explorer/explorer-setting-panel.vue'
import LolthListItemConcatCellRenderer
  from '@/module/components/lolth-explorer/renderer/cmp-list-item-concat-cell-renderer.vue'
import LolthCollapseTextCellRenderer
  from '@/module/components/lolth-explorer/renderer/cmp-collapse-text-cell-renderer.vue'
import LolthMultiLineTextCellRenderer
  from '@/module/components/lolth-explorer/renderer/cmp-multi-line-text-cell-renderer.vue'
import LolthExtraCellRenderer from '@/module/components/lolth-explorer/renderer/cmp-extra-cell-renderer.vue'
import LolthIsNullCellRenderer from '@/module/components/lolth-explorer/renderer/cmp-is-null-cell-renderer.vue'
import { ApplyColumnStateParams } from 'ag-grid-community/dist/lib/columnController/columnApi'

//* **************************************************************************
// Types
//* **************************************************************************

export enum ExplorerScalarType {
  ID = 'ID',
  String = 'String',
  Boolean = 'Boolean',
  Int = 'Int',
  Float = 'Float',
  Date = 'Date',
  DateTime = 'DateTime',
  ValuedEnum = 'ValuedEnum',
  Json = 'Json',
  Map = 'Map',
  File = 'File'
}

export interface RowsPage {
  total: number,
  rows: any[]
}

export interface ICustomRendererCellRendererParams extends ICellRendererParams {
  explorerTypeKey?: string
}

//* **************************************************************************
// Util Functions
//* **************************************************************************

export function isIgnoredField(gqlField: any): boolean {
  let directive = getDirective('explorerIgnore', gqlField.directives)
  if (directive && !directive.fetch && !directive.column) return true

  return gqlField.args?.length > 0 // 有参数的field
}

export function isIgnoreColumnField(gqlField: any): boolean {
  let directive = getDirective('explorerIgnore', gqlField.directives)
  if (directive && !directive.column) return true

  if (gqlField.name === 'uid' || gqlField.name === 'ver') return true
  if (gqlField.type.kind === 'SCALAR' && // 没有指定customCellRenderer的Json字段不展示
    gqlField.type.name === 'Json' &&
    !getDirective('explorerCustomCellRenderer', gqlField.directives)) return true
  if (gqlField.type?.kind === 'LIST' || gqlField.type?.ofType?.kind === 'LIST') {
    // ToMany的field不展示
    let actualType = gqlField.type
    if (actualType.ofType) actualType = actualType.ofType
    if (actualType.ofType) actualType = actualType.ofType
    if (actualType.ofType) actualType = actualType.ofType
    return actualType.kind !== 'SCALAR'
  }
  return false
}

export function isIgnoreFetchField(gqlField: any): boolean {
  let directive = getDirective('explorerIgnore', gqlField.directives)
  if (directive && !directive.fetch) return true

  if (gqlField.type?.kind === 'LIST' || gqlField.type?.ofType?.kind === 'LIST') {
    // ToMany的field不展示
    let actualType = gqlField.type
    if (actualType.ofType) actualType = actualType.ofType
    if (actualType.ofType) actualType = actualType.ofType
    if (actualType.ofType) actualType = actualType.ofType
    return actualType.kind !== 'SCALAR'
  }
  return false
}

export function getDirective(name: string, directives: Indexed[]): Indexed | null {
  for (const directive of directives) {
    if (directive.name === name) {
      const args:Indexed = {}
      const gqlArgs = directive.args || []
      gqlArgs.forEach((gqlArg:any) => {
        args[gqlArg.name] = gqlArg.value
      })
      return args
    }
  }
  return null
}

//* **************************************************************************
// sync Explorer Type from server
//* **************************************************************************

export async function syncExplorerTypes(): Promise<{ [typeName: string]: ExplorerType }> {
  return axios.post('/graphql', {
    query: `query introspection {
      __schema {
        types {
          ...type description
          fields {
            name
            description
            args {
              name
            }
            type {
              ...type
              ofType {
                ...type
                ofType {
                  ...type
                  ofType {
                    ...type
                    ofType {
                      ...type
                      ofType {
                        ...type
                        ofType {
                          ...type
                        }
                      }
                    }
                  }
                }
              }
            }
            directives {
              name
              args {
                name,
                value
              }
            }
          }
          directives {
            name
            args {
              name
              value
            }
          }
        }
      }
    }
    fragment type on __Type {
      name kind
    }`
  }).then((response: any) => {
    exitIfError(response.data.errors)
    const gqlResult = response.data
    const types: { [typeName: string]: ExplorerType } = {}
    gqlResult.data.__schema.types.forEach((gqlType: any) => {
      // filter query/mutation/scalar
      if (gqlType.kind !== 'OBJECT' ||
        gqlType.name.startsWith('__') ||
        gqlType.name === 'Query' ||
        gqlType.name === 'Mutation' ||
        !(getDirective('bizType', gqlType.directives) ||
          getDirective('explorerNestedType', gqlType.directives))) return
      types[gqlType.name] = new ExplorerType(gqlType)
    })
    return types
  })
}

//* **************************************************************************
// Default AgGrid Options
//* **************************************************************************

export const DefaultAgGridClientSideDataOptions: GridOptions = Object.freeze({
  rowGroupPanelShow: 'onlyWhenGrouping',
  sideBar: {
    position: 'right',
    toolPanels: ['columns']
  },
  suppressDragLeaveHidesColumns: true,
  animateRows: true,
  localeText: agGridLocale,
  defaultColDef: {
    filter: 'explorerFilter',
    resizable: true,
    floatingFilter: true,
    minWidth: 100
  },
  scrollbarWidth: 10,
  enableRangeSelection: true,
  masterDetail: true,
  columnTypes: {
    rawColumn: {},
    stringColumn: {
      filter: 'explorerFilter',
      floatingFilterComponent: 'textFloatingFilter',
      flex: 1,
      sortable: true,
      enableValue: true,
      allowedAggFuncs: ['count', 'first', 'last'],
      chartDataType: 'category'
    },
    numberColumn: {
      filter: 'explorerFilter',
      floatingFilterComponent: 'textFloatingFilter',
      sortable: true,
      enableValue: true,
      allowedAggFuncs: ['count', 'sum', 'avg', 'max', 'min'],
      chartDataType: 'series'
    },
    dateColumn: {
      filter: 'explorerFilter',
      // TODO support floatingFilterComponent
      sortable: true,
      enableValue: true,
      allowedAggFuncs: ['count'],
      chartDataType: 'time'
    },
    booleanColumn: {
      filter: 'explorerFilter',
      floatingFilterComponent: 'boolFloatingFilter',
      sortable: true,
      cellRenderer: 'boolRenderer',
      enableValue: true,
      allowedAggFuncs: ['count']
    },
    tagsColumn: {
      filter: 'explorerFilter',
      // TODO support floatingFilterComponent
      cellRenderer: 'tagsRenderer'
    },
    valuedEnumColumn: {
      filter: 'explorerFilter',
      floatingFilterComponent: 'enumFloatingFilter',
      sortable: true
    },
    filesColumn: {
      filter: false,
      sortable: false,
      cellRenderer: 'filesRenderer',
      autoHeight: true
    },
    extraColumn: {
      filter: false,
      sortable: false,
      cellRenderer: 'extraRenderer',
      autoHeight: true
    }
  },
  frameworkComponents: {
    // panel
    explorerFilterPanel: ExplorerFilterPanel,
    explorerSettingPanel: ExplorerSettingPanel,
    // filter
    explorerFilter: ExplorerFilter,
    // floating filter
    textFloatingFilter: LolthTextFloatingFilter,
    boolFloatingFilter: LolthBoolFloatingFilter,
    enumFloatingFilter: LolthEnumFloatingFilter,
    flagFloatingFilter: LolthFlagFloatingFilter,
    // common cell renderers
    boolRenderer: LolthBoolCellRenderer,
    clickableRenderer: LolthClickableCellRenderer,
    collapseTextRenderer: LolthCollapseTextCellRenderer,
    dateRenderer: LolthDateCellRenderer,
    diagStateRenderer: LolthDiagnosisCellRenderer,
    errorInfoRenderer: LolthErrorInfoCellRenderer,
    extraRenderer: LolthExtraCellRenderer,
    filesRenderer: LolthFilesCellRenderer,
    isNullRenderer: LolthIsNullCellRenderer,
    jsonRenderer: LolthJsonCellRenderer,
    listItemConcatRenderer: LolthListItemConcatCellRenderer,
    listItemCountRenderer: LolthListItemCountCellRenderer,
    multiLineTextRenderer: LolthMultiLineTextCellRenderer,
    objectLabelRenderer: LolthExplorerObjectLabelRenderer,
    qtyDetailsRenderer: LolthQtyDetailsCellRenderer,
    rgbColorRenderer: LolthRgbColorCellRenderer,
    stripeBgRenderer: LolthStripeBgCellRenderer,
    tagsRenderer: LolthTagsCellRenderer
  },
  context: {
    lazyFieldFetched: new Set(),
    autoColumnWidthEnabled: true,
    autoRowHeightEnabled: false,
    keepSelectionOnPaging: false
  },
  onFirstDataRendered(event: FirstDataRenderedEvent) {
    // autoSizeAllColumns
    const context = this.context as any
    if (context.autoColumnWidthEnabled) {
      debounceAutoSizeColumns(event.columnApi)
    }
  },
  onViewportChanged(event: ViewportChangedEvent) {
    const context = this.context as any
    fetchLazyFieldDataForRow(context, event.firstRow, event.lastRow)
  },
  getRowNodeId: getRowId,
  suppressCopyRowsToClipboard: true,
  processCellForClipboard(params) {
    if (_.isString(params.value)) return params.value
    const gridDiv: HTMLElement = (params.column as any).gridOptionsWrapper.environment.eGridDiv
    return params.node.id
      ? gridDiv.querySelector(`[row-id="${params.node.id}"] > [col-id="${params.column.getColId()}"]`).textContent
      : gridDiv.querySelector(`[row-index="${params.node.rowIndex}"] > [col-id="${params.column.getColId()}"]`).textContent
  },
  getChartToolbarItems(params) {
    return ['chartSettings', 'chartData', 'chartFormat', 'chartDownload']
  }
})

function fetchLazyFieldDataForRow(context: any, firstRow: number, lastRow: number) {
  if (!context.lazyFieldDataFetcher) return
  for (let i = firstRow; i <= lastRow; i++) {
    if (context.lazyFieldFetched.has(i)) continue
    context.lazyFieldDataFetcher(i)
  }
}

export const DefaultAgGridServerSideDataOptions: GridOptions = Object.freeze(_.assign(
  _.cloneDeep(DefaultAgGridClientSideDataOptions), {
    rowModelType: 'serverSide',
    paginationPageSize: 50,
    cacheBlockSize: 50,
    onFirstDataRendered: null, // override ClientSide autoSizeColumns
    onPaginationChanged(event: PaginationChangedEvent) {
      // loading not finished, return
      const loadingStates = event.api.getCacheBlockState()
      if (!loadingStates || _.find(loadingStates, state => state.pageStatus !== 'loaded')) return

      // autoSizeAllColumns
      const context = _.get(this, 'context') as any
      if (context.autoColumnWidthEnabled) {
        debounceAutoSizeColumns(event.columnApi)
      }

      // 刷新/查询/排序等操作不能触发onViewportChanged, 加载完数据先触发一下
      if (!context.isDetailGrid) {
        fetchLazyFieldDataForRow(context, event.api.getFirstDisplayedRow(), event.api.getLastDisplayedRow())
      }

      // 翻页后不保留前一页的选择
      if (!context.keepSelectionOnPaging) {
        event.api.deselectAll()
      }
    }
  }))

const debounceAutoSizeColumns = _.debounce((columnApi: ColumnApi) => {
  columnApi.autoSizeAllColumns(false)
}, 100)

export function getRowId(data: any) {
  return data.id?.toString() || data.code || data.dataId || data.key
}

export function convertSortColumns(sortModel: SortColumn[]): ApplyColumnStateParams {
  let sortIdx = 1
  return {
    state: sortModel.map(sort => {
      return {
        colId: sort.field,
        sort: sort.sort === SortType.Asc ? 'asc' : 'desc',
        sortIndex: sortIdx++
      }
    }),
    defaultState: {
      sort: null,
      rowGroup: null
    }
  }
}

export function convertFilterColumn(filterConditions?: FilterConditionView[]) {
  const converted: { [field: string]: FilterConditionView } = {}
  if (!filterConditions) return converted
  filterConditions.forEach(cond => {
    if (cond.field) converted[cond.filterViewDef?.fieldKey || cond.field] = cond
  })
  return converted
}

/** 从and/or的conditions里面去掉invalid的filterCondition */
export function stripInvalidFilterCondition(condition: FilterConditionView): FilterConditionView {
  if (!condition.conditions) return condition
  condition.conditions.forEach(cond => {
    stripInvalidFilterCondition(cond)
  })
  _.remove(condition.conditions, cond => !isValid_(cond))

  return condition
}

export const FrameworkComponentsRegistry: Indexed = {}

export function suppressSelectAllEvent(gridOption: GridOptions) {
  return (params: SuppressKeyboardEventParams): boolean => {
    if (gridOption.rowSelection !== 'multiple') return false
    if (params.event.key !== 'a' || !(params.event.ctrlKey || params.event.metaKey)) return false
    params.event.preventDefault()
    params.api.forEachNode(node => node.setSelected(true, false, true))
    return true
  }
}
