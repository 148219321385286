



























































import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { GridOptions, IToolPanel, IToolPanelParams } from 'ag-grid-community'
import {
  FilterType,
  MdFieldFragmentDoc,
  MdpMdDataView,
  MdStructureLevel,
  MdStructureLevelTreeFragmentDoc
} from '@/module/graphql'
import { VueFrameworkComponentWrapper } from 'ag-grid-vue/lib/VueFrameworkComponentWrapper'
import _ from 'lodash'
import LolthExplorer from '@/module/components/lolth-explorer/index.vue'
import gql from 'graphql-tag'
import { ElTree } from 'element-ui/types/tree'
import { convertAttrValueToFilterCondition } from '@/module/master-data/md-util'
import util from '@/d2admin/libs/util'
import LocalDbDao from '@/module/common/local-db-dao'
import { stripFilterViewDef } from '@/module/butler/butler-util'
import MixinExplorerMdAttributeExtend
  from '@/module/master-data/views/components/explorer-support/mixin-explorer-md-attribute-extend'

declare interface IExplorerToolPanelParams extends IToolPanelParams {
  frameworkComponentWrapper: VueFrameworkComponentWrapper,
  explorer: LolthExplorer,
  gridOptions: GridOptions,
  attrExtend: MixinExplorerMdAttributeExtend,
  attributesField: string
}

@Component
export default class CmpMdpDataViewPanel extends Vue implements IToolPanel {
  @Ref() private readonly elTree!: ElTree<any, any> | any
  @Ref() private readonly elContextMenu!: any
  private params!: IExplorerToolPanelParams
  private mMdpMdDataView: MdpMdDataView = null
  private mTreeData: MdStructureLevel[] = []
  private mFilterText: string = ''
  private mContextMenuLevel: MdStructureLevel
  private mLoading = false
  private mSearchAll = false
  private mLevelOneNodes: any[] = []
  private debouncedFilter: Function

  created() {
    this.mMdpMdDataView = LocalDbDao.getCustomData('mdpMdDataView' + this.params.explorer.getExplorerViewModelName(), true)
    if (this.mMdpMdDataView) {
      this.getMdpMdDataViewRootLevels()
    }
    let vue = this
    this.debouncedFilter = _.debounce(() => {
      vue.mLevelOneNodes.splice(0)
      vue.elTree.filter(vue.mFilterText)
    }, 200)
  }

  getGui(): HTMLElement {
    return document.createElement('div')
  }

  refresh(): void {
    this.$forceUpdate()
  }

  onClick(level: MdStructureLevel) {
    this.params.explorer.setExternalFilterCondition(
      this.buildMergeFilterCondition(level))
    this.params.explorer.refresh()
  }

  buildMergeFilterCondition(level: MdStructureLevel) {
    let structureFilter
    if (level.key === 'all') {
      structureFilter = this.mMdpMdDataView.filterCondition
    } else {
      structureFilter = {
        filterType: FilterType.And,
        conditions: [this.mMdpMdDataView.filterCondition,
          ...convertAttrValueToFilterCondition(level.attributes).conditions
        ]
      }
    }
    if (this.params.explorer.fixedFilterCondition) {
      return {
        filterType: FilterType.And,
        conditions: [this.params.explorer.fixedFilterCondition, structureFilter]
      }
    } else {
      return structureFilter
    }
  }

  @Watch('mFilterText')
  private onFilterTextChanged(val: string) {
    this.debouncedFilter()
  }

  filterNode(value: string, level: MdStructureLevel, node: any) {
    if (!value) return true
    if (this.mSearchAll) {
      return level.label!.toLowerCase().indexOf(value.toLowerCase()) >= 0
    } else {
      if (node.level === 2 && level.label!.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
        this.mLevelOneNodes.push(node)
        return true
      } else {
        let match = false
        this.mLevelOneNodes.forEach((parentNode: any) => {
          if (this.isChildNode(parentNode, node)) {
            match = true
          }
        })
        return match
      }
    }
  }

  isChildNode(parentNode: any, childNode: any) {
    if (parentNode.childNodes.filter((node: any) => node.id === childNode.id).length > 0) return true
    else {
      let match = false
      parentNode.childNodes.forEach((node: any) => {
        if (this.isChildNode(node, childNode)) {
          match = true
        }
      })
      return match
    }
  }

  onContextMenuClick(e: MouseEvent, level: MdStructureLevel) {
    this.mContextMenuLevel = level
    this.elContextMenu.handleReferenceContextmenu(e)
  }

  onMdpMdDataViewChange() {
    if (!this.mMdpMdDataView) {
      this.params.explorer.setExternalFilterCondition(null)
      this.params.explorer.setContextParam('treeData', null)
      this.params.explorer.refresh()
      this.mTreeData = []
      LocalDbDao.saveCustomData('mdpMdDataView' + this.params.explorer.getExplorerViewModelName(), null, true)
    } else {
      LocalDbDao.saveCustomData('mdpMdDataView' + this.params.explorer.getExplorerViewModelName(), this.mMdpMdDataView, true)
      this.params.explorer.setContextParam('updateColumn', false)
      this.getMdpMdDataViewRootLevels()
    }
  }

  async getMdpMdDataViewRootLevels() {
    this.mLoading = true
    this.mMdpMdDataView = await this.$apollo.query({
      query: gql`query {
        MdpMdDataView(id: ${this.mMdpMdDataView.id}) {
            id ver name filterCondition displayMdFields {
              ...mdField
            } viewMdFields {
              ...mdField
            }
        }
      }
      ${MdFieldFragmentDoc}`
    }).then(data => {
      return data.data.MdpMdDataView
    })
    const additionalFilter = _.clone(this.params.explorer.fixedFilterCondition)
    stripFilterViewDef(additionalFilter)
    this.$apollo.query({
      query: gql`query getMdpMdDataViewRootLevels($viewId: ID!, $additionalFilter: FilterCondition) {
        getMdpMdDataViewRootLevels(viewId: $viewId, additionalFilter: $additionalFilter) {
            ... mdStructureLevelTree
        }
      }
      ${MdStructureLevelTreeFragmentDoc}`,
      variables: {
        viewId: this.mMdpMdDataView.id,
        additionalFilter: additionalFilter
      }
    }).then(data => {
      this.mTreeData = [
        {
          key: 'all',
          label: '全部',
          groupKey: '',
          attributes: [],
          children: data.data.getMdpMdDataViewRootLevels.children,
          structureCount: data.data.getMdpMdDataViewRootLevels.children.reduce(
            (sum:number, level:any) => sum + level.structureCount, 0)
        }
      ]
      this.mLoading = false
      this.params.explorer.setExternalFilterCondition(this.mMdpMdDataView.filterCondition)
      if (!this.mMdpMdDataView.displayMdFields || this.mMdpMdDataView.displayMdFields.length === 0) {
        this.params.explorer.refresh()
      } else {
        if (!this.params.explorer.getContextParam('updateColumn')) {
          this.params.explorer.setContextParam('updateColumn', true)
          this.updateColumns()
        }
      }
    })
  }

  labelFieldParts(label: string) {
    const parts = label.split(':')
    if (parts.length > 1) return parts
    else return [parts[0], '']
  }

  updateColumns() {
    this.params.attrExtend.updateColumns(this.params.gridOptions.columnDefs,
      this.mMdpMdDataView.displayMdFields, this.params.attributesField)
  }

  openMdDataViewPage() {
    util.views.openTab(this, { path: '/mdp/md-data-view' })
  }
}
